import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../stores/reducers';
import { getTime } from '../../../utils/time';
import { getProperImage } from '../../../utils/commonFunc';
import { isTcBrand } from '../../../constants/structureTypes';
import { PLUS } from '../../../constants/text';
import { shouldShowTime } from '../../../stores/schemas/utils';

import PlayIcon from '../../../assets/icons/CardPlayerButton.svg';
import PlayIconShadow from '../../../assets/icons/CardShadow.svg';
import Label from '../../Label/Label';
import NoImage from '../../../assets/images/placeholder.png';

import './Card.scss';

interface ImageSrc {
  width?: string;
  height?: string;
  url: string;
}

type CardProps = {
  width: number | string;
  height: number;
  selected?: number;
  src?: Array<ImageSrc>;
  index?: number;
  alt?: string;
  title?: string;
  titleContainerStyles?: object;
  titleStyles?: object;
  isLiveVideo?: string;
  season?: string;
  duration?: string;
  logo?: {
    width: string;
    height: string;
    text: string;
    url: string;
  };
  first?: boolean;
  last?: boolean;
  cityDescription?: string;
  TCPlusContent?: boolean;
  cardId: string;
};

const Card = (props: CardProps) => {
  const {
    width,
    height,
    src = [],
    title,
    selected,
    index,
    titleContainerStyles,
    titleStyles,
    isLiveVideo = '',
    duration,
    alt,
    logo,
    TCPlusContent,
  } = props;

  const spoilerPreventionStatus = useSelector((state: RootState) => state.common.isSpoilerPreventionEnabled);
  const features = useSelector((state: RootState) => state.config.features);

  const [optimizedImage, setOptimizedImage] = useState(getProperImage(src, width as number, height));

  const time = duration ? getTime(duration, 'seconds') : null;

  const getImageUrl = () => {
    setOptimizedImage({ url: NoImage });
  };

  const convertToBoolean = (value: string): any => {
    if (!value) {
      return false;
    }

    return JSON.parse(value.toLowerCase()) && (
      <span className={`${isTcBrand && 'tc'}`}>
        LIVE
      </span>);
  };

  const showTime = shouldShowTime(spoilerPreventionStatus, features.spoiler_prevention, TCPlusContent);

  return (
    <div
      style={{
        width,
        height,
      }}
      className={`card-main ${!src.length && !alt && !logo ? 'card-season' : ''}`}
    >
      {TCPlusContent && <Label style={{right: 0}} title={PLUS}/>}
      {src.length ? (
        <>
          <img
            aria-hidden={true}
            style={{
              width,
              height,
            }}
            className={`card-image ${isTcBrand && 'tc'}`}
            src={optimizedImage?.url}
            alt={alt}
            onError={getImageUrl}
          />
          <div style={titleContainerStyles} className='card-title-container'>
            <p
              style={titleStyles}
              className='card-title'
              aria-hidden={true}
            >
              {convertToBoolean(isLiveVideo)}
              {title}
            </p>
          </div>

          {logo?.url && (
            <img
              aria-hidden={true}
              className='card-logo'
              alt='logo'
              src={logo.url}
              width={logo.width}
              height={logo.height}
            />
          )}
        </>
      ) : (
        <div
          className='season-item-wrapper'
          style={{
            width,
            height,
          }}
          tabIndex={index}
          key={index}
        >
          <span
            className={`season-item ${selected === index ? 'selectedSeason' : ''}`}
          >
            {title}
          </span>
        </div>
      )}
      {time && (
        <>
          <img
            className='card-play-image-btn'
            src={PlayIcon}
            alt="card play icon"
            aria-hidden={true}
          />
          <img
            className='card-play-image-shadow'
            src={PlayIconShadow}
            alt="shadow of play button icon"
            aria-hidden={true}
          />
          {showTime && <p className='card-play-time'>{time}</p>}
        </>
      )}
    </div>
  );
};

export default React.memo(Card);
