import React from 'react';
import { useSelector } from 'react-redux';
import { onEnterHandler } from '../../utils';
import { RootState } from '../../stores/reducers';

import './Checkbox.scss';

type CheckboxProps = {
  index?: number;
  checked?: boolean;
  onChangeCb: (string) => void;
  focusLeft?: string;
  focusRight?: string;
  focusDown?: string;
  focusUp?: string;
}

const Checkbox = (props: CheckboxProps) => {
  const {
    index = 0,
    checked,
    onChangeCb,
    focusLeft,
    focusRight,
    focusDown,
    focusUp,
  } = props;

  const inputStyles = useSelector((state: RootState) => state.config.styles?.general?.input);

  const handleKeyDown = (e) => {
    onEnterHandler(e, () => {
      e.preventDefault();
      onChangeCb(!checked);
    });
  };

  const checkboxGetFocused = (e) => {
    e.target.querySelector('.checkmark').style.border = `3px solid ${inputStyles?.borderColorActive}`;
  };

  const checkboxGetBlur = (e) => {
    e.target.querySelector('.checkmark').style.border = `3px solid ${inputStyles?.borderColor}`;
  };

  return (
    <div
      // eslint-disable-next-line
      role=''
      tabIndex={0}
      className={`checkbox-container checkbox-acc-${index}`}
      data-tv-focus-left={focusLeft ? focusLeft : `.checkbox-acc-${index - 1}`}
      data-tv-focus-right={focusRight ? focusRight : `.checkbox-acc-${index + 1}`}
      data-tv-focus-down={focusDown ? focusDown : `.checkbox-acc-${index + 1}`}
      data-tv-focus-up={focusUp ? focusUp : `.checkbox-acc-${index - 1}`}
      onFocus={checkboxGetFocused}
      onBlur={checkboxGetBlur}
      onKeyDown={handleKeyDown}
    >
      <input
        type='checkbox'
        checked={checked}
      />
      <span
        className='checkmark'
        style={{ border: `3px solid ${inputStyles?.borderColor}` }}
      />
    </div>
  );
};

export default Checkbox;
