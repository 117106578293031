import { applyMiddleware, createStore, Action } from 'redux';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
// import logger from 'redux-logger';

import { RootState } from './reducers';

export default (rootReducer: any, rootSaga: any) => {
  const middleware: Array<SagaMiddleware<object>> = [];
  const enhancers: Array<any> = [];
  const sagaMiddleware = createSagaMiddleware();

  middleware.push(sagaMiddleware);
  // middleware.push(logger);
  enhancers.push(applyMiddleware(...middleware));

  const store = createStore<RootState, Action, {}, {}>(rootReducer, composeWithDevTools(...enhancers));

  sagaMiddleware.run(rootSaga);

  return { store };
};
