import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import focusService from '../../../services/focusService';
import {
  BACK_BTN_TEXT, RESET_PASSWORD,
  TC_PLUS_RESET_PASSWORD_BTN_TEXT,
  TC_PLUS_PAYWALL_SIGN_IN_BTN,
  PRIVACY_POLICY_SCREEN,
  TERMS_OF_USE_SCREEN, SIGN_IN,
  TC_PLUS_PAYWALL_SIGN_OUT_BTN,
} from '../../../constants/text';
import { isTcBrand } from '../../../constants/structureTypes';
import { RootState } from '../../../stores/reducers';
import { formatTimeFromCleeng } from '../../../utils/time';
import { getCustomStyledSymbol } from '../../../utils/ui';
import { onEnterHandler } from '../../../utils';

import Button from '../../Button/Button';
import backArrowIcon from '../../../assets/icons/GoBackArrow.svg';

import './PlusPaywallScreen.scss';

interface IPaywallProps {
  availableSubscriptions: any;
  goBack?: () => void;
  signOutFromTCPlus: () => void;
  isFlowFromPlayback: boolean;
  setCurrPageType: (pageType: string) => void;
  isFlowFromCreateAccount: boolean;
  buySubscription: (id: string) => void;
  initFocusedEl: string;
  goToHomePage: () => void;
  updateFocusedElInHistory: (focusedEl?: string) => void;
}

const PlusPaywallScreen = (props: IPaywallProps) => {

  const {
    availableSubscriptions,
    goBack,
    signOutFromTCPlus,
    isFlowFromPlayback,
    setCurrPageType,
    buySubscription,
    initFocusedEl,
    updateFocusedElInHistory,
  } = props;

  const logoPlus = useSelector((state: RootState) => state.config.styles?.general?.logoPlus);
  const subscribeStyles = useSelector((state: RootState) => state.config.styles?.subscribe);
  const focusTOSColor = useSelector((state: RootState) => state.config.styles?.colors?.accents?.primary);
  const headingStyles = useSelector((state: RootState) => state.config.styles?.general?.heading);
  const errorColor = useSelector((state: RootState) => state.config.styles?.['general']?.['failureColor']);
  const paywallTitleFromPlayback = useSelector((state: RootState) => state.config.subscription['paywall_title']);
  const paywallSignInTitle = useSelector((state: RootState) => state.config.subscription['title_signed_in']);
  const paywallSignOutTitle = useSelector((state: RootState) => state.config.subscription['title_signed_out']);
  const paywallSignInDetails = useSelector((state: RootState) => state.config.subscription['detail_signed_in']);
  const paywallSignOutDetails = useSelector((state: RootState) => state.config.subscription['detail_signed_out']);
  const subscribeTOS = useSelector((state: RootState) => state.config.subscription['subscribe_tos']);
  const isTCUserLogin = useSelector((state: RootState) => state.Login.isUserTCLogin);
  const isCurrentSubscriptionActive = useSelector(
    (state: RootState) => state.cleengService.isCurrentSubscriptionActive
  );
  const currentSubscriptionDetails = useSelector((state: RootState) => state.cleengService.currentSubscriptionDetails);
  const cancelTextForPlatforms = useSelector((state: RootState) => state.config.subscription['payment_gateways']);

  const [isPageInfoAnnounced, setIsPageInfoAnnounced] = useState<boolean>(false);

  const isTCUserHasActiveSubscription = useMemo(
    () => isTCUserLogin && currentSubscriptionDetails?.offerId,
    [isTCUserLogin, currentSubscriptionDetails?.offerId]);

  const isTCUserHasExpiredSubscription = useMemo(
    () => !isCurrentSubscriptionActive && isTCUserLogin && !!currentSubscriptionDetails?.offerId,
    [isTCUserLogin, currentSubscriptionDetails?.offerId, isCurrentSubscriptionActive]);

  const paywallTitle = useMemo(() => {
    return isFlowFromPlayback
      ? paywallTitleFromPlayback
      : (isTCUserHasActiveSubscription || isTCUserHasExpiredSubscription)
        ? paywallSignInTitle : paywallSignOutTitle;
  }, [isFlowFromPlayback, isTCUserLogin, isTCUserHasActiveSubscription, isTCUserHasExpiredSubscription]);

  const paywallDescription = useMemo(() => {
    return (isTCUserHasActiveSubscription || isTCUserHasExpiredSubscription)
      ? paywallSignInDetails
      : paywallSignOutDetails;
  }, [isTCUserHasActiveSubscription, paywallSignOutDetails, paywallSignOutDetails, isTCUserHasExpiredSubscription]);

  const cancelText = useMemo(() => {
    return cancelTextForPlatforms.find(
      item => item.payment_gateway === currentSubscriptionDetails?.paymentGateway)?.cancel_text;
  }, [cancelTextForPlatforms, currentSubscriptionDetails?.paymentGateway]);

  const signInOutBtnText = useMemo(() => {
    return isTCUserLogin ? TC_PLUS_PAYWALL_SIGN_OUT_BTN : TC_PLUS_PAYWALL_SIGN_IN_BTN;
  }, [isTCUserLogin]);

  const subscriptionGetFocused = (e) => {
    e.target.style.border = `2px solid ${subscribeStyles?.itemFocusLineColor}`;
    e.target.style.background = subscribeStyles?.itemBackgroundColorFocus;
    e.target.style.color = subscribeStyles?.itemFocusTextColor;
  };

  const subscriptionGetBlurred = (e) => {
    e.target.style.border = `2px solid rgb(67, 77, 85)`;
    e.target.style.background = subscribeStyles?.itemBackgroundColor;
    e.target.style.color = subscribeStyles?.itemTextColor;
    setIsPageInfoAnnounced(true);
  };

  const agreementTextGetFocused = e => {
    e.target.style.color = focusTOSColor;
  };

  const agreementTextGetBlured = e => {
    e.target.style.color = subscribeStyles?.textColor;
  };

  const getTTSTextForUserWithActiveSubscription = (buttonText) => {
    return `${isTCUserHasActiveSubscription
      ? isPageInfoAnnounced
        ? ''
        : `${paywallTitle}. ${paywallDescription}.
          Subscription details: Subscription type: ${currentSubscriptionDetails?.title}.
          Billing amount: ${currentSubscriptionDetails?.nextPaymentPrice}$${currentSubscriptionDetails?.pricing_period}.
          Next payment due: ${formatTimeFromCleeng(currentSubscriptionDetails?.nextPaymentAt)}.
          Not Satisfied? ${cancelText}`
      : ''}${buttonText}`;
  };

  const getTTSTextForUserWithExpiredOrNoSubscription = (subDetails) => {
    return `${isPageInfoAnnounced
      ? ''
      : `${paywallTitle}. ${paywallDescription}.
      ${isTCUserHasExpiredSubscription ? `Your current TC Plus subscription is Expired.
        Expired ${formatTimeFromCleeng(currentSubscriptionDetails?.expiresAt, 'MMM DD, YYYY')}` : ''}.`}
    ${subDetails?.title}. ${subDetails?.description}.${subDetails?.price}$.${subDetails?.promo || ''}
    ${isPageInfoAnnounced ? '' : subscribeTOS}`;
  };

  useEffect(() => {
    const initFocusedItemSelector = initFocusedEl
      ? `#${initFocusedEl}`
      : ((isTCUserLogin && isCurrentSubscriptionActive) || (!isTCUserLogin && !availableSubscriptions?.length))
        ? '.btn-acc-0'
        : '.subscription-0';

    focusService.setFocus(document.querySelector(initFocusedItemSelector));
  }, [availableSubscriptions.length, isTCUserLogin, isCurrentSubscriptionActive]);

  return (
    <div
      className='paywall-screen'
      style={{
        backgroundImage: 'url(' + subscribeStyles?.paywallBackground + ')',
        color: subscribeStyles?.textColor,
      }}>
      <div className='paywall-screen-details-wrapper'>
        {isTCUserHasActiveSubscription && <img
          className='small-logo'
          src={logoPlus}
          alt='Tennis Channel Logo'
        />}
        <h1
          className='paywall-title'
          style={{
            color: subscribeStyles?.textColor,
            fontFamily: headingStyles?.titleFont,
          }}
        >
          {paywallTitle}
          {isTcBrand && getCustomStyledSymbol('.', headingStyles.accentColor)}
        </h1>
        <p
          className='paywall-details'
        >
          {paywallDescription}
        </p>
        {(!isCurrentSubscriptionActive && isTCUserLogin && !!currentSubscriptionDetails?.offerId) &&
        (<p className='expired-subscription-details'>
          <span>Your current TC Plus subscription is</span>
          <span
            className='expired-type-label'
            style={{background: errorColor}}
          >Expired</span>
          <p className='expired-date'>
            {`Expired ${formatTimeFromCleeng(currentSubscriptionDetails?.expiresAt, 'MMM DD, YYYY')}`}
          </p>
        </p>)}
        {(!isTCUserLogin || (isTCUserLogin && currentSubscriptionDetails?.offerId)) &&
        (<Button
          title={signInOutBtnText}
          index={0}
          onKeyDown={(e: React.KeyboardEvent<HTMLElement>): void => onEnterHandler(e, isTCUserLogin
            ? () => {
              signOutFromTCPlus();
              setIsPageInfoAnnounced(false);
            }
            : setCurrPageType(SIGN_IN))}
          focusUp='.btn-acc-0'
          focusDown='.btn-acc-1'
          focusLeft='.btn-acc-0'
          focusRight=''
          btnID={'plus-paywall-sign-in-out-btn'}
          TTSText={getTTSTextForUserWithActiveSubscription(signInOutBtnText)}
          onBlurFunc={() => setIsPageInfoAnnounced(true)}
        />)}
        <Button
          title={BACK_BTN_TEXT}
          onKeyDown={goBack}
          focusUp='.btn-acc-0'
          focusDown='.btn-acc-1'
          focusLeft='.btn-acc-1'
          focusRight='.subscription-0'
          index={1}
          btnID={'plus-paywall-go-back-btn'}
        >
          <>
            <img src={backArrowIcon} alt='back arrow' style={{marginRight: '16px'}}/>
          </>
        </Button>
      </div>
      {(isTCUserLogin && isCurrentSubscriptionActive)
        ? (<div className='subscription-details-wrapper'>
          <div className='subscription-details-title'>
            <span className='subscription-details-title-text'>Subscription details</span>
            <span className='subscription-details-title-line'></span>
          </div>
          <div className='subscription-details-item'>
            <span>Subscription type</span>
            <span
              className='subscription-type-value'
              style={{background: subscribeStyles?.badgeColor}}
            >{currentSubscriptionDetails?.title || 'Active'}</span>
          </div>
          <div className='subscription-details-item'>
            <span>Billing amount</span>
            <span>
              {currentSubscriptionDetails?.nextPaymentPrice && (
                <span className='bold'>{`$${currentSubscriptionDetails?.nextPaymentPrice}`}</span>
              )}
              {currentSubscriptionDetails?.pricing_period && currentSubscriptionDetails?.nextPaymentPrice && (
                <span>{`/ ${currentSubscriptionDetails?.pricing_period}`}</span>
              )}
            </span>
          </div>
          <div className='subscription-details-item'>
            <span>Next payment due</span>
            {currentSubscriptionDetails?.nextPaymentAt && (
              <span className='bold'>
                {formatTimeFromCleeng(currentSubscriptionDetails?.nextPaymentAt)}
              </span>
            )}
          </div>
          <div className='button-wrapper'>
            <span className='button-wrapper-title'>My password</span>
            <Button
              title={TC_PLUS_RESET_PASSWORD_BTN_TEXT}
              index={2}
              focusUp='.btn-acc-2'
              focusDown='.btn-acc-2'
              focusLeft='.btn-acc-0'
              focusRight='.btn-acc-2'
              onKeyDown={() => setCurrPageType(RESET_PASSWORD)}
              TTSText={`My password. ${TC_PLUS_RESET_PASSWORD_BTN_TEXT}`}
              btnID={'plus-paywall-reset-password-btn'}
            />
          </div>
          <div className='button-wrapper'>
            <span className='button-wrapper-title'>Not Satisfied?</span>
            <span className='cancel-text'>{cancelText}</span>
          </div>
        </div>)
        : (<div className='subscription-selection-wrapper'>
          <div className='logo-and-lines-wrapper'>
            <div className='line'></div>
            <img
              className='big-logo'
              src={logoPlus}
              alt='Tennis Channel Logo'
            />
            <div className='line'></div>
          </div>
          <div
            className='subscription-types-wrapper'
          >
            {availableSubscriptions.map((sub, index) => {
              return (
                <div
                  key={index}
                  id={`plus-paywall-subscription-item-${index}`}
                  className={`subscription-wrapper subscription-${index}`}
                  tabIndex={0}
                  // eslint-disable-next-line
                  role=''
                  aria-label={getTTSTextForUserWithExpiredOrNoSubscription(sub)}
                  style={{
                    background: subscribeStyles?.itemBackgroundColor,
                    color: subscribeStyles?.itemTextColor,
                  }}
                  onFocus={subscriptionGetFocused}
                  onBlur={subscriptionGetBlurred}
                  onKeyDown={(e: React.KeyboardEvent<HTMLElement>): void => {
                    onEnterHandler(e, () => {
                      updateFocusedElInHistory(document.activeElement?.id);
                      buySubscription(sub.id);
                    });
                  }}
                  data-tv-focus-left={index === 0 ? `.btn-acc-1` : `.subscription-${index - 1}`}
                  data-tv-focus-right={
                    index === availableSubscriptions.length - 1
                      ? `.subscription-${index}`
                      : `.subscription-${index + 1}`
                  }
                  data-tv-focus-down={`.pp-text`}
                  data-tv-focus-up={`.subscription-${index}`}
                >
                  <p className='subscription-title' style={{fontFamily: headingStyles?.titleFont}}>{sub?.title}</p>
                  <p className='subscription-details'>{sub?.description}</p>
                  <p className='subscription-price' style={{fontFamily: headingStyles?.titleFont}}>
                    <span>{`$${sub?.price}`}</span>
                    <span className='price-period'>{`/${sub?.pricing_period_shortcut}`}</span>
                  </p>
                  {sub?.promo &&
                    <div
                      style={{background: subscribeStyles?.badgeColor}}
                      className='best-value-label'
                    >
                      {sub.promo}
                    </div>
                  }
                </div>
              );
            })}
          </div>
          <div
            className='subscribe-tos'
          >
            <p>{subscribeTOS}</p>
            <p>By continuing you agree to our&nbsp;
              <span
                className='underlined-text pp-text'
                tabIndex={0}
                id='plus-paywall-pp-text'
                data-tv-focus-left={'.btn-acc-1'}
                data-tv-focus-right={'.tos-text'}
                data-tv-focus-down={'.pp-text'}
                data-tv-focus-up={'.subscription-0'}
                onFocus={agreementTextGetFocused}
                onBlur={agreementTextGetBlured}
                onKeyDown={
                  (e: React.KeyboardEvent) => onEnterHandler(e, () => setCurrPageType(PRIVACY_POLICY_SCREEN))
                }
              >
                Privacy Policy
              </span>
              &nbsp;&&nbsp;
              <span
                className='underlined-text tos-text'
                tabIndex={0}
                id='plus-paywall-tos-text'
                data-tv-focus-left={'.pp-text'}
                data-tv-focus-right={'.tos-text'}
                data-tv-focus-down={'.tos-text'}
                data-tv-focus-up={`.subscription-${availableSubscriptions.length - 1}`}
                onFocus={agreementTextGetFocused}
                onBlur={agreementTextGetBlured}
                onKeyDown={
                  (e: React.KeyboardEvent) => onEnterHandler(e, () => setCurrPageType(TERMS_OF_USE_SCREEN))
                }
              >Terms of Use
              </span>.
            </p>
          </div>
        </div>
        )}
    </div>
  );
};

export default PlusPaywallScreen;
