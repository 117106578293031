import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../stores/reducers';
import { LIVE, PLUS } from '../../constants/text';

import './Label.scss';

interface ILabelProps {
  title: string;
  style: {
    left?: number | string;
    right?: number;
    top?: number | string;
    width?: string;
    height?: string;
  };
}

const Label = (props: ILabelProps) => {

  const {
    title,
    style,
  } = props;
  const plusLabelBgColor = useSelector((state: RootState) => state.config.styles.teaser.streamTagColor);
  const liveLabelBgColor = useSelector((state: RootState) => state.config.styles.epg.cellLiveTagBgColor);

  const labelBgColor = {
    [LIVE]: liveLabelBgColor,
    [PLUS]: plusLabelBgColor,
  };

  return (
    <div
      className='label-content'
      style={{
        ...style,
        backgroundColor: labelBgColor[title],
      }}
    >{title}</div>
  );
};

export default React.memo(Label);
