import React, { useEffect, useRef, useState } from 'react';

import focusService from '../../../services/focusService';
import { onEnterHandler } from '../../../utils';
import useEventListener from '../../../hooks/eventListener';
import { useSelector } from '../../../stores';
import { isTcBrand } from '../../../constants/structureTypes';
import { MVPD_SIGN_IN_BTN_TEXT, MVPD_SELECT_PROVIDER_BTN_TEXT,
  BACK_BTN_TEXT, MVPD_SIGN_OUT_BTN_TEXT } from '../../../constants/text';
import { getCustomStyledSymbol } from '../../../utils/ui';
import Button from '../../Button/Button';
import backArrowIcon from '../../../assets/icons/GoBackArrow.svg';

import './MvpdPaywallScreen.scss';

interface IMvpdPaywallScreen {
  isFlowFromPlayback?: boolean;
  signOutOfProvider: () => void;
  showRegCode: () => void;
  handleBack: () => void;
}

const MvpdPaywallScreen = (props: IMvpdPaywallScreen) => {
  const {
    isFlowFromPlayback = false,
    signOutOfProvider,
    showRegCode,
    handleBack,
  } = props;

  const labelBgColor = useSelector((state) => state.config.styles?.settings?.badgeColor);
  const mvpdText = useSelector((state) => state.config.mvpd['mvpd_text']);
  const mvpdProviderLogo = useSelector((state) => state.Login.mvpd.mvpdLogo);
  const mvpdAuthToken = useSelector((state) => state.Login.mvpd.authTokenMVPD);
  const mvpdTextSettings = useSelector((state) => state.config.allConfigs['API']['settings']['tv_provider']);
  const headingStyles = useSelector((state) => state.config.styles.general?.heading);
  const mvpdProviderTitle = useSelector((state) => state.Login.mvpd.mvpdId);

  const [isPageInfoAnnounced, setIsPageInfoAnnounced] = useState<boolean>(true);

  const firstButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (firstButtonRef.current) {
      focusService.setFocus(firstButtonRef.current);
    }
  }, []);

  const getButtons = () => {
    const accountFlowLoggedOut = !isFlowFromPlayback && !mvpdAuthToken;
    const signInButtonTitle = accountFlowLoggedOut ? MVPD_SIGN_IN_BTN_TEXT : MVPD_SELECT_PROVIDER_BTN_TEXT;
    const headingText = isFlowFromPlayback ? mvpdText['paywall_title'] : mvpdTextSettings['title'];
    const headingDetailsAccount = mvpdAuthToken ?
      mvpdTextSettings['detail_signed_in'] : mvpdTextSettings['detail_signed_out'];
    const headingDetails = isFlowFromPlayback ? mvpdText['paywall_detail'] : headingDetailsAccount;

    let buttons = [
      {
        title: signInButtonTitle,
        TTSText: `${isPageInfoAnnounced ? `${headingText}. ${headingDetails}` : ''}. ${signInButtonTitle}`,
        name: 'mvpd-btn-sign-in',
      },
      {
        title: BACK_BTN_TEXT,
        TTSText: `${isPageInfoAnnounced ?
          `${mvpdAuthToken ? `${headingText}. ${headingDetailsAccount}. ${mvpdProviderTitle}.` : ''}` : ''}
         ${BACK_BTN_TEXT}`,
        name: 'mvpd-btn-go-back',
      },
    ];

    if (mvpdAuthToken) {
      const backButton = buttons.pop()!;

      buttons = [
        backButton,
        {
          title: MVPD_SIGN_OUT_BTN_TEXT,
          TTSText: MVPD_SIGN_OUT_BTN_TEXT,
          name: 'mvpd-btn-sign-out',
        },
      ];
    }

    return buttons;
  };

  useEventListener('keydown', (e) => {
    onEnterHandler(e, () => {
      switch (e.target.id) {
        case 'mvpd-btn-sign-out':
          signOutOfProvider();
          break;
        case 'mvpd-btn-sign-in':
          showRegCode();
          break;
        case 'mvpd-btn-go-back':
          handleBack();
          break;
        default:
          break;
      }
    });
  });

  return (
    <div className='mvpd-main-paywall-container'>
      <h2
        style={{ fontFamily: headingStyles?.titleFont }}
        className='mvpd-main-paywall-text'
      >
        {isFlowFromPlayback ? mvpdText['paywall_title'] : mvpdTextSettings['title']}
        {isTcBrand && getCustomStyledSymbol('.', headingStyles.accentColor)}
      </h2>
      <p className='mvpd-secondary-paywall-text'>
        {isFlowFromPlayback ? mvpdText['paywall_detail'] : mvpdAuthToken ?
          mvpdTextSettings['detail_signed_in'] : mvpdTextSettings['detail_signed_out']}
      </p>
      {mvpdProviderLogo && (
        <div
          className='mvpd-provider-logo-container'
          style={{ background: `${labelBgColor}` }}
        >
          <img src={mvpdProviderLogo} alt='provider logo' />
        </div>
      )}
      {getButtons().map((button, index) => (
        <Button
          title={button.title}
          key={button.title}
          TTSText={button.TTSText}
          ref={index === 0 ? firstButtonRef : null}
          btnID={button.name}
          onBlurFunc={() => setIsPageInfoAnnounced(false)}
        >
          <>
            {button.name === 'mvpd-btn-go-back' && (
              <img src={backArrowIcon} alt='back arrow' style={{ marginRight: '17px' }} />
            )}
          </>
        </Button>
      ))}
    </div>
  );
};

export default MvpdPaywallScreen;
