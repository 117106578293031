import * as actionTypes from '../actions/types/OTTSettings';
import { Action, ISettingsPayload } from '../actions/OTTSettings';

interface OTTSettingsState {
  settingsData: ISettingsPayload;
  components: ISettingsPayload;
  fetched: boolean;
  hasError: boolean;
  error: string;
  isLoading: boolean;
}

const initialState: OTTSettingsState = {
  settingsData: [],
  components: [],
  fetched: false,
  hasError: false,
  error: '',
  isLoading: false,
};

const OTTSettings = (state = initialState, action: Action): OTTSettingsState => {
  switch (action.type) {
    case actionTypes.LOAD_OTT_SETTINGS_PAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.LOAD_OTT_SETTINGS_PAGE_DATA_SUCCEEDED:
      return {
        ...state,
        components: action.payload,
        fetched: true,
        hasError: false,
        isLoading: false,
      };

    case actionTypes.LOAD_OTT_SETTINGS_PAGE_DATA_FAILED:
      return {
        ...state,
        fetched: false,
        hasError: true,
        error: action.error,
        isLoading: false,
      };

    case actionTypes.LOAD_OTT_SETTINGS_DATA:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.LOAD_OTT_SETTINGS_DATA_SUCCEEDED:
      return {
        ...state,
        settingsData: action.payload,
        fetched: true,
        hasError: false,
        isLoading: false,
      };

    case actionTypes.LOAD_OTT_SETTINGS_DATA_FAILED:
      return {
        ...state,
        fetched: false,
        hasError: true,
        error: action.error,
        isLoading: false,
      };

    case actionTypes.CLEAR_OTT_SETTINGS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default OTTSettings;
