import { takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../actions/types/common';
import { updateSessionDataInLocalStorage } from '../../utils/session';

function* commonWatcher() {
  yield takeLatest(
    [
      actionTypes.SET_STATION,
      actionTypes.REMOVE_STATION,
      actionTypes.SET_CITY,
      actionTypes.REMOVE_CITY,
    ],
    updateSessionDataInLocalStorage
  );
}

export default commonWatcher;
