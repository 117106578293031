export const CAPTIONS_SETTINGS_KEYS = {
  IS_ENABLED: 'IS_ENABLED',
  BACKGROUND_COLOR: 'BACKGROUND_COLOR',
  BACKGROUND_OPACITY: 'BACKGROUND_OPACITY',
  FONT_COLOR: 'FONT_COLOR',
  FONT_OPACITY: 'FONT_OPACITY',
  FONT_SIZE: 'FONT_SIZE',
  FONT_STYLE: 'FONT_STYLE',
  WINDOW_COLOR: 'WINDOW_COLOR',
  WINDOW_OPACITY: 'WINDOW_OPACITY',
  EDGE_COLOR: 'EDGE_COLOR',
  EDGE_TYPE: 'EDGE_TYPE',
};

export const CCStyles = {
  backgroundColor: {
    0: 'black',
    1: 'white',
    2: 'black',
    3: 'red',
    4: 'green',
    5: 'blue',
    6: 'yellow',
    7: 'magenta',
    8: 'cyan',
  },
  fontSize: {
    0: '43px',
    1: '35px',
    2: '43px',
    3: '48px',
    4: '62px',
  },

  fontStyle: {
    0: 'initial',
    1: 'initial',
    2: 'Courier',
    3: 'Times New Roman',
    4: 'Oswald',
    5: 'Nunito',
    6: 'Impressed',
    7: 'Sriracha',
    8: 'Gothic',
  },

  fontColor: {
    0: 'white',
    1: 'white',
    2: 'black',
    3: 'red',
    4: 'green',
    5: 'blue',
    6: 'yellow',
    7: 'magenta',
    8: 'cyan',
  },
  fontOpacity: {
    0: 1,
    1: '.flashing-cc',
    2: 0.6,
    3: 0,
    4: 1,
    5: 0.2,
    6: 0.4,
  },
  backgroundOpacity: {
    0: 1,
    1: '.flashing-cc',
    2: 0.6,
    3: 0,
    4: 1,
    5: 0.2,
    6: 0.4,
  },

  windowColor: {
    0: 'transparent',
    1: 'white',
    2: 'black',
    3: 'red',
    4: 'green',
    5: 'blue',
    6: 'yellow',
    7: 'magenta',
    8: 'cyan',
  },

  windowOpacity: {
    0: 1,
    1: '.flashing-cc',
    2: 0.6,
    3: 0,
    4: 1,
    5: 0.2,
    6: 0.4,
  },

  edgeColor: {
    0: 'transparent',
    1: 'white',
    2: 'black',
    3: 'red',
    4: 'green',
    5: 'blue',
    6: 'yellow',
    7: 'magenta',
    8: 'cyan',
  },

  edgeType: {
    0: '0px 0px -1px',
    1: '-3px -3px',
    2: '2px 2px',
    3: `
      -1px -1px 0, 1px -1px 0, -1px 1px 0, 1px 1px 0`,
    4: '-3px -3px',
  },
};
