import React, { forwardRef, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../stores/reducers';
import { onEnterHandler } from '../../utils';
import { buttonBgColorOff, primaryColor } from '../../constants/colors';

import './Button.scss';

interface IButtonProps {
  title?: string;
  disabled?: boolean;
  focusLeft?: string;
  focusRight?: string;
  focusDown?: string;
  focusUp?: string;
  index?: number;
  onKeyDown?: (e) => void;
  onFocusFunc?: (e) => void;
  onBlurFunc?: (e) => void;
  btnID?: string;
  children?: JSX.Element;
  TTSText?: string;
}

const Button = (props: IButtonProps, btnRef) => {
  const {
    title = '',
    index = 0,
    focusLeft,
    focusRight,
    focusDown,
    focusUp,
    btnID,
    onKeyDown,
    onFocusFunc,
    onBlurFunc,
    disabled = false,
    children,
    TTSText,
  } = props;

  const btnStyles = useSelector((state: RootState) => state.config.styles['button']);

  const btnGetFocused = useCallback((e) => {
    e.target.style.background = (btnStyles?.colorOn || primaryColor);
    e.target.style.color = disabled
      ? 'grey'
      : btnStyles?.textFocusColor;
    e.target.style.border = `${btnStyles?.borderSize}px solid ${disabled
      ? 'grey'
      : btnStyles?.borderColorOn}`;
    onFocusFunc && onFocusFunc(e);
  }, [btnStyles, disabled]);

  const btnGetBlur = useCallback((e) => {
    e.target.style.background = btnStyles?.colorOff;
    e.target.style.color = disabled
      ? 'grey'
      : btnStyles?.textColor;
    e.target.style.border = `${btnStyles?.borderSize}px solid ${disabled
      ? 'grey'
      : btnStyles?.borderColorOff}`;
    onBlurFunc && onBlurFunc(e);
    // eslint-disable-next-line
  }, [btnStyles, disabled]);

  const styles = useMemo(() => ({
    color: disabled
      ? 'grey'
      : btnStyles?.textColor,
    background: (btnStyles?.colorOff || buttonBgColorOff),
    border: `${btnStyles?.borderSize}px solid ${disabled
      ? 'grey'
      : btnStyles?.borderColorOff}`,
  }), [btnStyles, disabled]);

  const handleKeyDown = (e) => {
    onEnterHandler(e, () => {
      e.preventDefault();
      onKeyDown && onKeyDown(e);
    });
  };

  return (
    <div
      // eslint-disable-next-line
      role=''
      aria-label={TTSText || title}
      tabIndex={0}
      id={btnID}
      style={styles}
      onBlur={btnGetBlur}
      onFocus={btnGetFocused}
      ref={btnRef}
      className={`button btn-acc-${index}`}
      data-tv-focus-left={focusLeft ? focusLeft : `.btn-acc-${index - 1}`}
      data-tv-focus-right={focusRight ? focusRight : `.btn-acc-${index + 1}`}
      data-tv-focus-down={focusDown ? focusDown : `.btn-acc-${index + 1}`}
      data-tv-focus-up={focusUp ? focusUp : `.btn-acc-${index - 1}`}
      onKeyDown={handleKeyDown}
    >
      {children}
      {title}
    </div>
  );
};

export default React.memo(forwardRef(Button), (prev, next) => {
  const titleChanged = prev.title === next.title;
  const disabledChanged = prev.disabled === next.disabled;
  const onKeyDownChanged = prev.onKeyDown === next.onKeyDown;

  return titleChanged && disabledChanged && onKeyDownChanged;
});
