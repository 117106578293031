import * as actionTypes from '../actions/types/OTTEpisode';
import {
  Action,
  OTTEpisodeComponent,
  DRMAccesses,
  RecommendedPlaylist,
  CurrentPlaylistItemIndex,
  AddMidrollBreaks,
  DRMFail,
  IErrorAPI,
} from '../actions/OTTEpisode';

interface OTTEpisodeState {
  component: OTTEpisodeComponent;
  streams: Array<any>;
  fetched: boolean;
  hasError: boolean;
  error: IErrorAPI;
  drm: DRMAccesses;
  drmFail: DRMFail;
  adUrl: string;
  midrollBreaks: AddMidrollBreaks;
  isLoading: boolean;
  recommendedPlaylist: RecommendedPlaylist;
  currentPlaylistItemIndex: CurrentPlaylistItemIndex;
}

const initialState: OTTEpisodeState = {
  component: {
    item: {
      link: '',
      'media:content': {},
    },
  },
  streams: [],
  drm: {
    url: '',
    dashUrl: '',
    AuthXML: '',
    FPserver: '',
    FPcert: '',
    PRserver: '',
    WVserver: '',
    drmfail: '',
    drmfailMP4: '',
  },
  drmFail: {
    drmFailUrl: '',
    drmFailTitle: '',
  },
  adUrl: '',
  midrollBreaks: [],
  fetched: false,
  hasError: false,
  error: {
    errorCode: '',
    requestData: {},
  },
  isLoading: false,
  recommendedPlaylist: [],
  currentPlaylistItemIndex: -1,
};

const OTTEpisode = (state = initialState, action: Action): OTTEpisodeState => {
  switch (action.type) {
    case actionTypes.LOAD_OTTEPISODE_COMPONENT:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.LOAD_OTTEPISODE_COMPONENT_SUCCEEDED:
      return {
        ...state,
        component: action.payload.component,
        streams: action.payload.streams,
        drm: action.payload.drm,
        adUrl: action.payload.adUrl,
        drmFail: action.payload.drmFail,
        midrollBreaks: action.payload.midrollBreaks,
        fetched: true,
        hasError: false,
        isLoading: false,
      };

    case actionTypes.LOAD_OTTEPISODE_COMPONENT_FAILED:
      return {
        ...state,
        fetched: false,
        hasError: true,
        error: action.error,
        isLoading: false,
      };
    case actionTypes.SET_RECOMMENDED_PLAYLIST:
      return {
        ...state,
        recommendedPlaylist: action.payload.recommendedPlaylist,
      };

    case actionTypes.SET_CURRENT_PLAYLIST_ITEM_INDEX:
      return {
        ...state,
        currentPlaylistItemIndex: action.payload.currentPlaylistItemIndex,
      };

    case actionTypes.CLEAR_OTTEPISODE:
      return {
        ...initialState,
        recommendedPlaylist: {...state}.recommendedPlaylist,
        currentPlaylistItemIndex: {...state}.currentPlaylistItemIndex,
      };

    default:
      return state;
  }
};

export default OTTEpisode;
