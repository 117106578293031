import * as actionTypes from '../actions/types/OTTPage';
import { Action, Page, PageWithComponents } from '../actions/OTTPage';

interface OTTPageState {
  page: Array<Page>;
  components: Array<PageWithComponents | Array<PageWithComponents>>;
  hideNav: string;
  fetched: boolean;
  hasError: boolean;
  error: string;
  isLoading: boolean;
  link?: string,
}

const initialState: OTTPageState = {
  page: [],
  components: [],
  hideNav: 'false',
  fetched: false,
  hasError: false,
  error: '',
  isLoading: false,
};

const OTTPage = (state = initialState, action: Action): OTTPageState => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.LOAD_PAGE_SUCCEEDED:
      return {
        ...state,
        page: action.payload.data.page,
        hideNav: action.payload.data.hideNav,
        fetched: true,
        hasError: false,
        isLoading: false,
      };

    case actionTypes.LOAD_PAGE_FAILED:
      return {
        ...state,
        fetched: false,
        hasError: true,
        error: action.error,
        isLoading: false,
      };

    case actionTypes.LOAD_COMPONENT:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.LOAD_COMPONENT_SUCCEEDED:
      return {
        ...state,
        components: action.payload.components,
        fetched: true,
        hasError: false,
        isLoading: false,
      };

    case actionTypes.LOAD_COMPONENT_FAILED:
      return {
        ...state,
        fetched: false,
        hasError: true,
        error: action.error,
        isLoading: false,
      };
    case actionTypes.CLEAR_OTTPAGE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default OTTPage;
