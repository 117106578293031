import { put, takeLatest, all, call } from 'redux-saga/effects';
import * as actionTypes from '../actions/types/OTTSettings';
import { getSettingsContent, getSettingsDataContent } from '../../services/OTTSettingsService';
import { isStirrTcMarqueeBrand } from '../../constants/structureTypes';
import {
  PAGE_COMPONENTS_REQUEST_ERROR,
  PAGE_LAYOUT_REQUEST_ERROR,
} from '../../constants/errorCodes';

function* getSettingsData(data) {
  const { url } = data;

  try {
    const responseData = yield call(getSettingsDataContent, url);

    yield put({
      type: actionTypes.LOAD_OTT_SETTINGS_PAGE_DATA_SUCCEEDED,
      payload: responseData,
    });
  } catch (e) {
    yield put({
      type: actionTypes.LOAD_OTT_SETTINGS_PAGE_DATA_FAILED,
      error: PAGE_LAYOUT_REQUEST_ERROR,
    });
  }
}

function* setSettingsComponents(data) {
  try {
    const settingsData = yield all(
      data.settingsData.map(item => {
        if (typeof item?.content === 'object') {
          return item;
        } else {
          return call(() => getSettingsContent(item?.path || item?.content)
            .then(res => {
              return {
                ...item,
                description: res || '',
              };
            }));
        }
      }),
    );

    const filteredSettingsData = settingsData.filter(item => Boolean(item?.description) || Boolean(item?.content));
    const emptySettingsData = settingsData.filter(item => Boolean(!item?.description));

    if (
      (isStirrTcMarqueeBrand && filteredSettingsData.length)
      || (!isStirrTcMarqueeBrand && !emptySettingsData.length)
    ) {
      yield put({
        type: actionTypes.LOAD_OTT_SETTINGS_DATA_SUCCEEDED,
        payload: filteredSettingsData,
      });
    } else {
      yield put({
        type: actionTypes.LOAD_OTT_SETTINGS_DATA_FAILED,
        error: PAGE_COMPONENTS_REQUEST_ERROR,
      });
    }
  } catch (e: any) {
    yield put({
      type: actionTypes.LOAD_OTT_SETTINGS_DATA_FAILED,
      error: PAGE_COMPONENTS_REQUEST_ERROR,
    });
  }
}

function* watcher() {
  yield takeLatest(actionTypes.LOAD_OTT_SETTINGS_PAGE_DATA, getSettingsData);
  yield takeLatest(actionTypes.LOAD_OTT_SETTINGS_DATA, setSettingsComponents);
}

export default watcher;
