import { call, put, takeLatest } from 'redux-saga/effects';

import {
  LINEAR_TELEMETRY,
  LINEAR_TELEMETRY_FAILED,
  LINEAR_TELEMETRY_SUCCEEDED,
} from '../actions/types/linearTelemetry';
import { postLinearTelemetry } from '../../services/linearTelemetry';

function* sendLinearTelemetry(actionData): Generator {

  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const rep = yield call(postLinearTelemetry, actionData.payload);

    yield put({
      type: LINEAR_TELEMETRY_SUCCEEDED,
    });

  } catch (e) {
    yield put({
      type: LINEAR_TELEMETRY_FAILED,
    });
  }
}

function* linearTelemetryWatcher() {
  yield takeLatest(LINEAR_TELEMETRY, sendLinearTelemetry);
}

export default linearTelemetryWatcher;
