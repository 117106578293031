import * as actionTypes from '../actions/types/common';
import { Action } from '../actions/common';
import { isEnabledTTS } from '../../services/TTSService';
import { getInitialAppVersion, getParsedDataFromLocalStorage } from '../../utils/commonFunc';
import { CaptionsSettings } from '../../platform/interfaces/captions';
import { config } from '../../config';

const captionsSettings: CaptionsSettings = config.captions?.getCaptionsSettings?.() || {};
const isFireTVPlatform = config.appPlatform === 'firetv';

interface ICommonDataState {
  station: string;
  city: string;
  activeNavIdx: number;
  isConnectedToNetwork: boolean;
  TTSEnabled: boolean;
  isCCEnabled: boolean;
  isExitModalShown: boolean;
  isShownDebugSettings: boolean;
  isSpoilerPreventionEnabled: boolean;
  appVersion: string;
}

const initialState: ICommonDataState = {
  station: '',
  city: '',
  activeNavIdx: 0,
  isConnectedToNetwork: true,
  TTSEnabled: isEnabledTTS(),
  isCCEnabled: (isFireTVPlatform ? getParsedDataFromLocalStorage('isCCEnabled') : captionsSettings?.IS_ENABLED) || false,
  isExitModalShown: false,
  isShownDebugSettings: false,
  isSpoilerPreventionEnabled: getParsedDataFromLocalStorage('isSpoilerPreventionEnabled') || false,
  appVersion: getInitialAppVersion(),
};

const commonData = (state = initialState, action: Action): ICommonDataState => {
  switch (action.type) {
    case actionTypes.SET_STATION:
      return {
        ...state,
        station: action.station,
      };

    case actionTypes.REMOVE_STATION:
      return {
        ...state,
        station: '',
      };

    case actionTypes.SET_CITY:
      return {
        ...state,
        city: action.city,
      };

    case actionTypes.REMOVE_CITY:
      return {
        ...state,
        city: '',
      };

    case actionTypes.SET_ACTIVE_NAV:
      return {
        ...state,
        activeNavIdx: action.navIdx,
      };

    case actionTypes.NETWORK_CONNECTED:
      return {
        ...state,
        isConnectedToNetwork: action.isConnected,
      };

    case actionTypes.SET_TTS_STATUS:
      return {
        ...state,
        TTSEnabled: action.status,
      };

    case actionTypes.SET_CC_STATUS:
      return {
        ...state,
        isCCEnabled: action.status,
      };

    case actionTypes.TOGGLE_EXIT_MODAL:
      return {
        ...state,
        isExitModalShown: !state.isExitModalShown,
      };

    case actionTypes.TOGGLE_DEBUG_SETTINGS:
      return {
        ...state,
        isShownDebugSettings: !state.isShownDebugSettings,
      };

    case actionTypes.SET_APP_VERSION:
      return {
        ...state,
        appVersion: action.appVersion,
      };

    case actionTypes.TOGGLE_SPOILER_PREVENTION_SETTINGS:
      return {
        ...state,
        isSpoilerPreventionEnabled: !state.isSpoilerPreventionEnabled,
      };

    default:
      return state;
  }
};

export default commonData;
