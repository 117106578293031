import axiosInstance from './axiosInstance';
import { ComponentPayload, DRMAccesses, DRMFail } from '../stores/actions/OTTEpisode';
import { isTcBrand, X_BRAND_HEADER } from '../constants/structureTypes';
import { addParametersToURL } from '../utils/commonFunc';
import { getDefaultHeaders } from './helpers';

export async function getOTTEpisodeComponentContent(url: string, station: string): Promise<ComponentPayload> {
  const {
    data: {
      rss: {
        channel: {
          item,
        },
      },
    },
  } = await axiosInstance.get(addParametersToURL(url, { station }));

  return {
    item,
  };
}

export async function postOTTEpisodeComponentContent(
  url: string, postData: any, requestHeaders: any,
): Promise<ComponentPayload | any> {
  const options = {
    headers: {
      ...requestHeaders,
      'X-brand': X_BRAND_HEADER,
    },
  };

  try {
    const {
      data: {
        rss: {
          channel: {
            item,
          },
        },
      },
    } = await axiosInstance.makeApiCall(url, postData, options);

    return {
      item,
    };
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.error.code === 1600) {
        throw new Error('RefreshToken');
      }
    }
  }
}

export async function getDRMAccesses(
  url: string, station: string, body: object,
): Promise<DRMAccesses | DRMFail | any> {
  try {
    const { data } = await axiosInstance.post(
      addParametersToURL(url, { station }),
      body,
      {
        headers: getDefaultHeaders(),
      },
    );

    return { ...data };
  } catch (error: any) {
    if (error.response) {
      return {
        drmFailUrl: error.response.data.errors[0].meta.drmfail,
        drmFailTitle: error.response.data.errors[0].title,
      };
    }
  }
}

export async function getExtraAdParams(url: string, station: string): Promise<any> {
  try {
    const {
      data: {
        cust_params,
      },
    } = await axiosInstance.get(addParametersToURL(url, { station }));

    return {
      ...cust_params,
    };
  } catch (error) {
    console.log('Failed to load ad parameters');
  }
}

export async function getManifest(url: string): Promise<any> {
  const { data } = await axiosInstance.makeApiCall(url, {}, {
    headers: isTcBrand ? getDefaultHeaders() : {},
  });

  return data;
}
