import React, {
  useRef, useEffect, useState, useCallback,
  lazy, Suspense, MutableRefObject,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { sendLinearTelemetry } from '../../stores/actions/linearTelemetry';
import * as OTTChannelGuideActionTypes from '../../stores/actions/types/OTTChannelGuide';
import { RootState } from '../../stores/reducers';
import { loadChannelsListData } from '../../stores/actions/OTTChannelGuide';
import { setComponentOTTFeedData } from '../../stores/actions/OTTFeed';
import {
  playerAction,
  playerDestroyDaiStream,
  playerInitialize,
  playerOpenLiveUrl,
} from '../../stores/actions/player';
import {
  CaptionsSettingsKeyToListenerMap,
  CaptionsSettings,
} from '../../platform/interfaces/interfaces';
import currentPlatform from '../../platform/currentPlatform';
import { config } from '../../config';
import useEventListener from '../../hooks/eventListener';
import useComponentMounted from '../../hooks/isComponentMounted';
import useHideSignInButton from '../../hooks/hideSignInButton'; // left for Marquee(old sign-in flow)
import { getKeyInfo, onBackHandler } from '../../utils';
import { CAPTIONS_SETTINGS_KEYS } from '../../utils/captions';
import { IBasicParams } from '../../utils/advertisement';
import { skipVideoGA } from '../../utils/commonFunc'; // left for Marquee(old sign-in flow)
import {
  convertIntToTimeStringForTTS, speakTTS,
  stopTTS, useAnnouncePageLoading,
} from '../../services/TTSService';
import {
  clearHitDimensions,
  GAEvent,
  generateAnalyticsErrorDetails,
  setDimensions,
} from '../../services/analytics';
import adsService from '../../services/adsService';
import focusService from '../../services/focusService';
import TimeTracker from '../../services/TimeTracker';
import { navigateBack } from '../../services/NavigationService';
import { getLinearTelemetryURL, LinearEventType } from '../../services/linearTelemetry';

import {
  HIDE_OVERLAY_TIMEOUT,
  REFRESH_INTERVAL,
  EPG_CLOSE_TIMER_SEC,
  EXTREMELY_LONG_BUFFERING_TIMEOUT,
} from '../../constants/videoPlayer';
import {
  TTS_TEXT_TO_ANNOUNCE_LOADING,
  LINEAR_LIVE,
} from '../../constants/text';
import * as analyticsTypes from '../../constants/analyticsTypes';
import { DEFAULT_VIDEO_AD_ERROR, VIDEO_ERROR } from '../../constants/errorCodes';
import {
  OTT_BRAND,
  isStirrTcMarqueeBrand,
  isStirrBrand,
  isMarqueeBrand,
} from '../../constants/structureTypes';

import LiveOverlay from './LiveOverlay/LiveOverlay';
import LiveOverlayWithControls from './LiveOverlayWithControls/LiveOverlayWithControls';
import Spinner from '../Spinner/Spinner';
import Advertisement from '../Advertisement/Advertisement';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import VerticalEpg from '../VerticalEpg/VerticalEpg';
import ClosedCaptions from './ClosedCaptions/ClosedCaptions';
import CaptionsMenu from '../CaptionsMenu/CaptionsMenu';
import Button from '../Button/Button'; // left for Marquee(old sign-in flow)
import PlayerWrapper from '../Player/PlayerWrapper';
import ErrorModal from '../Modals/ErrorModal/ErrorModal';

import './LIVE.scss';

declare const google: any;

type LIVEProps = {
  source: string;
  includeAds: boolean;
  adUrl: string;
  custParams: string;
  standParams: IBasicParams;
  accessDisabled: boolean;
  activeChannel: number;
  setActiveChannel: (number) => void;
  activeChannelName: MutableRefObject<string>;
  selectedChannelId: string;
  setSelectedChannelId: (string) => void;
  initialId: string;
  currentLive: string;
  showAuthPopUp: boolean;
  setShowAuthPopUp: (boolean) => void;
  signInBtnPressed: MutableRefObject<boolean>;
  logInRequestor: string;
  assetKey: string;
  isShownEpgOnlyView: boolean;
  setIsShownEpgOnlyView: (value: boolean) => void;
  isFlowFromEPGOnlyView: MutableRefObject<boolean>;
  goBack: () => void;
  clearOTTFeedData: () => void;
  sinclairIdent: string,
  item: any;
};

let videoLaunched = false; // left for Marquee(old sign-in flow)

const useSetCaptionsSettingsChangeListenersOnMount = (map: CaptionsSettingsKeyToListenerMap): void => {
  useEffect(() => {
    const listenerIds = config.captions?.addCaptionsSettingsChangeListeners?.(map);

    return () => {
      listenerIds && listenerIds.forEach((listenerId) => {
        config.captions?.removeCaptionsSettingsChangeListener?.(listenerId);
      });
    };
    // eslint-disable-next-line
  }, []);
};

const Schedule = lazy(() => import('../Schedule/Schedule'));
const renderLoader = () => (<div className='epg-spinner'><Spinner /></div>);

const LIVE = (props: LIVEProps) => {
  const {
    source,
    includeAds,
    adUrl,
    custParams,
    accessDisabled,
    activeChannel,
    setActiveChannel,
    selectedChannelId,
    setSelectedChannelId,
    initialId,
    standParams,
    currentLive,
    showAuthPopUp,
    setShowAuthPopUp,
    signInBtnPressed,
    logInRequestor, // left for Marquee(old sign-in flow)
    assetKey,
    isShownEpgOnlyView,
    setIsShownEpgOnlyView,
    isFlowFromEPGOnlyView,
    activeChannelName,
    goBack,
    clearOTTFeedData,
    sinclairIdent,
    item,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const isComponentMounted = useComponentMounted();

  const features = useSelector((state: RootState) => state.config.features);
  const isChannelsListError = useSelector((state: RootState) => state.OTTChannelGuide.hasError);
  const isPageLoadingError = useSelector((state: RootState) => state.OTTFeed.hasError);
  const mvpdLoginError = useSelector((state: RootState) => state.Login.mvpd.error);
  const pageLoadingError = useSelector((state: RootState) => state.OTTFeed.error);
  const channelsListLoadingError = useSelector((state: RootState) => state.OTTChannelGuide.error);
  const channelsWithPrograms = useSelector((state: RootState) => state.OTTChannelGuide.channelsSortedByCategories);
  const navigation = useSelector((state: RootState) => state.config.navigation);
  const epgStyles = useSelector((state: RootState) => state.config.styles['epg']);
  const epgHideDelayFromStore = useSelector((state: RootState) => {
    return state.config.allConfigs.API['epg_dismissal_delay_seconds'];
  });
  const isConnectedToNetwork = useSelector((state: RootState) => state.common.isConnectedToNetwork);
  const TTSStatus = useSelector((state: RootState) => state.common.TTSEnabled);
  const captionsStatusFromStore = useSelector((state: RootState) => state.common.isCCEnabled);
  const { livePlayer } = useSelector((state: RootState) => state.config.players);
  const configDrmData = useSelector((state: RootState) => state.OTTFeed.configDrmData);
  const drmFail = useSelector((state: RootState) => state.OTTFeed.drmFail);
  const isOTTFeedDataLoading = useSelector((state: RootState) => state.OTTFeed.isLoading);
  const channelStatusUrl = useSelector((state: RootState) => state.config.allConfigs.API.channel_status);
  const isShowPromoVideo = useSelector((state: RootState) => state.config.allConfigs.API.paywall?.['show_promo_video']);

  const overlayTimerRef = useRef<any>(null);
  const epgCloseTimerRef = useRef<any>(null);
  const refreshTimerRef = useRef<any>(null);
  const adPlayingRef = useRef<Boolean>(false);
  const previousSelectedChannelId = useRef<string>(sinclairIdent);

  const adsVideoRef = useRef<any>(null);
  const adsLoadingRef = useRef<boolean>(false);
  const adsContainerRef = useRef<any>(null);
  const streamManager = useRef<any>(null);
  const videoRef = useRef<any>(null);
  const adShouldBeLoaded = useRef<boolean>(true);
  const neededCCTrack = useRef({});
  const hlsPlayerErrorCounter = useRef<number>(0);
  const hlsNotFatalErrorCounter = useRef<number>(0);
  const contentLoadingTimer = useRef<any>(null);
  const loadingTime = useRef<number>(moment().unix());
  const adsAmount = useRef<number>(0);
  const currentAdPosition = useRef<number>(0);
  const isShownSignInBtn = useRef<any>(true);
  const throttleCloseLiveOverlayTimer = useRef<number>(Date.now());
  const uuidForLinearTelemetry = useRef<string>('');
  const timerForLinearTelemetry = useRef<any>(null);
  const timerIntervalForLinearTelemetry = useRef<any>();
  const channelUUIDForLinearTelemetry = useRef<any>();
  const silentElem = useRef<any>(null);
  const isIMAAdPlaying = useRef<boolean>(false);
  const isPlayerError = useRef<any>(null);
  const timeouts = useRef<Array<any>>([]);
  const adLongBufferingTimeout = useRef<any>(null);
  const currentPlayPosition = useRef<number>(0);
  const throttleCheckAdPositionTimer = useRef<number>(0);
  const adPreviousTime = useRef<number>(0);

  const [isBuffering, setIsBuffering] = useState(true);
  const [isNewChannelLoading, setIsNewChannelLoading] = useState<boolean>(false);
  const [activeOverlay, setActiveOverlay] = useState(false);
  const [activeCategory, setActiveCategory] = useState<number>(0);
  const [isShownErrorMessage, setIsShownErrorMessage] = useState<boolean>(false);
  const [isNavMenuShown, setIsNavMenuShown] = useState<boolean>(false);
  const [isEpgOpen, setEpgOpen] = useState(false);
  const [adPlaying, setAdPlaying] = useState(false);
  const [adDuration, setAdDuration] = useState(0);
  const [adCurrentTime, setAdCurrentTime] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ccFontStyle, setCCFontStyle] = useState(true);
  const [currentCue, setCurrentCue] = useState('');
  const [isImaDaiLoaded, setIsImaDaiLoaded] = useState<boolean>(false);
  const [isImaDaiFailedToLoad, setIsImaDaiFailedToLoad] = useState<boolean>(false);
  const [isCaptionsMenuVisible, setIsCaptionsMenuVisible] = useState<boolean>(false);

  const captionsSettings: CaptionsSettings = config.captions?.getCaptionsSettings?.() || {};
  const isFireTVPlatform = config.appPlatform === 'firetv';
  const [isCaptionsEnabled, setCaptionsEnabled] = useState<boolean>((isFireTVPlatform
    ? captionsStatusFromStore
    : captionsSettings.IS_ENABLED) || false);
  const isDrm = !!configDrmData?.AuthXML;

  useEffect(() => {
    if (isShownEpgOnlyView && currentLive) {
      openEPG();
    }
  }, [isShownEpgOnlyView, currentLive]);

  useEffect(() => {
    const addScript = document.createElement('script');

    addScript.type = 'text/javascript';
    addScript.onerror = () => setIsImaDaiFailedToLoad(true);
    addScript.onload = () => setIsImaDaiLoaded(true);
    addScript.src = TTSStatus
      ? './ima3_dai.js'
      : 'https://imasdk.googleapis.com/js/sdkloader/ima3_dai.js';
    (document.getElementsByTagName('head')[0] || document.documentElement).appendChild(addScript);
  }, [TTSStatus]);

  useAnnouncePageLoading(isBuffering, isNewChannelLoading
    ? 'New channel loading.'
    : TTS_TEXT_TO_ANNOUNCE_LOADING
  );

  useEffect(() => {
    // left for Marquee(old sign-in flow)
    if (isShownEpgOnlyView && isMarqueeBrand) {
      isBuffering && setIsBuffering(false);

      return;
    }

    dispatch(playerDestroyDaiStream());
    isEpgOpen && !isShownEpgOnlyView && setEpgOpen(false);

    if (
      source &&
      (isImaDaiLoaded || isImaDaiFailedToLoad)
      && !isOTTFeedDataLoading
      && (!accessDisabled || (accessDisabled && isShowPromoVideo))
      && !isShownErrorMessage
    ) {
      loadingTime.current = moment().unix();

      if (includeAds) {
        adsService.initAds(
          adsContainerRef.current,
          adsVideoRef.current,
          adUrl,
          playingVideoListener,
          waitingVideoListener,
          onAdLoaded,
          onContentPauseRequested,
          onAdContentCompleted,
          onAdProgress,
          onAdCompleted,
          onAdStarted,
          loadAds,
          onAdBuffering,
          { playbackItemIdentifier: item['guid']['content'], playerName: livePlayer }
        );
        adPlayingRef.current = true;
      } else {
        initDaiStream();
        adShouldBeLoaded.current = false;
      }
      previousSelectedChannelId.current = sinclairIdent;
    } else if (!source && (isChannelsListError || isPageLoadingError)) {
      setIsBuffering(false);
      setIsShownErrorMessage(true);
    } else {
      isBuffering && isShownEpgOnlyView && setIsBuffering(false);
    }

    return () => {
      adShouldBeLoaded.current = true;
      clearTimers();
    };
    // eslint-disable-next-line
  }, [sinclairIdent, isPageLoadingError, configDrmData, isConnectedToNetwork, isImaDaiLoaded, isImaDaiFailedToLoad]);

  useEffect(() => {
    if (channelsWithPrograms.length) {
      activeChannelName.current = selectedChannelId
        ? channelsWithPrograms.find(({ id }) => id.includes(selectedChannelId))?.displayName
        : channelsWithPrograms[0].displayName;
    }
    // eslint-disable-next-line
  }, [channelsWithPrograms.length]);

  useEffect(() => {
    initRefreshEPG();
    silentElem.current = document.getElementById('appTitle');

    return () => {
      destroyDaiStream();
      adShouldBeLoaded.current = true;
      clearInterval(refreshTimerRef.current);
    };
    // eslint-disable-next-line
  }, []);

  // left for Marquee(old sign-in flow)
  useHideSignInButton(logInRequestor, isShownSignInBtn);

  const dispatchLinearTelemetry = useCallback((eventType: LinearEventType, channelUuId) => {
    if (isStirrBrand && getLinearTelemetryURL()) {
      dispatch(
        sendLinearTelemetry({
          content_type: LINEAR_LIVE,
          drm_required: false,
          duration: timerForLinearTelemetry.current?.getTimeTotal(),
          event_type: eventType,
          player: livePlayer,
          session_id: uuidForLinearTelemetry.current,
          uuidFeedOrChannel: channelUuId,
        })
      );
    }
    // eslint-disable-next-line
    }, [livePlayer]);

  const clearTimers = () => {
    timeouts.current.forEach(timeout => clearTimeout(timeout));
    timeouts.current = [];
    clearTimeout(contentLoadingTimer.current);
    clearTimeout(adLongBufferingTimeout.current);
    clearTimeout(epgCloseTimerRef.current);
    clearInterval(timerIntervalForLinearTelemetry.current);
    timerIntervalForLinearTelemetry.current = null;
  };

  const windowColorListener = () => {
    setCCFontStyle(prev => !prev);
  };

  const edgeTypeListener = () => {
    setCCFontStyle(prev => !prev);
  };

  const edgeColorListener = () => {
    setCCFontStyle(prev => !prev);
  };

  const windowOpacityListener = () => {
    setCCFontStyle(prev => !prev);
  };

  const fontSizeListener = () => {
    setCCFontStyle(prev => !prev);
  };

  const fontStyleListener = () => {
    setCCFontStyle(prev => !prev);
  };

  const fontOpacityListener = () => {
    setCCFontStyle(prev => !prev);
  };

  const fgStyleListener = () => {
    setCCFontStyle(prev => !prev);
  };

  const bgStyleListener = () => {
    setCCFontStyle(prev => !prev);
  };

  const listener = () => {
    setCaptionsEnabled(prev => !prev);
  };

  useSetCaptionsSettingsChangeListenersOnMount({
    [CAPTIONS_SETTINGS_KEYS.IS_ENABLED]: listener,
    [CAPTIONS_SETTINGS_KEYS.BACKGROUND_COLOR]: bgStyleListener,
    [CAPTIONS_SETTINGS_KEYS.FONT_COLOR]: fgStyleListener,
    [CAPTIONS_SETTINGS_KEYS.FONT_SIZE]: fontSizeListener,
    [CAPTIONS_SETTINGS_KEYS.FONT_STYLE]: fontStyleListener,
    [CAPTIONS_SETTINGS_KEYS.FONT_OPACITY]: fontOpacityListener,
    [CAPTIONS_SETTINGS_KEYS.WINDOW_COLOR]: windowColorListener,
    [CAPTIONS_SETTINGS_KEYS.WINDOW_OPACITY]: windowOpacityListener,
    [CAPTIONS_SETTINGS_KEYS.EDGE_COLOR]: edgeColorListener,
    [CAPTIONS_SETTINGS_KEYS.EDGE_TYPE]: edgeTypeListener,
  });

  const initRefreshEPG = () => {
    refreshTimerRef.current = setInterval(refreshEPG, REFRESH_INTERVAL);
  };

  const refreshEPG = () => {
    dispatch({
      type: OTTChannelGuideActionTypes.CLEAR_OTTCHANNELGUIDE,
    });
    dispatch(loadChannelsListData());
  };

  const displayOverlayWithTimer = useCallback(() => {
    !activeOverlay && isFireTVPlatform && setIsCaptionsMenuVisible(false);
    !activeOverlay && setActiveOverlay(true);
    clearTimeout(overlayTimerRef.current);
    overlayTimerRef.current = setTimeout(
      () => setActiveOverlay(false),
      HIDE_OVERLAY_TIMEOUT,
    );
  }, [activeOverlay, adPlaying]);

  const onStreamPause = useCallback(() => {
    videoLaunched = false; // left for Marquee(old sign-in flow)
  }, []);

  const onStreamPlay = useCallback(() => {
    videoLaunched = true; // left for Marquee(old sign-in flow)
  }, []);

  const showLoginIfUserRestricted = () => {
    isMarqueeBrand && setShowAuthPopUp(true);
  };

  const onStreamCompleted = useCallback(() => {
    setIsBuffering(false);
    if ((features.cleeng || features.mvpd) && accessDisabled) {
      showLoginIfUserRestricted();
      setIsBuffering(false);
      isShownSignInBtn.current = false;
      signInBtnPressed.current = false;
    }
    // eslint-disable-next-line
  }, [accessDisabled, features.cleeng, features.mvpd]);

  const openUrl = (url) => {
    const playbackUrl = isDrm
      ? assetKey
        ? url
        : configDrmData.dashUrl
      : drmFail?.drmFailUrl || configDrmData?.drmfail || url || source;

    console.log(`Loading: ${playbackUrl}`);

    const timeoutId = setTimeout(() => dispatch(playerOpenLiveUrl(playbackUrl)), 100);

    timeouts.current.push(timeoutId);
  };

  const onStreamEvent = (e) => {
    switch (e.type) {
      case google.ima.dai.api.StreamEvent.Type.LOADED:
        console.log('Stream loaded');
        setAdPlaying(false);
        openUrl(e.getStreamData().url);
        // TTSStatus && document.removeChild(adsContainerRef.current);
        break;
      case google.ima.dai.api.StreamEvent.Type.ERROR:
        console.log('Error loading stream, playing backup stream.');
        console.log(e);
        setDimensions({
          errorDetail: generateAnalyticsErrorDetails(livePlayer, e.getStreamData(), true),
          errorMessage: e.getStreamData()?.errorMessage || '',
          adType: adPlayingRef.current ? analyticsTypes.SERVER_STITCHED : '',
        });
        GAEvent(analyticsTypes.ERROR_PLAYER, analyticsTypes.VIDEO, item['guid']['content'], {
          nonInteraction: true,
        });
        clearHitDimensions(['errorDetail', 'adType', 'errorMessage']);
        openUrl(source);
        break;
      case google.ima.dai.api.StreamEvent.Type.AD_BREAK_STARTED:
        console.log('Ad Break Started');
        setAdPlaying(true);
        isIMAAdPlaying.current = false;
        isCaptionsEnabled && setCurrentCue('');
        setCCFontStyle(prev => !prev);
        adsContainerRef.current.style.display = 'block';
        GAEvent(
          `${analyticsTypes.LIVESTREAM}${analyticsTypes.MID_ROLL}`,
          analyticsTypes.PLAY,
          currentLive,
          { nonInteraction: true }
        );
        break;
      case google.ima.dai.api.StreamEvent.Type.AD_BREAK_ENDED:
        console.log('Ad Break Ended');
        setAdPlaying(false);
        currentAdPosition.current = 0;
        setCCFontStyle(prev => !prev);
        adsContainerRef.current.style.display = 'none';
        GAEvent(
          `${analyticsTypes.LIVESTREAM}${analyticsTypes.MID_ROLL}`,
          analyticsTypes.COMPLETE,
          currentLive,
          { nonInteraction: true }
        );
        break;
      case google.ima.dai.api.StreamEvent.Type.AD_PROGRESS:
        console.log('AD_PROGRESS');
        onAdProgress(e, null, false);
        break;
      default:
        break;
    }
  };

  const requestLiveStream = (assetKey, apiKey) => {
    const streamRequest = new google.ima.dai.api.LiveStreamRequest();

    const imaDAIparams = {
      ...standParams,
      cust_params: custParams,
    };

    streamRequest.assetKey = assetKey;
    streamRequest.apiKey = apiKey;
    streamRequest.adTagParameters = imaDAIparams;
    streamManager.current.requestStream(streamRequest);
  };

  const fatalErrorHandler = () => {
    setIsShownErrorMessage(true);
    dispatch(playerDestroyDaiStream());
    setIsBuffering(false);
    setActiveOverlay(false);
    timerForLinearTelemetry.current && timerForLinearTelemetry.current.reset();
    clearInterval(timerIntervalForLinearTelemetry.current);
    timerIntervalForLinearTelemetry.current = null;
  };

  const initDaiStream = useCallback(() => {
    setIsBuffering(true);

    const timerId = setTimeout(() => {
      dispatch(playerInitialize(videoRef.current));
    }, 100);

    timeouts.current.push(timerId);
    clearTimeout(contentLoadingTimer.current);
    // show error screen after 30sec of trying to load content;
    contentLoadingTimer.current = setTimeout(() => {
      const errorDetails = generateAnalyticsErrorDetails(livePlayer, {}, false);

      setDimensions({
        errorDetail: errorDetails,
        errorMessage: `${analyticsTypes.VIDEO} ${analyticsTypes.LONG_BUFFERING}`,
        adType: adPlayingRef.current ? analyticsTypes.SERVER_STITCHED : '',
      });
      GAEvent(analyticsTypes.ERROR_PLAYER, analyticsTypes.VIDEO, item['guid']['content'], {
        nonInteraction: true,
      });
      clearHitDimensions(['errorDetail', 'adType', 'errorMessage']);
      fatalErrorHandler();
    }, EXTREMELY_LONG_BUFFERING_TIMEOUT);
    hlsNotFatalErrorCounter.current = 0;

    if (isImaDaiLoaded) {
      streamManager.current = new google.ima.dai.api.StreamManager(videoRef.current, adsContainerRef.current);
      streamManager.current.addEventListener(
        [
          google.ima.dai.api.StreamEvent.Type.LOADED,
          google.ima.dai.api.StreamEvent.Type.ERROR,
          google.ima.dai.api.StreamEvent.Type.AD_BREAK_STARTED,
          google.ima.dai.api.StreamEvent.Type.AD_BREAK_ENDED,
          google.ima.dai.api.StreamEvent.Type.AD_PROGRESS,
        ],
        onStreamEvent,
        false);

      if (assetKey) {
        requestLiveStream(assetKey, null);
      } else {
        console.log('no asset key, playing backup stream');
        openUrl(source);
      }
    }
    // eslint-disable-next-line
  }, [source, assetKey, onStreamEvent, openUrl, requestLiveStream, isImaDaiLoaded]);

  const onAdContentCompleted = useCallback(() => {
    console.log('onAdContentCompleted');

    adPlayingRef.current = false;
    adShouldBeLoaded.current = false;
    setAdPlaying(false);
    setAdCurrentTime(0);
    setAdDuration(0);
    if (adsLoadingRef.current) {
      GAEvent(
        `${analyticsTypes.LIVESTREAM} ${analyticsTypes.PRE_ROLL}`,
        analyticsTypes.FAILED,
        currentLive,
        { nonInteraction: true }
      );
    }
    initDaiStream();
  }, [currentLive, initDaiStream]);

  const waitingVideoListener = () => {
    activeOverlay && setActiveOverlay(false);
  };

  const destroyDaiStream = useCallback((clearAds = true) => {
    if (clearAds) {
      adsService.destroyIMA();
      adsLoadingRef.current = false;
      if (adsVideoRef.current) {
        adsVideoRef.current.removeEventListener('playing', playingVideoListener);
        adsVideoRef.current.removeEventListener('waiting', waitingVideoListener);
      }
      setAdPlaying(false);
      adPlayingRef.current = false;
    }

    streamManager.current?.reset();
    videoLaunched = false; // left for Marquee(old sign-in flow)

    dispatchLinearTelemetry('stop', channelUUIDForLinearTelemetry.current);

    clearTimeout(overlayTimerRef.current);
    timerForLinearTelemetry.current && timerForLinearTelemetry.current.reset();
    clearInterval(timerIntervalForLinearTelemetry.current);
    timerIntervalForLinearTelemetry.current = null;
  }, [dispatchLinearTelemetry, onAdContentCompleted, waitingVideoListener]);

  useEffect(() => {
    if (videoLaunched) {
      clearTimeout(contentLoadingTimer.current);

      if (isBuffering) {
        contentLoadingTimer.current = setTimeout(() => {
          const errorDetails = generateAnalyticsErrorDetails(livePlayer, {}, false);

          setDimensions({
            errorDetail: errorDetails,
            errorMessage: `${analyticsTypes.VIDEO} ${analyticsTypes.LONG_BUFFERING}`,
            adType: adPlayingRef.current ? analyticsTypes.SERVER_STITCHED : '',
          });
          GAEvent(analyticsTypes.ERROR_PLAYER, analyticsTypes.VIDEO, item['guid']['content'], {
            nonInteraction: true,
          });
          clearHitDimensions(['errorDetail', 'adType', 'errorMessage']);
        }, EXTREMELY_LONG_BUFFERING_TIMEOUT);
      }
    }
  }, [isBuffering]);

  const checkPlayStatePosition = (position) => {
    if (position === currentPlayPosition.current) {
      const errorDetails = generateAnalyticsErrorDetails(livePlayer, {}, false);

      setDimensions({
        errorDetail: errorDetails,
        errorMessage: `${analyticsTypes.VIDEO} ${analyticsTypes.NO_POSITION_CHANGE}`,
        adType: adPlayingRef.current ? analyticsTypes.SERVER_STITCHED : '',
      });
      GAEvent(analyticsTypes.ERROR_PLAYER, analyticsTypes.VIDEO, item['guid']['content'], {
        nonInteraction: true,
      });
      clearHitDimensions(['errorDetail', 'adType', 'errorMessage']);
    }
    currentPlayPosition.current = position;
  };

  const launchLinearTelemetry = () => {
    if (isStirrBrand && getLinearTelemetryURL()) {
      uuidForLinearTelemetry.current = uuidv4();
      channelUUIDForLinearTelemetry.current = selectedChannelId;
      timerForLinearTelemetry.current = new TimeTracker();
      timerForLinearTelemetry.current.start();

      dispatchLinearTelemetry('play', channelUUIDForLinearTelemetry.current);
      timerIntervalForLinearTelemetry.current = setInterval(() => {
        dispatchLinearTelemetry('heartbeat', channelUUIDForLinearTelemetry.current);
      }, 60000);
    }
  };

  const onSuccessPrepared = useCallback(() => {
    setIsShownErrorMessage(false);
    !timerIntervalForLinearTelemetry.current && launchLinearTelemetry();
    // eslint-disable-next-line
  }, []);

  const changeCCFontStyle = useCallback(() => {
    if (isComponentMounted) {
      setCCFontStyle(prev => !prev);
    }
  }, [isComponentMounted]);

  const onBufferingComplete = () => {
    setIsBuffering(false);
    videoLaunched = true; // left for Marquee(old sign-in flow)
  };

  const onBufferingStart = () => {
    setIsBuffering(true);
  };

  const onParsingMetadata = useCallback((event, data) => {
    if (streamManager.current && data) {
      // For each ID3 tag in the metadata, pass in the type - ID3, the
      // tag data (a byte array), and the presentation timestamp (PTS).
      data.samples.forEach(function(sample) {
        streamManager.current.processMetadata('ID3', sample.data, sample.pts);
      });
    }
  }, []);

  const onSuccessLoad = useCallback(() => {
    dispatch(playerAction('play'));
    console.log('Video Play');
    videoLaunched = true; // left for Marquee(old sign-in flow)
    // eslint-disable-next-line
  }, []);

  const onCanPlay = videoBand => {
    const timeToLoad = moment().unix() - loadingTime.current;

    contentLoadingTimer.current && clearTimeout(contentLoadingTimer.current);
    setDimensions({ videoLoadTime: `${timeToLoad}`, videoBandwidth: videoBand });
    displayOverlayWithTimer();
  };

  const onFailedLoad = (e, data) => {
    console.log('Stream error: ', data);
    if (isConnectedToNetwork) {
      setIsShownErrorMessage(data.fatal);

      if (data.fatal) {
        const errorDetails = generateAnalyticsErrorDetails(livePlayer, data, false);

        setDimensions({
          errorDetail: errorDetails,
          errorMessage: e?.message,
          adType: adPlayingRef.current ? analyticsTypes.SERVER_STITCHED : '',
        });
        GAEvent(analyticsTypes.ERROR_PLAYER, analyticsTypes.VIDEO, item['guid']['content'], {
          nonInteraction: true,
        });
        clearHitDimensions(['errorDetail', 'adType', 'errorMessage']);
      }

      if (!data.fatal && hlsNotFatalErrorCounter.current < 4 && hlsPlayerErrorCounter.current < 2) {
        !isEpgOpen && setIsBuffering(true);
        setCurrentCue('');
        hlsNotFatalErrorCounter.current++;
      } else if (hlsPlayerErrorCounter.current < 1 || data.fatal) {
        videoLaunched = false; // left for Marquee(old sign-in flow)
        dispatch(playerDestroyDaiStream());
        initDaiStream();
        hlsPlayerErrorCounter.current++;
      } else if (hlsPlayerErrorCounter.current > 2 || hlsNotFatalErrorCounter.current >= 4) {
        videoLaunched = false; // left for Marquee(old sign-in flow)
        isPlayerError.current = data;

        // left this error here for hls.js player
        const errorDetails = generateAnalyticsErrorDetails(livePlayer, data, false);

        setDimensions({
          errorDetail: errorDetails,
          errorMessage: e?.getStreamData()?.errorMessage,
          adType: adPlayingRef.current ? analyticsTypes.SERVER_STITCHED : '',
        });
        GAEvent(analyticsTypes.ERROR_PLAYER, analyticsTypes.VIDEO, item['guid']['content'], {
          nonInteraction: true,
        });
        clearHitDimensions(['errorDetail', 'adType', 'errorMessage']);
        fatalErrorHandler();
      }
    }
  };

  const playingVideoListener = () => {
    setAdPlaying(true);
  };

  const loadAds = () => {
    // Prevent this function from running on if there are already ads loaded
    if (!adShouldBeLoaded.current) {
      return;
    }

    try {
      adsService.loadAdsWithIMA();
    } catch (adError) {
      console.log('Preroll error', adError);
      console.log('Playing video without preroll');
      // Play the video without ads, if an error occurs
      initDaiStream();
      setAdPlaying(false);
      adPlayingRef.current = false;
      GAEvent(
        `${analyticsTypes.LIVESTREAM} ${analyticsTypes.PRE_ROLL}`,
        analyticsTypes.FAILED,
        currentLive,
        { nonInteraction: true }
      );
    }
  };

  const onAdCompleted = () => {
    adsAmount.current = 0;
    currentAdPosition.current = 0;
    isIMAAdPlaying.current = false;
  };

  const onAdStarted = () => {
    setIsBuffering(false);
    setActiveOverlay(false);
    isIMAAdPlaying.current = true;
    !timerIntervalForLinearTelemetry.current && launchLinearTelemetry();
  };

  const onAdBuffering = (e) => {
    const adData = e.getAd();

    setIsBuffering(true);
    adLongBufferingTimeout.current = setTimeout(() => {
      const adErrorDetails = generateAnalyticsErrorDetails(livePlayer, adData, true);

      setDimensions({
        errorDetail: adErrorDetails,
        errorMessage: `${analyticsTypes.AD} ${analyticsTypes.LONG_BUFFERING}`,
        adType: analyticsTypes.CLIENT_STITCHED,
      });
      GAEvent(analyticsTypes.ERROR_PLAYER, analyticsTypes.AD, item['guid']['content'], {
        nonInteraction: true,
      });
      clearHitDimensions(['errorDetail', 'adType', 'errorMessage']);
    }, EXTREMELY_LONG_BUFFERING_TIMEOUT);
  };

  const onAdProgress = (e, currentAd, isIMA) => {
    const { currentTime, duration, adPosition, totalAds } = isIMA
      ? e.getAdData()
      : e.getStreamData().adProgressData;
    const nowDateForThrottleTimer = Date.now();

    if (isIMA && nowDateForThrottleTimer - throttleCheckAdPositionTimer.current > 5000) {
      if (adPreviousTime.current === currentTime) {
        const adErrorDetails = generateAnalyticsErrorDetails(
          livePlayer,
          { item: currentAd },
          true
        );

        setDimensions({
          errorDetail: adErrorDetails,
          errorMessage: `${analyticsTypes.AD} ${analyticsTypes.NO_POSITION_CHANGE}`,
          adType: analyticsTypes.CLIENT_STITCHED,
        });
        GAEvent(analyticsTypes.ERROR_PLAYER, analyticsTypes.AD, item['guid']['content'], {
          nonInteraction: true,
        });
        clearHitDimensions(['errorDetail', 'adType', 'errorMessage']);
      }

      throttleCheckAdPositionTimer.current = nowDateForThrottleTimer;
      adPreviousTime.current = currentTime;
    }

    if (currentAdPosition.current !== adPosition) {
      adsAmount.current = totalAds;
      currentAdPosition.current = adPosition;

      speakTTS(`Playing ad ${currentAdPosition.current} of ${adsAmount.current}.
                      ${convertIntToTimeStringForTTS((duration - currentTime) * 1000)} remaining`);
    }

    setAdCurrentTime((prevAdCurrentTime) => {
      if (isIMA && prevAdCurrentTime !== currentTime) {
        setIsBuffering(false);
        clearTimeout(adLongBufferingTimeout.current);
      }

      return currentTime;
    });
    setAdDuration(duration);
  };

  const onAdLoaded = (adEvent) => {
    const ad = adEvent.getAd();

    if (!ad.isLinear()) {
      adsVideoRef.current.play();
    }
  };

  const onContentPauseRequested = () => {
    if (assetKey && videoLaunched) {
      return;
    }
    adsLoadingRef.current = true;
    setAdPlaying(true);
    adPlayingRef.current = true;
  };

  const clearCloseEpgTimer = () => {
    clearTimeout(epgCloseTimerRef.current);
  };

  const closeEPGWithTimer = useCallback(() => {
    const epgTimeoutToHide: number = epgHideDelayFromStore
      // eslint-disable-next-line radix
      ? parseInt(epgHideDelayFromStore)
      : EPG_CLOSE_TIMER_SEC;

    clearCloseEpgTimer();
    epgCloseTimerRef.current = setTimeout(() => {
      if (isEpgOpen && !isShownEpgOnlyView && !isShownErrorMessage) {
        setEpgOpen(false);
      }
    }, epgTimeoutToHide * 1000);
  }, [isEpgOpen, epgHideDelayFromStore]);

  const openEPG = () => {
    clearTimeout(overlayTimerRef.current);
    activeOverlay && setActiveOverlay(false);
    isBuffering && setIsBuffering(false);
    !isEpgOpen && setEpgOpen(true);
  };

  const backHandler = () => {
    if (adPlaying) {
      GAEvent(
        `${analyticsTypes.LIVESTREAM} ${analyticsTypes.PRE_ROLL}`,
        analyticsTypes.ABANDON,
        currentLive
      );
    }

    if (activeOverlay && !isEpgOpen && !isShownEpgOnlyView) {
      setActiveOverlay(false);
    } else if (isFireTVPlatform && isCaptionsMenuVisible) {
      setIsCaptionsMenuVisible(false);
    } else if (isEpgOpen && !accessDisabled && !isShownEpgOnlyView) {
      setEpgOpen(false);
      isShownErrorMessage && focusService.setFocus(document.getElementById('closeContinueBtn'));
    } else if ((!isEpgOpen && !accessDisabled) || (isEpgOpen && isShownEpgOnlyView && accessDisabled)) {
      goBack();
    } else if (isEpgOpen && isShownEpgOnlyView && !accessDisabled) {
      setEpgOpen(false);
      setIsShownEpgOnlyView(false);
    }
  };

  useEventListener('keydown', event => {
    event.preventDefault();

    const isBtnOpenEpgFocused = document.querySelector('.overlay-button') === event.target;

    const keyInfo = getKeyInfo(event.keyCode);

    if (!showAuthPopUp) {
      onBackHandler(event, backHandler);

      if (!isEpgOpen && activeOverlay && isBtnOpenEpgFocused && (keyInfo === 'Enter')) {
        openEPG();

        return;
      } else if (isFireTVPlatform && !isEpgOpen && ['Down', 'Menu'].includes(keyInfo)) {
        if (keyInfo === 'Down') {
          if (!activeOverlay) {
            setIsCaptionsMenuVisible(true);

            return;
          }
        }
        if (keyInfo === 'Menu') {
          clearTimeout(overlayTimerRef.current);
          activeOverlay && setActiveOverlay(false);
          setIsCaptionsMenuVisible((prev) => !prev);

          return;
        }
        // eslint-disable-next-line max-len
      } else if (
        !isEpgOpen && !activeOverlay && !isShownEpgOnlyView &&
        !isCaptionsMenuVisible && keyInfo === 'Enter' && !isShownErrorMessage &&
        (isStirrTcMarqueeBrand || (!isStirrTcMarqueeBrand && !adPlaying))
      ) {

        if (channelsWithPrograms.length) {
          displayOverlayWithTimer();
        // user can call nav menu even when request for channels list failed
        } else if (isChannelsListError) {
          setIsNavMenuShown(true);
        }

      } else if (activeOverlay && (keyInfo !== 'Enter' && keyInfo !== 'Back')) {
        const nowDateForThrottleTimer = Date.now();

        if (nowDateForThrottleTimer - throttleCloseLiveOverlayTimer.current > 1000) {
          displayOverlayWithTimer();
          throttleCloseLiveOverlayTimer.current = nowDateForThrottleTimer;
        }
      }
    }
  });

  useEventListener(currentPlatform.multiTaskingEvent, () => {
    if (document['hidden'] || document['webkitHidden']) {
      clearTimers();
      stopTTS();
      dispatch(playerDestroyDaiStream());
    } else {
      initDaiStream();
      adShouldBeLoaded.current = false;
    }
  });

  const onChannelSelect = useCallback((channelIdx: number, isCurrentProgram?: boolean) => {
    const currentChannel = channelsWithPrograms[channelIdx];
    const currentCategoryIdx = currentChannel?.categoryIndex || 0;
    const channelName = currentChannel.displayName;

    setEpgOpen(false);

    // return when user select same channel and close EPG
    if (channelName === activeChannelName.current && (videoLaunched || adPlayingRef.current) && !isShownErrorMessage) {
      if (isShownEpgOnlyView) {
        isFlowFromEPGOnlyView.current = true;
        setIsShownEpgOnlyView(false);
      }

      return;
    }

    // return when user selected future program on EPG
    if (!isCurrentProgram) {

      return;
    }

    setIsShownErrorMessage(false);
    hlsNotFatalErrorCounter.current = 0;
    hlsPlayerErrorCounter.current = 0;
    isPlayerError.current = null;
    destroyDaiStream();
    setIsBuffering(true);
    isCaptionsEnabled && setCurrentCue('');

    // Lookup the program data for the channel
    if (!currentChannel?.programs && !currentChannel?.programs?.length) {
      return;
    }

    GAEvent(
      analyticsTypes.EPG,
      analyticsTypes.LINEAR_SELECT,
      channelName,
    );

    const nextLink = `${channelStatusUrl}${channelName}`;

    // console.log('nextLink ', nextLink);
    dispatch(setComponentOTTFeedData(nextLink));

    if (features.cleeng || features.mvpd) {
      setIsShownEpgOnlyView(false);
    }

    setActiveChannel(channelIdx);
    activeChannelName.current = channelName;
    setActiveCategory(currentCategoryIdx);
    setSelectedChannelId(channelsWithPrograms[channelIdx].id);
    setShowAuthPopUp(false);
  }, [channelsWithPrograms.length, navigation, isShownErrorMessage]);

  const setDownFocus = useCallback(() => {
    if (isStirrTcMarqueeBrand) {
      if (activeOverlay) {
        return (accessDisabled && isShownSignInBtn.current) ? '#sign-in-button' : '.overlay-button';
      } else if (isEpgOpen) {
        return '.meta-panel';
      } else if (isShownErrorMessage) {
        return;
      }
    } else {
      if (isEpgOpen) {
        return '.date-container';
      } else {
        return '.button';
      }
    }
  }, [isEpgOpen, activeOverlay, accessDisabled]);

  // left for Marquee(old sign-in flow)
  const onSignInBtnPress = (e) => {
    if (getKeyInfo(e.keyCode) === 'Enter' && videoLaunched) {
      adShouldBeLoaded.current = true;
      clearTimers();
      dispatch(playerDestroyDaiStream()); // TODO do we need this
      setShowAuthPopUp(true);
      isShownSignInBtn.current = false;
      signInBtnPressed.current = true;
      skipVideoGA(logInRequestor, currentLive);
    }
  };

  // left for Marquee(old sign-in flow)
  const shouldDisplaySignInBtn = () => {
    return (
      !isShownErrorMessage && (features.cleeng || features.mvpd) && isShownSignInBtn.current && accessDisabled
      && !showAuthPopUp && !isBuffering && !isShownEpgOnlyView && !isOTTFeedDataLoading
    );
  };

  const getErrorDetails = (): { errorCode: string, details: any } => {
    let errorCode: string = DEFAULT_VIDEO_AD_ERROR;
    let details = null;

    if (isChannelsListError) {
      errorCode = channelsListLoadingError;
    } else if (mvpdLoginError?.errorCode) {
      errorCode = mvpdLoginError?.errorCode;
    } else if (isPageLoadingError) {
      errorCode = pageLoadingError?.errorCode;
      details = pageLoadingError?.requestData;
    } else if (isPlayerError.current) {
      errorCode = VIDEO_ERROR;
      details = isPlayerError.current;
    }

    return { errorCode, details };
  };

  const getIndexEqualInitialId = (nameOfIndex) => {
    return ((channelsWithPrograms?.find(({ id }) => id === initialId)?.[nameOfIndex])
      || (channelsWithPrograms?.find(({ id }) => id.includes(initialId))?.[nameOfIndex])
      || 0);
  };

  const getChannelEqualSelectedChannel = () => {
    return channelsWithPrograms.find(({ id }) => id === selectedChannelId);
  };

  const handleErrBtn = useCallback(() => isChannelsListError
    ? navigateBack(history, clearOTTFeedData)
    : openEPG(),
  [isChannelsListError]);

  return (
    <>
      {/* eslint-disable-next-line max-len */}
      {!isShownErrorMessage && (isBuffering || isOTTFeedDataLoading || (adDuration <= 0 && adPlaying)) && <Spinner />}
      <NavigationMenu
        isNavBarVisible={(isEpgOpen || activeOverlay || isNavMenuShown || isShownErrorMessage) && !isOTTFeedDataLoading}
        isMenuFocusedOnStart={false}
        focusDownSelector={setDownFocus()}
        onEnterCallback={() => {
          clearOTTFeedData();
          destroyDaiStream();
        }}
      />
      <div className='player-container'>
        {isShownErrorMessage
          ? (<ErrorModal
            onBackHandlerCb={handleErrBtn}
            idContainer={'Live-Playback-Error-Modal'}
            error={getErrorDetails()}
            zIndex={103}
            shouldShowNav={false} // TODO remove with new navbar
          />)
          : (<>
            {source && currentLive &&
              <div>
                <PlayerWrapper
                  ref={videoRef}
                  playerName={livePlayer}
                  onStreamPlay={onStreamPlay}
                  onStreamPause={onStreamPause}
                  onStreamCompleted={onStreamCompleted}
                  onCanPlay={onCanPlay}
                  checkPlayStatePosition={checkPlayStatePosition}
                  onParsingMetadata={onParsingMetadata}
                  changeCCFontStyle={changeCCFontStyle}
                  onBufferingStart={onBufferingStart}
                  onBufferingComplete={onBufferingComplete}
                  onSuccessPrepared={onSuccessPrepared}
                  onSuccessLoad={onSuccessLoad}
                  onFailedLoad={onFailedLoad}
                  destroyDaiStream={destroyDaiStream}
                  neededCCTrack={neededCCTrack.current}
                  setCurrentCue={setCurrentCue}
                  typeContent={'live'}
                  drm={configDrmData}
                  isCaptionsEnabled={isCaptionsEnabled}
                />
                {isCaptionsMenuVisible && isFireTVPlatform && !accessDisabled &&
                    <CaptionsMenu
                      ref={silentElem}
                      visible={isCaptionsMenuVisible}
                      setIsCaptionsMenuVisible={setIsCaptionsMenuVisible}
                    />
                }
                {isCaptionsEnabled && !accessDisabled && (
                  <ClosedCaptions
                    currentCue={currentCue}
                    fontColor={captionsSettings.FONT_COLOR}
                    fontSize={captionsSettings.FONT_SIZE}
                    fontOpacity={captionsSettings.FONT_OPACITY}
                    bgColor={captionsSettings.BACKGROUND_COLOR}
                    bgOpacity={captionsSettings.BACKGROUND_OPACITY}
                    edgeType={captionsSettings.EDGE_TYPE}
                    edgeColor={captionsSettings.EDGE_COLOR}
                    windowColor={captionsSettings.WINDOW_COLOR}
                    windowOpacity={captionsSettings.WINDOW_OPACITY}
                    fontStyle={captionsSettings.FONT_STYLE}
                    isLiveOverlayOpen={activeOverlay}
                  />
                )}
              </div>
            }
          </>)
        }
        {!showAuthPopUp && !isOTTFeedDataLoading && (
          <>
            {channelsWithPrograms.length && !isShownErrorMessage
              ? (isStirrTcMarqueeBrand
                ? (<LiveOverlayWithControls
                  isConnectedToNetwork={isConnectedToNetwork}
                  visible={activeOverlay}
                  accessDisabled={accessDisabled}
                  channel={
                    getChannelEqualSelectedChannel()
                    || channelsWithPrograms.find(({ id }) => id.includes(selectedChannelId))
                    || channelsWithPrograms[0]
                  }
                  channelID={selectedChannelId}
                  isEpgOpen={isEpgOpen}
                  isShownSignInBtn={shouldDisplaySignInBtn()}
                />)
                : (<LiveOverlay
                  isConnectedToNetwork={isConnectedToNetwork}
                  visible={activeOverlay}
                  channel={getChannelEqualSelectedChannel() || channelsWithPrograms[0]}
                  channelID={selectedChannelId}
                  isEpgOpen={isEpgOpen}
                />)
              ) : null
            }
            {channelsWithPrograms.length && isEpgOpen ? (
              <>
                {isStirrTcMarqueeBrand && (
                  <div
                    className={`epg-overlay epg-overlay-open epg-overlay-${OTT_BRAND}`}
                  >
                    <Suspense fallback={renderLoader()}>
                      <Schedule
                        epg={{
                          channelsWithPrograms,
                        }}
                        onChannelSelect={onChannelSelect}
                        isEpgOpen={isEpgOpen}
                        selectedChannelId={selectedChannelId}
                        activeChannel={activeChannel || getIndexEqualInitialId('focusIndex')}
                        activeCategory={activeCategory || getIndexEqualInitialId('categoryIndex')}
                        closeEPGWithTimer={closeEPGWithTimer}
                        clearCloseEpgTimer={clearCloseEpgTimer}
                        setIsNewChannelLoading={setIsNewChannelLoading}
                        setEpgOpen={setEpgOpen}
                        accessDisabled={accessDisabled}
                      />
                    </Suspense>
                  </div>
                )}
                {!isStirrTcMarqueeBrand && (
                  <>
                    {!TTSStatus && (
                      <div
                        className='current-date-and-time'
                        style={{
                          fontFamily: epgStyles.dateDayNameFontFace.fontFamily,
                          textTransform: epgStyles.dateDayNameTextTransform,
                          fontSize: `${epgStyles.dateTimeNowFontSize}px`,
                          color: epgStyles.heroHeadlineTextColor,
                        }}
                      >{moment().format('ddd h:mm A')}</div>
                    )}
                    <VerticalEpg />
                  </>
                )}
              </>
            ) : null}
          </>
        )}
        {!isShownErrorMessage && <Advertisement
          adsContainerRef={adsContainerRef}
          adsVideoRef={adsVideoRef}
          adPlaying={adPlaying}
          adCurrentTime={adCurrentTime}
          adDuration={adDuration}
          adBuffering={isBuffering}
          adsLoading={adsLoadingRef}
          currentAdPosition={currentAdPosition.current}
          adsAmount={adsAmount.current}
          GA_media={currentLive}
          GA_category={`${analyticsTypes.LIVESTREAM} ${analyticsTypes.PRE_ROLL}`}
          isIMAAdPlaying={isIMAAdPlaying.current}
        />}
        {shouldDisplaySignInBtn() && ( // left for Marquee(old sign-in flow)
          <>
            <div className='sign-in-button-wrapper' style={{ bottom: activeOverlay ? '350px' : '100px' }}>
              <Button
                btnID='sign-in-button'
                focusDown='.interaction-info'
                focusUp='#navigationSelectedItem'
                focusLeft='#sign-in-button'
                focusRight='#sign-in-button'
                title='SIGN IN'
                onKeyDown={onSignInBtnPress}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LIVE;
