import { put, call, takeLatest, all, select } from 'redux-saga/effects';

import { RootState } from '../reducers';
import { setCity, setStation } from '../actions/common';
import * as actionTypes from '../actions/types/OTTPage';
import {
  getPage,
  getComponentContent,
  updateComponentContentFree,
  updateComponentContentSubscription,
} from '../../services/OTTPageService';
import { getComponents } from '../../utils/index';
import {
  FEED_TEASER_LIST,
  SEASON_GRID_TEASER_LIST,
  TEXT_TEASER,
  OTTFeedType,
  SEASON_TEASER_LIST,
} from '../../constants/structureTypes';
import { PAGE_COMPONENTS_REQUEST_ERROR, PAGE_LAYOUT_REQUEST_ERROR } from '../../constants/errorCodes';

function* setPageData(data) {
  const store: RootState = yield select();
  const {
    config: {
      allConfigs: {
        API: {
          city_auto_select,
        },
      },
    },
    common: {
      station,
    },
  } = store;

  const { url } = data;
  let content;

  try {
    content = yield call(() => getPage(url));

    yield put({
      type: actionTypes.LOAD_PAGE_SUCCEEDED,
      payload: content,
    });

  } catch (e: any) {

    if (!station && /stationAutoSelection/i.test(url)) {
      yield put(setStation(city_auto_select?.default_station || 'national'));
      yield put(setCity(city_auto_select?.default_city_name || 'National'));
    }

    yield put({
      type: actionTypes.LOAD_PAGE_FAILED,
      error: PAGE_LAYOUT_REQUEST_ERROR,
    });
  }
}

function* setComponentData(dataContent) {
  const { page } = dataContent;
  const store: RootState = yield select();
  const {
    config: {
      allConfigs: {
        API: {
          channel_status,
        },
      },
      features,
    },
    common: {
      station,
    },
  } = store;

  const seasonsIndex: number[] = [];
  const data = yield all(
    page.map((item, index) => {
      if (item.type === TEXT_TEASER) {
        return item;
      } else {
        if (item.type === SEASON_GRID_TEASER_LIST || item.type === SEASON_TEASER_LIST) {
          seasonsIndex.push(index);
        }

        return call(() => getComponentContent(item.content, station)
          .then(res => {
            return {
              ...item,
              component: res?.component || [],
            };
          }));
      }
    })
  );

  const calls: any = [];
  const updatedPositions: any = [];
  let updatedContent = [];

  data.forEach((element, pageIdx) => {
    element?.component?.forEach((item, componentIdx) => {
      if (page[pageIdx].type === FEED_TEASER_LIST && item.item.category === OTTFeedType) {
        updatedPositions.push({ pageIdx, componentIdx });
        if (features.cleeng || features.mvpd) {
          calls.push(
            call(
              () => updateComponentContentSubscription(`${channel_status}${item.item.guid.content}`)
            ),
          );
        } else {
          calls.push(
            call(
              () => updateComponentContentFree(`${channel_status}${item.item.guid.content}`, station)
            ),
          );
        }
      }
    });
  });

  try {
    updatedContent = yield all(calls);

    updatedPositions.forEach((item, index) => {
      data[item.pageIdx].component[item.componentIdx] = updatedContent[index];
    });
  } catch (e) {
    console.log('Error in updating components in OTTPage.ts');
  }

  const components = getComponents(data, seasonsIndex);

  const filteredComponents = components.filter(item => item?.component
    ? Boolean(item?.component?.length)
    : item);

  if (filteredComponents.length > 1 ||
    (filteredComponents.length === 1 && filteredComponents[0].type !== TEXT_TEASER)) {

    yield put({
      type: actionTypes.LOAD_COMPONENT_SUCCEEDED,
      payload: { components: filteredComponents },
    });
  } else {
    yield put({
      type: actionTypes.LOAD_COMPONENT_FAILED,
      error: PAGE_COMPONENTS_REQUEST_ERROR,
    });
  }
}

function* watcher() {
  yield takeLatest(actionTypes.LOAD_PAGE, setPageData);
  yield takeLatest(actionTypes.LOAD_COMPONENT, setComponentData);
}

export default watcher;
