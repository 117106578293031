import React, { forwardRef, useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import focusService from '../../../services/focusService';
import { RootState } from '../../../stores/reducers';
import { errorDetails, IError } from '../../../constants/errorDetails';
import {
  COMET_OTT_BRAND, TENNIS_CHANNEL_OTT_BRAND,
  MARQUEE_OTT_BRAND, STIRR_OTT_BRAND,
  OTT_BRAND, OTTFeedType, isStirrTcMarqueeBrand,
} from '../../../constants/structureTypes';
import { primaryColor } from '../../../constants/colors';
import TCErrorBg from '../../../assets/Tennis_channel/images/tc-error-bg.webp';
import StirrErrorBg from '../../../assets/Stirr/images/stirr-error-bg.webp';
import MarqueeErrorBg from '../../../assets/Marquee_sports/images/marquee-error-bg.webp';
import CometNetworkErrorBg from '../../../assets/Comet/images/Comet-Blurred-BG.png';
import { navigator } from '../../../services/NavigationService';
import * as analyticsTypes from '../../../constants/analyticsTypes';
import { GAEvent } from '../../../services/analytics';
import { DEFAULT_ERROR } from '../../../constants/errorCodes';

import NavigationMenu from '../../NavigationMenu/NavigationMenu';
import Modal from '../Modal';
import Button from '../../Button/Button';

import './ErrorModal.scss';

type TErrorModalProps = {
  error?: {
    errorCode?: string
    details?: any
  };
  onBackHandlerCb?: () => void;
  idContainer?: string;
  zIndex?: number;
  shouldShowNav?: boolean;
};

const ErrorModal = (props: TErrorModalProps) => {
  const {
    error,
    onBackHandlerCb,
    idContainer,
    zIndex,
    shouldShowNav = true,
  } = props;
  const errorStyles = useSelector((state: RootState) => state.config.styles.error);
  const btnStyles = useSelector((state: RootState) => state.config.styles.button);
  const fontFace = useSelector((state: RootState) => state.config.styles.error?.fontFace);
  const isNavError = useSelector((state: RootState) => state.config.navHasError);
  const isConfigError = useSelector((state: RootState) => state.config.configHasError);

  const [isInitialTTSPlayed, setIsInitialTTSPlayed] = useState<boolean>(false);

  const initialFocusItem = useRef<HTMLDivElement>(null);

  const { errorCode = '9000', title, message }:IError = {
    ...errorDetails[error?.errorCode || DEFAULT_ERROR],
    errorCode: error?.errorCode,
  };

  const errorBackgrounds = {
    [COMET_OTT_BRAND]: CometNetworkErrorBg,
    [TENNIS_CHANNEL_OTT_BRAND]: TCErrorBg,
    [MARQUEE_OTT_BRAND]: MarqueeErrorBg,
    [STIRR_OTT_BRAND]: StirrErrorBg,
  };

  const getTTSText = ():string =>
    !isInitialTTSPlayed ? `${errorCode || '9000' } error. ${title}. ${message}` : '';

  const btnGetFocused = (e) => {
    e.target.style.background = errorStyles?.errorAccentColor || primaryColor;
  };

  const btnGetBlurred = () => {
    setIsInitialTTSPlayed(true);
  };

  const onBackHandler = ()=> {
    focusService.setFocusRootToBody();
    focusService.setFocusToNavbar();
  };

  const onEnterHandler = () => (
    navigator.stack.length > 1 ||
      (navigator.stack.length === 1 &&
      navigator.stack[0]?.state?.configData.type === OTTFeedType &&
      isStirrTcMarqueeBrand)
  ) && onBackHandlerCb
    ? onBackHandlerCb()
    : window.location.reload();

  useEffect(() => {
    focusService.setFocus(initialFocusItem?.current);
  }, [title]);

  useEffect(() => {
    const errMessage = error?.details
      ? typeof error?.details === 'string' ? error?.details : JSON.stringify(error?.details)
      : message;

    GAEvent(analyticsTypes.ERROR, title || errorDetails[DEFAULT_ERROR]?.title, errMessage);
  }, []);

  return (
    <Modal
      onBackHandlerCb={onBackHandlerCb || onBackHandler}
      idContainer={idContainer}
      zIndex={zIndex}
    >
      <>
        {!(isNavError || isConfigError) && shouldShowNav && <NavigationMenu
          isMenuFocusedOnStart={false}
          focusDownSelector={'#closeContinueBtn'}
        />}
        <div
          className={'error-modal-content'}
          // eslint-disable-next-line
          role=''
          tabIndex={-1}
          style={{backgroundImage: `url(${errorStyles?.background || errorBackgrounds[OTT_BRAND]}`,
            fontFamily: errorStyles?.fontFace?.fontFamily || fontFace}}
        >
          <div className='error-modal-col'>
            <p aria-hidden={true} className={`error-modal-tag error-modal-tag-${OTT_BRAND}`}
              style={{backgroundColor: errorStyles?.errorAccentColor || primaryColor,
                color: btnStyles?.textFocusColor}}>
              {(`${errorCode || '9000'} error`).toUpperCase()}
            </p>
            <h1 aria-hidden={true} className='error-modal-title'>
              {title}
            </h1>
          </div>
          <div className='error-modal-col'>
            <p aria-hidden={true} className='error-modal-description'>
              {message}
            </p>
            <div className='error-modal-buttons'>
              <Button
                title={'Close & Continue'}
                TTSText={`${getTTSText()} Close and Continue`}
                key={'Close & Continue'}
                btnID={'closeContinueBtn'}
                ref={initialFocusItem}
                onFocusFunc={btnGetFocused}
                onBlurFunc={btnGetBlurred}
                onKeyDown={onEnterHandler}
                focusLeft={'#closeContinueBtn'}
                focusRight={'#closeContinueBtn'}
                focusDown={'#closeContinueBtn'}
                focusUp={isNavError || isConfigError
                  ? '#closeContinueBtn'
                  : '#navigationSelectedItem'
                }
              />
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default forwardRef<HTMLDivElement, TErrorModalProps>(ErrorModal);
