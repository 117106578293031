import { getDataForEden, getProperImage } from '../utils/commonFunc';
import appConfig from '../assets/config.json';
import { OTT_BRAND } from '../constants/structureTypes';
import { IPreview, IPreviewData, IPreviewService } from '../platform/interfaces/preview';

declare const tizen: any;

export class TizenPreviewService implements IPreviewService {
  private serviceId: string = `${appConfig[OTT_BRAND].brandId}.service`; // Application ID for background application
  private messagePortName: string = 'PREVIEW_SERVICE'; // Application port for background application
  private preview: IPreview = {
    sections: [
      {
        title: 'Recommended',
        tiles: [],
      },
    ],
  };

  async setPreviews(edenContentURL: string, deepLinkCallback: () => void) {
    try {
      const data: IPreviewData = await getDataForEden(`${edenContentURL}?station=national`);
      const edenData = data.rss.channel.pagecomponent.component;
      const dataForEden = edenData.length > 20 ? edenData.slice(0, 21) : edenData;

      dataForEden.forEach((item, index) => {
        let playable: boolean = false;

        if (item.item['category'] === 'OTTFeed' || item.item['category'] === 'OTTEpisode') {
          playable = true;
        }

        const image_Url = getProperImage(item.item['media:content']['media:thumbnail'], 530, 300)?.url;

        const tile = Object.assign({}, {
          title: item.item['media:content']['media:title']['content'],
          action_data: `{\"videoIdx\": ${++index}}`, /* eslint-disable-line */
          is_playable: playable,
          image_ratio: '16by9',
          image_url: image_Url,
        });

        this.preview.sections[0].tiles.push(tile);
      });

      if (typeof tizen !== 'undefined') {
        tizen.application.launchAppControl(
          new tizen.ApplicationControl(
            'http://tizen.org/appcontrol/operation/pick', null, 'image/jpeg', null,
            [new tizen.ApplicationControlData('caller', ['ForegroundApp'])],
          ),
          this.serviceId,
          () => {
            console.log(`Launch success: ${this.serviceId}`);
            this.sendMessageToBackgroundApp(this.preview);
            window.addEventListener('appcontrol', deepLinkCallback);
            deepLinkCallback();
          },
          (error) => {
            console.log(JSON.stringify(error));
          },
        );
      }
    } catch (e: any) {
      console.log(`Error in EdenUI script ${e.message}`);
    }
  }

  private sendMessageToBackgroundApp(preview: any): void {
    let remotePort;

    try {
      remotePort = tizen.messageport.requestRemoteMessagePort(this.serviceId, this.messagePortName);
    } catch (e: any) {
      console.log(`Error requesting Tizen remote port: ${e.message}`);

      return;
    }

    const messageData = {
      key: 'PREVIEW',
      value: JSON.stringify(preview),
    };

    try {
      console.log('sendMessage');
      remotePort.sendMessage([messageData]);
    } catch (e: any) {
      console.log(`sendMessage error: ${e.message}`);
    }
  }

  validateDeepLink(): boolean {
    if (typeof tizen !== 'undefined') {
      const requestedAppControl = tizen.application.getCurrentApplication().getRequestedAppControl();

      if (requestedAppControl) {
        const appControlData = requestedAppControl.appControl.data;

        for (let i = 0; i < appControlData.length; i++) {
          if (appControlData[i].key === 'PAYLOAD') {
            const actionData = JSON.parse(appControlData[i].value[0]).values;

            if (JSON.parse(actionData).videoIdx) {
              const videoIdx = JSON.parse(actionData).videoIdx;
              const edenPreview = {
                edenPreview: videoIdx,
              };

              localStorage.setItem('preview', JSON.stringify(edenPreview));
              sessionStorage.setItem('isLaunchedFromEdenPreview', JSON.stringify(true));
            } else {
              localStorage.setItem('preview', JSON.stringify(null));
            }
          }
        }

        return true;
      } else {
        console.log('no req app control');
        localStorage.setItem('preview', JSON.stringify(null));
      }
    }

    return false;
  }
}
