import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import UIGenerator from '../../services/UIGeneratorService';
import { setPageData, setComponentsData, PageWithComponents } from '../../stores/actions/OTTPage';
import FocusService from '../../services/focusService';
import { SetRecommendedPlaylist, SetRecommendedPlaylistItemIndex } from '../../stores/actions/OTTEpisode';
import { CLEAR_OTTCHANNELGUIDE } from '../../stores/actions/types/OTTChannelGuide';
import * as OTTPageActionTypes from '../../stores/actions/types/OTTPage';
import { setCity, setStation } from '../../stores/actions/common';
import { RootState } from '../../stores/reducers';
import { backHandlerForFirstLevelPage, onBackHandler } from '../../utils';
import { isEnabledTTS, useAnnouncePageLoading } from '../../services/TTSService';
import { openPage, IJson } from '../../utils/OpenPage';
import useEventListener from '../../hooks/eventListener';
import {
  navigate,
  navigateBack,
  navigateBackToSpecificPageType,
  navigateWithReset,
} from '../../services/NavigationService';
import * as GAManager from '../../services/analytics/index';
import * as analyticsTypes from '../../constants/analyticsTypes';
import { isRefreshedPage } from '../../utils/commonFunc';
import { NAVIGATE } from '../../constants/analyticsTypes';
import {
  OTTButtonType,
  TEXT_TEASER,
  CAROUSEL_TEASER_LIST,
  HERO_TEASER,
  SEASON_GRID_TEASER_LIST,
  THREE_COLUMN_TEASER_LIST,
  FOUR_COLUMN_TEASER_LIST,
  FEED_TEASER_LIST,
  OTTEpisodeType,
  FULL_SCREEN_CARD,
  SEASON_TEASER_LIST,
  OTTSettingsType,
} from '../../constants/structureTypes';

import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';
import Spinner from '../../components/Spinner/Spinner';
import NavigationMenu from '../../components/NavigationMenu/NavigationMenu';
import ScrollableList from '../../components/ScrollableList/ScrollableList';

import './OTTPage.scss';

type OTTPageProps = {
  location: {
    state: {
      configData: {
        title?: string;
        path: string;
        type?: string;
        web_route?: string;
        GAPath: string;
        tag?: string;
        guid?:{
          content?:string;
          link?: string;
        }
      };
      lastData: {
        dimensions: any;
        ignorePage: boolean;
        lastFocusedElement: string;
        defaultTranslateValue: number,
        defaultSeason: number,
        seasonTranslateValue: number;
        defaultCardIndex: number;
        defaultCarouselID: string,
        sliderCardIdx: number,
        isFirstLevelPage: boolean,
      }
    }
  }
}

const OTTPage = (props: OTTPageProps) => {
  const { location: { state: { configData, lastData } } } = props;

  const isPageDataFetched = useSelector((state: RootState) => state.OTTPage.fetched);
  const pageData = useSelector((state: RootState) => state.OTTPage.page);
  const hasErrorConnection = useSelector((state: RootState) => state.OTTPage.hasError);
  const pageError = useSelector((state: RootState) => state.OTTPage.error);
  const hideNav = useSelector((state: RootState) => state.OTTPage.hideNav);
  const components = useSelector((state: RootState) => state.OTTPage.components);
  const storedCity = useSelector((state: RootState) => state.common.city);
  const generalStyles = useSelector((state: RootState) => state.config.styles['general']);
  const storedStation = useSelector((state: RootState) => state.common.station);
  const activeNavIdx = useSelector((state: RootState) => state.common.activeNavIdx);
  const isConnected = useSelector((state: RootState) => state.common.isConnectedToNetwork);
  const navigation = useSelector((state: RootState) => state.config.navigation);
  const isCleengDataLoading = useSelector((state: RootState) => state.cleengService.isLoading);
  const defaultStationFromConfig = useSelector(
    (state: RootState) => state.config.allConfigs.API?.['city_auto_select']?.['default_station']
  ) || '';
  const defaultCityFromConfig = useSelector(
    (state: RootState) => state.config.allConfigs.API?.['city_auto_select']?.['default_city_name']
  ) || '';

  const [isPageLoading, setPageLoading] = useState<boolean>(true);
  const [carouselExist, setCarouselExist] = useState<boolean>(false);
  const [componentsList, setComponentsList] = useState<any>([]);
  const [isShownError, setIsShownError] = useState<boolean>(false);
  const [seasons, setSeasons] = useState<string[]>([]);
  const [defaultCarouselID, setDefaultCarouselID] = useState<string>(lastData.defaultCarouselID);

  const missingUrlTimer = useRef<any>('');
  const pageTranslateValue = useRef<number>(lastData.defaultTranslateValue);
  const seasonTranslateValue = useRef<number>(lastData.seasonTranslateValue);
  const selectedSeasonRef = useRef<number>(lastData.defaultSeason);
  const defaultFocusedElement = useRef<any>(null);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (
      !!(isPageDataFetched && components.length && pageData.length && !isCleengDataLoading) ||
      isShownError ||
      pageData[0]?.type === FULL_SCREEN_CARD
    ) {
      setPageLoading(false);
    }
  }, [isPageDataFetched, isShownError, isCleengDataLoading, components.length, pageData.length, pageData[0]?.type]);

  const getRowIndexOfLastFocusedElement = (defaultFocusedElement) => {
    if (defaultFocusedElement) {
      const rowIndexString = defaultFocusedElement?.className.split(' ').find(c => /rowIndex.*/.test(c));

      return rowIndexString ? parseInt(rowIndexString, 10) : 0;
    }

    return 0;
  };

  const getComponent = (item: any, index: number, isLastCarousel?: boolean) => {
    const cardPressed = (cardIndex: number, e: React.KeyboardEvent, seasonId?: string, sliderCardIdx?: number) =>
      onCardPressed(index, cardIndex, e, seasonId, sliderCardIdx);

    switch (item?.type || item[0]?.type) {
      case CAROUSEL_TEASER_LIST:
        return UIGenerator.getCarouselTeaserList({
          content: item.component,
          onMount: () => setCarouselExist(true),
          onPress: cardPressed,
          carouselExist: carouselExist,
          index: index,
          defaultCardIndex: lastData.sliderCardIdx,
        });

      case FEED_TEASER_LIST:
        return UIGenerator.getFeedTeaserList({
          content: item.component,
          index: index,
          title: item?.displayTitle,
          onPress: cardPressed,
          defaultCardIndex: lastData.defaultCardIndex,
          defaultCarouselID: lastData.defaultCarouselID,
          carouselUUID: item?.pageComponentUuid,
        });

      case HERO_TEASER:
        return UIGenerator.getHeroTeaser({
          content: item.component,
        });

      case SEASON_GRID_TEASER_LIST:
      case SEASON_TEASER_LIST:
        return UIGenerator.getSeasonGrid({
          content: item,
          onPress: cardPressed,
          seasons: seasons,
          carouselExist: carouselExist,
          index: index,
          initialFocusedElement: lastData.lastFocusedElement,
          translateValueRef: seasonTranslateValue,
          selectedSeasonRef: selectedSeasonRef,
          defaultCardIndex: lastData.defaultCardIndex,
          seasonDefaultCarouselID: lastData.defaultCarouselID,
        });

      case TEXT_TEASER:
        return UIGenerator.getTextTeaser({
          content: item,
        });

      case THREE_COLUMN_TEASER_LIST:
        return UIGenerator.getThreeColumnTeaserList({
          content: item.component,
          index: index,
          title: item?.displayTitle,
          onPress: cardPressed,
          isLastCarousel: isLastCarousel,
        });

      case FOUR_COLUMN_TEASER_LIST:
        return UIGenerator.getFourColumnTeaserList({
          content: item.component,
          index: index,
          title: item?.displayTitle,
          onPress: cardPressed,
          isLastCarousel: isLastCarousel,
        });

      case FULL_SCREEN_CARD:
        return UIGenerator.getFullScreenCard({
          data: pageData[0],
          onFullScreenCardFinish,
        });

      default:
        return UIGenerator.getStandardTeaserList({
          content: item.component,
          index: index,
          title: item?.displayTitle,
          onPress: cardPressed,
          isLastCarousel: isLastCarousel,
          defaultCarouselID: lastData.defaultCarouselID,
          defaultCardIndex: lastData.defaultCardIndex,
          carouselUUID: item?.pageComponentUuid,
        });
    }
  };

  const createComponents = () => {
    if (pageData && pageData.length && pageData[0].type === FULL_SCREEN_CARD) {
      return (<div>{getComponent({ component: '', type: 'FULL_SCREEN_CARD' }, 0)}</div>);
    } else {
      return components.map((carousel, index) => {
        const isLastCarousel = components.length - 1 === index;

        return (
          <div
            className={`row-${index} carousel-row`} key={`carousel-row-${index}`}
          >
            {getComponent(carousel, index, isLastCarousel)}
          </div>
        );
      });
    }
  };

  const formRecommendedPlaylist = (items: Array<any>): Array<any> => {
    return items.filter(({ item: { category } }) => category === OTTEpisodeType);
  };

  const setRecommendedPlaylist = (items: Array<any>, activeIndex: number): void => {
    const recommendedPlaylist = formRecommendedPlaylist(items);
    const currentPlaylistItemIndex = recommendedPlaylist.findIndex(
      ({ item }) => item.guid.content === items[activeIndex].item.guid.content,
    );

    dispatch(SetRecommendedPlaylist(recommendedPlaylist));
    dispatch(SetRecommendedPlaylistItemIndex(currentPlaylistItemIndex));
  };

  const getFocusDownSelector = () => {
    if (seasons.length) {
      if (isEnabledTTS() && components.length && (components[0] as PageWithComponents)?.type === HERO_TEASER) {
        return '.brand-info';
      } else {
        return '.season-list-last-focused-item';
      }
    } else if (carouselExist) {
      return '.watch-button-ref';
    } else {
      return '.card-main';
    }
  };

  const pageStyles = {
    display: isConnected ? 'block' : 'none',
    background: !isPageLoading && componentsList.length ? `url(${generalStyles['background']})` : '#000000',
  };

  const isFirstLevelPage = useMemo((): boolean => {
    return lastData?.isFirstLevelPage || false;
  }, [configData.guid]);

  const clearOTTPageData = () => {
    dispatch({
      type: OTTPageActionTypes.CLEAR_OTTPAGE,
    });

    setComponentsList([]);
    setSeasons([]);
    setCarouselExist(false);
    pageTranslateValue.current = 0;
    selectedSeasonRef.current = 0;
    seasonTranslateValue.current = 0;
    defaultFocusedElement.current = null;
    setDefaultCarouselID('');
    setPageLoading(true);
  };

  const onFullScreenCardFinish = ({ station, city }) => {
    navigateWithReset(history, openPage(navigation[0] as unknown as IJson,
      { station, city, navIdx: 0, isFirstLevelPage: true }),
    clearOTTPageData, true);
  };

  const onCardPressed = (
    rowIndex: number,
    cardIndex: number,
    e: React.KeyboardEvent,
    seasonId?: string,
    sliderCardIdx?: number,
  ) => {
    const seasonRowIndex = getRowIndexOfLastFocusedElement(e.target);

    let item;
    let seasonIdx;
    let carouselUUID;

    if (seasonId === undefined) {
      item = components[rowIndex]['component'][cardIndex]['item'];
      carouselUUID = (components[rowIndex] as PageWithComponents)?.pageComponentUuid;
      if (item['category'] === OTTEpisodeType) {
        setRecommendedPlaylist(components[rowIndex]['component'], cardIndex);
      }
    } else {
      seasonIdx = pageData.findIndex(item => item.pageComponentUuid === seasonId) - 1;
      item = components[rowIndex][seasonIdx]['component'][cardIndex]['item'];
      carouselUUID = components[rowIndex][seasonIdx]?.pageComponentUuid;
      item['category'] === OTTEpisodeType && setRecommendedPlaylist(
        components[rowIndex][seasonIdx]['component'],
        cardIndex,
      );
    }

    if (item['category'] === OTTButtonType) {
      let station = item['media:content']['sinclair:action_value'];
      let city = item['media:content']['sinclair:action_config'].city;

      if (station !== '-NEW') {
        GAManager.setDimensions({ stationId: station });
        if (configData['media:content'] && configData['media:content']['sinclair:ident']) {
          GAManager.GAEvent(
            analyticsTypes.SET_FAVORITE,
            analyticsTypes.SELECT,
            station,
          );
        }
        GAManager.GAEvent(
          analyticsTypes.STATION_SELECTION,
          analyticsTypes.SELECT,
          station,
        );
        dispatch(setCity(city));
        dispatch(setStation(station));
        dispatch({
          type: CLEAR_OTTCHANNELGUIDE,
        });
        navigateBackToSpecificPageType(OTTSettingsType, history, clearOTTPageData);
      } else {
        station = '';
        city = '';
        navigate(
          history,
          openPage(item as unknown as IJson,
            { station, city, navIdx: 0 }),
          clearOTTPageData,
          {
            lastFocusedElement: document.activeElement?.id,
            defaultTranslateValue: pageTranslateValue.current,
            defaultCardIndex: cardIndex,
            defaultCarouselID: carouselUUID,
            sliderCardIdx: sliderCardIdx,
          }
        );
      }
    } else {
      const seasonTitle = (seasonIdx !== undefined && seasonIdx >= 0)
        ? pageData[++seasonIdx]['title']
        : '';

      const seriesTitle = (seasonId !== undefined)
        ? pageData[0]['title']
        : '';

      const showTitle = (seasonId !== undefined)
        ? pageData[0]['title']
        : pageData[rowIndex]['displayTitle'];

      if (/News/g.test(item['media:content']['media:title']['content'])) {
        navigate(
          history,
          openPage(Object.assign(item, { title: 'News' }) as unknown as IJson,
            {
              station: storedStation, city: storedCity, navIdx: activeNavIdx,
              seasonTitle: seasonTitle, seriesTitle: seriesTitle, showTitle: showTitle,
            }),
          clearOTTPageData,
          {
            lastFocusedElement: document.activeElement?.id,
            defaultTranslateValue: pageTranslateValue.current,
            defaultCardIndex: cardIndex,
            defaultSeason: seasonRowIndex,
            sliderCardIdx: sliderCardIdx,
          }
        );
      } else {
        const fieldsObj = {
          contentUuid: item['guid']['content'],
          sourceScreen: (configData['path'] || ''),
          destinationScreen: item['link'],
          showTitle: item['media:content']['media:title']['content'],
          stationId: storedStation,
        };

        item['category'] === OTTEpisodeType ?
          navigate(
            history,
            openPage(Object.assign(item, {
              title: item['slug'],
            }) as unknown as IJson,
            {
              station: storedStation, city: storedCity, seasonTitle: seasonTitle, seriesTitle: seriesTitle,
              showTitle: showTitle,
            }),
            clearOTTPageData,
            {
              lastFocusedElement: document.activeElement?.id,
              defaultTranslateValue: pageTranslateValue.current,
              defaultCardIndex: cardIndex,
              defaultCarouselID: carouselUUID,
              sliderCardIdx: sliderCardIdx,
              defaultSeason: selectedSeasonRef.current,
              seasonTranslateValue: seasonTranslateValue.current,
            }
          ) :
          navigate(
            history,
            openPage(
              { ...item, isFlowFromTeaser: true } as unknown as IJson,
              {
                station: storedStation, city: storedCity, navIdx: activeNavIdx,
                seasonTitle: seasonTitle, seriesTitle: seriesTitle, showTitle: showTitle,
                dimensions: fieldsObj,
              }
            ),
            clearOTTPageData,
            {
              lastFocusedElement: document.activeElement?.id,
              defaultTranslateValue: pageTranslateValue.current,
              defaultCardIndex: cardIndex,
              defaultCarouselID: carouselUUID,
              sliderCardIdx: sliderCardIdx,
              defaultSeason: selectedSeasonRef.current,
              seasonTranslateValue: seasonTranslateValue.current,
            }
          );
      }
    }
  };

  useAnnouncePageLoading(isPageLoading);

  useEffect(() => {
    componentsList.length && setComponentsList([]);
    seasons.length && setSeasons([]);
    carouselExist && setCarouselExist(false);

    dispatch(setPageData(configData.path));
    // eslint-disable-next-line
  }, [configData]);

  useEffect(() => {
    if (pageData.length) {
      GAManager.GAScreen(configData.GAPath);
      if (pageData[0].type === FULL_SCREEN_CARD) {
        GAManager.GAScreen(FULL_SCREEN_CARD);
        setComponentsList(createComponents());
      } else {
        dispatch(setComponentsData(pageData));
      }
      // error handling for failed FULL_SCREEN_CARD request(take station and city from config)
    } else if (hasErrorConnection && /stationAutoSelection/i.test(configData.path)) {
      onFullScreenCardFinish({
        station: defaultStationFromConfig || 'national', city: defaultCityFromConfig || 'National',
      });
    }
    // eslint-disable-next-line
  }, [pageData.length, [...navigation.map(item => item.type)].length]);

  useEffect(() => {
    if (Object.keys(pageData)?.length > 0 && components && components?.length && !componentsList?.length) {
      const componentsSeasons: string[] = [];

      pageData.forEach((item) => {
        if (item.type === SEASON_GRID_TEASER_LIST || item.type === SEASON_TEASER_LIST) {
          if (!componentsSeasons.includes(item.title!)) {
            componentsSeasons.push(item.title!);
          }
        }
      });
      setSeasons(prev => [...prev, ...componentsSeasons]);
    }
    // eslint-disable-next-line
  }, [pageData, components, componentsList?.length]);

  useEffect(() => {
    if (components.length) {
      setComponentsList(createComponents());
    }
    // eslint-disable-next-line
  }, [seasons]);

  useEffect(() => {
    componentsList.length && defaultCarouselID && setComponentsList(createComponents());
  }, [defaultCarouselID, componentsList.length]);

  useEffect(() => {
    if (componentsList.length && !hasErrorConnection && !lastData.lastFocusedElement?.includes('watch-button')) {
      if (lastData.lastFocusedElement) {
        defaultFocusedElement.current = document.getElementById(lastData.lastFocusedElement);
      }

      // find initial focus for second level pages without navBar;
      if (!isFirstLevelPage && !defaultFocusedElement.current && !lastData.lastFocusedElement) {
        defaultFocusedElement.current = document.querySelector(getFocusDownSelector());
      }

      // find initial focus when on back carousel with last focused teaser failed to load;
      if (!defaultFocusedElement.current && lastData.lastFocusedElement) {
        defaultFocusedElement.current = isFirstLevelPage
          ? document.getElementById('navigationSelectedItem')
          : document.querySelector(getFocusDownSelector());
      }

      FocusService.setFocus(defaultFocusedElement.current);
    }

    if (!hasErrorConnection || componentsList.length) {
      if (missingUrlTimer.current) {
        clearTimeout(missingUrlTimer.current);
      }
      if (isShownError) {
        setIsShownError(false);
      }
    } else {
      if (missingUrlTimer.current) {
        clearTimeout(missingUrlTimer.current);
      }
      missingUrlTimer.current = setTimeout(() => {
        setIsShownError(true);
        clearTimeout(missingUrlTimer.current);
      }, 2000,
      );
    }

    return () => {
      if (missingUrlTimer.current) {
        clearTimeout(missingUrlTimer.current);
      }
    };
    // eslint-disable-next-line
  }, [componentsList.length, hasErrorConnection]);

  useEffect(() => {
    if (lastData?.dimensions) {
      GAManager.setDimensions({
        contentUuid: lastData?.dimensions['contentUuid'],
        sourceScreen: (lastData?.dimensions['sourceScreen'] || ''),
        destinationScreen: lastData?.dimensions['destinationScreen'],
        showTitle: lastData?.dimensions['showTitle'],
      });

      return () => {
        GAManager.clearHitDimensions();
      };
    }
  }, [lastData, storedStation]);

  useEffect(() => {
    if (!isRefreshedPage(history.location.pathname)) {
      if (configData['media:content'] && configData['media:content']['media:title']) {
        GAManager.GAEvent(
          NAVIGATE,
          analyticsTypes.SELECT,
          configData['media:content']['media:title']['content'],
        );
      }
    }
    // eslint-disable-next-line
  }, [configData]);

  useEventListener('keydown', (e: React.KeyboardEvent) => {
    if (isPageLoading || !componentsList.length && !isShownError) {
      return;
    }

    onBackHandler(e,
      () => backHandlerForFirstLevelPage(
        history,
        !hideNav && componentsList.length && isFirstLevelPage,
        clearOTTPageData
      )
    );
  });

  return (
    <div className='ott-page' style={pageStyles}>
      {isPageLoading
        ? <Spinner />
        : isShownError
          ? <ErrorModal
            error={{ errorCode: pageError }}
            onBackHandlerCb={() => navigateBack(history, clearOTTPageData, isFirstLevelPage)}
            zIndex={4000}
          />
          : <>
            <ScrollableList
              transition={0.3}
              translateValueRef={pageTranslateValue}
              initialTranslateValue={lastData.defaultTranslateValue}
              isSeasonsPage={!!seasons.length}
              isMainPageScroll={true}
            >
              {!hideNav && isFirstLevelPage && componentsList.length && (
                <NavigationMenu
                  isMenuFocusedOnStart={!lastData.lastFocusedElement}
                  focusDownSelector={getFocusDownSelector()}
                  onEnterCallback={clearOTTPageData}
                />
              )}
              {componentsList}
            </ScrollableList>
          </>
      }
    </div>
  );
};

export default OTTPage;
