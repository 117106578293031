import React, { useEffect, useRef, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../stores/reducers';
import { setCaptionsStatus } from '../../stores/actions/common';
import FocusService from '../../services/focusService';
import { getKeyInfo } from '../../utils';
import { moveFocusToPrevActiveElem } from '../../utils/commonFunc';

import './CaptionsMenu.scss';

type CaptionsMenuProps = {
  visible: boolean;
  setIsCaptionsMenuVisible: (isVisible: boolean) => void;
}

const CaptionsMenu = (props: CaptionsMenuProps, ref: any) => {
  const {
    visible,
    setIsCaptionsMenuVisible,
  } = props;

  const refFocusCounter = useRef<any>(null);

  const dispatch = useDispatch();
  const isEnabled = useSelector((state: RootState) => state.common.isCCEnabled);

  const captionsHandler = (e, isCCEnabled) => {
    if (getKeyInfo(e.keyCode) === 'Enter') {
      dispatch(setCaptionsStatus(isCCEnabled));
      localStorage.setItem('isCCEnabled', JSON.stringify(isCCEnabled));
      setIsCaptionsMenuVisible(false);
    }
  };

  useEffect(() => {
    clearTimeout(refFocusCounter.current);

    if (visible) {
      refFocusCounter.current = setTimeout(() => {
        const focusedOption = document.querySelector('.captions-option-on');

        FocusService.setFocus(focusedOption);
      }, 1);
    } else {
      moveFocusToPrevActiveElem(ref.current, 'appTitle');
    }

  }, [visible]);

  return (
    <div className='captions-menu' style={{display: visible ? 'flex' : 'none'}}>
      <div className='captions-title'>Subtitles</div>
      <div className='captions-options'>
        <div
          className={`captions-option captions-option-on ${isEnabled ? 'captions-option-active' : ''}`}
          tabIndex={0}
          data-tv-focus-up='.captions-option-on'
          data-tv-focus-right='.captions-option-off'
          data-tv-focus-down='.captions-option-on'
          data-tv-focus-left='.captions-option-on'
          onKeyDown={(e) => captionsHandler(e, true)}
          aria-label='Subtitles ON'
          // eslint-disable-next-line
          role=''
        >ON</div>
        <div
          className={`captions-option captions-option-off ${!isEnabled ? 'captions-option-active' : ''}`}
          tabIndex={0}
          data-tv-focus-up='.captions-option-off'
          data-tv-focus-right='.captions-option-off'
          data-tv-focus-down='.captions-option-off'
          data-tv-focus-left='.captions-option-on'
          onKeyDown={(e) => captionsHandler(e, false)}
          aria-label='Subtitles OFF'
          // eslint-disable-next-line
          role=''
        >OFF</div>
      </div>
    </div>
  );
};

export default React.memo(forwardRef(CaptionsMenu));
