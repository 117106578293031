import * as actionTypes from '../actions/types/cleengService';
import { ILoginError } from './Login';

export interface ILoadPurchaseDataAction {
  type: typeof actionTypes.LOAD_PURCHASE_DATA;
  isRefresh?: boolean;
}

export interface ILoadPurchaseDataSucceededAction {
  type: typeof actionTypes.LOAD_PURCHASE_DATA_SUCCEEDED;
  payload: {
    purchaseOptions: any;
    activeSubscriptions: any;
    allSubscriptions: any;
    currentSubscriptionDetails: any;
    isCurrentSubscriptionActive: boolean;
    isRefresh: boolean;
  };
}

export interface ILoadPurchaseDataFailedAction {
  type: typeof actionTypes.LOAD_PURCHASE_DATA_FAILED;
  error: ILoginError;
}

interface IClearPurchaseData {
  type: typeof actionTypes.CLEAR_PURCHASE_DATA;
}

interface IResetRefreshDataState {
  type: typeof actionTypes.RESET_REFRESH_DATA_STATE;
}

export type CleengActions =
   ILoadPurchaseDataAction |
   ILoadPurchaseDataSucceededAction |
   ILoadPurchaseDataFailedAction |
   IClearPurchaseData |
   IResetRefreshDataState;

export const getPurchaseData = (isRefresh?: boolean): CleengActions => ({
  type: actionTypes.LOAD_PURCHASE_DATA,
  isRefresh,
});
