import * as actionTypes from '../actions/types/liverampCollector';
import { ActionLiveramp } from '../actions/liverampCollector';

interface ILiverampState {
  isLoading: boolean,
  hasError: boolean,
}

const initialState: ILiverampState = {
  isLoading: false,
  hasError: false,
};

const liveramp = (state = initialState, action: ActionLiveramp) => {
  switch (action.type) {
    case actionTypes.LOAD_LIVERAMP:
      return { ...state, isLoading: true };
    case actionTypes.LOAD_LIVERAMP_SUCCEEDED:
      return { ...state, isLoading: false };
    case actionTypes.LOAD_LIVERAMP_FAILED:
      return { ...state, isLoading: false, hasError: true };
    default:
      return state;
  }
};

export default liveramp;
