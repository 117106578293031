import React, { useLayoutEffect, useState, MutableRefObject } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { RootState } from '../../stores/reducers';
import { logoutMVPD, refreshRegCode, clearMVPDAuthZError } from '../../stores/actions/Login';
import { MVPD } from '../../constants/text';
import { ABANDON, LOG_OUT, SETTINGS, TV_PROVIDER, VIDEO } from '../../constants/analyticsTypes';
import { isMarqueeBrand } from '../../constants/structureTypes';
import { onBackHandler } from '../../utils';
import useEventListener from '../../hooks/eventListener';
import { GAEvent, GAScreen } from '../../services/analytics';
import { navigateBack } from '../../services/NavigationService';
import { DEFAULT_MVPD_ERROR } from '../../constants/errorCodes';

import CodeScreen from '../../components/MVPD/CodeScreen/CodeScreen';
import MvpdPaywallScreen from '../../components/MVPD/MvpdPaywallScreen/MvpdPaywallScreen';
import Spinner from '../../components/Spinner/Spinner';
import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';

import './MvpdFlow.scss';

interface IMvpdFlow {
  isFlowFromPlayback?: boolean;
  successCallback: () => void;
  goBackFromPlayback: () => void;
  uuid?: string;
  signInBtnPressed: MutableRefObject<boolean>;
}

const MvpdFlow = (props: IMvpdFlow) => {
  const {
    isFlowFromPlayback = false,
    successCallback,
    goBackFromPlayback,
    uuid,
    signInBtnPressed,
  } = props;

  const isMVPDError = useSelector((state: RootState) => state.Login.hasError);
  const mvpdError = useSelector((state: RootState) => state.Login.mvpd.error);
  const mvpdStyles = useSelector((state: RootState) => state.config.styles.mvpd);
  const isLoading = useSelector((state: RootState) => state.Login.isLoading);

  const [isShownPaywallScreen, setIsShownPaywallScreen] = useState<boolean>(true);

  const dispatch = useDispatch();
  const history = useHistory();

  const analyticsValue = uuid ? VIDEO : SETTINGS;

  const mainStyles = {
    background: `url(${mvpdStyles ? isShownPaywallScreen
      ? mvpdStyles[isMarqueeBrand ? 'signinBackground' : 'paywallBackground']
      : !isLoading && mvpdStyles['codeBackground'] : '#000'})`,
  };

  const showRegCode = () => {
    dispatch(refreshRegCode());
    setIsShownPaywallScreen(false);
  };

  const signOutOfProvider = () => {
    GAEvent(MVPD, LOG_OUT, SETTINGS);
    dispatch(logoutMVPD());
    handleBack();
  };

  const handleBack = () => {
    if (isShownPaywallScreen || isMVPDError) {
      isFlowFromPlayback
        ? goBackFromPlayback()
        : navigateBack(history);
    } else if (signInBtnPressed?.current) {
      goBackFromPlayback();
      signInBtnPressed.current = false;
    } else {
      setIsShownPaywallScreen(true);
    }

    isMVPDError && dispatch(clearMVPDAuthZError());
  };

  useLayoutEffect(() => {
    GAScreen(TV_PROVIDER);

    if (signInBtnPressed?.current) {
      showRegCode();
    }

    return () => {
      const isAuthMVPD = localStorage.getItem('AuthMVPD');

      if (!isAuthMVPD) {
        GAEvent(MVPD, ABANDON, analyticsValue);
      }

      dispatch(clearMVPDAuthZError());
    };
    // eslint-disable-next-line
  }, []);

  useEventListener('keydown', (e) => {
    onBackHandler(e, handleBack, history);
  });

  return (
    <div
      className='mvpd-main-container'
      style={mainStyles}
    >
      {isLoading ? <Spinner /> : (
        <>
          {isMVPDError ? (
            <ErrorModal error={{errorCode: mvpdError.errorCode || DEFAULT_MVPD_ERROR }} onBackHandlerCb={handleBack} />
          ) : (
            <>
              <div
                className='mvpd-content-container'
              >
                {isShownPaywallScreen
                  ? (
                    <MvpdPaywallScreen
                      isFlowFromPlayback={isFlowFromPlayback}
                      signOutOfProvider={signOutOfProvider}
                      showRegCode={showRegCode}
                      handleBack={handleBack}
                    />
                  )
                  : (
                    <CodeScreen
                      successCallback={successCallback}
                      goToAccountPage={() => navigateBack(history)}
                      analyticsValue={analyticsValue}
                      handleBack={handleBack}
                    />
                  )
                }
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MvpdFlow;
