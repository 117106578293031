import store from './../stores/index';
import { toggleExitModal } from '../stores/actions/common';
import { SET_ACTIVE_NAV } from '../stores/actions/types/common';
import {
  isStirrBrand,
  isStirrTcMarqueeBrand,
  isTcBrand,
  OTTFeedType,
  OTTSubscribeType,
} from '../constants/structureTypes';
import { IJson, openPage } from '../utils/OpenPage';

export interface IStack {
  pathname: string;
  state: {
    lastData: any;
    configData: {
      path: string;
      GAPath?: string;
      category?: string;
      type?: string;
      title?: string;
    }
  },
}

interface IPageLastData {
  lastFocusedElement?: string,
  defaultTranslateValue?: number,
  defaultCardIndex?: number,
  defaultCarouselID?: string,
  sliderCardIdx?: number,
  defaultSeason?: number,
  seasonTranslateValue?: number,
  isFirstLevelPage?: boolean,
}

class Navigator {
  stack: IStack[];

  constructor() {
    this.stack = [];
  }

  push(page: IStack) {
    const index = this.stack.indexOf(page);

    if (index < 0) {
      this.stack.push(page);
    } else {
      this.stack = this.stack.slice(0, index + 1);
    }
  }

  pop() {
    if (this.stack.length > 1) {
      this.stack.pop();
    }
  }

  reset() {
    this.stack = [];
  }

  getCurrentPage() {
    return this.stack[this.stack.length - 1];
  }
}

const navigator = new Navigator();

function navigateBack(history, callback?: () => void, isFirstLevelPage: boolean = false) {
  const currentPage = navigator.getCurrentPage();
  const indexOfCurrentPage = navigator.stack.indexOf(currentPage);
  const state = store.getState();
  const filteredNavData = getFilteredNavData();
  let navIndex = 0;

  if (isStirrBrand &&
    navigator.stack[navigator.stack.length - 1].state?.configData?.category === OTTFeedType &&
    navigator.stack[navigator.stack.length - 1].state?.configData?.category === filteredNavData[0].type
  ) {
    navigator.reset();
    navigator.push(currentPage);
    store.dispatch(toggleExitModal());
  } else if (indexOfCurrentPage !== 0 && isStirrTcMarqueeBrand && isFirstLevelPage) {
    const goToSpecificNavItemCb = (): void => {
      store.dispatch({ type: SET_ACTIVE_NAV, navIdx: 0 });
      callback && callback();
    };

    goToSpecificNavItem(filteredNavData[0].type, history, goToSpecificNavItemCb);
  } else {
    navigator.pop();

    let pageToNavigate = navigator.getCurrentPage();

    if (isTcBrand && pageToNavigate) {
      const isTCUserLogin = state.Login.isUserTCLogin;

      if (pageToNavigate.state?.configData?.type === OTTSubscribeType && isTCUserLogin) {
        navigator.pop();
        pageToNavigate = navigator.getCurrentPage();
      }

      navIndex = filteredNavData.findIndex(item => item.type === pageToNavigate.state?.configData?.type);
    } else {
      navIndex = filteredNavData?.findIndex(item => (item.type === pageToNavigate?.state?.configData?.type
        && item.title === pageToNavigate?.state?.configData?.title))
        || pageToNavigate.state?.lastData?.navIdx
        || 0;
    }

    const navIdx = navIndex >= 0 ? navIndex : pageToNavigate.state?.lastData?.navIdx;

    if (pageToNavigate?.pathname && indexOfCurrentPage > 0) {
      callback && callback();
      store.dispatch({ type: SET_ACTIVE_NAV, navIdx });
      history.push(pageToNavigate);
    } else {
      store.dispatch(toggleExitModal());
    }
  }
}

function navigateBackToSpecificPageType(pageType: string, history, callback?: () => void) {
  const currentPage = navigator.getCurrentPage();
  const indexOfCurrentPage = navigator.stack.indexOf(currentPage);
  const filteredNavData = getFilteredNavData();
  let navIndex = 0;
  let pageToNavigate;

  do {
    navigator.pop();
    pageToNavigate = navigator.getCurrentPage();
  } while (pageType !== pageToNavigate?.state?.configData?.type);

  navIndex = filteredNavData?.findIndex(item => (item.type === pageToNavigate?.state?.configData?.type
    && item.title === pageToNavigate?.state?.configData?.title))
    || pageToNavigate.state?.lastData?.navIdx
    || 0;

  const navIdx = navIndex >= 0 ? navIndex : pageToNavigate.state?.lastData?.navIdx;

  if (pageToNavigate?.pathname && indexOfCurrentPage > 0) {
    callback && callback();
    store.dispatch({ type: SET_ACTIVE_NAV, navIdx });
    history.push(pageToNavigate);
  } else {
    store.dispatch(toggleExitModal());
  }
}

function getRefreshedPage(page: IStack) {
  return Object.assign(page, page.pathname = `${page.pathname}/${Date.now()}`);
}

function getFilteredNavData() {
  const state = store.getState();
  const isTCUserLogin = state.Login.isUserTCLogin;
  const isCurrentSubscriptionActive = state.cleengService.isCurrentSubscriptionActive;
  const navigation = state.config.navigation;

  return isTCUserLogin && isCurrentSubscriptionActive
    ? navigation.filter(item => item.type !== OTTSubscribeType)
    : navigation;
}

function navigate(history, page: IStack, callback?: () => void, pageLastData?: IPageLastData) {
  let nextPage: IStack = page;
  const currentPage = navigator.getCurrentPage();

  if (navigator?.stack[navigator.stack.length - 1]) {
    navigator.stack[navigator.stack.length - 1].state.lastData = {
      ...navigator.stack[navigator.stack.length - 1].state.lastData,
      ...pageLastData,
    };
  }

  callback && callback();

  if (currentPage?.pathname !== page?.pathname) {
    navigator.push(page);
  } else {
    nextPage = getRefreshedPage(page);
  }

  history.push(nextPage);
}

function navigateWithoutHistory(history, page: IStack, callback?: () => void, lastFocusedElement?: string,) {
  const currentPage = navigator.getCurrentPage();

  if (navigator?.stack[navigator.stack.length - 1]) {
    navigator.stack[navigator.stack.length - 1].state.lastData.lastFocusedElement = lastFocusedElement;
  }

  if (currentPage?.pathname !== page?.pathname) {
    const refreshPage = getRefreshedPage(page);

    callback && callback();
    history.push(refreshPage);
  }
}

function navigateWithReset(history, page: IStack, callback?: () => void, ignoreCurrentPage = false) {
  const currentPage = navigator.getCurrentPage();

  const newPage = { ...page };

  if (ignoreCurrentPage) {
    newPage.pathname = `${page.pathname}/${Date.now()}`;
  }

  if (currentPage?.pathname !== newPage?.pathname) {
    callback && callback();
    navigator.reset();
    navigator.push(newPage);
    history.push(newPage);
  }
}

function pushPageToHistory(page: IStack) {
  navigator.push(page);
}

function goToSpecificNavItem(
  pageType: string,
  history: any,
  callback: (navIndex: number) => void,
  lastFocusedElement?: string
) {
  const filteredNavData = getFilteredNavData();
  const navIndex = filteredNavData.findIndex(item => item.type === pageType);

  navigateWithReset(
    history,
    openPage(
      filteredNavData[navIndex] as unknown as IJson,
      { navIdx: navIndex, isFirstLevelPage: true, lastFocusedElement }
    ),
    () => callback(navIndex)
  );
}

export {
  navigate,
  navigateWithoutHistory,
  navigateWithReset,
  navigateBack,
  navigateBackToSpecificPageType,
  pushPageToHistory,
  goToSpecificNavItem,
  navigator,
};
