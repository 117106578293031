import {path, pathOr} from 'ramda';

export function stringify(color) {
  const alpha = pathOr(1, 'a', color);
  const red = pathOr(0, 'r', color);
  const green = pathOr(0, 'g', color);
  const blue = pathOr(0, 'b', color);

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}

export function getColor(color) {
  const rgba = path(['rgba', 0], color);

  return stringify(rgba);
}

export function getLinearGradient(gradient) {

  const stops = [];
  const colors = pathOr([], ['linear_gradient', 1, 'rgba'], gradient);

  // Apply gradient angle
  stops.push(`${path(['linear_gradient', 0, 'angle'], gradient)}deg`);

  // Map colors to gradient stops
  colors.forEach(color => {
    let v = '';

    v = stringify(color);
    if (color.percentage) {
      v += ` ${color.percentage}%`;
    }
    // Pulling out all the stops.
    stops.push(v);
  });

  return `linear-gradient(${stops.join(', ')})`;
}
