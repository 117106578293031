import * as actionTypes from '../actions/types/OTTEpisode';

export type Message = string | object;
export type RecommendedPlaylist = Array<OTTEpisodeComponent>;
export type CurrentPlaylistItemIndex = number;
export type AddMidrollBreaks = Array<number>;

export interface OTTEpisodeComponent {
  item: {
    link: string;
    'media:content': object;
  }
}

export interface IErrorAPI {
  errorCode: string,
  requestData: any;
}

export interface DRMAccesses {
  url: string;
  dashUrl: string;
  AuthXML: string;
  FPserver: string;
  FPcert: string;
  PRserver: string;
  WVserver: string;
  drmfail: string;
  drmfailMP4: string;
}

export interface DRMFail {
  drmFailUrl: string;
  drmFailTitle: string;
}

export interface ComponentPayload {
  item: OTTEpisodeComponent;
}

export interface ComponentSuccessPayload {
  component: OTTEpisodeComponent;
  streams: Array<any>;
  drm: DRMAccesses;
  drmFail: DRMFail;
  adUrl: string;
  midrollBreaks: AddMidrollBreaks;
}

export interface RecommendedPlaylistPayload {
  recommendedPlaylist: RecommendedPlaylist;
}

export interface RecommendedPlaylistItemIndexPayload {
  currentPlaylistItemIndex: CurrentPlaylistItemIndex;
}

interface LoadComponentAction {
  type: typeof actionTypes.LOAD_OTTEPISODE_COMPONENT;
  url: string;
  content: any;
}

interface LoadComponentSucceededAction {
  type: typeof actionTypes.LOAD_OTTEPISODE_COMPONENT_SUCCEEDED;
  payload: ComponentSuccessPayload;
}

interface LoadComponentFailedAction {
  type: typeof actionTypes.LOAD_OTTEPISODE_COMPONENT_FAILED;
  error: IErrorAPI;
}

interface SetRecommendedPlaylist {
  type: typeof actionTypes.SET_RECOMMENDED_PLAYLIST;
  payload: RecommendedPlaylistPayload;
}
interface SetRecommendedPlaylistItemIndex {
  type: typeof actionTypes.SET_CURRENT_PLAYLIST_ITEM_INDEX;
  payload: RecommendedPlaylistItemIndexPayload;
}

interface ClearOTTEpisode {
  type: typeof actionTypes.CLEAR_OTTEPISODE;
}

type ComponentAction = LoadComponentAction | LoadComponentSucceededAction | LoadComponentFailedAction;

type RecommendedPlaybackAction = SetRecommendedPlaylist | SetRecommendedPlaylistItemIndex;

export type Action = ComponentAction | RecommendedPlaybackAction | ClearOTTEpisode;

export const setComponentOTTEpisodeData = (url: string, content?: any): Action => ({
  type: actionTypes.LOAD_OTTEPISODE_COMPONENT,
  url,
  content,
});

export const SetRecommendedPlaylist = (recommendedPlaylist: RecommendedPlaylist): Action => ({
  type: actionTypes.SET_RECOMMENDED_PLAYLIST,
  payload: {
    recommendedPlaylist,
  },
});

export const SetRecommendedPlaylistItemIndex = (currentPlaylistItemIndex: CurrentPlaylistItemIndex): Action => ({
  type: actionTypes.SET_CURRENT_PLAYLIST_ITEM_INDEX,
  payload: {
    currentPlaylistItemIndex,
  },
});
