import { CAPTIONS_SETTINGS_KEYS } from '../../utils/captions';
import {Captions, CaptionsSettings, CaptionsSettingsKeyToListenerMap} from '../interfaces/captions';

declare const webapis: any;

const {
  IS_ENABLED,
  BACKGROUND_COLOR,
  BACKGROUND_OPACITY,
  FONT_COLOR,
  FONT_OPACITY,
  FONT_SIZE,
  FONT_STYLE,
  WINDOW_COLOR,
  WINDOW_OPACITY,
  EDGE_TYPE,
  EDGE_COLOR,
} = CAPTIONS_SETTINGS_KEYS;

const tizenCaptionsSettingsKeys = {
  [IS_ENABLED]: 'CAPTION_ONOFF_KEY',
  [BACKGROUND_COLOR]: 'CAPTION_BG_COLOR_KEY',
  [BACKGROUND_OPACITY]: 'CAPTION_BG_OPACITY_KEY',
  [FONT_COLOR]: 'CAPTION_FG_COLOR_KEY',
  [FONT_OPACITY]: 'CAPTION_FG_OPACITY_KEY',
  [FONT_SIZE]: 'CAPTION_FONT_SIZE_KEY',
  [FONT_STYLE]: 'CAPTION_FONT_STYLE_KEY',
  [WINDOW_COLOR]: 'CAPTION_WINDOW_COLOR_KEY',
  [WINDOW_OPACITY]: 'CAPTION_WINDOW_OPACITY_KEY',
  [EDGE_TYPE]: 'CAPTION_EDGE_TYPE_KEY',
  [EDGE_COLOR]: 'CAPTION_EDGE_COLOR_KEY',
};

class TizenCaptions implements Captions {
  private getCaptionsSetting = (settingKey: string) : undefined | number => {
    if (typeof webapis === 'undefined') {
      return;
    }

    return webapis.tvinfo.getMenuValue(webapis.tvinfo.TvInfoMenuKey[settingKey]);
  };

  private addCaptionsSettingsChangeListener = (settingKey: string, listener: () => void): undefined | number => {
    if (typeof webapis === 'undefined') {
      return;
    }

    return webapis.tvinfo.addCaptionChangeListener(webapis.tvinfo.TvInfoMenuKey[settingKey], listener);
  };

  removeCaptionsSettingsChangeListener = (listenerId: number): void => {
    if (typeof webapis === 'undefined') {
      return;
    }

    webapis.tvinfo.removeCaptionChangeListener(listenerId);
  };

  getCaptionsSettings = (): CaptionsSettings => {
    const captionsSettings: CaptionsSettings = {};

    captionsSettings.IS_ENABLED = Boolean(this.getCaptionsSetting(tizenCaptionsSettingsKeys.IS_ENABLED));
    captionsSettings.BACKGROUND_COLOR = this.getCaptionsSetting(tizenCaptionsSettingsKeys.BACKGROUND_COLOR);
    captionsSettings.BACKGROUND_OPACITY = this.getCaptionsSetting(tizenCaptionsSettingsKeys.BACKGROUND_OPACITY);
    captionsSettings.FONT_COLOR = this.getCaptionsSetting(tizenCaptionsSettingsKeys.FONT_COLOR);
    captionsSettings.FONT_OPACITY = this.getCaptionsSetting(tizenCaptionsSettingsKeys.FONT_OPACITY);
    captionsSettings.FONT_SIZE = this.getCaptionsSetting(tizenCaptionsSettingsKeys.FONT_SIZE);
    captionsSettings.FONT_STYLE = this.getCaptionsSetting(tizenCaptionsSettingsKeys.FONT_STYLE);
    captionsSettings.WINDOW_COLOR = this.getCaptionsSetting(tizenCaptionsSettingsKeys.WINDOW_COLOR);
    captionsSettings.WINDOW_OPACITY = this.getCaptionsSetting(tizenCaptionsSettingsKeys.WINDOW_OPACITY);
    captionsSettings.EDGE_TYPE = this.getCaptionsSetting(tizenCaptionsSettingsKeys.EDGE_TYPE);
    captionsSettings.EDGE_COLOR = this.getCaptionsSetting(tizenCaptionsSettingsKeys.EDGE_COLOR);

    return captionsSettings;
  };

  addCaptionsSettingsChangeListeners = (map: CaptionsSettingsKeyToListenerMap): Array<number> => {
    const listenerIds: Array<number> = [];

    for (const captionKey of Object.keys(map)) {
      const tizenCaptionKey = tizenCaptionsSettingsKeys[captionKey];

      if (!tizenCaptionKey) {
        continue;
      }

      const listener = map[captionKey];

      const listenerId = this.addCaptionsSettingsChangeListener(tizenCaptionKey, listener);

      listenerId && listenerIds.push(listenerId);
    }

    return listenerIds;
  };
}

export default TizenCaptions;
