import { put, takeLatest, call } from 'redux-saga/effects';
import * as actionTypes from '../actions/types/OTTText';
import { getSettingsContent } from '../../services/OTTSettingsService';
import { PAGE_CONTENT_REQUEST_ERROR } from '../../constants/errorCodes';

function* setPageData(data) {

  try {
    const pageText = yield call(() => getSettingsContent(data.url));

    if (pageText) {
      yield put({
        type: actionTypes.LOAD_OTT_TEXT_DATA_SUCCEEDED,
        payload: pageText,
      });
    } else {
      yield put({
        type: actionTypes.LOAD_OTT_TEXT_DATA_FAILED,
        error: PAGE_CONTENT_REQUEST_ERROR,
      });
    }
  } catch (e: any) {
    yield put({
      type: actionTypes.LOAD_OTT_TEXT_DATA_FAILED,
      error: PAGE_CONTENT_REQUEST_ERROR,
    });
  }
}

function* watcher() {
  yield takeLatest(actionTypes.LOAD_OTT_TEXT_DATA, setPageData);
}

export default watcher;
