import React, { useState, useEffect, useMemo, MutableRefObject } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../stores/reducers';
import SEASON_GRID_LIST_COMPONENT from './SEASON_GRID_LIST/SEASON_GRID_LIST';
import SEASONS_LIST from '../SEASON_GRID_TEASER_LIST/SEASON_LIST/SEASON_LIST';
import SEASON_TEASER_LIST_COMPONENT from './SEASON_TEASER_LIST/SEASON_TEASER_LIST';
import { GAEvent } from '../../services/analytics/index';
import { SELECT, CHANGE_SEASON } from '../../constants/analyticsTypes';
import { ERROR_LOADING_SEASON_EPISODES } from '../../constants/text';
import { SEASON_GRID_TEASER_LIST, SEASON_TEASER_LIST } from '../../constants/structureTypes';
import { convertContent } from '../../utils/commonFunc';

import './SEASON_GRID_TEASER_LIST.scss';

interface SEASON_GRID_TEASER_LIST_Props {
  content: any;
  onPress: () => null;
  seasons: string[];
  carouselExist: boolean;
  index: number;
  initialFocusedElement: string;
  translateValueRef: MutableRefObject<number>,
  selectedSeasonRef: MutableRefObject<number>;
  defaultCardIndex?: number;
  seasonDefaultCarouselID?: string;
}

const SEASON_GRID = (props: SEASON_GRID_TEASER_LIST_Props) => {
  const {
    content,
    onPress,
    seasons,
    index,
    carouselExist,
    initialFocusedElement,
    translateValueRef,
    selectedSeasonRef,
    defaultCardIndex,
    seasonDefaultCarouselID,
  } = props;

  const [selectedSeason, setSelectedSeason] = useState(0);

  const pageData = useSelector((state: RootState) => state.OTTPage.page);

  const setActiveSeason = (idx: number) => {
    setSelectedSeason(idx);
    selectedSeasonRef.current = idx;
  };

  useEffect(() => {
    GAEvent(CHANGE_SEASON, SELECT, seasons[selectedSeason]);
  }, [selectedSeason, seasons]);

  const updatedPageData = useMemo(() => convertContent(seasons, pageData, 0), []); // eslint-disable-line

  const updatedSeasonsContent = useMemo(() => convertContent(seasons, pageData, 1, content), []); // eslint-disable-line

  const renderSeasonsPageLayout = () => {
    return updatedSeasonsContent.map((item, index) => {
      const updatedPage = updatedPageData[index] as any;
      let component;

      if (item[0]?.component?.length || item.filter(item => Boolean(item?.component?.length))?.length) {
        if (updatedPage[0]?.type === SEASON_GRID_TEASER_LIST || updatedPage[0]?.category === SEASON_GRID_TEASER_LIST) {
          component = (
            <SEASON_GRID_LIST_COMPONENT
              key={`${updatedPage[0].componentInstanceId}-${index}`}
              selected={selectedSeasonRef.current || selectedSeason}
              seasonIndex={index}
              seasonId={updatedPage[0].pageComponentUuid}
              season={item[0].component}
              onCardPress={onPress}
              initialFocusedElement={initialFocusedElement}
              translateValueRef={translateValueRef}
            />
          );
        } else if (updatedPage[0]?.type === SEASON_TEASER_LIST || updatedPage[0]?.category === SEASON_TEASER_LIST) {
          component = (
            <SEASON_TEASER_LIST_COMPONENT
              key={`${updatedPage[0].componentInstanceId}-${index}`}
              seasonIndex={index}
              content={item.filter(item => Boolean(item?.component?.length))}
              selected={selectedSeasonRef.current || selectedSeason}
              seasonId={updatedPage.map((item) => item.pageComponentUuid)}
              onCardPress={onPress}
              isCarouselExisted={carouselExist}
              translateValueRef={translateValueRef}
              defaultCardIndex={defaultCardIndex}
              seasonDefaultCarouselID={seasonDefaultCarouselID}
            />
          );
        }
      } else if (selectedSeason === index) {
        component = (<div key={index} className='season-err-message'>{ERROR_LOADING_SEASON_EPISODES}</div>);
      }

      return component;
    });
  };

  return (
    <>
      <SEASONS_LIST
        index={index}
        selectedSeason={selectedSeasonRef.current || selectedSeason}
        onPress={setActiveSeason}
        content={seasons}
      />
      <div className='season-wrapper'>
        {renderSeasonsPageLayout()}
      </div>
    </>
  );
};

export default SEASON_GRID;
