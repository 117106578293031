import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores/reducers';
import { getTextWithBoldUrls } from '../../utils/ui';

import './ActionText.scss';

interface IActionTextProps {
  text: string;
  sendAnalytics: (itemIndex: number) => void;
  isDescriptionTextFocused: boolean;
  itemIndex?: number;
  selected?: number;
  image?:string;
  title?:string;
  onTextFocus: () => void;
  onTextBlur: () => void;
}

const ActionText = (props: IActionTextProps) => {
  const {
    itemIndex = 0,
    selected = 0,
    text,
    isDescriptionTextFocused,
    title,
    image,
    sendAnalytics,
    onTextFocus,
    onTextBlur,
  } = props;

  const settingsStyles = useSelector((state: RootState) => state.config.styles.settings);
  const fontFamily = useSelector((state: RootState) => state.config.styles['font']['face']['fontFamily']);

  const onFocus = () => {
    onTextFocus();
    sendAnalytics(itemIndex);
  };

  return (selected === itemIndex
    ? <div
      className={`link-text settings-key-text info-text`}
      tabIndex={0}
      style={{color: settingsStyles.textColor, fontFamily}}
      onFocus={onFocus}
      onBlur={onTextBlur}
      role={''}
      aria-label={`${title}. ${text}`}
      data-tv-focus-up={isDescriptionTextFocused ? '#navigationSelectedItem' : '.settings-key-text'}
      data-tv-focus-left={`.settings-key-${itemIndex}`}
      data-tv-focus-right='.settings-key-text'
      data-tv-focus-down='.settings-key-text'
    >
      <h1 className='settings-key-subtitle'>{title}</h1>
      <p>{getTextWithBoldUrls(text)}</p>
      <img
        className='settings-key-img'
        src={image}
        alt='QR code'>
      </img>
    </div>
    : null
  );
};

export default ActionText;
