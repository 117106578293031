import React, { MutableRefObject, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { RootState } from '../../../stores/reducers';
import * as commonActions from '../../../stores/actions/common';
import ToggleSwitch from '../../ToggleSwitch/ToggleSwitch';
import {
  getKeyInfo, isAboutCard,
  isCitySelectionCard, isProviderCard,
  isSubscriptionCard, onEnterHandler,
} from '../../../utils';
import {
  MvpdFlowType,
  OTTAboutType,
  OTTPageType,
  TCPlusFlowType,
  isTcBrand,
  isStirrBrand,
} from '../../../constants/structureTypes';
import { navigate } from '../../../services/NavigationService';
import { IJson, openPage } from '../../../utils/OpenPage';
import { PLUS_PAYWALL_SCREEN } from '../../../constants/text';
import { formatTimeFromCleeng } from '../../../utils/time';
import { getCustomStyledSymbol } from '../../../utils/ui';
import { GAEvent } from '../../../services/analytics';
import { MY_ACCOUNT, SELECT } from '../../../constants/analyticsTypes';

import './AccountSettings.scss';

interface ICardLabel {
  content: string|JSX.Element,
  children?: JSX.Element,
  style?: {
    background?: string,
    color?:string,
    fontFamily?: string,
  },
}

type AccountSettingsProps = {
  clearOTTSettingsData: () => void;
  lastFocusedElement: MutableRefObject<string>;
  setPressedKeysCombination: any;
};

const AccountSettings = (props: AccountSettingsProps) => {
  const { clearOTTSettingsData, lastFocusedElement, setPressedKeysCombination } = props;

  const toggleStatus = useSelector((state: RootState) => state.common.isSpoilerPreventionEnabled);
  const features = useSelector((state: RootState) => state.config.features);
  const currentCity = useSelector((state: RootState) => state.common.city);
  const navigation = useSelector((state: RootState) => state.config.navigation);
  const components = useSelector((state: RootState) => state.OTTSettings.components);
  const settingsData = useSelector((state: RootState) => state.config.settings);
  const citySelectData = useSelector((state: RootState) => state.config.settings.city_select);

  const isTCPlusUserLoggedIn = useSelector((state: RootState) => state.Login.isUserTCLogin);
  const isCurrentSubscriptionActive = useSelector(
    (state: RootState) => state.cleengService.isCurrentSubscriptionActive
  );
  const currentSubscriptionDetails = useSelector((state: RootState) => state.cleengService.currentSubscriptionDetails);

  const spoilerPreventionTitle = useSelector((state: RootState) =>
    state.config.settings.options?.spoiler_prevention?.title);
  const spoilerPreventionDescription = useSelector((state: RootState) =>
    state.config.settings.options?.spoiler_prevention?.description);

  const mvpdProviderLogo = useSelector((state: RootState) => state.Login.mvpd.mvpdLogo);
  const mvpdProviderTitle = useSelector((state: RootState) => state.Login.mvpd.mvpdId);
  const isUserMVPDLogin = useSelector((state: RootState) => state.Login.isUserMVPDLogin);

  const fontStyle = useSelector((state: RootState) => state.config.styles.font);
  const errorColor = useSelector((state: RootState) => state.config.styles?.general?.failureColor);
  const labelBgColor = useSelector((state: RootState) => state.config.styles?.settings?.linkColor);
  const settingsStyles = useSelector((state: RootState) => state.config.styles.settings);
  const headingStyles = useSelector((state: RootState) => state.config.styles.general?.heading);

  const [shouldPageAnnounced, setShouldPageAnnounced] = useState<boolean>(true);

  const history = useHistory();
  const dispatch = useDispatch();

  const pageTitle = useMemo(
    () => isTCPlusUserLoggedIn || isUserMVPDLogin || isStirrBrand
      ? 'WELCOME BACK'
      : 'WELCOME',
    [isTCPlusUserLoggedIn, isUserMVPDLogin]
  );

  const getPageInfoForTTS = useMemo(() => {
    let ttsText = `${pageTitle}. Choose one of the items below. `;

    ttsText += features.spoiler_prevention
      ? 'Also ' + spoilerPreventionTitle + 'settings. ' + spoilerPreventionDescription
      : settingsData.detail;

    return ttsText;
  }, [
    pageTitle, features.spoiler_prevention, spoilerPreventionTitle,
    spoilerPreventionDescription, settingsData?.detail,
  ]);

  const onFocusHandler = (e: React.FocusEvent<HTMLDivElement>, isLabelChangeColorOnFocus?: boolean) => {
    if (isLabelChangeColorOnFocus) {
      const selectedCardLabel = e.target.querySelector('.settings-card-label') as HTMLElement;

      if (selectedCardLabel) {
        selectedCardLabel.style.background = settingsStyles?.badgeActiveColor;
      }
    }

    e.target.style.background = `${settingsStyles.itemBackgroundColorActive}`;
    e.target.style.border = `4px solid ${settingsStyles.itemActiveLineColor}`;
  };

  const OnBlurHandler = (e: React.FocusEvent<HTMLDivElement>, isLabelChangeColorOnFocus?: boolean) => {
    if (isLabelChangeColorOnFocus) {
      const selectedCardLabel = e.target.querySelector('.settings-card-label') as HTMLDivElement;

      if (selectedCardLabel) {
        selectedCardLabel.style.background = settingsStyles?.badgeColor;
      }
    }
    e.target.style.background = `${settingsStyles.itemBackgroundColor}`;
    e.target.style.border = `4px solid ${settingsStyles.itemLineColor}`;

    setShouldPageAnnounced(false);
  };

  const keyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>, index: number) => {
    setPressedKeysCombination((prev: any) => [...prev, getKeyInfo(e.keyCode)]);

    onEnterHandler(e, () => {

      if (components.length) {
        const component = components[index];

        GAEvent(MY_ACCOUNT, SELECT, component.displayTitle);

        if (isProviderCard(component.pageComponentUuid)) {
          navigate(
            history,
            openPage(
              {
                title: MvpdFlowType,
                type: MvpdFlowType,
                path: '',
              } as unknown as IJson,
              { page: MvpdFlowType },
            ),
            clearOTTSettingsData,
            { lastFocusedElement: document.activeElement?.id }
          );
        } else if (isSubscriptionCard(component.pageComponentUuid)) {
          navigate(
            history,
            openPage(
              {
                title: 'PlusPaywallScreen',
                type: TCPlusFlowType,
                path: '',
              } as unknown as IJson,
              {
                page: PLUS_PAYWALL_SCREEN,
                isFlowFromSettings: true,
              }),
            clearOTTSettingsData,
            { lastFocusedElement: document.activeElement?.id }
          );
        } else if (isCitySelectionCard(component.pageComponentUuid)) {
          const mainOTTPageNavIdx = navigation.findIndex(nav => (nav.type === OTTPageType && nav.focusable)) || 0;

          navigate(
            history,
            openPage({
              title: 'STIRR City',
              type: OTTPageType,
              path: component.content,
            } as unknown as IJson,
            { navIdx: mainOTTPageNavIdx }),
            clearOTTSettingsData,
            { lastFocusedElement: document.activeElement?.id }
          );

          dispatch(commonActions.setActiveNavIdx(mainOTTPageNavIdx));
        } else if (component.pageComponentUuid.includes('ABOUT')) {
          navigate(
            history,
            openPage(
              {
                title: 'About',
                type: OTTAboutType,
                path: component.content,
              } as unknown as IJson,
              { page: ''}),
            clearOTTSettingsData,
            { lastFocusedElement: document.activeElement?.id }
          );
        } else if (document.activeElement?.id) {
          lastFocusedElement.current = document.activeElement.id;
        }
      }
    });
  };

  const getTtsText = (card) => {
    let text = `${card.displayTitle}, ${card.description}.`;

    if (!card.content) {
      if (isSubscriptionCard(card.pageComponentUuid)) {
        if (isTCPlusUserLoggedIn) {
          if (isCurrentSubscriptionActive) {
            text += `Your current subscription is ${currentSubscriptionDetails?.title}.`
            // eslint-disable-next-line max-len
             + (currentSubscriptionDetails?.nextPaymentAt ? `Renews ${formatTimeFromCleeng(currentSubscriptionDetails?.nextPaymentAt)}` : '');
          } else {
            text += currentSubscriptionDetails?.offerId ?
              `Your subscription has expired on ${formatTimeFromCleeng(currentSubscriptionDetails?.expiresAt)}` :
              'Subscribe today!';
          }
        } else {
          text += 'Sign in';
        }
      } else if (isProviderCard(card.pageComponentUuid)) {
        text += mvpdProviderLogo ? `You are currently logged in to ${mvpdProviderTitle}` : 'Sign in';
      }
    } else {
      if (isCitySelectionCard(card.pageComponentUuid)) {
        text = `Current city is ${currentCity}. ${card.description}.
        ${citySelectData?.badge_text || 'Change City'}`;
      }
    }

    text += 'Press to select';

    return `${shouldPageAnnounced ? getPageInfoForTTS : ''}. ${text}`;
  };

  const subscriptionLabel: ICardLabel = useMemo(() => {

    let label: ICardLabel = {
      content: '',
      style: {
        background: settingsStyles.badgeColor,
      },
    };

    if (!isTCPlusUserLoggedIn) {
      label.content = 'Subscribe today!';
    } else if (isCurrentSubscriptionActive) {
      label = {
        content: currentSubscriptionDetails?.title || 'Active',
        style: { background: labelBgColor },
        children: <span className='settings-card-label-info'>
          {currentSubscriptionDetails?.nextPaymentAt &&
          `Renews ${formatTimeFromCleeng(currentSubscriptionDetails?.nextPaymentAt, 'MMM DD, YYYY')}`}
        </span>,
      };
    } else if (currentSubscriptionDetails?.offerId) {
      label = {
        content: 'Expired',
        style: { background: errorColor },
        children: <span className='settings-card-label-info'>
          Expired {formatTimeFromCleeng(currentSubscriptionDetails?.expiresAt, 'MMM DD, YYYY')}
        </span>,
      };
    } else {
      label.content = 'Subscribe today!';
    }

    return label;
  }, [isTCPlusUserLoggedIn, isCurrentSubscriptionActive, currentSubscriptionDetails]);

  const getCardLabel = (cardType:string): JSX.Element => {
    let label: ICardLabel = { content: '', style: {background: 'transparent' }};
    const isNecessaryCard =
      isSubscriptionCard(cardType) ||
      isProviderCard(cardType) ||
      isCitySelectionCard(cardType) ||
      isAboutCard(cardType);

    switch (isNecessaryCard) {
      case isSubscriptionCard(cardType) : {
        label = subscriptionLabel;
        break;
      }

      case isProviderCard(cardType): {
        label.content = mvpdProviderLogo ? <img src={mvpdProviderLogo} alt='provider logo' /> : 'Sign in',
        label.style = { background: settingsStyles.badgeColor };
        break;
      }

      case isCitySelectionCard(cardType): {
        label.content = citySelectData?.badge_text || 'Change City';
        label.style = {
          background: settingsStyles.badgeActiveColor,
          color: settingsStyles.itemActiveTextColor,
          fontFamily: fontStyle?.faceBold?.fontFamily,
        };
        break;
      }

      default: label;
    }

    return (<>
      <span
        className='settings-card-label'
        style={label.style}
      >
        {label.content}
      </span>
      {label.children}
    </>);
  };

  const handleSwitch = (e: React.KeyboardEvent<HTMLDivElement>) => {
    onEnterHandler(e, () => {
      localStorage.setItem('isSpoilerPreventionEnabled', JSON.stringify(!toggleStatus));
      dispatch(commonActions.toggleSpoilerPreventionStatus());
    });
  };

  return (
    <div className='ott-settings-container' style={{ fontFamily: fontStyle?.face?.fontFamily }}>
      <h1
        style={{
          fontFamily: headingStyles?.titleFont || fontStyle?.faceBold?.fontFamily,
          textAlign: isTcBrand
            ? 'left'
            : 'center',
        }}
        className='ott-settings-main-text'
      >
        {pageTitle}
        {isTcBrand && getCustomStyledSymbol('.', headingStyles.accentColor)}
      </h1>
      <div className='settings-options-container'>
        {components.length && components.map((card, index) => {
          const isLabelChangeColorOnFocus = (!isUserMVPDLogin && isProviderCard(card.displayTitle)) ||
                                            (!currentSubscriptionDetails?.offerId &&
                                            isSubscriptionCard(card.displayTitle));

          return (
            <div
              key={card.displayTitle}
              id={card?.pageComponentUuid}
              className={`settings-card-container settings-card-${index}`}
              style={{
                background: settingsStyles.itemBackgroundColor,
                border: `4px solid ${settingsStyles.itemLineColor}`,
              }}
              onFocus={(e: React.FocusEvent<HTMLDivElement>) => onFocusHandler(e, isLabelChangeColorOnFocus)}
              onBlur={(e: React.FocusEvent<HTMLDivElement>) => OnBlurHandler(e, isLabelChangeColorOnFocus)}
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => keyDownHandler(e, index)}
              role=''
              tabIndex={0}
              aria-label={getTtsText(card)}
              data-tv-focus-up='#navigationSelectedItem'
              data-tv-focus-left={index ? `.settings-card-${index - 1}` : `.settings-card-${index}`}
              data-tv-focus-down={features.spoiler_prevention
                ? '.toggle-switch'
                : `.settings-card-${index}`}
              data-tv-focus-right={index === components.length - 1
                ? `.settings-card-${index}`
                : `.settings-card-${index + 1}`
              }>
              <div className='settings-card-upper-part' >
                <img className='settings-card-img' src={card.imageURL} alt='card' />
                <p style={{ fontFamily: settingsStyles.focusFontFace.fontFamily }}>{
                  isCitySelectionCard(card.pageComponentUuid) ? currentCity : card.displayTitle}</p>
              </div>
              <hr className='horizontal-line' style={{ background: settingsStyles.separatorColor }} />
              <p className='settings-secondary-text'>{card.description}</p>
              <div className='settings-card-extra-info'>
                {getCardLabel(card.pageComponentUuid)}
              </div>
            </div>
          );
        })}
      </div>

      {features.spoiler_prevention ?
        <div className='settings-spoiler-prevention'>
          <p className='settings-spoiler-prevention-title'
            style={{ fontFamily: settingsStyles.focusFontFace.fontFamily }}>
            {spoilerPreventionTitle}
          </p>
          <p className='settings-spoiler-prevention-description'>
            {spoilerPreventionDescription}
          </p>
          <ToggleSwitch handleSwitch={handleSwitch} />
        </div> :
        <div className='settings-help-container'>
          <pre className='settings-help-text' style={{ fontFamily: fontStyle?.face?.fontFamily }}>
            {settingsData.detail}
          </pre>
        </div>
      }
    </div>
  );
};

export default AccountSettings;
