import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CLEAR_OTT_SETTINGS } from '../../stores/actions/types/OTTSettings';
import { getSettingsPageData, setPageComponentsData } from '../../stores/actions/OTTSettings';
import { RootState } from '../../stores/reducers';
import { onBackHandler } from '../../utils';
import { getPath } from '../../utils/OpenPage';
import useEventListener from '../../hooks/eventListener';
import { GAScreen, GAEvent } from '../../services/analytics';
import { useAnnouncePageLoading } from '../../services/TTSService';
import FocusService from '../../services/focusService';
import { navigateBack } from '../../services/NavigationService';
import { isStirrBrand, isStirrTcMarqueeBrand, isTcBrand } from '../../constants/structureTypes';
import { VIEW, ABOUT } from '../../constants/analyticsTypes';
import { BACK_BTN_TEXT } from '../../constants/text';
import { getCustomStyledSymbol } from '../../utils/ui';

import Button from '../../components/Button/Button';
import ScrollableText from '../../components/ScrollableText/ScrollableText';
import ActionText from '../../components/ActionText/ActionText';
import Spinner from '../../components/Spinner/Spinner';
import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';
import backArrowIcon from '../../assets/icons/GoBackArrow.svg';

import './OTTAbout.scss';

type OTTAboutProps = {
  location: {
    state: {
      configData: {
        title?: string;
        path: string;
        type?: string;
        GAPath: string;
      };
      lastData: {
        lastFocusedElement: string;
        defaultTranslateValue: number,
      }
    },
  },
};

const OTTAbout = (props: OTTAboutProps) => {
  const { location: { state: { configData, lastData } } } = props;

  const settingsData = useSelector((state: RootState) => state.OTTSettings.settingsData);
  const components = useSelector((state: RootState) => state.OTTSettings.components);
  const aboutPageData = useSelector((state: RootState) => state.config.settings.about);
  const isLoading = useSelector((state: RootState) => state.OTTSettings.isLoading);
  const isCleengDataLoading = useSelector((state: RootState) => state.cleengService.isLoading);
  const hasError = useSelector((state: RootState) => state.OTTSettings.hasError);
  const pageLoadingError = useSelector((state: RootState) => state.OTTSettings.error);
  const headingStyles = useSelector((state: RootState) => state.config.styles.general?.heading);
  const settingsStyles = useSelector((state: RootState) => state.config.styles.settings);
  const aboutStyles = useSelector((state: RootState) => state.config.styles.settings.about);
  const fontFamily = useSelector((state: RootState) => state.config.styles.font.face.fontFamily);

  const buttonRef = useRef(null);
  const lastFocusedElement = useRef<string>(lastData?.lastFocusedElement || '');

  const [isDescriptionTextFocused, setIsDescriptionTextFocused] = useState<boolean>(false);
  const [focusedListItemIndex, setFocusedListItemIndex] = useState<number>(0);
  const [isItemActive, setIsItemActive] = useState<boolean>(false);
  const [isPageInfoAnnounced, setIsPageInfoAnnounced] = useState<boolean>(false);
  const [isScrollBarVisible, setIsScrollBarVisible] = useState<boolean>(true);

  const dispatch = useDispatch();
  const history = useHistory();

  const getBackgroundStyle = () =>
    settingsStyles.backgroundImage
      ? `url(${settingsStyles.backgroundImage})`
      : settingsStyles.backgroundColor;

  const focusedItemStyles = {
    backgroundColor: aboutStyles.itemActiveColor,
    borderColor: aboutStyles.itemActiveLineColor,
    fontFamily: settingsStyles.focusFontFace.fontFamily,
    color: aboutStyles.itemActiveTextColor,
  };

  const activeItemStyles = {
    color: aboutStyles.itemSelectedTextColor,
    fontFamily: settingsStyles.focusFontFace.fontFamily,
  };

  const blurredItemStyles = {
    color: settingsStyles.textColor,
    fontFamily,
  };

  const itemGetFocused = (index: number) => {
    !(focusedListItemIndex === 0 && index === 0) && setIsPageInfoAnnounced(true);
    setFocusedListItemIndex(index);
    setIsItemActive(false);
  };

  const itemGetBlurred = () => {
    setIsItemActive(true);
    setIsPageInfoAnnounced(true);
  };

  useEffect(() => {
    if (settingsData.length && isStirrTcMarqueeBrand) {
      GAScreen(ABOUT);
    }
    if (settingsData.length) {
      FocusService.setFocus(document.querySelector(`.settings-key-${focusedListItemIndex}`));
    }
  }, [settingsData.length]);

  useLayoutEffect(() => {
    if (!components.length && !settingsData.length) {
      dispatch(getSettingsPageData(configData.path));
    }

    if (components.length && !settingsData.length) {
      dispatch(setPageComponentsData(components));
    }
  }, [settingsData, configData, components]);

  const onTextFocus = () => setIsDescriptionTextFocused(true);

  useAnnouncePageLoading(isLoading || isCleengDataLoading);

  const onTextBlur = () => setIsDescriptionTextFocused(false);

  const sendAnalytics = (itemIndex: number) => {
    GAEvent(ABOUT, VIEW, getPath(settingsData[itemIndex]).split(' ')[0]);
  };

  const clearOTTSettingsData = () => {
    dispatch({ type: CLEAR_OTT_SETTINGS });
    lastFocusedElement.current = '';
  };

  const handleBack = () => {
    navigateBack(history, clearOTTSettingsData);
  };

  useEventListener('keydown', e => {
    onBackHandler(e, handleBack, history);
  });

  const getTTSText = (item):string => {
    let text = !isPageInfoAnnounced ? `${aboutPageData.title}. ${aboutPageData.detail}. ` : '';

    text += focusedListItemIndex !== -1 ?
      `${item.displayTitle}. ${!item.description ? `${item.content?.title}. ${item.content?.detail}` : ''}`
      : '';

    return text;
  };

  const getSettingsItem = (item, index:number):JSX.Element => {
    const Component = Object.prototype.hasOwnProperty.call(item, 'description') ? ScrollableText : ActionText;

    return (
      <Component
        selected={focusedListItemIndex}
        key={item?.pageComponentUuid}
        itemIndex={index}
        isDescriptionTextFocused={isDescriptionTextFocused}
        text={item.description || item.content?.detail}
        image={item.content?.image}
        title={item.content?.title}
        onTextFocus={onTextFocus}
        onTextBlur={onTextBlur}
        sendAnalytics={sendAnalytics}
        setIsScrollBarVisible={setIsScrollBarVisible}
      />
    );
  };

  return (
    <>
      {isLoading || isCleengDataLoading ? <Spinner /> : (
        <>
          {hasError
            ? (<ErrorModal
              error={{errorCode: pageLoadingError}}
              onBackHandlerCb={handleBack}
            />)
            : (
              <div className='settings-main-container' style={{background: getBackgroundStyle()}}>
                <div className={'settings-main-content'}>
                  <div style={{ color: `${settingsStyles['headlineColor']}`}}>
                    <h1
                      className='title-info'
                      style={{ fontFamily: headingStyles?.titleFont || settingsStyles.focusFontFace.fontFamily }}
                    >
                      {aboutPageData.title}
                      {isTcBrand && getCustomStyledSymbol('.', headingStyles.accentColor)}
                    </h1>
                    <p className='detail-info'>{aboutPageData.detail}</p>
                  </div>
                  {settingsData.length && (
                    <div className='li-settings-items'>
                      {settingsData.map((item, index) =>
                        (<>
                          <p
                            key={index}
                            style={index === focusedListItemIndex
                              ? isItemActive
                                ? activeItemStyles
                                : focusedItemStyles
                              : blurredItemStyles}
                            className={`settings-key settings-key-${index}`}
                            tabIndex={0}
                            data-tv-focus-up={index === 0
                              ? '#navigationSelectedItem'
                              : `.settings-key-${index - 1}`}
                            data-tv-focus-down={index === settingsData.length - 1
                              ? '#settings-back-btn'
                              : `.settings-key-${index + 1}`}
                            data-tv-focus-right={
                              `.settings-key-${item.description && isScrollBarVisible
                                ? 'text'
                                : index}`
                            }
                            data-tv-focus-left={`.settings-key-${index}`}
                            onFocus={() => itemGetFocused(index)}
                            onBlur= {itemGetBlurred}
                            aria-label={getTTSText(item)}
                            role=''
                          >
                            {item.displayTitle}
                          </p>
                          {getSettingsItem(item, index)}
                        </>
                        ))}
                    </div>
                  )}
                  <Button
                    title={BACK_BTN_TEXT}
                    TTSText={`${BACK_BTN_TEXT} button`}
                    key={BACK_BTN_TEXT}
                    ref={buttonRef}
                    focusUp={`.settings-key-${settingsData.length - 1}`}
                    btnID='settings-back-btn'
                    onKeyDown={handleBack}
                    onBlurFunc={() => setIsPageInfoAnnounced(false)}
                    onFocusFunc={()=> setFocusedListItemIndex(-1)}
                  >
                    <img src={backArrowIcon} alt='back arrow'
                      style={{ marginRight: '17px',
                        ...(isStirrBrand && focusedListItemIndex === -1) && { filter: 'invert(1)'},
                      }} />
                  </Button>
                </div>
              </div>
            )
          }
        </>
      )}
    </>
  );
};

export default OTTAbout;
