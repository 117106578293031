import { put, call, takeLatest, select } from 'redux-saga/effects';

import * as actionTypes from '../actions/types/cleengService';
import {
  getActiveSubscriptions,
  getAllSubscriptions,
  getPurchaseOptions,
} from '../../services/cleengService';
import { RootState } from '../reducers';
import { refreshTokenPlus } from './Login';
import { isTokenExpired } from '../../utils';
import { DEFAULT_CLEENG_ERROR } from '../../constants/errorCodes';
import moment from 'moment';

function* getPurchaseData(data) {
  const { isRefresh = false } = data;

  const store: RootState = yield select();
  const {
    Login: {
      subscription: {
        authTokenPlus,
        expiresTokenPlus,
      },
    },
    config: {
      subscription: {
        cleeng_getPurchaseOption,
        cleeng_listCustomerSubscriptions,
        cleeng_entitlements,
        subscriptionOptions,
      },
    },
  } = store;

  const postData = {
    brand: 'tennischannel',
    restriction: 'cleeng+tag://TENNISPLUS cleeng+tag://(all)',
  };
  const allUserSubscriptionsDetails: any = [];
  let activeSubscriptions: any = [];
  let allSubscriptions: any = [];
  let currentSubscriptionDetails: any = null;
  let purchaseOptions: any = [];
  let requestErrorCode: string = '';

  let userToken: string = '';

  if (authTokenPlus && isTokenExpired(expiresTokenPlus)) {

    const refreshedPlusToken = yield call(refreshTokenPlus);

    userToken = refreshedPlusToken;
  } else {
    userToken = authTokenPlus;
  }

  try {
    purchaseOptions = yield call(getPurchaseOptions, cleeng_getPurchaseOption, postData);

    if (userToken) {
      try {
        activeSubscriptions = yield call(getActiveSubscriptions, cleeng_listCustomerSubscriptions, userToken);
      } catch (e: any) {
        requestErrorCode = e.message;
      }

      try {
        allSubscriptions = yield call(getAllSubscriptions, cleeng_entitlements, userToken);
      } catch (e: any) {
        requestErrorCode = e.message;
      }

      if ((activeSubscriptions.length || allSubscriptions.length) && subscriptionOptions.length) {
        const userSubscriptions = activeSubscriptions.length
          ? activeSubscriptions
          : allSubscriptions;

        userSubscriptions.forEach(userSub => {
          subscriptionOptions.forEach(sub => {
            if (sub.id === userSub.offerId) {
              allUserSubscriptionsDetails.push({...userSub, ...sub});
            }
          });
        });

        // case with active subscriptions for outdated SKU(removed from store)
        if (!allUserSubscriptionsDetails.length && activeSubscriptions.length) {
          allUserSubscriptionsDetails.push(...activeSubscriptions);
        }

        if (allUserSubscriptionsDetails.length) {
          currentSubscriptionDetails = allUserSubscriptionsDetails.reduce(
            (prevSub, currSub) => (prevSub.expiresAt > currSub.expiresAt) ? prevSub : currSub);
        }
      }
    }

    console.log('=========== SAGA currentSubscriptionDetails', currentSubscriptionDetails);

    if (!userToken || (userToken && (currentSubscriptionDetails || !requestErrorCode))) {
      yield put({
        type: actionTypes.LOAD_PURCHASE_DATA_SUCCEEDED,
        payload: {
          purchaseOptions,
          activeSubscriptions,
          allSubscriptions,
          currentSubscriptionDetails,
          isCurrentSubscriptionActive: activeSubscriptions.length ||
            currentSubscriptionDetails?.expiresAt > moment().unix(),
          isRefresh,
        },
      });
    } else {
      yield put({
        type: actionTypes.LOAD_PURCHASE_DATA_FAILED,
        error: { errorCode: requestErrorCode || DEFAULT_CLEENG_ERROR },
      });
    }
  } catch (e: any) {
    yield put({
      type: actionTypes.LOAD_PURCHASE_DATA_FAILED,
      error: { errorCode: e?.message || DEFAULT_CLEENG_ERROR, details: e },
    });
  }
}

function* cleengWatcher() {
  yield takeLatest(actionTypes.LOAD_PURCHASE_DATA, getPurchaseData);
}

export default cleengWatcher;
