import React from 'react';
import Carousel from '../../components/Carousel/Carousel';
import Card from '../../components/Carousel/Card/Card';
import { TEASER_LIST_PROPS } from '../STANDARD_TEASER_LIST/STANDARD_TEASER_LIST';
import {DEFAULT_SLIDES_VALUE} from '../../constants/structureTypes';
import { CLEENG_RESTRICTION } from '../../constants/text';
import { isContentWithRestriction } from '../../utils';

import './FOUR_COLUMN_TEASER_LIST.scss';

const FOUR_COLUMN_TEASER_LIST = (props: TEASER_LIST_PROPS) => {
  const { content, index, title, onSelect, onPress, isLastCarousel } = props;

  const cardStyles = {
    width: 405,
    height: 245,
    marginLeft: 20,
    marginRight: 20,
  };

  const cardTitleStyles = {
    marginLeft: -15,
  };

  return (
    <div className="four-column-teaser-list">
      <p className="four-column-teaser-list_title">{title}</p>
      <Carousel
        cardStyles={cardStyles}
        carouselId={index}
        onCardSelection={onSelect}
        onCardPressed={onPress}
        slides={DEFAULT_SLIDES_VALUE}
        carouselTitle={title}
        isCitySelectionCard={true}
        isLastCarousel={isLastCarousel}
      >
        {content.map((item) => (
          <Card
            TCPlusContent={
              isContentWithRestriction(item?.item['media:content']['media:restriction'], CLEENG_RESTRICTION)
            }
            alt={item.item['slug']}
            width={cardStyles.width}
            height={cardStyles.height}
            key={index}
            src={item.item['media:content']['media:thumbnail']}
            title={item.item['media:content']['media:title']['content']}
            titleStyles={cardTitleStyles}
            cityDescription={item.item['media:content']['media:description']['content']}
            cardId={item.item['guid']['content']}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default FOUR_COLUMN_TEASER_LIST;
