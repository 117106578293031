import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSelector } from 'react-redux';

import { RootState } from '../../../stores/reducers';
import focusService from '../../../services/focusService';
import { getKeyInfo, onEnterHandler } from '../../../utils';
import {
  TC_PLUS_MARKETING_PAGE_SIGN_OUT_BTN,
  SIGN_IN, PLUS_PAYWALL_SCREEN,
} from '../../../constants/text';
import { GAScreen } from '../../../services/analytics';
import { SUBSCRIBE_TO_PLUS } from '../../../constants/analyticsTypes';

import Button from '../../Button/Button';
import NavigationMenu from '../../NavigationMenu/NavigationMenu';
import Spinner from '../../Spinner/Spinner';
import defaultImage from '../../../assets/images/placeholder.png';

import './MarketingPage.scss';

interface OTTSubscribeProps {
  initFocusedEl: string;
  setCurrPageType: (pageType: string) => void;
  signOutFromTCPlus: () => void;
}

const OTTSubscribe = (props: OTTSubscribeProps) => {
  const {
    initFocusedEl = '',
    setCurrPageType,
    signOutFromTCPlus,
  } = props;

  const sliderSlidesData = useSelector(
    (state: RootState) => state.config.subscription['subscription_promo_slider_slides'] || []
  );
  const bgImage = useSelector((state: RootState) => state.config.styles?.subscribePromo?.background || '');
  const focusedCardBorderColor = useSelector(
    (state: RootState) => state.config.styles?.subscribePromo?.sliderSelectionColor || ''
  );
  const isTCUserLogin = useSelector((state: RootState) => state.Login.isUserTCLogin);

  const [activeCard, setActiveCard] = useState<number>(0);
  const [isCardSelected, setCardSelected] = useState(false);
  const [isImagesLoading, setImagesLoading] = useState<boolean>(true);

  const sliderRef = useRef<Slider>(null);

  const sliderSettings = {
    dots: true,
    useCSS: true,
    infinite: true,
    speed: 300,
    easing: 'linear',
    slidesToShow: 1,
    className: 'center',
    centerMode: true,
    centerPadding: '60px',
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    beforeChange: (previous, next) => {
      setActiveCard(next);
    },
    afterChange: current => {
      if (isCardSelected) {
        const sliderCard = document.querySelectorAll(`#slider-card-${current}`);

        if (sliderSlidesData.length - 2 <= current && sliderCard[1]) {
          focusService.setFocus(sliderCard[1]);
        } else {
          focusService.setFocus(sliderCard[0]);
        }
      }
    },
  };

  const onCardPress = (e: React.KeyboardEvent) => {
    focusService.setFocusDelay(400);
    switch (getKeyInfo(e.keyCode)) {
      case 'Right':
        sliderRef.current.slickNext();
        break;
      case 'Left':
        sliderRef.current.slickPrev();
        break;
      case 'Enter':
        onEnterHandler(e, () => setCurrPageType(PLUS_PAYWALL_SCREEN));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    GAScreen(SUBSCRIBE_TO_PLUS);

    const loadImage = (imageURL) => {
      return new Promise((resolve) => {
        const loadImg = new Image();

        loadImg.src = imageURL;
        loadImg.onload = () => resolve(imageURL);
        loadImg.onerror = () => resolve(imageURL);
      });
    };

    Promise.all(sliderSlidesData.map((image) => loadImage(image?.image)))
      .then(() => setImagesLoading(false))
      .catch(err => console.log('Failed to load images', err));

    focusService.setFocus(document.getElementById(initFocusedEl));

    return () => focusService.setFocusDelay(0);
  }, []);

  return (
    <div className='ott-subscribe' style={{ background: `url('${bgImage}') center center no-repeat fixed`}}>
      {isImagesLoading
        ? (
          <div className='ott-subscribe__spinner-wrapper'>
            <Spinner />
          </div>
        )
        : (
          <NavigationMenu
            isMenuFocusedOnStart={!initFocusedEl}
            focusDownSelector={`slider-card-${activeCard}`}
          />
        )
      }
      <div className='ott-subscribe__infinite-slick-slider'>
        <Slider {...sliderSettings} ref={sliderRef}>
          {sliderSlidesData.map((item, index) => {
            const id = index;

            return (
              <div
                key={id}
                className={`slider-card`}
                // eslint-disable-next-line
                role=''
              >
                <div
                  tabIndex={0}
                  className={`slider-card-image`}
                  style={{
                    background: `url(${item?.image}) center no-repeat,
                                 url(${defaultImage}) center/cover no-repeat`}}
                  id={`slider-card-${index}`}
                  data-tv-focus-up='#navigationSelectedItem'
                  data-tv-focus-down='.btn-acc-0'
                  onFocus={(e) => {
                    e.target.style.border = `2px solid ${focusedCardBorderColor}`;
                    setCardSelected(true);
                    sliderRef.current.slickPause();
                  }}
                  onBlur={(e) => {
                    e.target.style.border = '2px solid black';
                    setCardSelected(false);
                    sliderRef.current.slickPlay();
                  }}
                  onKeyDown={onCardPress}
                  // eslint-disable-next-line
                  role={''}
                  aria-label={`${item?.title || ''} ${item?.detail || ''} ${item?.cards?.join('') || ''}`}
                ></div>
              </div>
            );
          })}
        </Slider>
      </div>;
      <div
        // eslint-disable-next-line
        role=''
        className='ott-subscribe__buttons-group'
      >
        <Button
          title='SUBSCRIBE'
          btnID='marketing-page-sign-up-btn'
          focusUp={`slider-card-${activeCard}`}
          focusDown='.btn-acc-0'
          focusLeft='.btn-acc-0'
          focusRight='.btn-acc-1'
          index={0}
          aria-label='SUBSCRIBE'
          onKeyDown={(e) => onEnterHandler(e, setCurrPageType(PLUS_PAYWALL_SCREEN))}
        />
        <div
          className='ott-subscribe__buttons-group_text'
        >
          OR
        </div>
        <Button
          title={isTCUserLogin ? TC_PLUS_MARKETING_PAGE_SIGN_OUT_BTN : SIGN_IN}
          btnID='marketing-page-sign-in-out-btn'
          focusUp={`slider-card-${activeCard}`}
          focusDown='.btn-acc-1'
          focusLeft='.btn-acc-0'
          focusRight='.btn-acc-1'
          index={1}
          aria-label={isTCUserLogin ? TC_PLUS_MARKETING_PAGE_SIGN_OUT_BTN : SIGN_IN}
          onKeyDown={(e) => onEnterHandler(e, isTCUserLogin
            ? () => signOutFromTCPlus()
            : setCurrPageType(SIGN_IN))}
        />
      </div>
    </div>
  );
};

export default OTTSubscribe;
