import axiosInstance from './axiosInstance';
import { AxiosResponse } from 'axios';
import { errorDetails } from '../constants/errorDetails';
import { GENERATE_CUSTOMER_TOKEN_ERROR, REGISTER_CLEENG_CUSTOMER_ERROR } from '../constants/errorCodes';
import { getEnvironment } from '../utils/commonFunc';
import { NEWSLETTER_API_KEY } from '../constants/newsletterApiKeys';

export async function getRegCode(url: string, deviceId: string, brand: string): Promise<AxiosResponse> {
  const postData = {
    deviceId,
    brand,
  };

  try {
    const { data } = await axiosInstance.post(url, postData);

    return {...data};
  } catch (e: any) {
    console.log(`getRegCode, ERROR: ${e?.response?.data}`);
    throw new Error();
  }
}

export async function mvpdAuth(url: string, deviceId: string, brand: string): Promise<AxiosResponse> {
  const postData = {
    deviceId,
    brand,
  };

  try {
    const { data } = await axiosInstance.post(url, postData);

    return {
      ...data,
    };
  } catch (error: any) {
    let stringifyErrObj: string = JSON.stringify(error);

    if (error.response) {
      const err = error.response.data.error.slice(
        error.response.data.error.indexOf('{'), error.response.data.error.length,
      );
      const errData = JSON.parse(err);

      if (errData.status === 403 || errData.message === 'noAuthz') {
        stringifyErrObj = JSON.stringify(errData);
      }
    }

    console.log(`mvpdAuth, ERROR: ${stringifyErrObj}`);
    throw new Error(stringifyErrObj);
  }
}

export async function getProvidersLogos(url: string): Promise<AxiosResponse> {
  try {
    const { data } = await axiosInstance.get(url);

    return data;
  } catch (error: any) {
    throw Error(error?.response?.data?.message);
  }
}

export async function createUserService(url: string, postData): Promise<AxiosResponse> {
  try {
    const { data } = await axiosInstance.post(url, postData);

    return data;
  } catch (error: any) {
    console.log(`createUserService, ERROR: ${error?.response?.data}`);

    const defaultErrorMsg = `${REGISTER_CLEENG_CUSTOMER_ERROR}:
    ${errorDetails[REGISTER_CLEENG_CUSTOMER_ERROR].title}.
    ${errorDetails[REGISTER_CLEENG_CUSTOMER_ERROR].message}`;

    throw Error(error?.response?.data?.message || defaultErrorMsg);
  }
}

export async function emailAuthService(url: string, postData): Promise<AxiosResponse> {
  try {
    const { data } = await axiosInstance.post(url, postData);

    return data;
  } catch (error: any) {
    console.log(`emailAuthService, ERROR: ${error?.response?.data}`);

    const defaultErrorMsg = `${GENERATE_CUSTOMER_TOKEN_ERROR}:
    ${errorDetails[GENERATE_CUSTOMER_TOKEN_ERROR].title}.
    ${errorDetails[GENERATE_CUSTOMER_TOKEN_ERROR].message}`;

    throw Error(error?.response?.data?.message || defaultErrorMsg);
  }
}

export async function refreshUserToken(url: string, postData): Promise<AxiosResponse> {
  try {
    const { data } = await axiosInstance.post(url, postData);

    return data;
  } catch (error: any) {
    throw Error(error?.response?.data?.message);
  }
}

export async function resetPasswordService(url: string, postData): Promise<AxiosResponse> {
  try {
    const { data } = await axiosInstance.post(url, postData);

    return data;
  } catch (error: any) {
    throw Error(error?.response?.data?.message);
  }
}

export async function getAgreementText(url: string): Promise<AxiosResponse | undefined> {
  try {
    const { data } = await axiosInstance.get(url);

    return data;
  } catch (error: any) {
    console.log('Login::getAgreementText service error', error?.response?.data?.error);
  }
}

export async function activateNewsletter(url: string, postData): Promise<AxiosResponse | undefined> {
  try {
    const key = NEWSLETTER_API_KEY[getEnvironment()] || NEWSLETTER_API_KEY.default;
    const { data } = await axiosInstance.post(url, {
      email_address: postData.email,
    }, {
      headers: {
        'x-api-key': key,
      },
    });

    return data;
  } catch (error: any) {
    console.log('Login::activateNewsletter service error', error?.response?.data?.error);
  }
}
