import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import ChevronArrow from '../../../components/ChevronArrow/ChevronArrow';
import { getKeyInfo } from '../../../utils';
import { RootState } from '../../../stores/reducers';
import { OTT_BRAND, CHARGE_OTT_BRAND, COMET_OTT_BRAND } from '../../../constants/structureTypes';
import { speakTTS, stopTTS } from '../../../services/TTSService';
import { TODAY_SCHEDULE, NEXT_DAY_SCHEDULE } from '../../../constants/text';

import './CustomCalendar.scss';

type CustomCalendarProps = {
  changeDateForChannels: (selectedDate: any) => boolean;
  showDownArrow: boolean;
  value: any;
}

const CustomCalendar = ({ value, showDownArrow, changeDateForChannels } : CustomCalendarProps) => {
  const [selectedDayOfMonth, setSelectedDayOfMonth] = useState(moment(value).format());
  const [isDateContainerFocused, setIsDateContainerFocused] = useState<boolean>(false);
  const [couldMoveUpToNavBar, setCouldMoveUpToNavBar] = useState<boolean>(true);

  const epgStyles = useSelector((state: RootState) => state.config.styles['epg']);

  const today = moment().format('MM/DD/YYYY');
  const isToday = moment(today).isSame(selectedDayOfMonth);

  const onKeyDown = (e) => {
    if (getKeyInfo(e.keyCode) === 'Down' && showDownArrow) {
      if (changeDateForChannels(moment(selectedDayOfMonth).add(1, 'days').format('DD/MM/YYYY'))) {
        const nextDay = moment(selectedDayOfMonth).add(1, 'days').toString();
        const dayWeek = renderDayOfWeek(nextDay);
        const month = renderMonth(nextDay);
        const day = renderDay(nextDay);

        setSelectedDayOfMonth(nextDay);
        speakTTS(`Schedule for ${dayWeek} ${month} the ${day}th, ${TODAY_SCHEDULE}`);
      }
    } else if (getKeyInfo(e.keyCode) === 'Up' && !isToday) {
      if (changeDateForChannels(moment(selectedDayOfMonth).subtract(1, 'days').format('DD/MM/YYYY'))) {
        const prevDay = moment(selectedDayOfMonth).subtract(1, 'days').toString();
        const dayWeek = renderDayOfWeek(prevDay);
        const month = renderMonth(prevDay);
        const day = renderDay(prevDay);

        setSelectedDayOfMonth(prevDay);
        speakTTS(`Schedule for ${dayWeek} ${month} the ${day}th, ${NEXT_DAY_SCHEDULE}`);
      }
    } else if (getKeyInfo(e.keyCode) === 'Up' && isToday) {
      setCouldMoveUpToNavBar(true);
      stopTTS();
    }
  };

  const renderMonth = (day: null | string = null) => {
    const month = moment(day ? day : selectedDayOfMonth).month();

    return moment(month + 1, 'MM').format('MMMM').toUpperCase();
  };
  const renderDay = (currentDay: null | string = null) => {
    const day = moment(currentDay ? currentDay : selectedDayOfMonth).date();

    return (day < 10 && !currentDay) ? `0${day}` : day;
  };

  const renderDayOfWeek = (day: null | string = null) => {
    const propsDayOfWeek = moment(day ? day : selectedDayOfMonth).format('MM/DD/YYYY');

    return today === propsDayOfWeek ? 'Today' : `${moment(day ? day : selectedDayOfMonth).format('dddd')}`;
  };

  const setArrowColor = useCallback(() => {
    if ((OTT_BRAND === CHARGE_OTT_BRAND && isDateContainerFocused) || (OTT_BRAND === COMET_OTT_BRAND)) {
      return epgStyles.dateMonthBgColorFocused;
    }

    return '#FFFFFF';

  }, [epgStyles.dateMonthBgColorFocused, isDateContainerFocused]);

  return (
    <div
      className={`date-container ${OTT_BRAND === CHARGE_OTT_BRAND ? 'date-container-charge' : ''}`}
      style={{border: `${epgStyles.cellLogoBorderColor} ${epgStyles.dateDayNameBorderOn}px solid`}}
      tabIndex={0}
      // eslint-disable-next-line
      role=''
      aria-label={`Schedule for ${renderDayOfWeek()} ${renderMonth()}
      the ${renderDay(selectedDayOfMonth)}th, press ${isToday ? 'down' : 'up'}
      to see schedule for ${isToday ? 'next day' : 'today'}`}
      data-tv-focus-down=".date-container"
      data-tv-focus-up={couldMoveUpToNavBar ? '#navigationSelectedItem' : '.date-container'}
      data-tv-focus-left='.date-container'
      data-tv-focus-right=".program-0"
      onKeyDown={onKeyDown}
      onFocus={() => {
        setCouldMoveUpToNavBar(false);
        !isDateContainerFocused && setIsDateContainerFocused(true);
      }}
      onBlur={() => {
        setIsDateContainerFocused(false);
      }}
    >
      <div className='date-day-month-container'
        style={{
          border: `${epgStyles.cellLogoBorderColor} ${epgStyles.dateDayNameBorderOn}px solid`,
          fontFamily: epgStyles.dateDayNameFontFace.fontFamily,
          backgroundColor: `${isDateContainerFocused
            ? epgStyles.dateMonthBgColorFocused
            : epgStyles.dateMonthBgColorUnfocused}`,
        }}
      >
        <div style={{
          fontSize: `${epgStyles.dateDayNameFontSize}px`,
          textTransform: epgStyles.dateDayNameTextTransform,
          color: isDateContainerFocused ? epgStyles.dateDayColorOn : epgStyles.dateDayColorOff,
        }}>
          {renderDayOfWeek()}
        </div>
        <div style={{
          fontFamily: epgStyles.dateMonthNameFontFace.fontFamily,
          fontSize: `${epgStyles.dateMonthNameFontSize}px`,
          color: isDateContainerFocused ? epgStyles.dateDayColorOn : epgStyles.dateDayColorOff,
        }}>
          {renderMonth()}
        </div>
      </div>
      <div
        className='date-day-number-container'
        tabIndex={0}
        style={{
          border: `${epgStyles.cellLogoBorderColor} ${epgStyles.dateDayNameBorderOn}px solid`,
        }}
      >
        <p className='date-day-number'
          style={{
            fontFamily: epgStyles.dateDayNameFontFace.fontFamily,
            fontSize: `${epgStyles.dateDayNumberFontSize}px`,
            height: `${epgStyles.dateDayNumberFontSize}px`,
          }}
        >{renderDay()}</p>
        {!isToday &&
          <ChevronArrow top={-60} left={54} rotateValue={180} color={setArrowColor} isOverlayArrow={false} />
        }
        {showDownArrow && <ChevronArrow bottom={-60} left={54} color={setArrowColor} isOverlayArrow={false}/>}
      </div>
    </div>
  );
};

export default CustomCalendar;
