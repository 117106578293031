import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../stores/reducers';
import { getProperImage } from '../../../utils/commonFunc';
import NoImage from '../../../assets/images/placeholder.png';

import './DetailsOverlay.scss';

type DetailsOverlayProps = {
  metadataForOverlay: {
    posterImages: Array<any>;
    title: string;
    description: string;
  }
  visible: boolean;
  isConnectedToNetwork: boolean;
}

const DetailsOverlay = (props: DetailsOverlayProps) => {
  const {
    metadataForOverlay: {
      title,
      description,
      posterImages,
    },
    visible,
    isConnectedToNetwork,
  } = props;

  const [optimizedImage, setOptimizedImage] = useState(
    getProperImage(posterImages, 355, 200)
  );

  const getImageUrl = () => {
    setOptimizedImage({ url: NoImage });
  };

  const itemStyles = useSelector((state: RootState) => state.config.styles['teaser']);

  const itemGetFocused = useCallback((e) => {
    e.target.style.border = `${itemStyles.focusBorderSize}px solid ${itemStyles.focusBorderColor}`;
  }, []); //eslint-disable-line

  const itemGetBlur = useCallback((e) => {
    e.target.style.border = `${itemStyles.focusBorderSize}px solid ${itemStyles.vodBgColor}`;
  }, []); //eslint-disable-line

  const styles = useMemo(() => ({
    opacity: (visible && isConnectedToNetwork) ? 1 : 0,
    fontFamily: itemStyles.headlineFontFace.fontFamily,
    width: `${1920 - 2 * itemStyles.focusBorderSize}px`,
    border: `${itemStyles.focusBorderSize}px solid ${itemStyles.vodBgColor}`,
    backgroundColor: `${itemStyles.vodBgColor}`,
  }), [isConnectedToNetwork, visible, itemStyles]);

  const detailsRef = useRef(null);

  return (
    <div
      className='video-player-details'
      style={styles}
      ref={detailsRef}
      // eslint-disable-next-line
      role=''
      aria-label=''
      data-tv-focus-down='.play-pause-container'
      onFocus={itemGetFocused}
      onBlur={itemGetBlur}>
      <div className='background-rectangle'>
        <div className='data-container'>
          <div className='poster-container'>
            <img
              alt='Poster'
              src={optimizedImage?.url}
              aria-hidden={true}
              onError={getImageUrl}
            />
          </div>
          <div className='metadata-container'>
            <h3>{title}</h3>
            <p style={{fontFamily: `${itemStyles.streamFontFace.fontFamily}`}}>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DetailsOverlay);
