import axiosInstance from './axiosInstance';
import { ComponentPayload, DRMAccessesLive } from '../stores/actions/OTTFeed';
import { addParametersToURL } from '../utils/commonFunc';
import { DRMFail } from '../stores/actions/OTTEpisode';
import { getDefaultHeaders } from './helpers';

export async function getOTTFeedComponentContent(url: string, station: string): Promise<ComponentPayload> {
  const {
    data: {
      rss: {
        channel: {
          item,
        },
      },
    },
  } = await axiosInstance.get(addParametersToURL(url, {station}));

  return {
    item,
  };
}

export async function requestOTTFeedComponentContent(
  url: string,
  postData: any,
  requestHeaders: any
): Promise<ComponentPayload | any> {

  const options = {
    headers: {
      ...requestHeaders,
      ...getDefaultHeaders(),
    },
  };

  try {
    const {
      data: {
        rss: {
          channel: {
            item,
          },
        },
      },
    } = await axiosInstance.makeApiCall(url, postData, options);

    return {
      item,
    };
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.error.code === 1600) {throw new Error('RefreshToken');}
    } else {
      throw new Error('Failed to load data');
    }
  }
}

export async function getDRMAccesses(
  url: string,
  station: string,
  body: object
): Promise<DRMAccessesLive | DRMFail | any> {
  const headers = {
    ...getDefaultHeaders(),
  };

  try {
    const { data } = await axiosInstance.post(
      addParametersToURL(url, { station }),
      body,
      {
        headers,
      },
    );

    return { ...data };
  } catch (error: any) {
    if (error.response) {
      return {
        drmFailUrl: error.response.data.errors[0].meta.drmfail,
        drmFailTitle: error.response.data.errors[0].title,
      };
    }
  }
}

export async function getExtraAdParams(url: string, station: string): Promise<any> {
  try {
    const {
      data: {
        cust_params,
      },
    } = await axiosInstance.get(addParametersToURL(url, {station}));

    return {
      ...cust_params,
    };
  } catch (error) {
    console.log('Failed to load ad parameters');
  }
}
