import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import currentPlatform from '../../../platform/currentPlatform';
import { networkConnection } from '../../../stores/actions/common';
import { NETWORK_STATUS_MODAL_DESCRIPTION, NETWORK_STATUS_MODAL_TITLE } from '../../../constants/text';

import Modal from '../Modal';

const NetworkStatusModal = () => {
  const [isError, setIsError] = useState<boolean>(false);

  const dispatch = useDispatch();

  const networkChangeHandler = () => {
    currentPlatform.addNetworkHandler({
      setToStoreIsNetworkConnected: (val: boolean) => dispatch(networkConnection(val)),
      setIsModalErrorShown: (val: boolean) => setIsError(val),
    });
  };

  useEffect(() => {
    window.addEventListener('load', networkChangeHandler);

    return () => {
      window.removeEventListener('load', networkChangeHandler);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isError ? (
        <Modal
          title={NETWORK_STATUS_MODAL_TITLE}
          description={NETWORK_STATUS_MODAL_DESCRIPTION}
        />
      ) : null}
    </>
  );
};

export default React.memo(NetworkStatusModal);
