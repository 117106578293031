import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { RootState } from '../../stores/reducers';
import { SetRecommendedPlaylistItemIndex } from '../../stores/actions/OTTEpisode';
import {
  RecommendedPlaylist,
  CurrentPlaylistItemIndex,
  AddMidrollBreaks,
} from '../../stores/actions/OTTEpisode';
import { sendLinearTelemetry } from '../../stores/actions/linearTelemetry';
import {
  playerAction, playerInitialize,
  playerOpenVODUrl, playerSeekTo,
} from '../../stores/actions/player';
import { setComponentOTTEpisodeData } from '../../stores/actions/OTTEpisode';
import * as OTTEpisodeActionTypes from '../../stores/actions/types/OTTEpisode';
import { sendTitleLevelReport } from '../../stores/actions/titleLevelReport';

import useComponentMounted from '../../hooks/isComponentMounted';
import useEventListener from '../../hooks/eventListener';
import useHideSignInButton from '../../hooks/hideSignInButton';
import { clearHitDimensions, GAEvent, generateAnalyticsErrorDetails, setDimensions } from '../../services/analytics';
import { getLinearTelemetryURL, LinearEventType } from '../../services/linearTelemetry';
import focusService from '../../services/focusService';
import adsService from '../../services/adsService';
import TimeTracker from '../../services/TimeTracker';
import {
  speakTTS, getDurationString,
  getCurrentTimeString, stopTTS,
  convertIntToTimeStringForTTS,
  useAnnouncePageLoading,
} from '../../services/TTSService';
import { navigateBack, navigateWithoutHistory } from '../../services/NavigationService';
import { getKeyInfo, isContentWithRestriction, onBackHandler, onStopHandler } from '../../utils';
import { setMute } from '../../utils/tizen';
import { openPage, IJson } from '../../utils/OpenPage';
import { skipVideoGA } from '../../utils/commonFunc';
import { shouldShowTime } from '../../stores/schemas/utils';

import currentPlatform from '../../platform/currentPlatform';
import { config } from '../../config';

import {
  SEEK_INTERVAL,
  HIDE_OVERLAY_TIMEOUT,
  BUFFER_TIME,
  FAST_SEEK_TIMER_INTERVAL,
  MAX_FAST_SEEK_INTERVAL_SPEED,
  FAST_SEEK_MULTIPLIER,
  EXTREMELY_LONG_BUFFERING_TIMEOUT,
} from '../../constants/videoPlayer';
import * as analyticsTypes from '../../constants/analyticsTypes';
import { LINEAR_VOD, CLEENG_RESTRICTION } from '../../constants/text';
import { isStirrBrand } from '../../constants/structureTypes';
import { VIDEO_ERROR } from '../../constants/errorCodes';

import Controls from './Controls/Controls';
import DetailsOverlay from './DetailsOverlay/DetailsOverlay';
import RecommendedPlayback from './RecommendedPlayback/RecommendedPlayback';
import Advertisement from '../Advertisement/Advertisement';
import Spinner from '../Spinner/Spinner';
import PlayerWrapper from '../Player/PlayerWrapper';
import CaptionsMenu from '../CaptionsMenu/CaptionsMenu';
import Button from '../Button/Button';
import ErrorModal from '../Modals/ErrorModal/ErrorModal';

import './VOD.scss';

declare const webapis: any;

const controlTypes = {
  MediaRewind: 'MediaRewind',
  JumpBackward: 'JumpBackward',
  MediaPlay: 'MediaPlay',
  MediaPause: 'MediaPause',
  MediaFastForward: 'MediaFastForward',
  JumpForward: 'JumpForward',
  MediaStop: 'MediaStop',
};

type VODProps = {
  configData: any;
  source: string;
  streams: Array<any>;
  refreshStreamLimit: number;
  includeAds: boolean;
  adUrl: string;
  midrollBreaks: AddMidrollBreaks;
  mediaContent: any;
  accessDisabled: boolean;
  recommendedPlaylist: RecommendedPlaylist;
  currentPlaylistItemIndex: CurrentPlaylistItemIndex;
  station: string;
  clearPage: () => void;
  dataForLiveRamp: {
    station: string;
    navIdx?: number;
    seasonTitle: string;
    seriesTitle: string;
  };
  showAuthPopUp: boolean;
  setShowAuthPopUp: (boolean) => void;
  signInBtnPressed: any;
  logInRequestor: string;
  shouldSendAnalyticsOnEnd: any;
};

const VOD = (props: VODProps) => {
  const {
    configData,
    source,
    streams,
    refreshStreamLimit,
    includeAds,
    adUrl,
    midrollBreaks,
    mediaContent,
    accessDisabled,
    recommendedPlaylist,
    currentPlaylistItemIndex,
    station,
    clearPage,
    dataForLiveRamp,
    showAuthPopUp,
    setShowAuthPopUp,
    signInBtnPressed,
    logInRequestor,
    shouldSendAnalyticsOnEnd,
  } = props;

  const isComponentMounted = useComponentMounted();

  const drm = useSelector((state: RootState) => state.OTTEpisode.drm);
  const drmFail = useSelector((state: RootState) => state.OTTEpisode.drmFail);

  const videoRef = useRef<any>();
  const adsVideoRef = useRef<any>(null);
  const adsContainerRef = useRef<any>(null);
  const overlayTimerRef = useRef<any>(null);
  const seekingInterval = useRef<any>(null);
  const ttsSeekingInterval = useRef<any>(null);
  const ttsFastFwdRwdSeekingInterval = useRef<any>(null);
  const ttsSeekingTimeOut = useRef<any>(null);
  const seekSpeedForTTS = useRef<number>(0);
  const adsLoadingRef = useRef<boolean>(false);
  const adSubStr = useRef<any>(null);
  const refreshTimer = useRef<any>(null);
  const watchDuration = useRef<number>(0);
  const tempWatchDuration = useRef<number>(0);
  const isPlayerAlive = useRef<boolean>(true);
  const lastActionName = useRef<string>('');
  const loadingVodTime = useRef<number>(moment().unix());
  const vodLength = useRef<number>(0); // vod length in seconds
  const isShownSignInBtn = useRef<boolean>(true);
  const adsAmount = useRef<number>(0);
  const currentAdPosition = useRef<number>(0);
  const uuidForLinearTelemetry = useRef<string>('');
  const timerForLinearTelemetry = useRef<any>(null);
  const timerIntervalForLinearTelemetry = useRef<any>();
  const adsWasSent = useRef<boolean>(false);
  const focusedElBeforeCCMenuAppear = useRef<any>(null);
  const shouldRefreshStream = useRef<boolean>(true); // TODO: do we need this?
  const videoBandwidth = useRef<string | null>(null);
  const contentLoadingTimer = useRef<any>(null);
  const currentTime4GA = useRef<number>(0);
  const videoOpenedAfterMidRoll = useRef<boolean>(false);
  const playerActionsTimeouts = useRef<Array<any>>([]);
  const speakTTSTimerRef = useRef<any>(null);
  const adLongBufferingTimeout = useRef<any>(null);
  const currentPlayPosition = useRef<number>(0);
  const throttleCheckAdPositionTimer = useRef<number>(0);
  const adPreviousTime = useRef<number>(0);

  const [isOverlayInfoAnnounced, setOverlayInfoAnnounced] = useState(false);
  const [videoLaunched, setVideoLaunched] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [paused, setPaused] = useState(includeAds);
  const [isBuffering, setIsBuffering] = useState(true);
  const [isSeeking, setIsSeeking] = useState(false);
  const [isSeeked, setIsSeeked] = useState(false);
  const [seekSpeed, setSeekSpeed] = useState(0);
  const [activeOverlay, setActiveOverlay] = useState(false);
  const [startSeekingToRight, setStartSeekingToRight] = useState(false);
  const [startSeekingToLeft, setStartSeekingToLeft] = useState(false);
  const [adsLoaded, setAdsLoaded] = useState(false);
  const [adPlaying, setAdPlaying] = useState(includeAds);
  const [adDuration, setAdDuration] = useState(0);
  const [adCurrentTime, setAdCurrentTime] = useState(0);
  const [refreshDRM, setRefreshDRM] = useState(false);
  const [previousDRMToken, setPreviousDRMToken] = useState(drm.AuthXML);
  const [isShownErrorMessage, setIsShownErrorMessage] = useState(false);
  const [isStartOrEndPosition, setIsStartOrEndposition] = useState<boolean>(false);
  const [isCaptionsMenuVisible, setIsCaptionsMenuVisible] = useState<boolean>(false);
  const [ttsCounterToAnnounceSeekingText, setTTSCounterToAnnounceSeekingText] = useState<number>(0);

  const features = useSelector((state: RootState) => state.config.features);
  const isConnectedToNetwork = useSelector((state: RootState) => state.common.isConnectedToNetwork);
  const storeAllConfigs = useSelector((state: RootState) => state.config.allConfigs);
  const { vodPlayer } = useSelector((state: RootState) => state.config.players);
  const spoilerPreventionStatus = useSelector((state: RootState) => state.common.isSpoilerPreventionEnabled);

  const history = useHistory();
  const dispatch = useDispatch();

  const isDrm = !!drm?.AuthXML;
  const isFireTVPlatform = config.appPlatform === 'firetv';
  const isTimeVisible = shouldShowTime(
    spoilerPreventionStatus,
    features.spoiler_prevention,
    isContentWithRestriction(mediaContent?.['media:restriction'], CLEENG_RESTRICTION)
  );

  let streamRefreshedTimes = -1;

  const visibleOverlay = (activeOverlay || paused) &&
    !adPlaying &&
    !showAuthPopUp &&
    !accessDisabled;

  const timeLeft = Math.round((duration - currentTime) / 1000);
  const isRecommendedPlaybackVisible = duration ? (timeLeft <= 10 && !isSeeking) : false;

  const metadataForOverlay = useMemo(() => {
    let title = '';
    let description = '';
    let posterImages = [];

    if (mediaContent) {
      title = mediaContent['media:title']?.content;
      description = mediaContent['media:description']?.content;
      if (mediaContent['media:thumbnail'] && mediaContent['media:thumbnail'].length) {
        posterImages = mediaContent['media:thumbnail'];
      }
    }

    return { title, description, posterImages };
  }, [
    mediaContent['media:title']?.content,
    mediaContent['media:description']?.content,
    mediaContent['media:thumbnail']?.length,
  ]);

  const getTtsTextAfterSeeking = () => isTimeVisible
    ? `Seek to ${getCurrentTimeString(currentTime)} ${getDurationString(duration)}, press play to play`
    : 'Seek finished, press play to play';

  const getSeekingAction = () => seekSpeedForTTS.current < 0
    ? 'rewinding'
    : 'fast forwarding';

  const getTTSTextForSeekingAction = () => {
    const ttsInitialText = !(Math.abs(seekSpeedForTTS.current) === 0)
      ? `${getSeekingAction()} speed x${Math.abs(seekSpeedForTTS.current)}
        ${isTimeVisible ? getCurrentTimeString(currentTime) : ''}, press play to resume, or pause to stop seeking`
      : getTtsTextAfterSeeking();

    speakTTS(ttsInitialText);
  };

  const openUrl = (videoUrl: string) => {
    let playbackUrl = isDrm ? drm.dashUrl : drmFail.drmFailUrl || videoUrl || source;

    if (streamRefreshedTimes >= 0 && streams[streamRefreshedTimes]?.uri) {
      playbackUrl = streams[streamRefreshedTimes].uri;
    }

    const timeoutId = setTimeout(() => {
      dispatch(playerOpenVODUrl(playbackUrl));
    }, 100);

    playerActionsTimeouts.current.push(timeoutId);
  };

  const fatalErrorHandler = () => {
    setIsShownErrorMessage(true);
    destroyPlayer();
    setIsBuffering(false);
  };

  const initPlayer = useCallback((videoUrl = '') => {
    dispatch(playerInitialize(videoRef.current));

    openUrl(videoUrl);
    isShownErrorMessage && setIsShownErrorMessage(false);
    isPlayerAlive.current = true;
    clearTimeout(contentLoadingTimer.current);

    contentLoadingTimer.current = setTimeout(() => {
      const errorDetails = generateAnalyticsErrorDetails(
        vodPlayer,
        { item: configData },
        false
      );

      setDimensions({
        errorDetail: errorDetails,
        errorMessage: `${analyticsTypes.VIDEO} ${analyticsTypes.LONG_BUFFERING}`,
        adType: '',
      });
      GAEvent(
        analyticsTypes.ERROR_PLAYER,
        analyticsTypes.VIDEO,
        configData['guid']['content'],
        { nonInteraction: true },
      );
      clearHitDimensions(['errorDetail', 'adType', 'errorMessage']);
      fatalErrorHandler();
    }, EXTREMELY_LONG_BUFFERING_TIMEOUT);
    // eslint-disable-next-line
  }, [isShownErrorMessage, openUrl]);

  const callback = (arg) => {
    setOverlayInfoAnnounced(arg);
  };

  const speakTTSCallback = useCallback((currentTimeFromPlayer) => {
    const overlayTextForTTS = !accessDisabled && !isSeeked
      ? `${drmFail.drmFailTitle}, pause button, button 1 of 1,
        ${isTimeVisible ? getCurrentTimeString(currentTimeFromPlayer) : ''}
        ${isTimeVisible ? getDurationString(duration) : ''}
        ${metadataForOverlay.title} ${metadataForOverlay.description}`
      : '';

    speakTTS(overlayTextForTTS, callback, true);
  }, [
    metadataForOverlay.description, metadataForOverlay.title,
    accessDisabled, drmFail.drmFailTitle, duration, isSeeked,
  ]);

  const displayOverlayWithTimer = () => {
    if (!activeOverlay && !isCaptionsMenuVisible) {
      setOverlayInfoAnnounced(false);
      setActiveOverlay(true);
      clearTimeout(speakTTSTimerRef.current);
      speakTTSTimerRef.current = setTimeout(() => {
        speakTTSCallback(currentTime);
      }, 1000);
    }

    clearTimeout(overlayTimerRef.current);
    overlayTimerRef.current = setTimeout(
      () => {
        setActiveOverlay(false);
        setOverlayInfoAnnounced(false);
      },
      HIDE_OVERLAY_TIMEOUT,
    );
  };

  const callTitleLvlReportService = (eventName: string) => {
    if (eventName === lastActionName.current
      || (eventName === 'pause' && lastActionName.current === 'seek')
      || (eventName === 'back' && lastActionName.current === 'seek')
      || (eventName === 'seek' && lastActionName.current === 'pause')) {
      return;
    }
    if (storeAllConfigs['API']['video_events_track']) {

      const dateNowInSec = Math.round(Date.now() / 1000);

      let actionName: string = '';

      if (eventName === 'play') {
        isPlayerAlive.current = true;
        watchDuration.current = dateNowInSec;
        actionName = 'play';
        lastActionName.current = 'play';
      } else if (eventName === 'back') {
        if (lastActionName.current === 'pause' || lastActionName.current === '') {
          if (!tempWatchDuration.current) {
            tempWatchDuration.current = 0;
          }
        } else {
          tempWatchDuration.current = dateNowInSec - watchDuration.current;
        }
        actionName = 'pause';
      } else if (eventName === 'seek' || eventName === 'pause') {
        (watchDuration.current)
          ? tempWatchDuration.current = dateNowInSec - watchDuration.current
          : tempWatchDuration.current = 0;
        lastActionName.current = eventName;
        actionName = 'pause';
      }

      dispatch(sendTitleLevelReport({
        url: storeAllConfigs['API']['video_events_track'],
        isDrm,
        eventType: actionName,
        asset_title: configData['media:content']['media:title']['content'],
        seasonTitle: dataForLiveRamp.seasonTitle,
        series_name: dataForLiveRamp.seriesTitle,
        asset_uuid: configData['guid']['content'],
        watch_duration: tempWatchDuration.current,
      }));
    }
  };

  useHideSignInButton(logInRequestor, isShownSignInBtn);

  const dispatchLinearTelemetry = (eventType: LinearEventType) => {
    if (isStirrBrand && getLinearTelemetryURL()) {

      dispatch(sendLinearTelemetry(
        {
          content_type: LINEAR_VOD,
          drm_required: isDrm,
          duration: timerForLinearTelemetry.current.getTimeTotal(),
          event_type: eventType,
          player: vodPlayer,
          session_id: uuidForLinearTelemetry.current,
          uuidFeedOrChannel: configData['guid']['content'],
        }));
    }
  };

  const launchLinearTelemetry = () => {
    if (getLinearTelemetryURL() && !timerForLinearTelemetry.current && isStirrBrand) {
      uuidForLinearTelemetry.current = uuidv4();
      timerForLinearTelemetry.current = new TimeTracker();
      dispatchLinearTelemetry('play');
      timerForLinearTelemetry.current.start();
      timerIntervalForLinearTelemetry.current = setInterval(() => {
        if (!isComponentMounted) {
          clearInterval(timerIntervalForLinearTelemetry.current);

          return;
        }
        dispatchLinearTelemetry('heartbeat');
      }, 60000);
    }
  };

  const onDurationChange = useCallback((event) => {
    const durationMSec = Math.floor(event.target.duration * 1000);

    if (duration !== durationMSec) {
      setDuration(durationMSec);
    }
  }, [duration]);
  const setOriginDuration = useCallback((timeDuration) => {
    if (duration !== timeDuration) {
      setDuration(timeDuration);
    }
  }, [duration]);

  const onCanPlay = useCallback((videoBand, timeDuration) => {
    displayOverlayWithTimer();

    if (videoBand) {
      videoBandwidth.current = videoBand;
    }
    if (!adsWasSent.current) {

      const timeToLoad = moment().unix() - loadingVodTime.current;

      vodLength.current = Math.floor(moment.duration(timeDuration, 'milliseconds').asSeconds());

      setOriginDuration(timeDuration);
      setDimensions({
        vodLength: `${vodLength.current}`,
        videoLoadTime: `${timeToLoad}`,
        videoBandwidth: videoBand,
      });
      adsWasSent.current = true;
    }
  }, [setOriginDuration, isBuffering]);

  const setGADimensions = () => {
    const currentSecond = Math.floor(moment.duration(currentTime, 'milliseconds').asSeconds());

    setDimensions({
      vodTimeWatched: `${currentSecond}`,
      vodPctComplete: parseFloat(`${(currentSecond * 100) / vodLength.current}`).toFixed(2),
      videoBandwidth: videoBandwidth.current,
    });
  };

  const loadAds = () => {
    // Prevent this function from running on if there are already ads loaded
    if (adsLoaded) {
      return;
    }

    try {
      setAdsLoaded(true);
      setAdPlaying(true);
      adsService.loadAdsWithIMA();
    } catch (adError) {
      // Play the video without ads, if an error occurs
      console.log('AdsManager could not be started');
      console.log(adError);
      setPaused(false);
      dispatch(playerAction('play'));
      callTitleLvlReportService('play');
      !timerIntervalForLinearTelemetry.current && launchLinearTelemetry();
      setAdPlaying(false);
      setGADimensions();
      GAEvent(
        `${analyticsTypes.ON_DEMAND} ${adSubStr.current}-Roll`,
        analyticsTypes.FAILED,
        mediaContent['media:title']['content'],
        { nonInteraction: true },
      );
    }
  };

  const onSuccessLoad = useCallback(async (videoBand, timeDuration) => {
    if ((videoBand && timeDuration) || (vodPlayer === 'avplay')) {
      onCanPlay(videoBand, timeDuration);
    }

    // need seek to current time because can't restore playback with avplay.restore() on some tvs after network error
    if (currentTime) {
      dispatch(playerSeekTo(currentTime));
    }

    setPaused(false);
    setVideoLaunched(true);
    dispatch(playerAction('play'));
    callTitleLvlReportService('play');
    clearTimeout(refreshTimer.current);
    // eslint-disable-next-line
  }, [callTitleLvlReportService, onCanPlay, vodPlayer]);

  const destroyPlayer = useCallback(() => {
    isPlayerAlive.current && callTitleLvlReportService('back');
    timerIntervalForLinearTelemetry.current && dispatchLinearTelemetry('stop');
    timerForLinearTelemetry.current && timerForLinearTelemetry.current.reset();
    clearInterval(timerIntervalForLinearTelemetry.current);
    timerIntervalForLinearTelemetry.current = null;
    dispatch(playerAction('close'));
    adsWasSent.current = false;
    isPlayerAlive.current = false;
    clearTimeout(overlayTimerRef.current);
    adsService.destroyIMA();
  }, []); //eslint-disable-line

  const onFailedLoad = useCallback((e: any) => {
    console.error('Loading video failed ', e);

    isBuffering && setIsBuffering(false);
    onError(e);
  }, [isBuffering]);

  const calculatePercentage = (currTime, duration): boolean =>
    +((currTime / duration) * 100).toFixed(0) >= 90;

  const onBufferingComplete = useCallback(() => {
    setIsBuffering(false);
    // eslint-disable-next-line
  }, []);

  const onCurrentPlaytime = useCallback((currTime) => {
    if (typeof currTime === 'number' && currTime >= 0) {

      isComponentMounted && setCurrentTime(currTime);
      if (currTime > 0) {
        currentTime4GA.current = currTime;
      }

      if (adsVideoRef.current) {
        adsVideoRef.current.currentTime = currTime / 1000;
      }

      if (ttsFastFwdRwdSeekingInterval.current && (Math.abs(seekSpeedForTTS.current) === 0)) {
        clearInterval(ttsFastFwdRwdSeekingInterval.current);
        ttsFastFwdRwdSeekingInterval.current = null;
      }
    }
  }, [isComponentMounted]);

  const onDrmEvent = useCallback((drmEvent, drmData) => {
    console.log('DRM callback: ' + drmEvent + ', data: ' + drmData);
  }, []);

  const onError = useCallback((error) => {
    console.log(`Player Error: `, error.message);
    setIsBuffering(true);

    if ((streamRefreshedTimes < refreshStreamLimit && shouldRefreshStream.current)) {
      streamRefreshedTimes++;
      destroyPlayer();
      initPlayer();
    } else if (streamRefreshedTimes >= refreshStreamLimit) {
      clearTimeout(refreshTimer.current);
      refreshTimer.current = setTimeout(() => {
        let errorForAnalytics = {};
        const isErrorWithInfo = typeof error !== 'string';

        if (vodPlayer === 'avplay') {

          errorForAnalytics = {
            item: configData,
            ...(isErrorWithInfo ? error : { message: error }),
          };
        } else {
          errorForAnalytics = {
            ...error,
          };
        }

        const errorDetails = generateAnalyticsErrorDetails(vodPlayer, errorForAnalytics, false);

        setDimensions({
          errorDetail: errorDetails,
          errorMessage: (isErrorWithInfo ? error?.message : error) || '',
          adType: adsVideoRef.current ? analyticsTypes.CLIENT_STITCHED : '',
        });
        GAEvent(
          analyticsTypes.ERROR_PLAYER,
          analyticsTypes.VIDEO,
          configData['guid']['content'],
          { nonInteraction: true },
        );
        clearHitDimensions(['errorDetail', 'adType', 'errorMessage']);
        fatalErrorHandler();
      }, 10000);
    }
  }, [refreshStreamLimit, streamRefreshedTimes, setIsBuffering]);

  const clearOTTEpisodeData = () => {
    dispatch({
      type: OTTEpisodeActionTypes.CLEAR_OTTEPISODE,
    });
  };

  const isRecommendedPlaybackAvailable =
    recommendedPlaylist.length &&
    currentPlaylistItemIndex >= 0 &&
    recommendedPlaylist[currentPlaylistItemIndex + 1] &&
    !accessDisabled;

  const openRecommendedPlayback = () => {
    clearInterval(seekingInterval.current);
    clearInterval(ttsFastFwdRwdSeekingInterval.current);
    dispatch(SetRecommendedPlaylistItemIndex(currentPlaylistItemIndex + 1));
    navigateWithoutHistory(
      history,
      openPage(
        recommendedPlaylist[currentPlaylistItemIndex + 1].item as unknown as IJson,
        { station },
      ),
      clearPage,
    );
  };

  const onStreamCompleted = useCallback(() => {
    if ((features.cleeng || features.mvpd) && accessDisabled) {
      setShowAuthPopUp(true);
      signInBtnPressed.current = false;
      isShownSignInBtn.current = false;
    } else {
      dispatch(playerAction('close'));
      isRecommendedPlaybackAvailable
        ? openRecommendedPlayback()
        : navigateBack(history, clearOTTEpisodeData);
    }
    // eslint-disable-next-line
  }, [
    accessDisabled, clearOTTEpisodeData, features.cleeng, features.mvpd, history,
    isRecommendedPlaybackAvailable, openRecommendedPlayback, setShowAuthPopUp, signInBtnPressed,
  ]);

  const onSubtitleChange = useCallback((duration, text) => {
    console.log('onsubtitlechange', text);
  }, []);

  const isLoadedAtTizen = currentPlatform.appPlatform === 'tizen' && !currentPlatform.isLoadedInBrowser;

  const deinitilizeBixby = () => {
    if (isLoadedAtTizen) {
      try {
        webapis?.bixby?.deinitialize();
      } catch (e) {
        console.log('Error with bixby deinitialize', e);
      }
    }
  };

  const cleanUpPlayer = useCallback(() => {
    clearTimeout(overlayTimerRef.current);
    clearTimeout(seekingInterval.current);
    clearTimeout(refreshTimer.current);
    clearInterval(seekingInterval.current);
    clearInterval(ttsFastFwdRwdSeekingInterval.current);
    clearTimeout(speakTTSTimerRef.current);
    stopTTS();
    destroyPlayer();
    isLoadedAtTizen && setMute(false);
    deinitilizeBixby();
    // eslint-disable-next-line
  }, [destroyPlayer]);

  const onSignInBtnPress = (e) => {
    if (getKeyInfo(e.keyCode) === 'Enter' && videoLaunched) {
      cleanUpPlayer();
      setShowAuthPopUp(true);
      signInBtnPressed.current = true;
      isShownSignInBtn.current = false;

      skipVideoGA(logInRequestor, mediaContent['media:title']['content']);
    }
  };

  const cleanUpCb = useCallback((currentTimeFromPlayer, timeDurationFromPlayer) => {
    shouldSendAnalyticsOnEnd.current = calculatePercentage(currentTimeFromPlayer, timeDurationFromPlayer);
  }, [shouldSendAnalyticsOnEnd]);

  const onAdCompleted = () => {
    adsAmount.current = 0;
    currentAdPosition.current = 0;
  };

  const onAdStarted = () => {
    setIsBuffering(false);
    !timerIntervalForLinearTelemetry.current && launchLinearTelemetry();
  };

  const onAdBuffering = (e) => {
    const adData = e.getAd();

    setIsBuffering(true);
    adLongBufferingTimeout.current = setTimeout(() => {
      const adErrorDetails = generateAnalyticsErrorDetails(vodPlayer, adData, true);

      setDimensions({
        errorDetail: adErrorDetails,
        adType: analyticsTypes.CLIENT_STITCHED,
        errorMessage: `${analyticsTypes.AD} ${analyticsTypes.LONG_BUFFERING}`,
      });
      GAEvent(analyticsTypes.ERROR_PLAYER, analyticsTypes.AD, configData['guid']['content'], { nonInteraction: true });
      clearHitDimensions(['errorDetail', 'errorMessage', 'adType']);
    }, EXTREMELY_LONG_BUFFERING_TIMEOUT);
  };

  const onAdProgress = (adProgressEvent, currentAd) => {
    const { currentTime, duration, adPosition, totalAds } = adProgressEvent.getAdData();
    const nowDateForThrottleTimer = Date.now();

    if (nowDateForThrottleTimer - throttleCheckAdPositionTimer.current > 5000) {
      if (adPreviousTime.current === currentTime) {
        const adErrorDetails = generateAnalyticsErrorDetails(
          vodPlayer,
          { item: currentAd },
          true
        );

        setDimensions({
          errorDetail: adErrorDetails,
          errorMessage: `${analyticsTypes.AD} ${analyticsTypes.NO_POSITION_CHANGE}`,
          adType: analyticsTypes.CLIENT_STITCHED,
        });
        GAEvent(
          analyticsTypes.ERROR_PLAYER,
          analyticsTypes.AD,
          configData['guid']['content'],
          { nonInteraction: true },
        );
        clearHitDimensions(['errorDetail', 'errorMessage', 'adType']);
      }

      throttleCheckAdPositionTimer.current = nowDateForThrottleTimer;
      adPreviousTime.current = currentTime;
    }

    if (!adsAmount.current && !currentAdPosition.current) {
      const addDurationForTTS = convertIntToTimeStringForTTS((duration - currentTime) * 1000);

      adsAmount.current = totalAds;
      currentAdPosition.current = adPosition;
      speakTTS(`Playing ad ${currentAdPosition.current} of ${adsAmount.current} ${addDurationForTTS} remaining`);
    }

    setAdCurrentTime(prevAdCurrentTime => {
      if (prevAdCurrentTime !== currentTime) {
        setIsBuffering(false);
        clearTimeout(adLongBufferingTimeout.current);
      }

      return currentTime;
    });
    setAdDuration(duration);
  };

  const onAdLoaded = (adEvent) => {
    const ad = adEvent.getAd();

    if (!ad.isLinear()) {
      adsVideoRef.current.play();
    }
  };

  const playingVideoListener = () => {
    setIsBuffering(false);
  };

  const waitingVideoListener = () => {
    setIsBuffering(true);
  };

  const onPausedBeforeAd = useCallback((currentTimeFromPlayer) => {
    adSubStr.current = currentTimeFromPlayer > 3000 ? 'Mid' : 'Pre';
  }, []);

  const onContentPauseRequested = useCallback(() => {
    dispatch(playerAction('pauseBeforeAd'));
    adsLoadingRef.current = true;
    setAdPlaying(true);
    callTitleLvlReportService('pause');
    setPaused(true);
    // eslint-disable-next-line
  }, [callTitleLvlReportService]);

  const onAdContentCompleted = () => {
    if (!videoOpenedAfterMidRoll.current) {
      videoOpenedAfterMidRoll.current = true;
      !timerIntervalForLinearTelemetry.current && launchLinearTelemetry();
    }

    initPlayer();
    setAdPlaying(false);
    setIsBuffering(true);
    setPaused(false);
    setAdCurrentTime(0);
    setAdDuration(0);

    if (adsLoadingRef.current) {
      setGADimensions();
      GAEvent(
        `${analyticsTypes.ON_DEMAND} ${adSubStr.current}-Roll`,
        analyticsTypes.FAILED,
        mediaContent['media:title']['content'],
        { nonInteraction: true },
      );
    }
  };

  const onPlayPause = (event: React.KeyboardEvent) => {
    if (getKeyInfo(event.keyCode) === 'Enter') {
      if (paused) {
        onButtonPressed(controlTypes.MediaPlay);
      } else {
        onButtonPressed(controlTypes.MediaPause);
      }
    }
  };

  const onSeek = (event: React.KeyboardEvent) => {
    if (getKeyInfo(event.keyCode) === 'Left') {
      setSeekSpeed(0);
      clearInterval(seekingInterval.current);
      setStartSeekingToLeft(true);
      onButtonPressed(controlTypes.JumpBackward);
    }
    if (getKeyInfo(event.keyCode) === 'Right') {
      setSeekSpeed(0);
      clearInterval(seekingInterval.current);
      setStartSeekingToRight(true);
      onButtonPressed(controlTypes.JumpForward);
    }
  };

  const onKeyUp = (event: React.KeyboardEvent) => {
    if (getKeyInfo(event.keyCode) === 'Right' || getKeyInfo(event.keyCode) === 'Left') {
      speakTTS(getTtsTextAfterSeeking());
      getKeyInfo(event.keyCode) === 'Right' ? setStartSeekingToRight(false) : setStartSeekingToLeft(false);
    }
  };

  const seekToSuccessCb = useCallback(() => setIsSeeking(false), []);

  const onButtonPressed = (type: string, seekInterval: number = SEEK_INTERVAL) => {
    switch (type) {
      case controlTypes.JumpBackward:
        if (!isBuffering) {
          dispatch(playerAction('pause'));
          callTitleLvlReportService('seek');
          timerForLinearTelemetry.current && timerForLinearTelemetry.current.pause();
          setPaused(true);
          setIsSeeking(true);
          setCurrentTime(currentTime => {
            if (currentTime > duration - BUFFER_TIME) {
              return currentTime - seekInterval - BUFFER_TIME;
            }
            if (currentTime > seekInterval) {
              return currentTime - seekInterval;
            }

            return 0;
          });
        }
        break;

      case controlTypes.MediaRewind:
        clearInterval(ttsFastFwdRwdSeekingInterval.current);
        clearInterval(seekingInterval.current);
        if (!isBuffering) {
          setOverlayInfoAnnounced(true);
          if (seekSpeed > 0) {
            const seekIntervalSpeed = Math.floor(seekSpeed / FAST_SEEK_MULTIPLIER);

            setSeekSpeed(seekIntervalSpeed);
            seekSpeedForTTS.current = seekIntervalSpeed;
            if (Math.abs(seekIntervalSpeed) === 0) {
              getTTSTextForSeekingAction();
              setIsSeeked(true);
            }
            if (!seekIntervalSpeed) {
              break;
            }
            seekingInterval.current = setInterval(() => {
              onButtonPressed(controlTypes.JumpForward, seekIntervalSpeed * SEEK_INTERVAL);
            }, FAST_SEEK_TIMER_INTERVAL);
          } else if (seekSpeed > -MAX_FAST_SEEK_INTERVAL_SPEED) {
            const seekIntervalSpeed = seekSpeed * FAST_SEEK_MULTIPLIER || -1;

            setSeekSpeed(seekIntervalSpeed);
            seekSpeedForTTS.current = seekIntervalSpeed;
            seekingInterval.current = setInterval(() => {
              onButtonPressed(controlTypes.JumpBackward, Math.abs(seekIntervalSpeed) * SEEK_INTERVAL);
            }, FAST_SEEK_TIMER_INTERVAL);
          } else {
            seekingInterval.current = setInterval(() => {
              onButtonPressed(controlTypes.JumpBackward, Math.abs(seekSpeed) * SEEK_INTERVAL);
            }, FAST_SEEK_TIMER_INTERVAL);
          }
          getTTSTextForSeekingAction();
          ttsFastFwdRwdSeekingInterval.current = setInterval(() => {
            setTTSCounterToAnnounceSeekingText(prev => prev + 1);
          }, 10000);
          setOverlayInfoAnnounced(false);
          setIsSeeked(true);
        }
        break;

      case controlTypes.MediaPlay:
        setOverlayInfoAnnounced(true);
        setSeekSpeed(0);
        clearInterval(ttsFastFwdRwdSeekingInterval.current);
        clearInterval(seekingInterval.current);
        if (isSeeking && !isBuffering) {
          dispatch(playerSeekTo(currentTime));
        }

        // eslint-disable-next-line no-case-declarations
        const timeoutId = setTimeout(() => {
          dispatch(playerAction('play'));
        }, 100);

        playerActionsTimeouts.current.push(timeoutId);

        setPaused(false);
        speakTTS(
          isTimeVisible
            ? `playing, ${getCurrentTimeString(currentTime)} ${getDurationString(duration)}`
            : 'Playing',
          () => setIsSeeked(false),
        );
        timerForLinearTelemetry.current && timerForLinearTelemetry.current.resume();
        callTitleLvlReportService('play');
        setGADimensions();
        GAEvent(analyticsTypes.ON_DEMAND, analyticsTypes.RESUME, mediaContent['media:title']['content']);
        break;

      case controlTypes.MediaPause:
        setOverlayInfoAnnounced(true);
        setSeekSpeed(0);
        clearInterval(ttsFastFwdRwdSeekingInterval.current);
        clearInterval(seekingInterval.current);
        speakTTS(
          seekSpeed === 0
            ? isTimeVisible
              ? `Paused, ${getCurrentTimeString(currentTime)} ${getDurationString(duration)}`
              : 'Paused'
            : getTtsTextAfterSeeking(),
          () => setIsSeeked(false),
        );
        dispatch(playerAction('pause'));
        callTitleLvlReportService('pause');
        timerForLinearTelemetry.current && timerForLinearTelemetry.current.pause();
        setPaused(true);
        setGADimensions();
        GAEvent(analyticsTypes.ON_DEMAND, analyticsTypes.PAUSE, mediaContent['media:title']['content']);
        break;

      case controlTypes.MediaStop:
        setTimeout(() => {
          speakTTS('Stopping the video');
        }, 0);
        break;

      case controlTypes.JumpForward:
        if (!isBuffering) {
          dispatch(playerAction('pause'));
          callTitleLvlReportService('seek');
          timerForLinearTelemetry.current && timerForLinearTelemetry.current.pause();
          setPaused(true);
          setIsSeeking(true);
          setCurrentTime(currentTime => {
            if (duration - currentTime > seekInterval + BUFFER_TIME) {
              return currentTime + seekInterval;
            }

            return duration - BUFFER_TIME * 1.2;
          });
        }
        break;

      case controlTypes.MediaFastForward:
        clearInterval(ttsFastFwdRwdSeekingInterval.current);
        clearInterval(seekingInterval.current);
        if (!isBuffering) {
          setOverlayInfoAnnounced(true);
          if (seekSpeed < 0) {
            const seekIntervalSpeed = Math.round(seekSpeed / FAST_SEEK_MULTIPLIER);

            setSeekSpeed(seekIntervalSpeed);
            seekSpeedForTTS.current = seekIntervalSpeed;
            if (Math.abs(seekIntervalSpeed) === 0) {
              getTTSTextForSeekingAction();
              setIsSeeked(true);
            }
            if (!seekIntervalSpeed) {
              break;
            }
            seekingInterval.current = setInterval(() => {
              onButtonPressed(controlTypes.JumpBackward, Math.abs(seekIntervalSpeed) * SEEK_INTERVAL);
            }, FAST_SEEK_TIMER_INTERVAL);
          } else if (seekSpeed < MAX_FAST_SEEK_INTERVAL_SPEED) {
            const seekIntervalSpeed = seekSpeed * FAST_SEEK_MULTIPLIER || 1;

            setSeekSpeed(seekIntervalSpeed);
            seekSpeedForTTS.current = seekIntervalSpeed;
            seekingInterval.current = setInterval(() => {
              onButtonPressed(controlTypes.JumpForward, seekIntervalSpeed * SEEK_INTERVAL);
            }, FAST_SEEK_TIMER_INTERVAL);
          } else {
            seekingInterval.current = setInterval(() => {
              onButtonPressed(controlTypes.JumpForward, seekSpeed * SEEK_INTERVAL);
            }, FAST_SEEK_TIMER_INTERVAL);
          }
          getTTSTextForSeekingAction();
          ttsFastFwdRwdSeekingInterval.current = setInterval(() => {
            setTTSCounterToAnnounceSeekingText(prev => prev + 1);
          }, 10000);
          setOverlayInfoAnnounced(false);
          setIsSeeked(true);
        }
        break;

      default:
        break;
    }
  };

  const checkPlayStatePosition = (position) => {
    if (position === currentPlayPosition.current) {
      const errorDetails = generateAnalyticsErrorDetails(
        vodPlayer,
        {
          item: configData,
          message: `${analyticsTypes.VIDEO} ${analyticsTypes.NO_POSITION_CHANGE}`,
        },
        false,
      );

      setDimensions({
        errorDetail: errorDetails,
      });
      GAEvent(
        analyticsTypes.ERROR_PLAYER,
        analyticsTypes.VIDEO,
        configData['guid']['content'],
        { nonInteraction: true },
      );
      clearHitDimensions(['errorDetail']);
    }
    currentPlayPosition.current = position;
  };

  const shouldDisplaySignInBtn = () => {
    return (
      (features.cleeng || features.mvpd) && isShownSignInBtn.current && accessDisabled &&
      !showAuthPopUp && !isBuffering
    );
  };

  const handleBack = () => {
    navigateBack(history, clearOTTEpisodeData);
  };

  const handleStop = () => {
    navigateBack(history, clearOTTEpisodeData);
  };

  useEffect(() => {
    return () => {
      const currentSecond = Math.floor(moment.duration(currentTime4GA.current, 'milliseconds').asSeconds());

      setDimensions({
        vodTimeWatched: `${currentSecond}`,
        vodPctComplete: parseFloat(`${(currentSecond * 100) / vodLength.current}`).toFixed(2),
      });
    };
  }, []);

  useAnnouncePageLoading(isBuffering);

  useEffect(() => {
    shouldDisplaySignInBtn() && focusService.setFocus(document.querySelector('.btn-acc-0'));

    clearTimeout(contentLoadingTimer.current);

    if (isBuffering) {
      contentLoadingTimer.current = setTimeout(() => {
        const errorDetails = generateAnalyticsErrorDetails(
          vodPlayer,
          { item: configData },
          false);

        setDimensions({
          errorDetail: errorDetails,
          errorMessage: `${analyticsTypes.VIDEO} ${analyticsTypes.LONG_BUFFERING}`,
          adType: '',
        });
        GAEvent(
          analyticsTypes.ERROR_PLAYER,
          analyticsTypes.VIDEO,
          configData['guid']['content'],
          { nonInteraction: true },
        );
        clearHitDimensions(['errorDetail', 'errorMessage', 'adType']);
      }, EXTREMELY_LONG_BUFFERING_TIMEOUT);
    }
  }, [isBuffering]);

  useEffect(() => {
    if (isConnectedToNetwork && !refreshDRM) {
      if (includeAds) {
        adsService.initAds(
          adsContainerRef.current,
          adsVideoRef.current,
          adUrl,
          playingVideoListener,
          waitingVideoListener,
          onAdLoaded,
          onContentPauseRequested,
          onAdContentCompleted,
          onAdProgress,
          onAdCompleted,
          onAdStarted,
          loadAds,
          onAdBuffering,
          { playbackItemIdentifier: configData['guid']['content'], playerName: vodPlayer },
        );
      } else {
        initPlayer();
      }

      if (isLoadedAtTizen) {
        try {
          webapis?.bixby?.initialize();
        } catch (e) {
          console.log('Error with bixby initialize', e);
        }
      }
    } else if (!isConnectedToNetwork && isDrm) {
      setRefreshDRM(true);
    }

    return function cleanup() {
      clearTimeout(overlayTimerRef.current);
      clearTimeout(refreshTimer.current);
      clearTimeout(speakTTSTimerRef.current);
      clearTimeout(adLongBufferingTimeout.current);
      clearTimeout(contentLoadingTimer.current);
      clearInterval(seekingInterval.current);
      clearInterval(ttsFastFwdRwdSeekingInterval.current);
      stopTTS();
      setAdsLoaded(false);
      setAdCurrentTime(0);
      setAdDuration(0);
      destroyPlayer();
      setMute(false);
      deinitilizeBixby();
      playerActionsTimeouts.current.forEach(timeout => clearTimeout(timeout));
    };
    // eslint-disable-next-line
  }, [isConnectedToNetwork, refreshDRM]);

  useEffect(() => {
    if (isConnectedToNetwork && refreshDRM) {
      if (previousDRMToken === drm.AuthXML) {
        if (configData.path.indexOf('.m3u8') === -1) {
          dispatch(setComponentOTTEpisodeData(configData.path));
        } else {
          const content = { ...configData };

          dispatch(setComponentOTTEpisodeData('', content));
        }
      } else {
        setPreviousDRMToken(drm.AuthXML);
        setRefreshDRM(false);
      }
    }
    // eslint-disable-next-line
  }, [isConnectedToNetwork, refreshDRM, previousDRMToken, drm.AuthXML]);

  useEffect(() => {
    if (startSeekingToRight || startSeekingToLeft) {
      const ttsText = startSeekingToRight ? 'fast forwarding' : 'rewinding';

      ttsSeekingTimeOut.current = setTimeout(() => {
        speakTTS(ttsText);
      }, 400);
      ttsSeekingInterval.current = setInterval(() => {
        speakTTS(ttsText);
      }, 5000);
    }

    return () => {
      ttsSeekingInterval.current && clearInterval(ttsSeekingInterval.current);
      ttsSeekingTimeOut.current && clearTimeout(ttsSeekingTimeOut.current);
    };
  }, [startSeekingToLeft, startSeekingToRight]);

  useEffect(() => {
    if (!(Math.abs(seekSpeedForTTS.current) === 0)) {
      speakTTS(
        `${getSeekingAction()}, speed x${Math.abs(seekSpeedForTTS.current)}
        ${isTimeVisible ? getCurrentTimeString(currentTime) : ''}
        ${isTimeVisible ? getDurationString(duration) : ''}`
      );
    }
  }, [ttsCounterToAnnounceSeekingText]);

  useEffect(() => {
    if (timeLeft <= 10 && isSeeking) {
      speakTTS('Seeked to the end of the video.', () => onButtonPressed(controlTypes.MediaPlay));
      setIsStartOrEndposition(true);
    }
    // eslint-disable-next-line
  }, [timeLeft]);

  useEffect(() => {
    if (isSeeking && currentTime === 0) {
      onButtonPressed(controlTypes.MediaPause);
      setIsStartOrEndposition(true);
    }
    // eslint-disable-next-line
  }, [currentTime]);

  useEffect(() => {
    if (isStartOrEndPosition) {
      clearInterval(ttsFastFwdRwdSeekingInterval.current);
      setIsStartOrEndposition(false);
      ttsFastFwdRwdSeekingInterval.current = null;
    }
    // eslint-disable-next-line
  }, [isStartOrEndPosition]);

  useEventListener('keydown', e => {
    e.preventDefault();

    const pressedKey = getKeyInfo(e.keyCode);

    if (!adPlaying && !showAuthPopUp) {

      if (isFireTVPlatform) {
        if (isCaptionsMenuVisible) {
          if (['Back', 'Menu'].includes(pressedKey)) {
            setIsCaptionsMenuVisible(false);

            return;
          }
        } else {
          if (pressedKey === 'Menu') {
            focusedElBeforeCCMenuAppear.current = document.activeElement;
            clearTimeout(overlayTimerRef.current);
            setActiveOverlay(false);
            setOverlayInfoAnnounced(false);
            setIsCaptionsMenuVisible(true);

            return;
          }
        }
      }

      videoLaunched && displayOverlayWithTimer();

      const playerButtonEvent = controlTypes[pressedKey];

      playerButtonEvent && onButtonPressed(playerButtonEvent);

      if (pressedKey === 'MediaPlayPause') {
        if (paused) {
          onButtonPressed(controlTypes['MediaPlay']);
        } else {
          onButtonPressed(controlTypes['MediaPause']);
        }
      }

    } else {
      if (adPlaying && !showAuthPopUp && pressedKey === 'Enter') {
        return;
      }

      isPlayerAlive.current && callTitleLvlReportService('back');

      setGADimensions();
      GAEvent(
        `${analyticsTypes.ON_DEMAND} ${adSubStr.current}-Roll`,
        analyticsTypes.ABANDON,
        mediaContent['media:title']['content']);
    }

    // handle 'Back'
    if (activeOverlay) {
      onBackHandler(e, setActiveOverlay, false); // close overlay;
    } else {
      onBackHandler(e, handleBack, history); // go back;
    }

    onStopHandler(e, handleStop, history);
  });

  useEventListener(currentPlatform.multiTaskingEvent, () => {
    if (document['hidden'] || document['webkitHidden']) {
      dispatch(playerAction('suspend'));
    } else {
      isConnectedToNetwork && dispatch(playerAction('restore'));
    }
  });

  return (
    <div className='vod'>
      {isBuffering ? <Spinner /> : null}
      <div className='player'>
        <PlayerWrapper
          ref={videoRef}
          playerName={vodPlayer}
          onBufferingComplete={onBufferingComplete}
          onCurrentPlaytime={onCurrentPlaytime}
          onDrmEvent={onDrmEvent}
          onError={onError}
          onStreamCompleted={onStreamCompleted}
          onSubtitleChange={onSubtitleChange}
          onSuccessLoad={onSuccessLoad}
          onFailedLoad={onFailedLoad}
          seekToSuccessCb={seekToSuccessCb}
          checkPlayStatePosition={checkPlayStatePosition}
          cleanUpCb={cleanUpCb}
          onPausedBeforeAd={onPausedBeforeAd}
          onDurationChange={onDurationChange}
          typeContent={'vod'}
          drm={drm}
          onCanPlay={onCanPlay}
        />
        <DetailsOverlay
          metadataForOverlay={metadataForOverlay}
          visible={visibleOverlay}
          isConnectedToNetwork={isConnectedToNetwork}
        />
        {isCaptionsMenuVisible && isFireTVPlatform && (
          <CaptionsMenu
            ref={focusedElBeforeCCMenuAppear}
            visible={isCaptionsMenuVisible}
            setIsCaptionsMenuVisible={setIsCaptionsMenuVisible}
          />
        )}
        {visibleOverlay && <Controls
          paused={paused}
          currentTime={currentTime}
          duration={duration}
          isTimeVisible={isTimeVisible}
          isRecommendedPlaybackAvailable={!!isRecommendedPlaybackAvailable}
          midrollBreaks={midrollBreaks}
          onPlayPause={onPlayPause}
          onSeek={onSeek}
          onKeyUp={onKeyUp}
          visible={visibleOverlay}
          seekSpeed={seekSpeed}
          isConnectedToNetwork={isConnectedToNetwork}
          isOverlayInfoAnnounced={isOverlayInfoAnnounced}
          isRecommendedPlaybackVisible={isRecommendedPlaybackVisible}
          isSeeked={isSeeked}
        />}
        {(!!isRecommendedPlaybackAvailable && !adPlaying && timeLeft <= 10) && (
          <RecommendedPlayback
            onPlaybackFinished={openRecommendedPlayback}
            nextPlaybackItem={recommendedPlaylist[currentPlaylistItemIndex + 1]}
            isSeekingToRight={setStartSeekingToRight}
            hasToBeShown={isRecommendedPlaybackVisible && isConnectedToNetwork}
            timeLeft={timeLeft}
          />
        )}
        <Advertisement
          adsContainerRef={adsContainerRef}
          adsVideoRef={adsVideoRef}
          adPlaying={adPlaying}
          adCurrentTime={adCurrentTime}
          adDuration={adDuration}
          adBuffering={isBuffering}
          adsLoading={adsLoadingRef}
          currentAdPosition={currentAdPosition.current}
          adsAmount={adsAmount.current}
          isIMAAdPlaying={true}
          GA_media={metadataForOverlay.title}
          GA_category={`${analyticsTypes.ON_DEMAND} ${adSubStr.current}-Roll`}
        />
        {shouldDisplaySignInBtn() && (
          <>
            {!videoLaunched && <div className='sign-in-btn-overlay' />}
            <div className='sign-in-button-wrapper'>
              <Button
                index={0}
                focusDown='.btn-acc-0'
                focusUp='.btn-acc-0'
                focusLeft='.btn-acc-0'
                focusRight='.btn-acc-0'
                title='SIGN IN'
                onKeyDown={onSignInBtnPress}
              />
            </div>
          </>
        )}
      </div>
      {isShownErrorMessage ? (
        <ErrorModal
          error={{errorCode: VIDEO_ERROR}}
          onBackHandlerCb={handleBack}
        />
      ) : null}
    </div>
  );
};

export default VOD;
