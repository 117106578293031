import axiosInstance from './axiosInstance';

export async function getSettingsContent(url: string): Promise<any> {
  try {
    const { data } = await axiosInstance.get(url);

    return data;
  } catch (error) {
    console.log('Error in getting OTTSettings content');

    return '';
  }
}

export async function getSettingsDataContent(url: string): Promise<any> {
  try {
    const {
      data: {
        page,
      },
    } = await axiosInstance.get(url);

    return page;
  } catch (error: any) {
    console.log('Error in getting OTTSettings content data');

    throw new Error(error?.response?.data?.message);
  }
}
