import { useRef, useEffect } from 'react';

const useComponentMounted = () => {
  const isMounted = useRef<boolean>(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  });

  return isMounted.current;

};

export default useComponentMounted;
