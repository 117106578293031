import { CLEAR_PURCHASE_DATA } from '../actions/types/cleengService';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import * as actionTypes from '../actions/types/Login';
import { REFRESH_DEVICE_ID } from '../actions/types/config';
import {
  emailAuthService,
  getRegCode,
  mvpdAuth,
  createUserService,
  resetPasswordService,
  getProvidersLogos,
  refreshUserToken,
  getAgreementText,
  activateNewsletter,
} from '../../services/Login';
import { registerCleengCustomer, generateCustomerToken } from '../../services/cleengService';
import {
  TENNIS_CHANNEL,
  MARQUEE_SPORTS,
  isTcBrand,
} from '../../constants/structureTypes';
import { DEFAULT_SUBSCRIPTION_ERROR_MESSAGE } from '../../constants/text';
import { getParsedDataFromLocalStorage, getRandomNumber } from '../../utils/commonFunc';
import { ILoginUserResponse, IRefreshPlusTokenPayload, logoutMVPD as logoutMVPDAction } from '../actions/Login';
import { CLEAR_ERROR, CLEAR_USER_DATA } from '../actions/types/Login';
import { setDimensions } from '../../services/analytics';
import { RootState } from '../reducers';
import { FREE, PLUS, NOT_MVPD } from '../../constants/analyticsTypes';
import { errorDetails } from '../../constants/errorDetails';
import {
  CODE_GENERATION_MVPD_ERROR,
  GENERATE_CUSTOMER_TOKEN_ERROR,
  LOGIN_MVPD_ERROR,
  REGISTER_CLEENG_CUSTOMER_ERROR,
  RESET_PLUS_PASSWORD_ERROR,
} from '../../constants/errorCodes';

function* getRegistrationCode() {
  const brand = isTcBrand ? TENNIS_CHANNEL : MARQUEE_SPORTS;

  try {
    const store: RootState = yield select();
    const {
      config: {
        deviceId,
        mvpd: {
          mvpd_regcode,
        },
      },
    } = store;
    const ID = deviceId + getRandomNumber();
    const registrationData: object = yield call(getRegCode, mvpd_regcode, ID, brand);

    yield put({
      type: REFRESH_DEVICE_ID,
      payload: ID,
    });

    yield put({
      type: actionTypes.MVPD_REGCODE_REFRESH_SUCCEEDED,
      payload: registrationData,
    });
  } catch (e: any) {
    yield put({
      type: actionTypes.MVPD_REGCODE_REFRESH_FAILED,
      error: { errorCode: CODE_GENERATION_MVPD_ERROR },
    });
  }
}

export function* checkUserAuth(data) {
  const brand = isTcBrand ? TENNIS_CHANNEL : MARQUEE_SPORTS;
  const { refresh } = data;

  try {
    const store: RootState = yield select();
    const {
      config: {
        deviceId,
        mvpd: {
          mvpd_auth,
          mvpdProvidersLogos,
        },
      },
    } = store;
    const ID = (refresh && localStorage.getItem('AuthMVPD')) ?
      getParsedDataFromLocalStorage('AuthMVPD')?.deviceId : deviceId;
    let authData: any;

    try {
      authData = yield call(mvpdAuth, mvpd_auth, ID, brand);
    } catch (e: any) {
      const errData = JSON.parse(e.message);

      if (errData.status === 403 || errData.message === 'noAuthz') {
        yield put({
          type: actionTypes.MVPD_AUTHZ_ERROR,
          error: { errorCode: LOGIN_MVPD_ERROR, details: errData.details },
        });
      } else {
        throw new Error(e);
      }

      return;
    }

    yield put({
      type: refresh ? actionTypes.MVPD_TOKEN_REFRESH_SUCCEEDED : actionTypes.MVPD_AUTH_SUCCEEDED,
      payload: authData,
    });

    if (authData) {
      setDimensions({ mvpd: authData.mvpdId, mvpdMediaToken: authData.serializedToken });
    }

    if (refresh) {
      const refreshedToken = (authData as any)?.serializedToken;
      const oldData = getParsedDataFromLocalStorage('AuthMVPD');
      const refreshedData = JSON.stringify({ ...oldData, token: refreshedToken });

      localStorage.setItem('AuthMVPD', refreshedData);

      return refreshedToken;
    } else {
      try {
        const providersLogos = yield call(getProvidersLogos, mvpdProvidersLogos);
        const provider = providersLogos['requestor']['mvpds']['mvpd']
          .find(provider => provider.id.value === authData.mvpdId);

        if (provider['logoUrl']['value']) {
          yield put({
            type: actionTypes.MVPD_SET_PROVIDER_LOGO,
            logoUrl: provider['logoUrl']['value'],
          });
          localStorage.setItem('mvpdProviderLogo', provider['logoUrl']['value']);
        }
      } catch (e) {
        console.log('Error in fetching logo for TV Provider');
      }
    }
  } catch (e: any) {

    if (refresh) {
      yield put(logoutMVPDAction());
    }

    yield put({
      type: refresh ? actionTypes.MVPD_TOKEN_REFRESH_FAILED : actionTypes.MVPD_AUTH_FAILED,
      error: { errorCode: LOGIN_MVPD_ERROR },
    });
  }
}

export function* createUser(actionData) {
  const store: RootState = yield select();
  const {
    config: {
      subscription: {
        firebase_createUser,
        cleeng_registerCustomer,
        newsletter_url,
      },
    },
  } = store;

  try {
    const content: ILoginUserResponse = yield call(createUserService, firebase_createUser, actionData.data);
    const cleengRegistration = yield call(registerCleengCustomer, cleeng_registerCustomer, content.accessToken);

    if (actionData?.data?.newsletter) {
      yield call(activateNewsletter, newsletter_url, actionData.data);
    }

    const authData = JSON.stringify({
      email: content.email,
      token: content.accessToken,
      isAuth: true,
      cleengToken: cleengRegistration.result.token,
      refreshToken: content.refreshToken,
      expiresTokenPlus: content.expirationTime,
    });

    localStorage.setItem('AuthPlus', authData);

    setDimensions({
      cleengCustomerToken: cleengRegistration.result.token,
      userAuthToken: content.accessToken,
      memberType: PLUS,
    });

    yield put({
      type: CLEAR_PURCHASE_DATA,
    });

    yield put({
      type: actionTypes.CREATE_USER_SUCCEEDED,
      payload: {
        ...content,
        cleengToken: cleengRegistration.result.token,
      },
    });
  } catch (e: any) {
    yield put({
      type: CLEAR_ERROR,
    });

    yield put({
      type: actionTypes.CREATE_USER_FAILED,
      signInOrUpError: e?.message || errorDetails[REGISTER_CLEENG_CUSTOMER_ERROR].title,
      error: { errorCode: REGISTER_CLEENG_CUSTOMER_ERROR },
    });
  }
}

export function* emailAuth(actionData) {
  const store: RootState = yield select();
  const {
    config: {
      subscription: {
        firebase_authUser,
        cleeng_generateCustomerToken,
      },
    },
  } = store;

  try {
    const content: ILoginUserResponse = yield call(emailAuthService, firebase_authUser, actionData.data);
    const cleengGetTokenResponse = yield call(generateCustomerToken, cleeng_generateCustomerToken, content.accessToken);

    const authData = JSON.stringify({
      email: content.email,
      token: content.accessToken,
      isAuth: true,
      cleengToken: cleengGetTokenResponse.token,
      refreshToken: content.refreshToken,
      expiresTokenPlus: content.expirationTime,
    });

    setDimensions({
      cleengCustomerToken: cleengGetTokenResponse.token,
      userAuthToken: content.accessToken,
      memberType: PLUS,
    });

    localStorage.setItem('AuthPlus', authData);

    yield put({
      type: CLEAR_PURCHASE_DATA,
    });

    yield put({
      type: actionTypes.EMAIL_AUTH_SUCCEEDED,
      payload: {
        ...content,
        cleengToken: cleengGetTokenResponse.token,
      },
    });
  } catch (e: any) {
    yield put({
      type: CLEAR_ERROR,
    });

    yield put({
      type: actionTypes.EMAIL_AUTH_FAILED,
      signInOrUpError: e?.message || errorDetails[GENERATE_CUSTOMER_TOKEN_ERROR].title,
      error: { errorCode: GENERATE_CUSTOMER_TOKEN_ERROR },
    });
  }
}

export function* refreshTokenPlus() {
  const store: RootState = yield select();
  const {
    config: {
      subscription: {
        firebase_refreshToken,
        cleeng_generateCustomerToken,
      },
    },
    Login: {
      subscription: {
        refreshTokenPlus,
      },
    },
  } = store;

  const requestBody = {
    refreshToken: refreshTokenPlus,
  };

  try {
    const content: IRefreshPlusTokenPayload = yield call(() => refreshUserToken(firebase_refreshToken, requestBody));
    const cleengGetTokenResponse = yield call(generateCustomerToken, cleeng_generateCustomerToken, content['access_token']);
    const expirationTime = Date.now() + parseInt(content['expires_in'], 10) * 1000;
    const oldAuthPlusData = getParsedDataFromLocalStorage('AuthPlus');
    const refreshedAuthPlusData = JSON.stringify({
      ...oldAuthPlusData,
      cleengToken: cleengGetTokenResponse.token,
      token: content['access_token'],
      refreshToken: content['refresh_token'],
      expiresTokenPlus: expirationTime,
    });

    localStorage.setItem('AuthPlus', refreshedAuthPlusData);

    yield put({
      type: actionTypes.PLUS_TOKEN_REFRESH_SUCCEEDED,
      payload: {
        ...content,
        expiresTokenPlus: expirationTime,
        cleengToken: cleengGetTokenResponse.token,
      },
    });

    return content['access_token'];
  } catch (e: any) {
    localStorage.removeItem('AuthPlus');

    yield put({
      type: actionTypes.PLUS_TOKEN_REFRESH_FAILED,
      error: { errorCode: '', details: e.message || e },
    });

    return '';
  }
}

function* logoutMVPD() {
  try {
    const store: RootState = yield select();
    const {
      config: {
        deviceId,
      },
    } = store;

    localStorage.removeItem('AuthMVPD');
    localStorage.removeItem('mvpdProviderLogo');

    setDimensions({ mvpd: NOT_MVPD, mvpdMediaToken: '' });

    const ID = deviceId + getRandomNumber();

    yield put({
      type: actionTypes.MVPD_LOGOUT_SUCCEEDED,
    });
    yield put({
      type: REFRESH_DEVICE_ID,
      payload: ID,
    });
  } catch (e: any) {
    yield put({
      type: actionTypes.MVPD_LOGOUT_FAILED,
      error: { errorCode: '', details: e.message || e },
    });
  }
}

function* emailSignOut() {
  try {
    localStorage.removeItem('AuthPlus');

    setDimensions({
      userAuthToken: '',
      cleengCustomerToken: '',
      memberType: FREE,
    });

    yield put({
      type: CLEAR_PURCHASE_DATA,
    });

    yield put({
      type: CLEAR_USER_DATA,
    });

    yield put({
      type: actionTypes.EMAIL_SIGN_OUT_SUCCEEDED,
    });
  } catch (e: any) {
    yield put({
      type: actionTypes.EMAIL_SIGN_OUT_FAILED,
      error: { errorCode: '', details: e.message || e },
    });
  }
}

function* resetPassword(actionData) {
  const store: RootState = yield select();
  const {
    config: {
      subscription: {
        firebase_resetPassword,
      },
    },
  } = store;

  try {
    const postData = { email: actionData.email };
    const content: object = yield call(() => resetPasswordService(firebase_resetPassword, postData));

    yield put({
      type: actionTypes.RESET_PASSWORD_SUCCEEDED,
      payload: content,
    });
  } catch (e: any) {
    let errorMessage = '';

    if (e?.message && typeof e?.message === 'string' && e?.message !== '{}') {
      errorMessage = e.message;
    } else if (typeof e === 'string' && e !== '{}' && e) {
      errorMessage = e;
    }

    yield put({
      type: actionTypes.RESET_PASSWORD_FAILED,
      error: { errorCode: RESET_PLUS_PASSWORD_ERROR, details: errorMessage || DEFAULT_SUBSCRIPTION_ERROR_MESSAGE },
    });
  }
}

function* loadAgreementText() {
  const store: RootState = yield select();
  const {
    config: {
      subscription: {
        terms_of_use_text,
        privacy_policy_text,
      },
    },
  } = store;

  try {
    const termsOfUse: string = yield call(getAgreementText, terms_of_use_text);
    const privacyPolicy: string = yield call(getAgreementText, privacy_policy_text);

    yield put({
      type: actionTypes.AGREEMENT_TEXT_LOAD_SUCCEEDED,
      payload: {
        termsOfUse,
        privacyPolicy,
      },
    });
  } catch (e: any) {
    yield put({
      type: actionTypes.AGREEMENT_TEXT_LOAD_FAILED,
      error: { errorCode: '', details: e.message || e },
    });
  }
}

function* loginWatcher() {
  yield takeLatest([actionTypes.MVPD_REGCODE_REFRESH], getRegistrationCode);
  yield takeLatest([actionTypes.MVPD_AUTH, actionTypes.MVPD_TOKEN_REFRESH], checkUserAuth);
  yield takeLatest(actionTypes.MVPD_LOGOUT, logoutMVPD);
  yield takeLatest(actionTypes.CREATE_USER, createUser);
  yield takeLatest(actionTypes.EMAIL_AUTH, emailAuth);
  yield takeLatest(actionTypes.EMAIL_SIGN_OUT, emailSignOut);
  yield takeLatest(actionTypes.RESET_PASSWORD, resetPassword);
  yield takeLatest(actionTypes.PLUS_TOKEN_REFRESH, refreshTokenPlus);
  yield takeLatest(actionTypes.AGREEMENT_TEXT_LOAD, loadAgreementText);
}

export default loginWatcher;
