import { useRef } from 'react';

const useLatest = (value) => {
  const ref = useRef();

  ref.current = value;

  return ref;
};

export default useLatest;
