export const ON_DEMAND = 'On Demand';
export const ABANDON = 'Abandon';
export const COMPLETE = 'Complete';
export const FAILED = 'Failed';
export const RESUME = 'Resume';
export const PAUSE = 'Pause';
export const CC = 'CC';
export const OFF = 'off';
export const ON = 'on';
export const PLAY = 'Play';
export const NAVIGATE = 'Navigate';
export const SELECT = 'Select';
export const OPEN = 'Open';
export const CHANGE_SEASON = 'Change season';
export const AUTO_TEASER = 'Auto Teaser';
export const LIVESTREAM = 'Livestream';
export const VIEW = 'View';
export const SETTINGS = 'Settings';
export const SEARCH = 'Search';
export const RE_ENTER = 'Re-enter';
export const INITIATE = 'Initiate';
export const REFINES = 'Refines';
export const STATION_SELECTION = 'Station selection';
export const APP_OPEN = 'App Open';
export const SPLASH = 'Splash';
export const PRE_ROLL = 'Pre-Roll';
export const MID_ROLL = 'Mid-Roll';
export const LINEAR_PLAYER = 'LinearPlayer';
export const EXIT = 'Exit';
export const LIVE = 'Live';
export const EPG = 'EPG';
export const LINEAR_SELECT = 'Linear-Select';
export const VIEW_DESCRIPTION = 'View-Description';
export const HIDE_EPG = 'Hide-EPG';
export const CATEGORY_VIEW_FOCUSED = 'Category view focused';
export const CATEGORIES_ACCESSED = 'Categories_accessed';
export const CATEGORY_SELECTED_EVENT = 'Category selected';
export const CATEGORY_SELECTED_ACTION = 'Category_selected';
export const SCROLL_END = 'Scroll-End';
export const CHANGE_DAY = 'Change-Day';
export const HOME = 'Home';
export const LINEAR_HEARTBEAT = 'Linear Heartbeat';
export const IS_WATCHING = 'Is Watching';
export const APP_NAME = 'appName';
export const APP_VERSION = 'appVersion';
export const DATA_SOURCE = 'dataSource';
export const APP = 'app';
export const FREE = 'Free';
export const STATION_SELECT = 'Station Select';
export const SET_FAVORITE = 'Set Favorite';
export const PLUS = 'Plus';
export const AUTHENTICATES = 'Authenticates';
export const LOG_OUT = 'Log-out';
export const LOG_IN = 'Log-in';
export const SUBSCRIPTION = 'Subscription';
export const PW_RESET = 'PW-Reset';
export const PW_RESET_FAIL = 'PW-Reset-Fail';
export const TERMS_CONDITIONS = 'Terms & Conditions';
export const AGREE = 'Agree';
export const DECLINE = 'Decline';
export const CREATE_ACCOUNT = 'Create Account';
export const MY_ACCOUNT = 'My Account';
export const TV_PROVIDER = 'TV Provider';
export const SUBSCRIPTION_PREVIEW = 'Subscription Preview';
export const SUBSCRIBE_TO_PLUS = 'Subscribe to Plus';
export const SKIP = 'Skip';
export const AUTH_FAIL = 'Auth-Fail';
export const PURCHASE = 'Purchase';
export const SUCCESS = 'Success';
export const ABOUT = 'About';
export const NOT_MVPD = 'Not MVPD';
export const MVPD_PREVIEW = 'MVPD Preview';
export const NAV = 'Nav';
export const VIDEO = 'Video';
export const ERROR = 'Error';
export const API_ERROR = 'API Failure';
export const PLAYER_ERROR = 'Player Error';
export const ERROR_PLAYER = 'PlayerError';
export const AD = 'Ad';
export const API = 'API';
export const EMPTY_ADS = 'EmptyAds';
export const NO_POSITION_CHANGE = 'play state no position changing';
export const LONG_BUFFERING = 'extremely long buffering';
export const CLIENT_STITCHED = 'Client stitched';
export const SERVER_STITCHED = 'Server stitched';

export const dimensionsDictionary = {
  memberType: {id: 'dimension1', scope: 'user'},
  mvpd: {id: 'dimension2', scope: 'user'},
  apiVersion: {id: 'dimension3', scope: 'user'},
  brand: {id: 'dimension4', scope: 'user'},
  appPlatform: {id: 'dimension5', scope: 'user'},
  app_Version: {id: 'dimension6', scope: 'user'},
  appOsVersion: {id: 'dimension7', scope: 'user'},
  userAuthToken: {id: 'dimension8', scope: 'user'},
  deviceUuid: {id: 'dimension9', scope: 'user'},
  mvpdMediaToken: {id: 'dimension10', scope: 'user'},
  cleengCustomerToken: {id: 'dimension11', scope: 'user'},
  sourceScreen: {id: 'dimension12', scope: 'hit'},
  destinationScreen: {id: 'dimension13', scope: 'hit'},
  sourceTeaserListLabel: {id: 'dimension14', scope: 'hit'},
  liveTimeWatched: {id: 'dimension15', scope: 'hit'},
  vodTimeWatched: {id: 'dimension16', scope: 'hit'},
  vodPctComplete: {id: 'dimension17', scope: 'hit'},
  vodLength: {id: 'dimension18', scope: 'hit'},
  contentUuid: {id: 'dimension19', scope: 'hit'},
  itemTitle: {id: 'dimension20', scope: 'hit'},
  showTitle: {id: 'dimension21', scope: 'hit'},
  seasonTitle: {id: 'dimension22', scope: 'hit'},
  genre: {id: 'dimension23', scope: 'hit'},
  producerName: {id: 'dimension24', scope: 'hit'},
  entitlement: {id: 'dimension25', scope: 'hit'},
  deviceBrand: {id: 'dimension26', scope: 'user'},
  deviceModel: {id: 'dimension27', scope: 'user'},
  screenWidth: {id: 'dimension28', scope: 'user'},
  screenHeight: {id: 'dimension29', scope: 'user'},
  videoLoadTime: {id: 'dimension30', scope: 'hit'},
  videoBandwidth: {id: 'dimension31', scope: 'hit'},
  channelId: {id: 'dimension33', scope: 'hit'},
  channelUrl: {id: 'dimension34', scope: 'hit'},
  stationId: {id: 'dimension35', scope: 'user'},
  contentType: {id: 'dimension36', scope: 'hit'},
  errorDetail: {id: 'dimension37', scope: 'hit'},
  errorMessage: {id: 'dimension38', scope: 'hit'},
  adType: {id: 'dimension39', scope: 'hit'},
};
