import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { RootState } from '../../../stores/reducers/index';
import FocusService from '../../../services/focusService';
import { speakTTS, stopTTS } from '../../../services/TTSService';
import {
  LIVE,
  OVERLAY_PRE_PHRASE_COMET_CHARGE,
  OVERLAY_PRE_PHRASE_STIRR,
  OVERLAY_PRE_PHRASE_TC,
} from '../../../constants/text';
import {
  CHARGE_OTT_BRAND,
  COMET_OTT_BRAND,
  isStirrBrand,
  OTT_BRAND,
  TENNIS_CHANNEL_OTT_BRAND,
} from '../../../constants/structureTypes';
import { onEnterHandler } from '../../../utils';
import { calculateTime, getTextTimeLeft } from '../../../utils/time';

import Label from '../../Label/Label';

import './LiveOverlayWithControls.scss';

type LiveOverlayProps = {
  visible: boolean;
  channel: any;
  channelID: string;
  isConnectedToNetwork: boolean;
  isEpgOpen: boolean;
  accessDisabled: boolean;
  isShownSignInBtn: boolean;
}

const LiveOverlayWithControls = (props: LiveOverlayProps) => {
  const {
    visible,
    channelID,
    channel,
    isConnectedToNetwork,
    isEpgOpen,
    accessDisabled,
    isShownSignInBtn,
  } = props;

  const isProgramsLoading = useSelector((state: RootState) => state.OTTChannelGuide.isProgramsLoading);
  const playerStyles = useSelector((state: RootState) => state.config.styles['player']);
  const teaserStyles = useSelector((state: RootState) => state.config.styles['teaser']);
  const buttonStyles = useSelector((state: RootState) => state.config.styles['button']);

  const [channelLogoInternal, setChannelLogoInternal] = useState('');
  const initialState = {
    label: '',
    title: '',
    description: '',
    start: '',
    stop: '',
    isLiveProgram: false,
  };
  const [currentMetaInt, setCurrentMetaInt] = useState(initialState);

  const openScheduleRef = useRef(null);
  const refreshTimerRef = useRef<any>(null);
  const speakTTSTimerRef = useRef<any>(null);
  const focusTimerRef = useRef<any>(null);
  const delayTimerRef = useRef<any>(null);

  const INTERVAL_TIMEOUT: number = 30000;

  const start = moment(currentMetaInt.start);
  const end = moment(currentMetaInt.stop);

  const nameOfGuideBtn = isStirrBrand ? 'GUIDE' : 'OPEN GUIDE';

  const {
    percentage,
    minutes,
    hours,
  } = calculateTime(start, end);

  useEffect(() => {
    const setDataToOverlay = () => {
      if (!isProgramsLoading && channel.programs) {

        const filteredPrograms = channel.programs.filter(program => program.stopLong > moment().unix());
        const currentMetaInternal = filteredPrograms[0];

        setMetaOverlay(channel.icon, currentMetaInternal);
      }
    };

    setDataToOverlay();

    refreshTimerRef.current = setInterval(setDataToOverlay, INTERVAL_TIMEOUT);

    return () => {
      clearInterval(refreshTimerRef.current);
    };
    // eslint-disable-next-line
  }, [channelID, channel.programs.length, !isProgramsLoading, channel.icon]);

  const clearTimerFunc = (timerRef: any): void => {
    if (timerRef !== null) {
      clearTimeout(timerRef);
    }
  };

  useEffect(() => {
    const silentElem = document.querySelector('#appTitle');
    const signInBtn = document.querySelector('#sign-in-button');
    const getElement4FirstFocus = () => {
      if (accessDisabled && isShownSignInBtn) {
        return signInBtn;
      } else {
        return (visible) ? openScheduleRef.current : silentElem;
      }
    };

    if (!isEpgOpen) {
      clearTimeout(focusTimerRef.current);
      focusTimerRef.current = setTimeout(() => {
        FocusService.setFocus(getElement4FirstFocus());
      }, 0);
    }

    return () => {
      clearTimerFunc(speakTTSTimerRef.current);
      clearTimeout(focusTimerRef.current);
      clearTimeout(delayTimerRef.current);
    };
    // eslint-disable-next-line
  }, [visible, isEpgOpen, isShownSignInBtn]);

  const setMetaOverlay = (channelLogo, channelFirstProgram) => {
    setChannelLogoInternal(channelLogo);
    setCurrentMetaInt(prev => ({
      ...prev,
      label: 'NOW PLAYING',
      title: channelFirstProgram?.title,
      description: channelFirstProgram?.desc,
      start: channelFirstProgram?.start,
      stop: channelFirstProgram?.stop,
      isLiveProgram: channelFirstProgram?.isLiveProgram,
    }));
  };

  const speakTTSMetaData = (additionalTTStext: string = '') => {
    const getStringTTSPhrase = () => {
      const getBeginningPhrase = () => {
        switch (OTT_BRAND) {
          case COMET_OTT_BRAND:
          case CHARGE_OTT_BRAND:
            return OVERLAY_PRE_PHRASE_COMET_CHARGE;
          case TENNIS_CHANNEL_OTT_BRAND:
            return OVERLAY_PRE_PHRASE_TC;
          default:
            return OVERLAY_PRE_PHRASE_STIRR;
        }
      };

      return `${additionalTTStext} ${getBeginningPhrase()}. ${currentMetaInt.title} ` +
        `${getTextTimeLeft(hours, minutes)} ` +
        `${currentMetaInt.description ? currentMetaInt.description : ''} `;
    };

    clearTimerFunc(speakTTSTimerRef.current);
    stopTTS();
    speakTTSTimerRef.current = setTimeout(() => {
      speakTTS(getStringTTSPhrase());
    }, 2500);
  };

  const buttonGetFocused = (e) => {
    e.target.style.background = `${buttonStyles.colorOn}`;
    e.target.style.border = `${buttonStyles.borderSize}px solid ${buttonStyles.borderColorOn}`;
    e.target.style.color = `${buttonStyles.textFocusColor}`;
    speakTTSMetaData();
  };

  const buttonGetBlur = (e) => {
    e.target.style.background = `${buttonStyles.colorOff}`;
    e.target.style.border = `${buttonStyles.borderSize}px solid ${buttonStyles.borderColorOff}`;
    e.target.style.color = `${buttonStyles.textColor}`;
  };

  const keyDownFunction = (e) => {
    onEnterHandler(e, () => {
      clearTimerFunc(speakTTSTimerRef.current);
      stopTTS();
    });
  };

  const liveLabelStyles = {
    left: '4px',
    top: '10px',
    width: '55px',
    height: '26px',
    color: 'black',
  };

  return (
    <>
      {!channel?.programs
        ? null :
        (<div
          className='stirr-live-overlay'
          style={{
            opacity: visible && isConnectedToNetwork ? 1 : 0,
            backgroundColor: `rgba(14, 31, 47, 0.9)`, // TODO move to config
          }}>
          <div className='progress-bar-container'>
            <div
              className='progress-bar'
              style={{
                backgroundColor: `${teaserStyles.streamLiveTagColor}`,
              }}
            >
              { isNaN(percentage)
                ? null
                : (<div
                  className='progress-line'
                  style={{
                    width: `${percentage > 100 ? 100 : percentage}%`,
                    backgroundColor: `${playerStyles['scrubberBarColor']}`,
                  }}>
                </div>)}
            </div>
          </div>
          <div className='live-overlay-container'>
            <div className='logo-container'>
              <div
                className='channel-number'
              >
                {channel.number}
              </div>
              <div className='channel-logo-and-arrows-wrapper'>
                <img
                  aria-hidden={true}
                  src={channelLogoInternal}
                  className='channel-logo'
                  alt='channel-logo'
                />
              </div>
            </div>
            <div
              className='main-container'
              style={{ width: '60%' }}
            >
              <div className='progress-info-container'>
                {currentMetaInt.isLiveProgram && <Label style={liveLabelStyles} title={LIVE} />}
                <span
                  className='meta-time progress-bar-time'
                  style={{
                    color: `${teaserStyles['streamTextColor']}`,
                    fontFamily: `${teaserStyles['streamFontFace']['fontFamily']}`,
                    paddingLeft: `${currentMetaInt.isLiveProgram ? '90px' : ''}`,
                  }}
                >
                  {getTextTimeLeft(hours, minutes)}
                </span>
              </div>
              <div className='description-container'>
                <h3
                  className='meta-title'
                  style={{
                    color: `${teaserStyles['headlineColor']}`,
                    fontFamily: `${teaserStyles['streamLiveTagFontFace']['fontFamily']}`,
                  }}
                >
                  {currentMetaInt.title}
                </h3>
                <h5
                  className='meta-description'
                  style={{
                    color: `${teaserStyles['headlineColor']}`,
                    fontFamily: `${teaserStyles['descriptionFontFace']['fontFamily']}`,
                  }}
                >
                  {currentMetaInt.description}
                </h5>
              </div>
            </div>
            <div className='button-container' style={{ width: '20%' }}>
              <button
                // eslint-disable-next-line
                role=''
                aria-label={'Live '}
                className='overlay-button'
                ref={openScheduleRef}
                data-tv-focus-left='.overlay-button'
                data-tv-focus-right='.overlay-button'
                data-tv-focus-down='.overlay-button'
                data-tv-focus-up={isShownSignInBtn ? '#sign-in-button' : '#navigationSelectedItem'}
                style={{
                  backgroundColor: `${buttonStyles.colorOff}`,
                  border: `${buttonStyles.borderSize}px solid ${buttonStyles.borderColorOff}`,
                  color: buttonStyles.textColor,
                  fontFamily: `${teaserStyles['streamLogoFontFace']['fontFamily']}`,
                }}
                onFocus={buttonGetFocused}
                onBlur={buttonGetBlur}
                onKeyDown={keyDownFunction}
                tabIndex={visible ? 0 : -1}
              >
                {nameOfGuideBtn}
              </button>
            </div>
          </div>
        </div>
        )}
    </>
  );
};

export default React.memo(LiveOverlayWithControls);
