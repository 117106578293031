declare const tizen: any;

const NOT_TIZEN_API = 'Tizen API is not available';

export const setMute = isMuted => {
  try {
    tizen.tvaudiocontrol.setMute(isMuted);
  } catch (e) {
    return { NOT_TIZEN_API, e };
  }
};
