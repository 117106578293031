import { put, call, takeLeading } from 'redux-saga/effects';

import * as actionTypes from '../actions/types/liverampCollector';
import { setLiverampData } from '../../services/liverampCollectorService';

function* setLiveramp(action) {

  try {
    const { urlLiveramp } = action.payload;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const resp = yield call(setLiverampData, urlLiveramp);

    yield put({
      type: actionTypes.LOAD_LIVERAMP_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: actionTypes.LOAD_LIVERAMP_FAILED,
    });
  }
}

function* liverampWatcher() {
  yield takeLeading(actionTypes.LOAD_LIVERAMP, setLiveramp);
}

export default liverampWatcher;
