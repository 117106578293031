export const hlsConfig = {
  // debug: true,
  enableWebVTT: true,
  enableWorker: true,
  enableCEA708Captions: true,
  // levelLoadingTimeOut: 8000, // uncommitted row
  levelLoadingRetryDelay: 500,
  levelLoadingMaxRetryTimeout: 9000, // 25000
  fragLoadingTimeOut: 6000, // 15000
  fragLoadingRetryDelay: 500,
  fragLoadingMaxRetryTimeout: 9000, // 25000
  levelLoadingMaxRetry: 2,
  fragLoadingMaxRetry: 2,
};
