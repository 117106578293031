import { MutableRefObject, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { mvpdLoginRequired, tennisPlusLoginRequired } from '../utils';
import { RootState } from '../stores/reducers';

const useHideSignInButton = (logInRequestor: string, isShownSignInBtn: MutableRefObject<boolean>) => {
  const isUserAuthMVPD = useSelector((state: RootState) => state.Login.isUserMVPDLogin);
  const isUserAuthPlus = useSelector((state: RootState) => state.Login.isUserTCLogin);
  const isCurrentSubscriptionActive = useSelector(
    (state: RootState) => state.cleengService.isCurrentSubscriptionActive
  );

  useEffect(() => {
    if (mvpdLoginRequired(logInRequestor)) {
      if (isUserAuthMVPD) {
        isShownSignInBtn.current = false;
      }
    } else if (tennisPlusLoginRequired(logInRequestor)) {
      if (isUserAuthPlus && isCurrentSubscriptionActive) {
        isShownSignInBtn.current = false;
      }
    }
    // eslint-disable-next-line
  }, [isUserAuthMVPD, isUserAuthPlus, isCurrentSubscriptionActive]);
};

export default useHideSignInButton;
