import { put, call, all, takeLatest, select } from 'redux-saga/effects';

import * as actionTypes from '../actions/types/OTTSearch';
import { getSuggestionsData, getPageData, getComponentContent } from '../../services/OTTSearchService';
import { RootState } from '../reducers';
import { PAGE_COMPONENTS_REQUEST_ERROR, PAGE_LAYOUT_REQUEST_ERROR } from '../../constants/errorCodes';

function* getSuggestions(data) {
  const { url, query } = data;

  try {
    const content: object = yield call(getSuggestionsData, url, query);

    yield put({
      type: actionTypes.LOAD_OTTSEARCH_SUGGESTIONS_SUCCEEDED,
      payload: content,
    });
  } catch (e: any) {
    yield put({
      type: actionTypes.LOAD_OTTSEARCH_SUGGESTIONS_FAILED,
      error: PAGE_LAYOUT_REQUEST_ERROR,
    });
  }
}

function* getOTTSearchComponents(data) {
  const { url } = data;
  const {
    common: {
      station,
    },
  }: RootState = yield select();

  try {
    const page = yield call(getPageData, url);

    yield put({
      type: actionTypes.SET_OTTSEARCH_PAGE_DATA,
      payload: page,
    });

    const components = yield all(
      page.map((item) => call(getComponentContent, item.content, station)),
    );

    yield put({
      type: actionTypes.LOAD_OTTSEARCH_COMPONENT_SUCCEEDED,
      payload: { components },
    });

  } catch (e: any) {
    yield put({
      type: actionTypes.LOAD_OTTSEARCH_COMPONENT_FAILED,
      error: PAGE_COMPONENTS_REQUEST_ERROR,
    });
  }
}

function* watcher() {
  yield takeLatest(actionTypes.LOAD_OTTSEARCH_SUGGESTIONS, getSuggestions);
  yield takeLatest(actionTypes.LOAD_OTTSEARCH_COMPONENT, getOTTSearchComponents);
}

export default watcher;
