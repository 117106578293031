import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import focusService from '../../services/focusService';
import { RootState } from '../../stores/reducers';

import './TEXT_TEASER.scss';

interface TextTeaserProps {
  content: {
    content: string;
    imageURL: string;
  }
}

const TEXT_TEASER = (props: TextTeaserProps) => {
  const {
    content: {
      content,
      imageURL,
    },
  } = props;
  const initFocusText = useRef<any>(null);
  const TTSStatus = useSelector((state: RootState) => state.common.TTSEnabled);

  useEffect(() => {
    (initFocusText.current && TTSStatus) && focusService.setFocus(initFocusText.current);
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="text-teaser"
      ref={initFocusText}
      tabIndex={TTSStatus ? 0 : -1}
      // eslint-disable-next-line
      role=''
      aria-label={content}
      data-tv-focus-down='#carouselId-1 .card-main.index-1-0'
    >
      <img
        className="text-teaser_img"
        src={imageURL}
        alt="STIRR Cities Logo"
        aria-hidden={true}
      />
      <h1>{content}</h1>
    </div>
  );
};

export default TEXT_TEASER;
