import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import FocusService from '../../services/focusService';
import { getKeyInfo } from '../../utils/index';
import { GAEvent } from '../../services/analytics/index';
import { PLAY, AUTO_TEASER } from '../../constants/analyticsTypes';
import { OTTEpisodeType, OTTFeedType, OTT_BRAND } from '../../constants/structureTypes';

import './SlickSlider.scss';

export interface Item {
  item: {
    'guid': {
      'content': string,
    },
    'media:content': {
      'media:title': {
        'content': string,
      },
      'media:description': {
        'content': string,
      },
      'media:thumbnail': Array<{
        'url': string,
      }>
    }
  }
}

type SlickSliderProps = {
  items: Array<Item>,
  onPress: (index: number, e: React.KeyboardEvent, seasonId?: string, sliderCardIdx?: number) => void,
  sliderId: number,
  defaultCardIndex?: number;
}

const SlickSlider = (props: SlickSliderProps) => {
  const { items, onPress, sliderId, defaultCardIndex } = props;

  const sliderRef = useRef<Slider>(null);

  const [activeItem, setActiveItem] = useState(defaultCardIndex || 0);
  const [onButtonSelected, setButtonSelected] = useState(false);

  const settings = {
    dots: true,
    useTransform: false,
    infinite: true,
    speed: 500,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    initialSlide: defaultCardIndex || 0,
    beforeChange: (previous, next) => {
      setActiveItem(next);
    },
    afterChange: current => {
      if (onButtonSelected) {
        focusWatchButton(current);
      }
    },
  };

  useEffect(() => {
    defaultCardIndex !== undefined && focusWatchButton(defaultCardIndex);
  }, []);

  const focusWatchButton = (currentItem: number) => {
    const watchButton = document.querySelectorAll(`#watch-button-${currentItem}`);

    if (items.length - 1 === currentItem && watchButton[1]) {
      FocusService.setFocus(watchButton[1]);
    } else {
      FocusService.setFocus(watchButton[0]);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    const currentCard = items[activeItem].item;
    let eventLabel: string = '';

    switch (getKeyInfo(e.keyCode)) {
      case 'Right':
        sliderRef.current.slickNext();
        break;
      case 'Left':
        sliderRef.current.slickPrev();
        break;
      case 'Enter':
        if (currentCard['category'] === OTTFeedType || currentCard['type'] === OTTFeedType) {
          eventLabel = currentCard['slug'];
        } else if (currentCard['category'] === OTTEpisodeType || currentCard['type'] === OTTEpisodeType) {
          eventLabel = currentCard['media:content']['media:title']['content'];
        }
        eventLabel && GAEvent(
          AUTO_TEASER,
          PLAY,
          eventLabel,
        );
        onPress(activeItem, e, undefined, activeItem);
        break;
      default:
        break;
    }
  };

  return (
    <div className={`infinite-slick-slider ${OTT_BRAND}`}>
      <div
        tabIndex={0}
        className='watch-button-ref'
        style={{display: onButtonSelected ? 'none' : 'block'}}
        onFocus={() => {
          const watchButton = document.querySelectorAll(`#watch-button-${activeItem}`);

          if (items.length - 1 === activeItem && watchButton[1]) {
            FocusService.setFocus(watchButton[1]);
          } else {
            FocusService.setFocus(watchButton[0]);
          }
        }}>
      </div>
      <Slider ref={sliderRef} {...settings}>
        {items.map((item, index) => {
          const id = item.item.guid.content || index;

          return (
            <div key={id} className={`card`}>
              <div className='left-container'>
                <h2 className='title'>
                  {items[activeItem].item['media:content']['media:title']['content']}
                </h2>
                <p className='description'>
                  {items[activeItem].item['media:content']['media:description']['content']}
                </p>
                <button
                  data-tv-focus-left={`.watch-button`}
                  data-tv-focus-right={`.watch-button`}
                  data-tv-focus-up={`${sliderId === 0 && '#navigationSelectedItem'}`}
                  onFocus={() => setButtonSelected(true)}
                  onBlur={() => setButtonSelected(false)}
                  aria-label={`${items[activeItem].item['media:content']['media:title']['content']}/n
                    ${items[activeItem].item['media:content']['media:description']['content']} watch`}
                  aria-atomic='true'
                  onKeyDown={onKeyDown}
                  className={`watch-button ${OTT_BRAND}`}
                  id={`watch-button-${index}`}
                >
                  WATCH
                </button>
              </div>
              <div className={`image card-gradient`}
                style={{background: `url(
                   ${item.item['media:content']['media:thumbnail'][0]['url']}
                   ) center center no-repeat`}} />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default SlickSlider;
