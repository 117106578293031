// services/cleengService
export const DEFAULT_CLEENG_ERROR = '1000';
export const REGISTER_CLEENG_CUSTOMER_ERROR = '1001';
export const GENERATE_CUSTOMER_TOKEN_ERROR = '1002'; // cleeng token for sign in flow
export const GET_PURCHASE_OPTIONS_ERROR = '1003'; // product list from cleeng
export const SUBMIT_RECEIPT_ERROR = '1004';
export const GET_ACTIVE_USER_SUBSCRIPTIONS_ERROR = '1005'; // user subscriptions list(only active)
export const GET_ALL_USER_SUBSCRIPTIONS_ERROR = '1006'; // user subscriptions list(all)
export const RESET_PLUS_PASSWORD_ERROR = '1007';
export const STILL_ACTIVE_SUBSCRIPTION_IN_CLEENG = '1008';

// purchase SDK errors
export const DEFAULT_PURCHASE_ERROR = '2000';
export const REGUEST_PRODUCTS_LIST_ERROR = '2001'; // products list from samsung
export const REQUEST_PURCHASES_LIST_ERROR = '2002'; // purchases list from samsung
export const REQUEST_BUY_ITEM_ERROR = '2003';
export const REQUEST_USER_STATUS_ERROR = '2004';
export const EMPTY_PRODUCT_LIST_OR_NO_SELECTED_PRODUCT_ID_ERROR = '2005';
export const REQUEST_BUY_ITEM_EXCEPTION_ERROR = '2006';
export const STILL_ACTIVE_SUBSCRIPTION_IN_STORE = '2007';
export const USER_NOT_LOGIN_ERROR_FIRE_TV = '2008';
export const USER_NOT_LOGIN_ERROR_TIZEN = '2009';

// MVPD
export const DEFAULT_MVPD_ERROR = '3000';
export const LOGIN_MVPD_ERROR = '3001';
export const CODE_GENERATION_MVPD_ERROR = '3002';
export const PROVIDER_MVPD_LIST_ERROR = '3003';

// Video&Ads
export const DEFAULT_VIDEO_AD_ERROR = '4000';
export const VIDEO_API_ERROR = '4001';
export const VIDEO_ERROR = '4002'; // Player issues + while server stitched ads in case no ad end event

// Other
export const DEFAULT_ERROR = '9000';
export const APP_CONFIG_REQUEST_ERROR = '9001';
export const APP_NAVIGATION_REQUEST_ERROR = '9002';
export const PAGE_LAYOUT_REQUEST_ERROR = '9003';
export const PAGE_COMPONENTS_REQUEST_ERROR = '9004';
export const CHANNEL_LIST_REQUEST_ERROR = '9005';
export const PAGE_CONTENT_REQUEST_ERROR = '9006';
