import { call, select } from 'redux-saga/effects';

import { isContentWithRestriction } from '../../utils/index';
import { checkUserAuth, refreshTokenPlus } from '../sagas/Login';
import { RootState } from '../reducers';
import { CLEENG_RESTRICTION, MVPD_RESTRICTION } from '../../constants/text';
import { addParametersToURL, getParsedDataFromLocalStorage } from '../../utils/commonFunc';

export function* getDataForPlayback(
  { url, content },
  getPlaybackComponentContent,
  requestPlaybackComponentContent,
  isLive
) {
  const store: RootState = yield select();
  const {
    common: { station },
    config: {
      features,
      allConfigs: {
        API: { channel_status },
      },
    },
    Login: {
      mvpd: {
        authTokenMVPD,
      },
      subscription: {
        authTokenPlus,
      },
    },
    cleengService: {
      isCurrentSubscriptionActive,
    },
  } = store;
  const mvpdToken = authTokenMVPD || getParsedDataFromLocalStorage('AuthMVPD')?.token || '';

  let componentContent = content;
  let component: any;
  let postData: any = {};
  let requestHeaders: any = {};
  let refreshedMvpdToken: string = '';
  let refreshedPlusToken: string = '';
  let updatedRequestURL: string = '';

  if (features.cleeng || features.mvpd) {

    if (url && isLive) {
      const component = yield call(requestPlaybackComponentContent, url, postData, requestHeaders);

      componentContent = component.item;
    }

    const requestUrl = isLive
      ? `${channel_status}${componentContent.slug || componentContent.guid.content}`
      : content['media:content']['url'];

    if (componentContent['media:content']) {
      updatedRequestURL = requestUrl;

      const contentRestriction = componentContent['media:content']['media:restriction'];

      if (contentRestriction) {

        if (isContentWithRestriction(contentRestriction, MVPD_RESTRICTION) && mvpdToken) {
          refreshedMvpdToken = yield call(checkUserAuth, { refresh: true });

          postData = { mvpdMediaToken: refreshedMvpdToken };
          requestHeaders = {
            'x-mvpdMediaToken': refreshedMvpdToken,
          };
        }

        if (isContentWithRestriction(contentRestriction, CLEENG_RESTRICTION) && authTokenPlus) {
          refreshedPlusToken = yield call(refreshTokenPlus);

          requestHeaders = {
            'x-userauthenticationtoken': refreshedPlusToken,
          };

          // trigger cache cleaning on EE side for already existing account without subscription after purchase;
          updatedRequestURL = addParametersToURL(requestUrl, {}, !!(authTokenPlus && !isCurrentSubscriptionActive));
        }
      }

      component = yield call(requestPlaybackComponentContent, updatedRequestURL, postData, requestHeaders);
    }
  } else {
    if (url) {
      component = yield call(getPlaybackComponentContent, url, station);
    } else {
      component = { item: { ...content } };
    }
  }

  return { component, refreshedMvpdToken, refreshedPlusToken };
}
