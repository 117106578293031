import moment from 'moment';
import { RawProgram } from '../stores/actions/OTTChannelGuide';
import { isEmpty } from 'ramda';
import { isStirrTcMarqueeBrand } from '../constants/structureTypes';
import { convertXmltvDate } from '../stores/schemas/utils';

const today = moment(new Date()).format('DD/MM/YYYY');
const tommorrow = moment(new Date()).add(1, 'd').format('DD/MM/YYYY');

export type Dates = {
  [key: string]: Array<RawProgram>,
}

export const filterProgramsByDate = (data: Array<RawProgram>): Dates => {

  const dateObject: Dates = {
    [today]: [],
    [tommorrow]: [],
  };

  data.forEach((item: any) => {
    const retData = !isEmpty(item.start)
      ? isStirrTcMarqueeBrand
        ? convertXmltvDate(item.start)
        : item.start
      : null;
    const date = moment(retData);

    item.start = moment(retData).format();

    if (moment(date).isSame(new Date(), 'date')) {
      dateObject[today].push(item);
    } else {
      dateObject[tommorrow].push(item);
    }
  });

  return dateObject;
};
