import { PLAYER_WIDTH, PLAYER_HEIGHT } from '../../constants/videoPlayer';
import { IAnalyticsDetails, sendAdErrorDetails } from '../analytics';

declare const google: any;

class AdsService {
  adsManager: any = null;
  adsLoader: any = null;
  adDisplayContainer: any = null;
  analyticsDetail: IAnalyticsDetails = {
    playbackItemIdentifier: '',
    playerName: '',
    currentAd: {},
  };

  initAds(
    adsContainerRef,
    adsVideoRef,
    adUrl,
    playingVideoListener,
    waitingVideoListener,
    onAdLoaded,
    onContentPauseRequested,
    onAdContentCompleted,
    onAdProgress,
    onAdCompleted,
    onAdStarted,
    loadAds,
    onAdBuffering,
    analyticsData,
  ): void {
    this.adDisplayContainer = new google.ima.AdDisplayContainer(adsContainerRef, adsVideoRef);
    this.adDisplayContainer.initialize();
    this.adsLoader = new google.ima.AdsLoader(this.adDisplayContainer);
    this.analyticsDetail = analyticsData;

    this.adsLoader.addEventListener(
      google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
      (e) => this.onAdsManagerLoaded(
        e,
        adsVideoRef,
        onAdLoaded,
        onContentPauseRequested,
        onAdContentCompleted,
        onAdProgress,
        onAdCompleted,
        onAdStarted,
        loadAds,
        onAdBuffering,
      ),
      false,
    );

    this.adsLoader.addEventListener(
      google.ima.AdErrorEvent.Type.AD_ERROR,
      (e) => this.onAdError(e, onAdContentCompleted),
      false,
    );

    this.adsLoader.addEventListener(
      google.ima.AdErrorEvent.Type.COMPLETE,
      () => console.log('COMPLETE'),
      false,
    );

    adsVideoRef.addEventListener('playing', playingVideoListener);
    adsVideoRef.addEventListener('waiting', waitingVideoListener);

    const adsRequest = new google.ima.AdsRequest();

    adsRequest.adTagUrl = adUrl;

    // Specify the linear and nonlinear slot sizes. This helps the SDK to
    // select the correct creative if multiple are returned.
    adsRequest.linearAdSlotWidth = PLAYER_WIDTH;
    adsRequest.linearAdSlotHeight = PLAYER_HEIGHT;
    adsRequest.nonLinearAdSlotWidth = PLAYER_WIDTH;
    adsRequest.nonLinearAdSlotHeight = PLAYER_HEIGHT / 3;

    // Pass the request to the adsLoader to request ads
    this.adsLoader.requestAds(adsRequest);
  }

  private onAdsManagerLoaded(
    adsManagerLoadedEvent,
    adsVideoRef,
    onAdLoaded,
    onContentPauseRequested,
    onAdContentCompleted,
    onAdProgress,
    onAdCompleted,
    onAdStarted,
    loadAds,
    onAdBuffering,
  ): void {
    console.log('onAdsManagerLoaded');

    const adsRenderingSettings = new google.ima.AdsRenderingSettings();

    // Time in milliseconds for player to load information for ads
    adsRenderingSettings.loadVideoTimeout = 60000;

    // Instantiate the AdsManager from the adsLoader response and pass it the video element
    this.adsManager = adsManagerLoadedEvent.getAdsManager(adsVideoRef, adsRenderingSettings);

    this.adsManager.addEventListener(
      google.ima.AdErrorEvent.Type.AD_ERROR,
      this.onAdError,
    );

    this.adsManager.addEventListener(
      google.ima.AdEvent.Type.LOADED,
      onAdLoaded,
    );

    this.adsManager.addEventListener(
      google.ima.AdEvent.Type.AD_BUFFERING,
      onAdBuffering,
    );

    this.adsManager.addEventListener(
      google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
      onContentPauseRequested,
    );

    this.adsManager.addEventListener(
      google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
      onAdContentCompleted,
    );

    this.adsManager.addEventListener(
      google.ima.AdEvent.Type.AD_PROGRESS,
      (e) => onAdProgress(e, this.analyticsDetail.currentAd, true),
    );

    this.adsManager.addEventListener(
      google.ima.AdEvent.Type.COMPLETE,
      (e) => {
        onAdCompleted(e);
        this.analyticsDetail.currentAd = {};
      },
    );

    this.adsManager.addEventListener(
      google.ima.AdEvent.Type.STARTED,
      (e) => {
        onAdStarted(e);
        this.analyticsDetail.currentAd = e.getAd();
      },
    );

    this.adsManager.addEventListener(
      google.ima.AdEvent.Type.LOG,
      (e) => {
        const adData = e.getAdData();
        const adError = adData?.adError;

        // Fires when a non-fatal error is encountered
        adError && sendAdErrorDetails(this.analyticsDetail, adError);
      });

    loadAds();
  }

  private onAdError(adErrorEvent, onAdContentCompleted): void {
    // Handle the error logging.
    const objError = adErrorEvent.getError();

    if (objError) {

      sendAdErrorDetails(this.analyticsDetail, objError);

      // if problem with loading/requesting of ads
      // if (errorCode === 1005 || (errorCode >= 301 && errorCode <= 304)) {
      //   onAdContentCompleted();
      // }

      onAdContentCompleted();
    }

    if (this.adsManager) {
      this.adsManager.destroy();
    }
  }

  loadAdsWithIMA(): void {
    this.adsManager.init(PLAYER_WIDTH, PLAYER_HEIGHT, google.ima.ViewMode.NORMAL);
    this.adsManager.start();
  }

  destroyIMA(): void {
    if (this.adsManager) {
      this.adsManager.destroy();
      this.adDisplayContainer?.destroy();
      this.adsLoader?.destroy();
    }

    this.adsLoader = null;
    this.adsManager = null;
    this.adDisplayContainer = null;
  }
}

export default new AdsService();
