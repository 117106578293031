// external
import { all } from 'redux-saga/effects';
import configWatcher from './config';
import commonWatcher from './common';
import OTTPageWatcher from './OTTPage';
import OTTEpisodeWatcher from './OTTEpisode';
import OTTFeedWatcher from './OTTFeed';
import OTTChannelGuideWatcher from './OTTChannelGuide';
import OTTSearchWatcher from './OTTSearch';
import OTTSettingsWather from './OTTSettings';
import OTTText from './OTTText';
import liverampWatcher from './liverampCollector';
import cleengWatcher from './cleengService';
import Login from './Login';
import linearTelemetryWatcher from './linearTelemetry';
import titleLevelReportWatcher from './titleLevelReport';
// internal

export default function* root() {
  yield all([
    configWatcher(),
    commonWatcher(),
    OTTPageWatcher(),
    OTTEpisodeWatcher(),
    OTTFeedWatcher(),
    OTTChannelGuideWatcher(),
    OTTSearchWatcher(),
    OTTSettingsWather(),
    OTTText(),
    liverampWatcher(),
    linearTelemetryWatcher(),
    titleLevelReportWatcher(),
    cleengWatcher(),
    Login(),
  ]);
}
