import * as actionTypes from '../actions/types/OTTSearch';

export type Message = string | object;

export interface Suggestion {
  label: string;
  url: string;
}

export interface Page {
  title?: string;
  componentInstanceId: string;
  content: string;
  displayTitle: string;
  linkType: string;
  pageComponentUuid: string;
  slug: string;
  type: string;
}

export interface SuggestionsPayload {
  suggestions: Array<Suggestion>;
}

interface LoadSuggestionsAction {
  type: typeof actionTypes.LOAD_OTTSEARCH_SUGGESTIONS;
  url: string;
  query: string;
}

interface LoadSuggestionsSucceededAction {
  type: typeof actionTypes.LOAD_OTTSEARCH_SUGGESTIONS_SUCCEEDED;
  payload: SuggestionsPayload;
}

interface LoadSuggestionsFailedAction {
  type: typeof actionTypes.LOAD_OTTSEARCH_SUGGESTIONS_FAILED;
  error: string;
}

export interface Component {
  component: Array<{ item: object }>;
}

export interface ComponentPayload {
  component: object;
}

export interface ComponentSuccessPayload {
  components: Array<Component>;
}

interface LoadComponentAction {
  type: typeof actionTypes.LOAD_OTTSEARCH_COMPONENT;
  url: string;
}

interface LoadComponentSucceededAction {
  type: typeof actionTypes.LOAD_OTTSEARCH_COMPONENT_SUCCEEDED;
  payload: ComponentSuccessPayload;
}

interface LoadComponentFailedAction {
  type: typeof actionTypes.LOAD_OTTSEARCH_COMPONENT_FAILED;
  error: string;
}

interface SetOTTSearchPageDataAction {
  type: typeof actionTypes.SET_OTTSEARCH_PAGE_DATA;
  payload: Array<Page>;
}

interface ClearOTTSearch {
  type: typeof actionTypes.CLEAR_OTTSEARCH;
}

interface ClearOTTSearchSuggestions {
  type: typeof actionTypes.CLEAR_OTTSEARCH_SUGGESTIONS;
}

interface ClearOTTSearchContent {
  type: typeof actionTypes.CLEAR_OTTSEARCH_SUGGESTIONS_CONTENT;
}

type ComponentAction = LoadComponentAction | LoadComponentSucceededAction | LoadComponentFailedAction;

type SuggestionsAction = LoadSuggestionsAction | LoadSuggestionsSucceededAction | LoadSuggestionsFailedAction;

type ClearAction = ClearOTTSearchContent | ClearOTTSearchSuggestions | ClearOTTSearch;

export type Action = SetOTTSearchPageDataAction | ComponentAction | SuggestionsAction | ClearAction;

export const getSuggestions = (url: string, query: string): Action => {
  return ({
    type: actionTypes.LOAD_OTTSEARCH_SUGGESTIONS,
    url,
    query,
  });
};

export const getOTTSearchComponents = (url: string): Action => ({
  type: actionTypes.LOAD_OTTSEARCH_COMPONENT,
  url,
});
