import {
  LINEAR_TELEMETRY,
  LINEAR_TELEMETRY_FAILED,
  LINEAR_TELEMETRY_SUCCEEDED,
} from '../actions/types/linearTelemetry';

interface ILinearTelemetryState {
  isLoading: boolean;
  hasError: boolean;
}

const initialState: ILinearTelemetryState = {
  isLoading: false,
  hasError: false,
};

const linearTelemetry = (state = initialState, action) => {
  switch (action.type) {
    case LINEAR_TELEMETRY:
      return { ...state, isLoading: true, hasError: false };
    case LINEAR_TELEMETRY_SUCCEEDED:
      return { ...state, isLoading: false };
    case LINEAR_TELEMETRY_FAILED:
      return { ...state, isLoading: false, hasError: true };
    default:
      return state;
  }
};

export default linearTelemetry;
