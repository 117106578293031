import React, {useState, useRef, forwardRef} from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../stores/reducers';
import FocusService from '../../services/focusService';
import { getKeyInfo } from '../../utils';

import './Input.scss';

interface IInputProps {
  ariaLabel?: string;
  placeholder?: string;
  type?: string;
  value: string;
  index?: number;
  dataTvFocusUp?: string;
  dataTvFocusDown?: string;
  dataTvFocusLeft?: string;
  dataTvFocusRight?: string;
  onChangeCb: (string) => void;
  onFocusInputWrapperCb?: (e) => void;
  onBlurInputWrapperCb?: (e) => void;
  onFocusInputCb?: (e) => void;
  onBlurInputCb?: (e) => void;
  styleWrapper?: object;
  hasValueToBeTrimmed?: boolean;
  children?: JSX.Element;
  shouldMoveDownOnEnter?: boolean;
}

const Input = (props: IInputProps, initialFocusItemRef) => {
  const {
    ariaLabel = '',
    placeholder = '',
    type = 'text',
    value,
    index = 0,
    dataTvFocusUp,
    dataTvFocusDown,
    dataTvFocusLeft,
    dataTvFocusRight,
    onChangeCb,
    onFocusInputWrapperCb,
    onBlurInputWrapperCb,
    onFocusInputCb,
    onBlurInputCb,
    styleWrapper,
    shouldMoveDownOnEnter = true,
    hasValueToBeTrimmed = true,
    children,
  } = props;

  const inputStyles = useSelector((state: RootState) => state.config.styles?.general?.input);

  const inputWrapperRef = useRef<any>(null);
  const inputRef = useRef<any>(null);

  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(true);

  const moveFocusToInputWrapper = () => {
    process.nextTick(() => {
      // will move focus to next input or submit button if the user type value(not empty) and press 'Enter'
      const focusTarget = value && dataTvFocusDown && shouldMoveDownOnEnter
        ? document.querySelector(dataTvFocusDown)
        : inputWrapperRef.current;

      FocusService.setFocus(focusTarget);
    });
  };

  const inputWrapperStyles = {
    ... styleWrapper,
    border: `2px solid ${inputStyles?.borderColor}`,
  };

  const inputWrapperGetFocused = (e) => {
    e.target.style.border = `2px solid ${inputStyles?.borderColorActive}`;
    onFocusInputWrapperCb && onFocusInputWrapperCb(e);
  };

  const inputWrapperGetBlured = (e) => {
    e.target.style.border = `2px solid ${inputStyles?.borderColor}`;
    onBlurInputWrapperCb && onBlurInputWrapperCb(e);
  };

  return (
    <div
      ref={initialFocusItemRef ? initialFocusItemRef : inputWrapperRef}
      tabIndex={isInputDisabled ? 0 : -1}
      className={`input-wrapper input-wrapper-acc-${index}`}
      style={inputWrapperStyles}
      // eslint-disable-next-line
      role=''
      aria-label={ariaLabel}
      data-tv-focus-up={dataTvFocusUp}
      data-tv-focus-right={dataTvFocusRight}
      data-tv-focus-down={dataTvFocusDown}
      data-tv-focus-left={dataTvFocusLeft}
      onFocus={inputWrapperGetFocused}
      onBlur={inputWrapperGetBlured}
      onKeyDown={(e) => {
        if (getKeyInfo(e.keyCode) === 'Enter' && isInputDisabled) {
          e.preventDefault();
          process.nextTick(() => {
            FocusService.setFocus(inputRef.current);
          });
          setIsInputDisabled(false);
        }
      }}
    >
      <input
        ref={inputRef}
        className={`input input-acc-${index}`}
        tabIndex={isInputDisabled ? -1 : 0}
        // eslint-disable-next-line
        role={''}
        type={type}
        value={value}
        onChange={(e) => {
          hasValueToBeTrimmed
            ? onChangeCb(e.target.value.trim())
            : onChangeCb(e.target.value);
        }}
        aria-placeholder=""
        placeholder={isInputDisabled ? placeholder : ''}
        disabled={isInputDisabled}
        onKeyDown={(e) => {
          const keyInfo = getKeyInfo(e.keyCode);

          if ((keyInfo === 'Enter' || keyInfo === 'Done') && !isInputDisabled) {
            moveFocusToInputWrapper();
          }

          // prevent from focus changing when Left or Right buttons on keyboard are pressed
          if (keyInfo === 'Left' || keyInfo === 'Right') {
            if (e.target === document.activeElement) {
              e.stopPropagation();
            }
          }
        }}
        onFocus={(e) => {
          onFocusInputCb && onFocusInputCb(e);
        }}
        onBlur={(e) => {
          onBlurInputCb && onBlurInputCb(e);
          setIsInputDisabled(true);
        }}
      />
      {children}
    </div>
  );
};

export default forwardRef<HTMLDivElement, IInputProps>(Input);
