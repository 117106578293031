import moment from 'moment';

function formatTime(str: string): string {
  return str.replace(/^0{2}:/gi, '');
}

export function getTime(
  time: moment.DurationInputArg1,
  type?: moment.DurationInputArg2,
  format: string = 'HH:mm:ss',
): string | undefined {
  if (time === '' || time === '0' || time === undefined) {
    return;
  } else {
    return formatTime(
      moment.utc(moment.duration(time, type).asMilliseconds()).format(format),
    );
  }
}

export const formatTimeFromCleeng = (time: number, format: string = 'MMMM DD, YYYY') => {

  return moment((time * 1000)).format(format);
};

export const calculateTime = (start, end) => {
  const duration = moment.duration(end.diff(start)).as('milliseconds');
  const elapsed = moment.duration(moment().diff(start)).as('milliseconds');

  return {
    percentage: Math.floor((elapsed / duration) * 100),
    minutes: moment.duration(duration - elapsed).minutes(),
    hours: Math.floor(moment.duration(duration - elapsed).as('hours')),
  };
};

export const getTextTimeLeft = (hours, minutes) => {
  const bulletSymbol = '\u2022';

  return hours > 0 ? `${hours}hr ${bulletSymbol} ${minutes}min left` : (minutes < 1)
    ? `less than 1 min left` : `${minutes} min left`;
};

export const getTimestampSeconds = (): number => Math.floor(Date.now() / 1000);

export const getMilliseconds = (timeInSec): number => {
  if (isNaN(timeInSec)) {
    return 0;
  }

  return Math.floor(timeInSec * 1000);
};

export const getDateWithMonth = (date): string => {
  const milliseconds = 1000;
  const format = '2-digit';

  const customDate = new Date(date * milliseconds);

  return customDate.toLocaleString('en-US', { month: format, day: format});
};
