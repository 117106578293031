import { GeneralPlatform, webOSVersion } from './GeneralPlatform';
import { IExtendedPlatform } from './interfaces/interfaces';
import { INetworkInitParams } from './interfaces/network';

export class WebPlatform extends GeneralPlatform implements IExtendedPlatform {
  deviceBrand = 'Web';
  multiTaskingEvent = 'visibilitychange';
  idType = '';

  get deviceModel(): string {
    return ''; // TODO webVersion implementation
  }

  get deviceUuid(): string {
    return ''; // TODO webVersion implementation
  }

  get isLoadedInBrowser(): boolean {
    return true;
  }

  get osVersion(): string {
    return webOSVersion();
  }

  get wrapperVersion(): string {
    return '';
  }

  getAdvertisementId(): string {
    return ''; // TODO webVersion implementation
  }

  // Check whether ad tracking is restricted. 1 - tracking is restricted / 0 - tracking is not restricted
  isEnabledLimitationAdTracking(): number {
    return 0; // TODO webVersion implementation
  }

  getIp(): string {
    return ''; // TODO webVersion implementation
  }

  getPurchaseService(): null {
    return null;
  }
  // eslint-disable-next-line
  addNetworkHandler(networkUtils: INetworkInitParams): void { // disabled eslint line above will be removed when platform method will be implemented
    return;
  }

  getPreviewService(): null {
    return null;
  }

  registerKeys() {
    return;
  }

  async requestAdditionalAdsParams() {
    return;
  }

  getPackageName() {
    return this.brand;
  }
}
