import React, { useEffect, useRef } from 'react';

import { setIgnoreSpeakTTSCalls } from '../../services/TTSService';
import FocusService from '../../services/focusService';
import { moveFocusToPrevActiveElem } from '../../utils/commonFunc';
import useEventListener from '../../hooks/eventListener';
import { onBackHandler } from '../../utils';

import DefaultModal from './DefaultModal/DefaultModal';

import './Modal.scss';

export type TButton = {
  id: string;
  text: string;
  onPress: () => void;
  displayChildren?: JSX.Element;
};

type TModalProps = {
  title?: string;
  description?: string;
  zIndex?: number;
  buttons?: Array<TButton>;
  idContainer?: string;
  children?: JSX.Element;
  onBackHandlerCb?: () => void;
  setDebugPassword?: (value: string) => void;
  debugPassword?: string;
  isDebugModal?: boolean;
  isWrongDebugPassword?: boolean;
};

const Modal = (props: TModalProps) => {
  const {
    title,
    description,
    buttons = [],
    zIndex = 99999,
    idContainer,
    children = null,
    onBackHandlerCb,
    setDebugPassword,
    debugPassword,
    isDebugModal = false,
    isWrongDebugPassword = false,
  } = props;

  const initialFocusItem = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (idContainer && idContainer !== 'Live-Playback-Error-Modal') {
      const modal = document.getElementById(idContainer);

      FocusService.setFocusRoot(modal);
    }

    setIgnoreSpeakTTSCalls(true);

    const activeElementBeforeModalMounted = FocusService.activeElem;

    FocusService.setFocus(initialFocusItem.current);

    return () => {
      setIgnoreSpeakTTSCalls(false);
      FocusService.setFocusRootToBody();
      if (idContainer !== 'Live-Playback-Error-Modal') {
        moveFocusToPrevActiveElem(activeElementBeforeModalMounted, 'navigationSelectedItem');
      }
    };
  }, []);

  const getInitialTTS = (): string => [title, description, buttons[0]?.text].filter(Boolean).join('. ');

  useEventListener('keydown', event => {
    if (idContainer === 'Live-Playback-Error-Modal') {
      return;
    }

    if (document.querySelector('input:focus')) {
      return null; // cancel goBack to previous page when we need to delete sms from input
    } else {
      onBackHandler(event, () => {
        // stop propagation of event, because underlying components can listen to keydown event and change their state
        event.stopImmediatePropagation();
        onBackHandlerCb && onBackHandlerCb();
      }, null);
    }
  }, document, { capture: true });

  return (
    <div className='modal-container' style={{ zIndex: zIndex }}>
      {children ||
        <DefaultModal
          ref={initialFocusItem}
          title={title}
          description={description}
          buttons={buttons}
          textTTS={getInitialTTS()}
          idContainer={idContainer}
          isDebugModal={isDebugModal}
          isWrongDebugPassword={isWrongDebugPassword}
          setDebugPassword={setDebugPassword}
          debugPassword={debugPassword}/>
      }
    </div>
  );
};

export default Modal;
