import { getTimestampSeconds } from '../utils/time';

export default class TimeTracker {
  private _startTime: number;
  private _timeTotal: number;
  private _savedTimeTotal: number;

  constructor() {
    this._startTime = 0;
    this._timeTotal = 0;
    this._savedTimeTotal = 0;
  }

  start() {
    this._timeTotal = 0;
    this._savedTimeTotal = 0;
    this._startTime = getTimestampSeconds();
  }

  stop() {
    if (this._startTime) {
      this._savedTimeTotal = this._timeTotal + getTimestampSeconds() - this._startTime;
    }
    this._startTime = 0;
    this._timeTotal = 0;
  }

  resume() {
    this._startTime = getTimestampSeconds();
  }

  pause() {
    if (this._startTime) {
      this._timeTotal += getTimestampSeconds() - this._startTime;
    }
    this._startTime = 0;
  }

  reset() {
    this._startTime = 0;
    this._timeTotal = 0;
    this._savedTimeTotal = 0;
  }

  getTimeTotal() {
    let currentTotalTime = this._timeTotal || this._savedTimeTotal;

    // calculate time in case tracker is not stopped or paused
    if (!this._savedTimeTotal && this._startTime) {
      currentTotalTime += getTimestampSeconds() - this._startTime;
    }

    return currentTotalTime;
  }
}
