import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { backHandlerForFirstLevelPage, getKeyInfo, onBackHandler } from '../../utils/index';
import { getSuggestions, getOTTSearchComponents, Suggestion } from '../../stores/actions/OTTSearch';
import { RootState } from '../../stores/reducers/index';
import { navigate, navigateBack } from '../../services/NavigationService';
import * as OTTSearchActionTypes from '../../stores/actions/types/OTTSearch';
import { setActiveNavIdx } from '../../stores/actions/common';
import useEventListener from '../../hooks/eventListener';
import UIGenerator from '../../services/UIGeneratorService';
import { openPage, IJson } from '../../utils/OpenPage';
import { useAnnouncePageLoading } from '../../services/TTSService';
import {
  OTT_BRAND, OTTEpisodeType,
  STIRR_OTT_BRAND,
  TENNIS_CHANNEL_OTT_BRAND,
  THREE_SLIDES_VALUE,
} from '../../constants/structureTypes';
import { SEARCH_INIT_TEXT_STIRR, SEARCH_INIT_TEXT_TC } from '../../constants/text';
import { GAScreen, GAEvent } from '../../services/analytics/index';
import * as analyticsTypes from '../../constants/analyticsTypes';
import { primaryColor as primaryColorAccent } from '../../constants/colors';
import { isRefreshedPage } from '../../utils/commonFunc';

import Spinner from '../../components/Spinner/Spinner';
import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';
import NavigationMenu from '../../components/NavigationMenu/NavigationMenu';
import ScrollableList from '../../components/ScrollableList/ScrollableList';
import List from '../../components/List/List';
import Input from '../../components/Input/Input';

import './OTTSearch.scss';

type IOTTSearchProps = {
  location: {
    state: {
      configData: {
        title?: string;
        path: string;
        type?: string;
        web_route?: string;
        GAPath: string;
      };
      lastData: {
        lastFocusedElement: string;
      }
    };
  };
};

const OTTSearch = ({ location: { state: { configData, lastData }}}: IOTTSearchProps) => {
  const isConnected = useSelector((state: RootState) => state.common.isConnectedToNetwork);
  const TTSStatus = useSelector((state: RootState) => state.common.TTSEnabled);
  const suggestionsList = useSelector((state: RootState) => state.OTTSearch.suggestions);
  const components = useSelector((state: RootState) => state.OTTSearch.components);
  const isLoading = useSelector((state: RootState) => state.OTTSearch.isLoading);
  const pageHasError = useSelector((state: RootState) => state.OTTSearch.hasError);
  const pageError = useSelector((state: RootState) => state.OTTSearch.error);
  const pageData = useSelector((state: RootState) => state.OTTSearch.page);
  const { accents: accentColorStyleStore } = useSelector((state: RootState) => state.config.styles['colors']);
  const { station: storedStation, city: storedCity } = useSelector((state: RootState) => {
    return state.common;
  });
  const navigationFromStore = useSelector((state: RootState) => state.config.navigation);
  const generalStyles = useSelector((state: RootState) => state.config.styles['general']);

  const [inputState, setInputState] = useState<string>('');
  const [isNavBarVisible, setNavBarVisible] = useState<boolean>(true);

  const openVideo = useRef(false);
  const lastInputValue = useRef(inputState);

  const dispatch = useDispatch();
  const history = useHistory();

  const getTrimmedInput = () => inputState.trim();
  const isFilledInput = () => getTrimmedInput() !== '';

  const primaryColor = accentColorStyleStore.primary || primaryColorAccent;
  const colorTransparent = 'transparent';

  useEffect(() => {
    const suggestionsTimeout = setTimeout(() => {
      dispatch(getSuggestions(configData.path, getTrimmedInput()));
    }, 800);

    return () => {
      clearTimeout(suggestionsTimeout);
    };
    // eslint-disable-next-line
  }, [configData]);

  useAnnouncePageLoading(isLoading);

  useEffect(() => {
    if (!isRefreshedPage(history.location.pathname)) {
      GAScreen(configData.GAPath);
    }

    return () => {
      if (!isRefreshedPage(history.location.pathname)) {
        if (!openVideo.current) {
          GAEvent(analyticsTypes.SEARCH, analyticsTypes.ABANDON, lastInputValue.current);
        }
      }
    };
    // eslint-disable-next-line
  }, [history.location.pathname, configData.GAPath]);

  useEffect(() => {
    if (suggestionsList.length) {
      dispatch({
        type: OTTSearchActionTypes.CLEAR_OTTSEARCH_SUGGESTIONS_CONTENT,
      });
    }

    if (isFilledInput() && suggestionsList.length) {
      dispatch(getOTTSearchComponents(suggestionsList[0].url));
    }
    // eslint-disable-next-line
  }, [suggestionsList.length]);

  const createComponents = () => {
    return components.map((carousel, index) => {
      const isLastCarousel = components.length - 1 === index;

      return (
        <div className={`row-${index} carousel-row`} key={`carousel-row-${index}`}>
          {getComponent(carousel, index, isLastCarousel)}
        </div>
      );
    });
  };

  const getComponent = (item: any, index: number, isLastCarousel?: boolean) => {
    const cardPressed = (cardIndex: number, e: React.KeyboardEvent) => {
      return onCardPressed(index, cardIndex, e);
    };

    return UIGenerator.getStandardTeaserList({
      content: item.component,
      index: index,
      title: pageData[index]?.displayTitle,
      onPress: cardPressed,
      isMoveLeft: true,
      isLastCarousel: isLastCarousel,
      slides: THREE_SLIDES_VALUE,
    });
  };

  const onCardPressed = (rowIndex: number, cardIndex: number, e: React.KeyboardEvent) => {
    if (getKeyInfo(e.keyCode) === 'Enter') {
      const item = components[rowIndex]['component'][cardIndex]['item'];
      const navIdx = navigationFromStore.findIndex(nav => nav.type === (item['type'] || item['category']));

      const clearOTTSearchWrapper = () => {
        clearOTTSearchData();
        dispatch(setActiveNavIdx(navIdx));
      };

      const link = item['link'];
      const mediaContent = item['media:content'];

      if (link || mediaContent?.['url']) {
        GAEvent(analyticsTypes.SEARCH, analyticsTypes.COMPLETE, getTrimmedInput());
        openVideo.current = true;

        if (item['category'] === OTTEpisodeType) {
          navigate(
            history,
            openPage(
              Object.assign(item, { title: item['slug'] }) as unknown as IJson,
              {station: storedStation, city: storedCity, navIdx}
            ),
            clearOTTSearchWrapper,
          );
        } else {
          navigate(
            history,
            openPage(
              (item as unknown) as IJson,
              {station: storedStation, city: storedCity, navIdx}
            ),
            clearOTTSearchWrapper,
          );
        }
      }
    }
  };

  const clearOTTSearchData = () => {
    dispatch({
      type: OTTSearchActionTypes.CLEAR_OTTSEARCH,
    });
    lastInputValue.current = inputState;
    setInputState('');
  };

  const getSuggestionsContent = (e: React.KeyboardEvent, item: Suggestion) => {
    if (getKeyInfo(e.keyCode) === 'Enter') {
      dispatch({
        type: OTTSearchActionTypes.CLEAR_OTTSEARCH_SUGGESTIONS_CONTENT,
      });

      dispatch(getOTTSearchComponents(item.url));

      setInputState(item.label);

      const suggestion = e.target as HTMLElement;

      GAEvent(analyticsTypes.SEARCH, analyticsTypes.REFINES, suggestion.textContent!);
    }
  };

  const handleBack = () => {
    navigateBack(history, clearOTTSearchData, true);
  };

  useEventListener('keydown', (e: React.KeyboardEvent) => {
    onBackHandler(e,
      () => backHandlerForFirstLevelPage(
        history,
        !isLoading && !document.querySelector('input:focus'),
        clearOTTSearchData
      )
    );
  });

  const onBlurInputCb = () => {
    dispatch({
      type: OTTSearchActionTypes.CLEAR_OTTSEARCH_SUGGESTIONS,
    });
    dispatch(getSuggestions(configData.path, getTrimmedInput()));
    if (lastInputValue.current) {
      GAEvent(analyticsTypes.SEARCH, analyticsTypes.RE_ENTER, getTrimmedInput());
    } else {
      GAEvent(analyticsTypes.SEARCH, analyticsTypes.INITIATE, getTrimmedInput());
    }
    lastInputValue.current = inputState;
  };

  const initSearchPhraseObj = {
    [STIRR_OTT_BRAND]: SEARCH_INIT_TEXT_STIRR,
    [TENNIS_CHANNEL_OTT_BRAND]: SEARCH_INIT_TEXT_TC,
  };

  return (
    <div
      className="ott-search"
      style={{ background: `url(${generalStyles['background']})` }}
    >
      {pageHasError
        ? <ErrorModal
          error={{ errorCode: pageError }}
          onBackHandlerCb={handleBack}
        />
        : <>
          <NavigationMenu
            isMenuFocusedOnStart={!lastData?.lastFocusedElement}
            isNavBarVisible={isNavBarVisible}
            focusDownSelector={'.input-wrapper-acc-0'}
            onEnterCallback={clearOTTSearchData}
          />
          <div style={{ display: isConnected ? 'block' : 'none' }}>
            <div className="search-container">
              <Input
                type="text"
                value={inputState}
                onChangeCb={setInputState}
                placeholder='Search Term'
                ariaLabel={`Search Term. ${inputState} ${inputState === '' ? initSearchPhraseObj[OTT_BRAND] : ''}`}
                index={0}
                hasValueToBeTrimmed={false}
                dataTvFocusDown='.suggestion-0'
                dataTvFocusUp='#navigationSelectedItem'
                onBlurInputCb={onBlurInputCb}
                shouldMoveDownOnEnter={false}
                onFocusInputWrapperCb={() => {
                  !isNavBarVisible && setNavBarVisible(true);
                }}
                onBlurInputWrapperCb={() => {
                  isNavBarVisible && setNavBarVisible(false);
                }}
              />
            </div>
            <div className="content-container">
              <div className="suggested-section">
                <h1 className="title">Suggested</h1>
                <List
                  transition={0.3}
                  offset={185}
                  incrementReducer={1}
                  lastTranslatedRowIndex={2}
                >
                  {suggestionsList.map((item, index) => (
                    <p
                      tabIndex={1}
                      className={`suggestion-item suggestion-${index}`}
                      key={index}
                      // eslint-disable-next-line
                      role=''
                      aria-label={`suggestion list ${item.label}`}
                      onKeyDown={(e) => {
                        getSuggestionsContent(e, item);
                      }}
                      onFocus={e => e.target.style.borderColor = primaryColor}
                      onBlur={e => e.target.style.borderColor = colorTransparent}
                      data-tv-focus-right={components.length ? '' : `.suggestion-${index}`}
                      data-tv-focus-up={(index - 1 >= 0) ? `.suggestion-${index - 1}` : '.input-wrapper-acc-0'}
                      data-tv-focus-down={index === suggestionsList.length - 1
                        ? `.suggestion-${index}` : ''}
                    >
                      {item.label}
                    </p>
                  ))}
                </List>
              </div>
              <div className="content-section">
                {isLoading ? (
                  <div className="ott-search__spinner">
                    <Spinner />
                  </div>
                ) : (
                  <div className="content-list">
                    { components.length ?
                      <ScrollableList
                        transition={0.3}
                      >
                        { createComponents() }
                      </ScrollableList> :
                      <h2
                        className='content-title'
                        tabIndex={TTSStatus ? 1 : -1}
                        // eslint-disable-next-line
                        role=''
                        aria-label={initSearchPhraseObj[OTT_BRAND]}
                        onFocus={(e) => {
                          if (TTSStatus) {
                            e.target.style.borderBottomColor = primaryColor;
                          }
                        }}
                        onBlur={(e) => {
                          e.target.style.borderBottomColor = colorTransparent;
                        }}
                      >
                        {initSearchPhraseObj[OTT_BRAND]}
                      </h2>
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default OTTSearch;
