import {
  OTT_BRAND,
  STIRR_OTT_BRAND,
  COMET_OTT_BRAND,
  CHARGE_OTT_BRAND,
  TENNIS_CHANNEL_OTT_BRAND,
  MARQUEE_OTT_BRAND,
} from './structureTypes';

// In case of missing colors from API
export const COLOR_FOCUS_OFF: string = '#a7a7a7';
export const buttonBgColorOff: string = 'linear-gradient(rgb(52, 56, 59), rgb(35, 35, 36))';

const defaultStyles = {
  [STIRR_OTT_BRAND]: {
    primaryColor: '#ffde22',
    fontFamily: 'Montserrat-Regular',
  },
  [COMET_OTT_BRAND]: {
    primaryColor: '#22d1f9',
    fontFamily: 'Nimbus-Sans-L-Regular',
  },
  [CHARGE_OTT_BRAND]: {
    primaryColor: '#ff522b',
    fontFamily: 'Roboto-Regular',
  },
  [TENNIS_CHANNEL_OTT_BRAND]: {
    primaryColor: '#80ca4f',
    fontFamily: 'Open-Sans-Regular',
  },
  [MARQUEE_OTT_BRAND]: {
    primaryColor: '#b1172e',
    fontFamily: 'Neutra-Text-Regular',
  },
};

export const primaryColor = defaultStyles[OTT_BRAND].primaryColor;
export const fontFamily = defaultStyles[OTT_BRAND].fontFamily;
