import { GeneralPlatform, webOSVersion } from '../GeneralPlatform';
import { IExtendedPlatform, TRequestAddParams } from '../interfaces/interfaces';
import { INetworkInitParams } from '../interfaces/network';
import { callbackAndroidAsync } from './FireTVHelper';
import { IPurchaseService } from '../interfaces/purchaseService';
import { FireTvPurchaseService } from '../../services/fireTvPurchaseService';

declare const Android: any;

export class FireTVPlatform extends GeneralPlatform implements IExtendedPlatform {
  deviceBrand = 'Amazon';
  multiTaskingEvent = 'webkitvisibilitychange';
  idType = 'afai';

  get deviceModel(): string {
    if (typeof Android !== 'undefined') {
      return Android.getModel();
    }

    return '';
  }

  get deviceUuid(): string {
    if (typeof Android !== 'undefined') {
      return Android.getUuid();
    }

    return '';
  }

  get isLoadedInBrowser(): boolean {
    return (typeof Android === 'undefined');
  }

  get wrapperVersion(): string {
    try {
      if (typeof Android !== 'undefined') {
        return Android?.getAppVersion();
      }
    } catch (e: any) {
      console.log(`Error requesting wrapperVersion - ${e.name}`);

      return '';
    }

    return '';
  }

  get osVersion(): string {
    if (typeof Android !== 'undefined') {
      return Android.getOsVersion();
    }

    return webOSVersion();
  }

  exitFromApp = (): void => {
    if (typeof Android !== 'undefined') {
      Android.exitApp();
    }
  };

  getAdvertisementId(): string {
    let afai: string = '';

    try {
      if (typeof Android !== 'undefined') {
        afai = this.isEnabledLimitationAdTracking() ? '' : Android.getAdvertisementId();
      }
    } catch (e: any) {
      console.log(`Error requesting platform adId - ${e.name}`);
    }

    return afai;
  }

  // Check whether ad tracking is restricted. 1 - tracking is restricted / 0 - tracking is not restricted
  isEnabledLimitationAdTracking(): number {
    let lat: number = 0;

    try {
      if (typeof Android !== 'undefined') {
        lat = Android.isLATEnabled();
      }
    } catch (e: any) {
      console.log(`Error requesting platform adLat - ${e.name}`);
    }

    return lat;
  }

  getIp(): string {
    let deviceIp: string = '';

    try {
      if (typeof Android !== 'undefined') {
        deviceIp = Android.getUserIpAddress();
      }
    } catch (e: any) {
      console.log(`Error requesting deviceIp from platform - ${e.message}`);
    }

    return deviceIp;
  }

  getPurchaseService(): IPurchaseService {
    return new FireTvPurchaseService();
  }

  // eslint-disable-next-line
  addNetworkHandler(networkUtils: INetworkInitParams): void {
    return; // disabled eslint line above will be removed when platform method will be implemented
  }

  getPreviewService(): null {
    return null;
  }

  registerKeys() {
    // Function for simulating "Back" and "Menu" event at FireTV.
    // This func is triggered by Android when btns are pressed.
    const btnsEvents = [
      {funcName: 'dispatchBackEvent', code: 'Back', keyCode: 196, key: 'back'},
      {funcName: 'dispatchMenuEvent', code: 'Menu', keyCode: 1457, key: 'menu'},
    ];

    if (typeof Android !== 'undefined') {
      btnsEvents.forEach(({ funcName, code, keyCode, key }) => {
        window[funcName] = function() {
          // @ts-ignore
          document.dispatchEvent(new KeyboardEvent('keydown', { code, keyCode, key }));
        };
      });
    } else {
      console.log('Error: Special Media keys for Android can not be registered.');
    }
  }

  async requestAdditionalAdsParams(params: TRequestAddParams) {
    return await callbackAndroidAsync('requestAPS', params);
  }

  getPackageName() {
    let packageName = `com.sbgtv.${this.brand}.amazon`;

    if (typeof Android !== 'undefined') {
      try {
        const packageNameFromAndroid = Android?.getPackageName();

        if (packageNameFromAndroid) {
          packageName = packageNameFromAndroid;
        }
      } catch (e: any) {
        console.log('Error with Android.getPackageName: ', e?.message);
      }
    }

    return packageName;
  }
}
