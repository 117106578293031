import * as actionTypes from '../actions/types/OTTSearch';
import { Action, Suggestion, Component, Page } from '../actions/OTTSearch';

interface OTTSearchState {
  suggestions: Array<Suggestion>;
  page: Array<Page>;
  components: Array<Component>;
  fetched: boolean;
  hasError: boolean;
  error: string;
  isLoading: boolean;
}

const initialState: OTTSearchState = {
  suggestions: [],
  page: [],
  components: [],
  fetched: false,
  hasError: false,
  error: '',
  isLoading: false,
};

const OTTSearch = (state = initialState, action: Action): OTTSearchState => {
  switch (action.type) {
    case actionTypes.LOAD_OTTSEARCH_SUGGESTIONS:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.LOAD_OTTSEARCH_SUGGESTIONS_SUCCEEDED:
      return {
        ...state,
        suggestions: action.payload.suggestions,
        fetched: true,
        hasError: false,
        isLoading: false,
      };

    case actionTypes.LOAD_OTTSEARCH_SUGGESTIONS_FAILED:
      return {
        ...state,
        fetched: false,
        hasError: true,
        error: action.error,
        isLoading: false,
      };

    case actionTypes.LOAD_OTTSEARCH_COMPONENT:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.LOAD_OTTSEARCH_COMPONENT_SUCCEEDED:
      return {
        ...state,
        components: action.payload.components,
        fetched: true,
        hasError: false,
        isLoading: false,
      };

    case actionTypes.LOAD_OTTSEARCH_COMPONENT_FAILED:
      return {
        ...state,
        fetched: false,
        hasError: true,
        error: action.error,
        isLoading: false,
      };

    case actionTypes.SET_OTTSEARCH_PAGE_DATA:

      return {
        ...state,
        page: action.payload,
      };

    case actionTypes.CLEAR_OTTSEARCH_SUGGESTIONS:

      return {
        ...state,
        suggestions: [],
      };

    case actionTypes.CLEAR_OTTSEARCH_SUGGESTIONS_CONTENT:
      return {
        ...state,
        page: [],
        components: [],
      };

    case actionTypes.CLEAR_OTTSEARCH:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default OTTSearch;
