import { put, call, takeLatest, select } from 'redux-saga/effects';
import hls from 'hls-parser';

import { getDataForPlayback } from './sagas_common';
import { RootState } from '../reducers';
import * as actionTypes from '../actions/types/OTTEpisode';
import { config as configApp } from '../../config';
import {
  getOTTEpisodeComponentContent,
  getDRMAccesses,
  getExtraAdParams,
  getManifest,
  postOTTEpisodeComponentContent,
} from '../../services/OTTEpisodeService';
import {
  getCustomParams,
  getStandartParams,
  IBasicParams,
  getParamsStringForAds,
  getQueryStringFromAps,
} from '../../utils/advertisement';
import { OTTEpisodeType } from '../../constants/structureTypes';
import currentPlatform from '../../platform/currentPlatform';
import { VIDEO_API_ERROR } from '../../constants/errorCodes';

function* setComponentOTTEpisodeData(data) {
  const store: RootState = yield select();
  const {
    common: { station },
    config: {
      allConfigs: {
        API: {
          vmap_generator,
          cust_params_extras,
        },
      },
    },
  } = store;

  let component: any = {};
  let item: any = {};
  let mediaContent: any = {};
  let streams: any = [];
  let drm = {
    url: '',
    dashUrl: '',
    AuthXML: '',
    FPserver: '',
    FPcert: '',
    PRserver: '',
    WVserver: '',
    drmfail: '',
    drmfailMP4: '',
  };
  let drmFail = {
    drmFailUrl: '',
    drmFailTitle: '',
  };
  let adUrl = '';
  let midrollBreaks = [];

  try {
    const dataForPlayback = yield call(
      getDataForPlayback, data, getOTTEpisodeComponentContent, postOTTEpisodeComponentContent, false,
    );

    component = dataForPlayback.component;
    item = component?.item;
    mediaContent = item['media:content'];

    try {
      if (mediaContent['sinclair:ad_preroll']
        || mediaContent['sinclair:ad_midroll']
        || mediaContent['sinclair:ad_postroll']
      ) {
        const extraAdParams = yield call(getExtraAdParams, cust_params_extras, station);
        const customAdParams = getCustomParams(configApp.brand, OTTEpisodeType);

        const adParams = {
          ...extraAdParams,
          ...customAdParams,
        };

        const basicParams: IBasicParams = getStandartParams();
        const adParamsString = getParamsStringForAds(adParams);
        const standParams = getParamsStringForAds(basicParams);

        adUrl += `${vmap_generator}?`;

        mediaContent['sinclair:ad_preroll']
          && (adUrl += `preroll=${encodeURIComponent(mediaContent['sinclair:ad_preroll'])}&`);
        mediaContent['sinclair:ad_midroll']
          && (adUrl += `midroll=${encodeURIComponent(mediaContent['sinclair:ad_midroll']['content'])}&`);
        mediaContent['sinclair:ad_postroll']
          && (adUrl += `postroll=${encodeURIComponent(mediaContent['sinclair:ad_postroll'])}&`);
        if (mediaContent['sinclair:ad_midroll']) {
          adUrl += `breaks=${mediaContent['sinclair:ad_midroll']['breaks']}&`;
          midrollBreaks = mediaContent['sinclair:ad_midroll']['breaks'].split(',').map(
            (breakString: string) => parseInt(breakString, 10) * 1000, // converting to milliseconds
          );
        }

        adUrl += `${standParams}cust_params=${encodeURIComponent(adParamsString)}`;

        // Adding APS from fireTV WebView to the adUrl
        if (currentPlatform.appPlatform === 'firetv' && mediaContent?.duration) {
          const durationMin = ((mediaContent.duration / 60) < 60) ? '30_min' : '60_min';
          let resultFromAPSCall;

          try {
            resultFromAPSCall = yield call(currentPlatform.requestAdditionalAdsParams, { apsContentType: durationMin });
          } catch (e: any) {
            resultFromAPSCall = { error: 'Error from VOD APSCall: ' + (e?.error || e?.message) };
            console.log(resultFromAPSCall.error);
          }

          if (!resultFromAPSCall?.error && resultFromAPSCall?.imaResponse) {
            const stringifyImaRes = JSON.parse(resultFromAPSCall.imaResponse);
            const imaResData = getQueryStringFromAps(stringifyImaRes);

            adUrl += encodeURIComponent(imaResData);
          }
        }
      } else {
        console.log('Playback without ads');
      }
    } catch (error) {
      console.log('OTTEpisode::error with getting ads params.');
    }

    if (mediaContent['sinclair:drm'] && mediaContent['sinclair:drm_url']) {
      console.log('DRM playback');
      try {
        const drmData = yield call(() =>
          getDRMAccesses(mediaContent['sinclair:drm_url'], station, {
            'sinclair:drm': mediaContent['sinclair:drm'],
            guid: item['guid']['content'],
          }),
        );

        if (drmData?.AuthXML) {
          drm = { ...drmData };
        } else {
          drmFail = { ...drmData };
          adUrl = '';
        }
      } catch (e) {
        yield put({
          type: actionTypes.LOAD_OTTEPISODE_COMPONENT_FAILED,
          error: {
            errorCode: VIDEO_API_ERROR,
            requestData: data.url || data.content,
          },
        });
      }
    } else {
      try {
        const manifest = yield call(getManifest, component.item.link);
        const playlist = hls.parse(manifest);
        const { variants } = playlist;
        const basicUrl = `${component.item.link.split('/').slice(0, -1).join('/')}/`;

        streams = variants.map((item) => {
          if (item.uri.indexOf('http') === -1) {
            item.uri = `${basicUrl}${item.uri}`;
          }

          return item;
        });

        streams.sort((currentStream, nextStream) => nextStream.bandwidth - currentStream.bandwidth);
      } catch (e) {
        streams.push({ uri: component.item.link });
      }
    }

    yield put({
      type: actionTypes.LOAD_OTTEPISODE_COMPONENT_SUCCEEDED,
      payload: { component, streams, drm, drmFail, adUrl, midrollBreaks },
    });

  } catch (e: any) {
    yield put({
      type: actionTypes.LOAD_OTTEPISODE_COMPONENT_FAILED,
      error: {
        errorCode: VIDEO_API_ERROR,
        requestData: data.url || data.content,
      },
    });
  }
}

function* watcher() {
  yield takeLatest(actionTypes.LOAD_OTTEPISODE_COMPONENT, setComponentOTTEpisodeData);
}

export default watcher;
