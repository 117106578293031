import {
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
} from 'react-redux';

import { RootState } from './reducers';
import { rootReducer } from './reducers';
import configureStore from './CreateStore';
import rootSaga from './sagas';

const { store } = configureStore(rootReducer, rootSaga);

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export default store;
