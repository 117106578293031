import {
  LINEAR_TELEMETRY,
  LINEAR_TELEMETRY_FAILED,
  LINEAR_TELEMETRY_SUCCEEDED,
} from './types/linearTelemetry';
import { LinearTelemetryPayload } from '../../services/linearTelemetry';

export interface ILoadLinearTelemetry {
  type: typeof LINEAR_TELEMETRY;
  payload: LinearTelemetryPayload;
}
export interface ILoadLinearTelemetrySucceededAction {
  type: typeof LINEAR_TELEMETRY_SUCCEEDED;
}

export interface ILoadLinearTelemetryFailedAction {
  type: typeof LINEAR_TELEMETRY_FAILED;
}

export type ActionLinearTelemetry =
  | ILoadLinearTelemetry
  | ILoadLinearTelemetrySucceededAction
  | ILoadLinearTelemetryFailedAction;

export const sendLinearTelemetry = (payload: LinearTelemetryPayload): ILoadLinearTelemetry => ({
  type: LINEAR_TELEMETRY,
  payload,
});
