import React, {useEffect, useMemo, useState} from 'react';
import { useSelector } from 'react-redux';

import {
  SUCCESSFUL_PAYMENT_FROM_PLAYBACK_BTN_TEXT,
  SUCCESSFUL_PAYMENT_BTN_TEXT,
} from '../../../constants/text';
import focusService from '../../../services/focusService';
import { RootState } from '../../../stores/reducers';
import { formatTimeFromCleeng } from '../../../utils/time';
import Button from '../../Button/Button';

import './SuccessfulPaymentScreen.scss';

interface ISuccessfulPayment {
  watchNowHandler: () => void;
  exploreTCPlusHandler: () => void;
  isFlowFromPlayback: boolean;
}

const SuccessfulPaymentScreen = (props: ISuccessfulPayment) => {
  const {
    watchNowHandler,
    exploreTCPlusHandler,
    isFlowFromPlayback,
  } = props;

  const labelBgColor = useSelector((state: RootState) => state.config.styles?.settings?.linkColor);
  const logoPlus = useSelector((state: RootState) => state.config.styles?.general?.logoPlus);
  const pageBgImg = useSelector((state: RootState) => state.config.styles?.subscribe?.backgroundPaymentSuccess);
  const successPaymentTitle = useSelector((state: RootState) => state.config.subscription['payment_success_title']);
  const successPaymentDetails = useSelector((state: RootState) => state.config.subscription['payment_success_detail']);
  const currentSubscriptionDetails = useSelector((state: RootState) => state.cleengService.currentSubscriptionDetails);
  const titleFont = useSelector((state: RootState) => state.config.styles.general?.heading?.titleFont);

  const [isPageInfoAnnounced, setIsPageInfoAnnounced] = useState<boolean>(false);

  const btnText = useMemo(() => {
    return isFlowFromPlayback
      ? SUCCESSFUL_PAYMENT_FROM_PLAYBACK_BTN_TEXT
      : SUCCESSFUL_PAYMENT_BTN_TEXT;
  }, [isFlowFromPlayback]);

  const getTTSTextForPage = (btnText) => {
    return `${isPageInfoAnnounced
      ? ''
      : `${successPaymentTitle}. ${successPaymentDetails}.
        Subscription type:${currentSubscriptionDetails?.title}.
        Next billing date: ${formatTimeFromCleeng(currentSubscriptionDetails?.nextPaymentAt, 'dddd MMMM DD, YYYY')}`}.
      ${btnText}`;
  };

  useEffect(() => {
    focusService.setFocus(document.querySelector('.btn-acc-0'));
  }, []);

  return (
    <div className='successful-payment-screen' style={{backgroundImage: 'url(' + pageBgImg + ')'}}>
      <img className='successful-payment-screen__logo' src={logoPlus} alt='Tennis Channel Logo' />
      <h1
        className='successful-payment-screen__title'
        style={{ fontFamily: titleFont }}
      >
        {successPaymentTitle}
      </h1>
      <p
        className='successful-payment-screen__description'
      >{successPaymentDetails}</p>
      <div className='subscription-type-wrapper'>
        <span>Subscription type</span>
        <span
          className='subscription-type-value'
          style={{background: labelBgColor}}
        >{currentSubscriptionDetails?.title}</span>
      </div>
      <div className='next-billing-date'>
        {`Next billing date: ${formatTimeFromCleeng(currentSubscriptionDetails?.nextPaymentAt, 'dddd MMMM DD, YYYY')}`}
      </div>
      <Button
        title={btnText}
        index={0}
        focusUp='.btn-acc-0'
        focusDown='.btn-acc-0'
        focusLeft='.btn-acc-0'
        focusRight='.btn-acc-0'
        onKeyDown={() => isFlowFromPlayback ? watchNowHandler() : exploreTCPlusHandler()}
        TTSText={getTTSTextForPage(btnText)}
        onBlurFunc={() => setIsPageInfoAnnounced(true)}
      />
    </div>
  );
};

export default SuccessfulPaymentScreen;
