import axiosInstance from '../services/axiosInstance';
import { Features } from '../stores/actions/config';
import { mvpdLoginRequired, tennisPlusLoginRequired } from './index';
import { GAEvent } from '../services/analytics';
import FocusService from '../services/focusService';
import { speakTTS } from '../services/TTSService';
import { MVPD_PREVIEW, SKIP, SUBSCRIPTION_PREVIEW } from '../constants/analyticsTypes';
import { INavigationItem } from '../stores/reducers/config';
import {
  SEASON_GRID_TEASER_LIST,
  SEASON_TEASER_LIST,
} from '../constants/structureTypes';
import currentPlatform from '../platform/currentPlatform';
import { config } from '../config';
import { v4 as uuidv4 } from 'uuid';
import { LOCAL_STORAGE_DEBUG_APP_VERSION_KEY, LOCAL_STORAGE_DEBUG_ENVIRONMENT_KEY } from '../constants/debugSettings';

export interface IURLParams {
  station?: string;
  city?: string;
  query?: string;
}

interface Thumbnail {
  width?: string;
  height?: string;
  url: string;
}

type HtmlElemOrNull = HTMLElement | null;

export const isUrlWithParams = (url: string): boolean => {
  return /\?/g.test(url);
};

export const isUrlWithParam = (url: string, paramName: string): boolean => {
  return new RegExp(`${paramName}=`).test(url);
};

export function addParametersToURL(url: string, params: IURLParams = {}, shouldClearCache: boolean = false): string {
  const newParams: Array<string> = [];

  if (params.station && !isUrlWithParam(url, 'station')) {
    newParams.push(`station=${params.station}`);
  }

  if (params.city && !isUrlWithParam(url, 'city')) {
    newParams.push(`city=${params.city}`);
  }

  if (shouldClearCache) {
    newParams.push(`no-cache=${uuidv4()}`);
  }

  if (params.query) {
    newParams.push(`query=${encodeURIComponent(params.query)}`);
  }

  let newUrl = url;

  if (newParams.length) {
    newUrl += isUrlWithParams(url) ? '&' : '?';
    newUrl += newParams.join('&');
  }

  return newUrl;
}

export function isRefreshedPage(url: string): boolean {
  return /\/[.0-9]+$/.test(url);
}

// Resolve proper image object from given list of images
export const getProperImage = (thumbnailList: Thumbnail[], width: number, height: number): Thumbnail | null => {
  if (thumbnailList === null || thumbnailList.length === 0) {
    return null;
  }

  const viewAspectRatio = width / height;
  let closestDiff = 0;
  let foundThumbnail: Thumbnail | null = null;

  thumbnailList.forEach(thumbnail => {
    if (thumbnail.width && thumbnail.height) {
      const aspectRatio = parseInt(thumbnail.width, 10) / parseInt(thumbnail.height, 10);
      const diff = Math.abs(viewAspectRatio - aspectRatio);
      let isBetterImage = false;

      if (closestDiff === 0 ? true : closestDiff > diff) {
        isBetterImage = true;
      } else if ((foundThumbnail != null) && foundThumbnail.width) {
        isBetterImage = Math.abs(width - parseInt(thumbnail.width, 10)) < Math.abs(width
          - parseInt(foundThumbnail.width, 10));
      }

      if (isBetterImage) {
        closestDiff = diff;
        foundThumbnail = thumbnail;
      }
    }
  });

  return foundThumbnail;
};

export async function getDataForEden(url: string): Promise<any> {
  try {
    return await axiosInstance.get(url).then(res => res.data);
  } catch (e: any) {
    console.log(e.message);
  }
}

export function getRandomNumber() {
  return Math.trunc(Math.random() * 100000000);
}

export function getDeviceId(): string {
  return `${currentPlatform.deviceUuid}${getRandomNumber()}`;
}

function isSeasonItem(type: string): boolean {
  return (type === SEASON_GRID_TEASER_LIST || type === SEASON_TEASER_LIST);
}

export function convertContent(seasons: string[], page: any, decrement: number, content?: any[]): any[] {
  // transforming content structure from [el, el, ...] to [[el, el], [el], ...] for SEASON_TEASER_LIST
  return seasons.map(el => {
    return page.reduce((accum, incr, i): any => {
      if (el === incr.title && isSeasonItem(incr.type)) {
        return [...accum, content?.length ? content[i - decrement] : page[i - decrement]];
      } else {
        return accum;
      }
    }, []);
  });
}

export function convertFeatures(features: any): Features {
  const converted = {};

  for (const [key, value] of Object.entries(features)) {
    let convertedValue;

    if (typeof value === 'string') {
      convertedValue = value.toLowerCase().trim() === 'true' ? true : false;
    } else {
      convertedValue = value;
    }
    converted[key] = convertedValue;
  }

  return converted;
}

export function skipVideoGA(logInRequestor: string, currentVideoTitle: string) {
  if (tennisPlusLoginRequired(logInRequestor)) {
    GAEvent(SUBSCRIPTION_PREVIEW, SKIP, currentVideoTitle);
  } else if (mvpdLoginRequired(logInRequestor)) {
    GAEvent(MVPD_PREVIEW, SKIP, currentVideoTitle);
  }
}

export const getParsedDataFromLocalStorage = (key) => {
  const storedData = localStorage.getItem(key);

  return storedData ? JSON.parse(storedData) : null;
};

export function getInitialAppVersion(): string {
  const storedAppVersion = localStorage.getItem(LOCAL_STORAGE_DEBUG_APP_VERSION_KEY);
  const storedEnvName = localStorage.getItem(LOCAL_STORAGE_DEBUG_ENVIRONMENT_KEY);
  const appVersion = storedAppVersion ? JSON.parse(storedAppVersion) : config.appVersion;
  const appEnv = storedEnvName ? JSON.parse(storedEnvName) : config.envName;

  return `${currentPlatform.wrapperVersion ? `${currentPlatform.wrapperVersion}/` : ''}${appVersion}.${+appEnv}`;
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function getCapitalisePhrase(phraseString: string) {
  return phraseString.split(' ').reduce((accum, item) => {
    return (accum + ' ' + capitalize(item)).trim();
  }, '');
}

export function getCapitaliseAnalyticsValue(navItem: INavigationItem): string {
  // Transforming string to uppercase only first letters in the string
  return getCapitalisePhrase(navItem.title || navItem.displayTitle || '');
}

export const handleTSSMoveFocusToFirstEl = (ttsStatus, phraseToSpeak, nextFocusedEl) => {
  if (ttsStatus) {
    FocusService.setFocusToSilentEl();
    speakTTS(phraseToSpeak, FocusService.setFocus, nextFocusedEl);
  } else {
    FocusService.setFocus(nextFocusedEl);
  }
};

export const getParamsStringForAds = (adParams): string => {
  let adParamsString = '';

  for (const [key, value] of Object.entries(adParams)) {
    adParamsString += `${key}${value ? `=${value}` : ''}&`;
  }

  return adParamsString;
};

export const getQueryStringFromCollection = (collection) => {

  return !collection.length ? '' : collection.reduce((acc, {key, value}, index) => {
    const startSymbol = index > 0 ? '&' : '';

    return acc + `${startSymbol}${key}=${value}`;
  }, '');
};

export const isEqualArrays = (firstArr: string[], secondArr: string | string[]) => {
  if (!firstArr.length || !secondArr.length || firstArr.length !== secondArr.length) {
    return false;
  }

  for (let index = 0; index < firstArr.length; index++) {
    if (firstArr[index] !== secondArr[index]) {
      return false;
    }
  }

  return true;
};

export const isElementInViewport = (el: HTMLElement): boolean => {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const isElementHiddenWithOpacity = (el: HTMLElement): boolean => {
  let parentElement = el.parentElement;

  while (parentElement) {
    if (parentElement.style.opacity === '0') {
      return true;
    }

    parentElement = parentElement.parentElement;
  }

  return false;
};

export const isElementVisible = (el: HtmlElemOrNull): boolean => {
  return !!(
    el &&
    document.body.contains(el) &&
    isElementInViewport(el) &&
    !isElementHiddenWithOpacity(el)
  );
};

export const moveFocusToPrevActiveElem = (prevActiveElemToFocus: HtmlElemOrNull, backupIdElemToFocus: string): void => {
  process.nextTick(() => {
    if (prevActiveElemToFocus && isElementVisible(prevActiveElemToFocus)) {
      FocusService.setFocus(prevActiveElemToFocus);
    } else {
      const htmlElemToFocus = document.getElementById(backupIdElemToFocus);

      FocusService.setFocus(htmlElemToFocus);
    }
  });
};

export const getEnvironment = () => {
  // TODO: use one envNames here and for debug settings screen.
  const envNames = {
    prod: '0',
    mock: '1',
    qa: '2',
    int: '3',
    local: '4',
    demo: '5',
    stage: '6',
    alpha: '7',
    beta: '8',
  };

  return Object.keys(envNames).find(key => envNames[key] === config.envName) || 'prod';
};
