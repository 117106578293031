import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../stores/reducers';
import FocusService from '../../services/focusService';
import { config } from '../../config';
import { getInitialAppVersion, moveFocusToPrevActiveElem} from '../../utils/commonFunc';
import {
  RESTORE_TO_DEFAULTS_BUTTON_TITLE,
  SAVE_AND_RELOAD_BUTTON_TITLE,
} from '../../constants/text';
import {
  ENV_NAMES,
  LOCAL_STORAGE_DEBUG_APP_VERSION_KEY,
  LOCAL_STORAGE_DEBUG_ENVIRONMENT_KEY,
} from '../../constants/debugSettings';
import { onBackHandler, onEnterHandler } from '../../utils';
import useEventListener from '../../hooks/eventListener';
import { setAppVersion, toggleDebugModal } from '../../stores/actions/common';

import Button from '../Button/Button';
import Input from '../Input/Input';

import './DebugSettings.scss';

const DebugSettings = () => {
  const appVersion = useSelector((state: RootState) => state.common.appVersion);
  const cleengToken = useSelector((state: RootState) => state.Login.subscription.cleengToken);
  const styles = useSelector((state: RootState) => state.config.styles);

  const [selectedEnvironment, setSelectedEnvironment] = useState<number>(
    parseInt(appVersion[appVersion.length - 1], 10)
  );
  const [customAppVersion, setCustomAppVersion] = useState<string>('');
  const [hasWrongCustomAppVersion, setHasWrongCustomAppVersion] = useState<boolean>(false);

  const firstEnvName = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const debugSettings = document.querySelector('.debug-container');
    const activeElementBeforeModalMounted = FocusService.activeElem;

    FocusService.setFocusRoot(debugSettings);
    FocusService.setFocus(firstEnvName.current);

    return () => {
      FocusService.setFocusRootToBody();
      moveFocusToPrevActiveElem(activeElementBeforeModalMounted, 'navigationSelectedItem');
    };
  }, []);

  const validateAppVersion = (appVersion: string): boolean => {
    // match only x.x.x (x is number)
    return /^\d+(?:\.\d+){2}$/gm.test(appVersion);
  };

  const onEnvPress = (e: React.KeyboardEvent, environment: number) => {
    onEnterHandler(e, () => {
      setSelectedEnvironment(environment);
    });
  };

  const saveAndReloadApp = () => {
    localStorage.setItem(LOCAL_STORAGE_DEBUG_ENVIRONMENT_KEY, JSON.stringify(selectedEnvironment));

    if (customAppVersion) {
      if (validateAppVersion(customAppVersion)) {
        localStorage.setItem(LOCAL_STORAGE_DEBUG_APP_VERSION_KEY, JSON.stringify(customAppVersion));
      } else {
        setHasWrongCustomAppVersion(true);

        return;
      }
    }

    window.location.reload();
  };

  const restoreToDefaults = () => {
    localStorage.removeItem(LOCAL_STORAGE_DEBUG_ENVIRONMENT_KEY);
    localStorage.removeItem(LOCAL_STORAGE_DEBUG_APP_VERSION_KEY);
    dispatch(setAppVersion(getInitialAppVersion()));
    setSelectedEnvironment(parseInt(config?.envName, 10));
    setCustomAppVersion('');
  };

  const buttons = [
    {
      title: SAVE_AND_RELOAD_BUTTON_TITLE,
      onPress: saveAndReloadApp,
    },
    {
      title: RESTORE_TO_DEFAULTS_BUTTON_TITLE,
      onPress: restoreToDefaults,
    },
  ];

  const envNames = useMemo(() => Object.keys(ENV_NAMES), []);

  useEventListener('keydown', (event) => {
    event.stopImmediatePropagation();
    if (document.querySelector('input:focus')) {
      return null; // cancel goBack to previous page when we need to delete sms from input
    } else {
      onBackHandler(event, () => dispatch(toggleDebugModal()), null);
    }
  });

  return (
    <div className='debug-container'>
      <div className='debug-container-content'>
        <div className='debug-container-content-general'>
          <div className='debug-container-content-general-info'>
            <p className='debug-container-content-general-info-key'>
              App version:
            </p>
            <p className='debug-container-content-general-info-value'>
              {appVersion}
            </p>
          </div>
          <div className='debug-container-content-general-info'>
            <p className='debug-container-content-general-info-key'>
                Cleeng token:
            </p>
            <p className='debug-container-content-general-info-value'>
              {cleengToken || 'User is not logged in to TC Plus'}
            </p>
          </div>
        </div>
        <div className='debug-container-content-envs'>
          <p className='debug-container-content-general-envs-key'>
              Environment:
          </p>
          <div className='debug-container-content-general-envs-value'>
            {envNames.map((environment, index) => (
              <div
                className={`debug-container-content-environment env-name-${index}
                       ${selectedEnvironment === ENV_NAMES[environment] ? 'selected' : ''}`}
                ref={index === 0 ? firstEnvName : null}
                key={environment}
                tabIndex={0}
                // eslint-disable-next-line
                role=''
                aria-label={`${environment} environment`}
                data-tv-focus-left={`${index === 0 ? `.env-name-${index}` : `.env-name-${index - 1}`}`}
                data-tv-focus-right={`${index === envNames.length - 1 ?
                  `.env-name-${index}` : `.env-name-${index + 1}`}`}
                data-tv-focus-up={`.env-name-${index}`}
                data-tv-focus-down='.input-wrapper'
                onFocus={e => e.target.style.background = styles.colors.accents.primary}
                onBlur={e => e.target.style.background = ''}
                onKeyDown={(e) => onEnvPress(e, ENV_NAMES[environment])}
              >
                {environment}
              </div>
            ))}
          </div>
        </div>
        <div className='debug-container-content-input'>
          {hasWrongCustomAppVersion && (
            <p className='debug-container-content-input-wrong-password'>
              Wrong app version. Try again!
            </p>
          )}
          <Input
            type='text'
            value={customAppVersion}
            onChangeCb={setCustomAppVersion}
            onFocusInputCb={() => setHasWrongCustomAppVersion(false)}
            placeholder='Enter app version'
            ariaLabel='app version input'
            index={0}
            dataTvFocusUp={'.env-name-0'}
            dataTvFocusRight={'.input-wrapper-acc-0'}
            dataTvFocusDown={'.btn-acc-0'}
            dataTvFocusLeft={'.input-wrapper-acc-0'}
          />
        </div>
        <div className='debug-container-content-buttons'>
          {buttons.map((button, index) => (
            <Button
              title={button.title}
              key={button.title}
              index={index}
              TTSText={button.title}
              onKeyDown={button.onPress}
              focusLeft={`.btn-acc-${index}`}
              focusRight={`.btn-acc-${index}`}
              focusDown={index === buttons.length - 1 ? `.btn-acc-${index}` : `.btn-acc-${index + 1}`}
              focusUp={index === 0 ? `.input-wrapper` : `.btn-acc-${index - 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DebugSettings;
