import { call, put, takeLatest } from 'redux-saga/effects';

import {
  TITLE_LEVEL_REPORT,
  TITLE_LEVEL_REPORT_FAILED,
  TITLE_LEVEL_REPORT_SUCCEEDED,
} from '../actions/types/titleLevelReport';
import { setTitleLevelReport } from '../../services/titleLevelReport';

function* callTitleLevelReport(actionData): Generator {

  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const rep = yield call(setTitleLevelReport, actionData.payload);

    yield put({
      type: TITLE_LEVEL_REPORT_SUCCEEDED,
    });

  } catch (e) {
    yield put({
      type: TITLE_LEVEL_REPORT_FAILED,
    });
  }
}

function* titleLevelReportWatcher() {
  yield takeLatest(TITLE_LEVEL_REPORT, callTitleLevelReport);
}

export default titleLevelReportWatcher;
