import { PLAYER_WIDTH, PLAYER_HEIGHT } from '../constants/videoPlayer';
import { TFCD } from '../constants/advertisement';
import currentPlatform from '../platform/currentPlatform';

export interface IBasicParams {
  idtype: string;
  tfcd: string;
  an: string;
  msid: string;
  rdid:string;
  is_lat: string;
}

interface IParamsAPS {
  key: string;
  value: string;
}

export const getCustomParams = (
  channel: string,
  categories: string,
  uuid = null,
  configCustomParams = {},
  mvpd = null,
) => {
  const session = Math.floor(Math.random() * 9876543210987);
  const xForwardedForUserAgent = encodeURIComponent(window.navigator.userAgent)
    .replace(/[!'()*]/g, (c) => {
      return '%' + c.charCodeAt(0).toString(16);
    }); // to be double-encoded for DFP redirect including ( )

  return {
    xForwardedForUserAgent,
    w: PLAYER_WIDTH,
    h: PLAYER_HEIGHT,
    platform: currentPlatform.appPlatform,
    model: currentPlatform.deviceModel,
    channel,
    uuid,
    mvpd,
    video_type: categories,
    session,
    ...configCustomParams,
  };
};

export const getStandartParams = (): IBasicParams => ({
  idtype: currentPlatform.idType,
  tfcd: TFCD,
  an: currentPlatform.brand,
  msid: currentPlatform.getPackageName(),
  rdid: currentPlatform.getAdvertisementId(),
  is_lat: `${currentPlatform.isEnabledLimitationAdTracking()}`,
});

export const getParamsStringForAds = (adParams): string => {
  let adParamsString = '';

  for (const [key, value] of Object.entries(adParams)) {
    adParamsString += `${key}${value ? `=${value}` : ''}&`;
  }

  return adParamsString;
};

export const getQueryStringFromAps = (params: IParamsAPS[]): string => {
  if (!params.length) {
    return '';
  }

  const validApsParams: IParamsAPS[] = [];

  for (const param of params) {
    const duplications = params.filter(({ key }) => key === param.key);
    const isKeyInValidParams = validApsParams.some(validParam => validParam.key === param.key);

    if (isKeyInValidParams) {
      continue;
    }

    // if we have more than 1 match in duplications array
    if (duplications.length - 1) {
      let validParamValue = '';

      duplications.forEach((duplication, index) => validParamValue += `${index ? ',' : ''}${duplication.value}`);
      validApsParams.push({ key: param.key, value: validParamValue });
    } else {
      validApsParams.push(param);
    }
  }

  return validApsParams.reduce((acc, {key, value}, index) => acc + `${index ? '&' : ''}${key}=${value}`, '');
};
