import React, { Children, useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../stores/reducers';
import CardWrapper from './CardWrapper/CardWrapper';
import { DEFAULT_SLIDES_VALUE, HERO_TEASER } from '../../constants/structureTypes';
import { primaryColor } from '../../constants/colors';

import './Carousel.scss';

type CarouselProps = {
  cardStyles: {
    width: number;
    height: number;
    marginRight: number;
    marginLeft: number;
  };
  children: any;
  carouselId: number;
  selected?: number;
  onCardPressed: () => void;
  isMoveLeft?: boolean;
  isLastCarousel?: boolean;
  onSeasonSelect?: (idx: number) => void;
  onCardSelection: () => void;
  dynamic?: boolean;
  slides?: number;
  additionalHeight?: number;
  carouselTitle?: string;
  isCitySelectionCard?: boolean;
  seasonId?: string;
  defaultCarouselID?: string;
  defaultCardIndex?: number;
  seasonDefaultCarouselID?: string;
  carouselUUID?: any;
}

const Carousel = (props: CarouselProps) => {
  const {
    cardStyles,
    children,
    carouselId,
    selected,
    onCardPressed,
    onCardSelection,
    isMoveLeft,
    isLastCarousel,
    onSeasonSelect,
    dynamic,
    slides = DEFAULT_SLIDES_VALUE,
    additionalHeight = 120,
    carouselTitle,
    isCitySelectionCard,
    seasonId,
    defaultCarouselID,
    defaultCardIndex,
    seasonDefaultCarouselID,
    carouselUUID,
  } = props;

  const cardWidth = cardStyles.width + cardStyles.marginRight + cardStyles.marginLeft;
  const elemsLength = Children.toArray(children).length;

  const carouselRef = useRef<any>(null);
  const activeLast = useRef<any>(null);
  const initSelected = useRef<any>(null);
  const dynamicCarouselAverageWidth = useRef<number>(0);

  const [translateValue, setTranslateValue] = useState(
    // eslint-disable-next-line max-len
    defaultCardIndex && (defaultCarouselID === carouselUUID || seasonDefaultCarouselID === carouselUUID) && elemsLength - 1 >= slides
      ? -((slides && elemsLength - defaultCardIndex <= slides - 1 ? (elemsLength - slides) : defaultCardIndex) * cardWidth)
      : 0
  );
  const [activeItem, setActiveItem] = useState<HTMLDivElement>(initSelected.current);
  const [lastFocusedSeasonItem, setLastFocusedSeasonItem] = useState<number>(selected || 0);

  const itemForIndex = useSelector((state: RootState) => state.OTTPage.page);
  const TTSStatus = useSelector((state: RootState) => state.common.TTSEnabled);
  const borderColorFocusOn = useSelector((state: RootState) => {
    return state.config.styles?.['teaser']?.['focusBorderColor'] || primaryColor;
  });

  const onPress = () => onSeasonSelect || onCardPressed;

  const styles = {
    width: cardStyles.width,
    height: cardStyles.height,
    marginLeft: `${cardStyles.marginLeft}px`,
    marginRight: `${cardStyles.marginRight}px`,
  };

  const selectCard = (e, currentlyActive) => {
    handleScroll(e, currentlyActive);
    onCardSelection && onCardSelection();
  };

  const getFocusSelectorUp = () => {
    if (carouselId === 0 || itemForIndex[carouselId - 1]?.type === HERO_TEASER || dynamic) {
      if (TTSStatus && (itemForIndex[carouselId - 1]?.type === HERO_TEASER)) {
        return '.brand-info';
      } else {
        return '#navigationSelectedItem';
      }
    } else if (seasonId && carouselId === 2) {
      return '.season-list-last-focused-item';
    } else {
      return `.carousel-selected-${carouselId - 1}`;
    }
  };

  const getFocusSelectorDown = () => `.carousel-selected-${carouselId + 1}`;

  useEffect(() => {
    if (activeLast.current) {
      if (activeLast.current.classList.contains(`carousel-selected-${carouselId}`)) {
        initSelected.current?.classList?.remove(`carousel-selected-${carouselId}`);
      }
      activeLast.current.classList.add(`carousel-selected-${carouselId}`);
    }
  }, [carouselId, activeItem, selected]);

  const handleScroll = (e: React.KeyboardEvent, active: number) => {
    const activeElement = active + 1;

    if (slides) {
      if (elemsLength <= slides) {return;}

      if (elemsLength - activeElement <= slides - 1) {
        setTranslateValue((-elemsLength + slides) * cardWidth);
      } else {
        setTranslateValue((-active) * cardWidth);
      }
      if (activeLast.current) {
        if (!(activeLast.current === e.target)) {
          activeLast.current.classList.remove(`carousel-selected-${carouselId}`);
        }
      }

      activeLast.current = e.target;
      setActiveItem(activeLast.current);
    }

    if (dynamic) {
      const dynamicCardWidth = (e.target as HTMLDivElement).offsetWidth;

      if (activeElement > lastFocusedSeasonItem) {
        dynamicCarouselAverageWidth.current += dynamicCardWidth;
      } else {
        dynamicCarouselAverageWidth.current -= dynamicCardWidth;
      }

      setLastFocusedSeasonItem(active);

      if (elemsLength > 7 && elemsLength - activeElement >= 4) {
        const translateValueX = -active * (dynamicCarouselAverageWidth.current / activeElement);

        setTranslateValue(translateValueX > 0 ? 0 : translateValueX);
      }
    }
  };

  return (
    <div
      className='carousel'
      ref={carouselRef}
      id={`carouselId-${carouselId}`}
    >
      <div
        style={{
          height: cardStyles.height + additionalHeight,
          WebkitTransform: `translate3d(${translateValue}px, 0, 0)`,
          transition: `transform 0.45s`,
        }}
        className='cards-wrapper'
      >
        {React.Children.map(children, (el: any, index) => (
          <CardWrapper
            seasonId={seasonId}
            ref={index === 0 ? initSelected : null}
            focusUp={getFocusSelectorUp()}
            focusDown={getFocusSelectorDown()}
            dynamic={dynamic}
            key={`${index}-wrapper`}
            index={index}
            focused={selectCard}
            borderColorFocusOn={borderColorFocusOn}
            keyPressed={onPress()}
            child={el}
            first={index === 0}
            last={index === children.length - 1}
            carouselId={carouselId}
            style={styles}
            isMoveLeft={isMoveLeft}
            isLastCarousel={isLastCarousel}
            carouselTitle={carouselTitle}
            isCitySelectionCard={isCitySelectionCard}
            lastFocusedSeasonItem={lastFocusedSeasonItem}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
