import * as actionTypes from '../actions/types/OTTPage';

export interface Page {
  title?: string;
  componentInstanceId: string;
  content: string;
  displayTitle: string;
  linkType: string;
  pageComponentUuid: string;
  slug: string;
  type: string;
}

export interface PageWithComponents extends Page {
  component: Array<{ item: object }>;
}

export interface PagePayload {
  data: {
    page: Array<Page>,
    hideNav: string,
  };
}
export interface ComponentPayload {
  component: object;
}
export interface ComponentSuccessPayload {
  components: Array<PageWithComponents | Array<PageWithComponents>>;
  dataForPage: Array<Page>;
}

interface LoadPageAction {
  type: typeof actionTypes.LOAD_PAGE;
  url: string;
}

interface LoadPageSucceededAction {
  type: typeof actionTypes.LOAD_PAGE_SUCCEEDED;
  payload: PagePayload;
}

interface LoadPageFailedAction {
  type: typeof actionTypes.LOAD_PAGE_FAILED;
  error: string;
}

interface LoadComponentAction {
  type: typeof actionTypes.LOAD_COMPONENT;
  page: Array<Page>;
}

interface LoadComponentSucceededAction {
  type: typeof actionTypes.LOAD_COMPONENT_SUCCEEDED;
  payload: ComponentSuccessPayload;
}

interface LoadComponentFailedAction {
  type: typeof actionTypes.LOAD_COMPONENT_FAILED;
  error: string;
}

interface ClearOTTPage {
  type: typeof actionTypes.CLEAR_OTTPAGE;
}

type PageAction = LoadPageAction | LoadPageSucceededAction | LoadPageFailedAction;

type ComponentAction = LoadComponentAction | LoadComponentSucceededAction | LoadComponentFailedAction;

export type Action = PageAction | ComponentAction | ClearOTTPage;

export const setPageData = (url: string): Action => ({
  type: actionTypes.LOAD_PAGE,
  url,
});

export const setComponentsData = (page: Array<Page>): Action => ({
  type: actionTypes.LOAD_COMPONENT,
  page,
});
