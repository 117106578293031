import React from 'react';

import Carousel from '../../components/Carousel/Carousel';
import Card from '../../components/Carousel/Card/Card';
import {DEFAULT_SLIDES_VALUE} from '../../constants/structureTypes';
import { CLEENG_RESTRICTION } from '../../constants/text';
import { isContentWithRestriction } from '../../utils';
import placeholder from '../../assets/Stirr/images/placeholder.png';

import './STANDARD_TEASER_LIST.scss';

export type TEASER_LIST_PROPS = {
  content: Array<{ item: object }>;
  index: number;
  title: string;
  onSelect: () => void;
  onPress: () => void;
  isMoveLeft?: boolean;
  isLastCarousel?: boolean;
  slides?: number;
  seasonId?: string;
  defaultCarouselID?: string;
  carouselUUID?: any;
  defaultCardIndex?: number;
  seasonDefaultCarouselID?: string;
};

const STANDARD_TEASER_LIST = (props: TEASER_LIST_PROPS) => {
  const {
    content,
    index,
    title,
    onSelect,
    onPress,
    isMoveLeft,
    isLastCarousel,
    slides,
    seasonId,
    defaultCarouselID,
    defaultCardIndex,
    seasonDefaultCarouselID,
    carouselUUID,
  } = props;

  const cardStyles = {
    width: 400,
    height: 240,
    marginLeft: 25,
    marginRight: 25,
  };

  return (
    <div className="standard-teaser-list">
      <p className="list-title">{title}</p>
      <Carousel
        seasonId={seasonId}
        cardStyles={cardStyles}
        carouselId={index}
        onCardSelection={onSelect}
        onCardPressed={onPress}
        isMoveLeft={isMoveLeft}
        isLastCarousel={isLastCarousel}
        slides={slides || DEFAULT_SLIDES_VALUE}
        carouselTitle={title}
        defaultCarouselID={defaultCarouselID}
        carouselUUID={carouselUUID}
        defaultCardIndex={defaultCardIndex}
        seasonDefaultCarouselID={seasonDefaultCarouselID}
      >
        {content && content.map((item) => (
          <Card
            TCPlusContent={
              isContentWithRestriction(item?.item['media:content']['media:restriction'], CLEENG_RESTRICTION)
            }
            alt={item.item['slug']}
            width={cardStyles.width}
            height={cardStyles.height}
            key={index}
            src={[...item.item['media:content']['media:thumbnail'], {url: placeholder}]}
            title={item.item['media:content']['media:title']['content']}
            isLiveVideo={item.item['media:content']['sinclair:isLiveProgram']}
            duration={item.item['media:content']['duration']}
            cardId={item.item['guid']['content']}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default STANDARD_TEASER_LIST;
