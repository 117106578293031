import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../stores/reducers';
import { OTT_BRAND } from '../../constants/structureTypes';
import { ERROR_LOADING_TEXT } from '../../constants/text';

import './ScrollableText.scss';

interface IScrollableText {
  text: string;
  sendAnalytics: (itemIndex: number) => void;
  isDescriptionTextFocused: boolean;
  itemIndex?: number;
  selected?: number;
  onTextFocus?: () => void;
  onTextBlur?: () => void;
  setIsScrollBarVisible?: Dispatch<SetStateAction<boolean>>
}

const ScrollableText = (props: IScrollableText) => {
  const {
    text,
    itemIndex = 0,
    selected = 0,
    sendAnalytics,
    isDescriptionTextFocused,
    onTextFocus,
    onTextBlur,
    setIsScrollBarVisible,
  } = props;

  const settingsStyles = useSelector((state: RootState) => state.config.styles['settings']);
  const fontFamily = useSelector((state: RootState) => state.config.styles['font']['face']['fontFamily']);

  const scrollableTextStyles = {
    color: settingsStyles['textColor'],
    fontFamily: fontFamily,
    backgroundColor: settingsStyles['about']['contentBackgroundColor'],
  };

  const handleScroll = (e) => {
    !isDescriptionTextFocused && onTextFocus && onTextFocus();

    if (e.target.scrollTop === 0 && onTextBlur) {
      onTextBlur();
    }
  };

  const onFocus = (e) => {
    if (e.target.scrollTop !== 0 && onTextFocus) {
      onTextFocus();
    }
    sendAnalytics(itemIndex);
  };

  useEffect(() => {
    const scrollableText = document.querySelector('.link-text');

    if (scrollableText && selected === itemIndex && setIsScrollBarVisible) {
      setIsScrollBarVisible(scrollableText?.scrollHeight > scrollableText?.clientHeight);
    }
  }, [selected]);

  return (
    <>
      {selected === itemIndex && (
        <div
          className={`link-text settings-key-text ${OTT_BRAND}`}
          tabIndex={0}
          style={scrollableTextStyles}
          onFocus={onFocus}
          onScroll={handleScroll}
          onBlur={onTextBlur}
          data-tv-focus-up={!isDescriptionTextFocused ? '#navigationSelectedItem' : '.settings-key-text'}
          data-tv-focus-left={`.settings-key-${itemIndex}`}
          data-tv-focus-right='.settings-key-text'
          data-tv-focus-down='.settings-key-text'
          data-scroll-enabled={true}
          // eslint-disable-next-line
          role={''}
          aria-label={text}
        >
          <p>{text || ERROR_LOADING_TEXT}</p>
        </div>
      )}
    </>
  );
};

export default ScrollableText;
