import React from 'react';
import { ReactImageTint } from 'react-image-tint';

import spinnerIcon from '../../assets/icons/spinnerIcon.png';

import './Spinner.scss';

const Spinner = () => {
  const styles = JSON.parse(localStorage.getItem('stylesConfig')
    ? localStorage.getItem('stylesConfig') as string
    : '{}');
  const iconColor = styles.topnav?.buttonColorSelect ? styles.topnav.buttonColorSelect : '';

  return (
    <div className="spinner">
      <div className="spinnerIcon">
        <ReactImageTint src={spinnerIcon} color={`${iconColor}`} alt="spinner-icon" />
      </div>
    </div>
  );
};

export default Spinner;
