import axiosInstance from './axiosInstance';
import { config } from '../config';

const TITLE_LEVEL_REPORT_KEY = 'AIzaSyA61KBeBCSGWQPIsnIpt7FCA6suFI-r9zg';

interface IAxiosHeaders {
  'Content-Type': string;
  'X-Api-Key': string;
}

interface ITitleLevelReportInfo {
  content_type: string,
  drm_required: boolean,
  event_type: string,
  metadata: {
    network: string,
    asset_title: string,
    season: string,
    episode_name: string,
    series_name: string,
    asset_uuid: string,
  },
  platform: string,
  subscription_required: string,
  timestamp: string,
  watch_duration: string,
}
export interface ITitleLevelReportPayload {
  url: string;
  isDrm: boolean;
  eventType: string;
  asset_title: string;
  seasonTitle: string;
  series_name: string;
  asset_uuid: string;
  watch_duration: number;
}

export async function setTitleLevelReport(payload: ITitleLevelReportPayload): Promise<any> {

  const data: ITitleLevelReportInfo = {
    content_type: 'VOD',
    drm_required: payload.isDrm,
    event_type: payload.eventType, // 'play|pause',
    metadata: {
      network: config.brand,
      asset_title: payload.asset_title,
      season: payload.seasonTitle,
      episode_name: '', // for now we don't pass any data here
      series_name: payload.series_name || '',
      asset_uuid: payload.asset_uuid,
    },
    platform: config.appPlatform,
    subscription_required: `${config.brand !== 'stirr'}`, // 'false for stirr',
    timestamp: `${Math.floor(Date.now() / 1000)}`,
    watch_duration: `${payload.watch_duration || 0}`,
  };

  const axiosHeaders: IAxiosHeaders = {
    'Content-Type': 'application/json',
    'X-Api-Key': TITLE_LEVEL_REPORT_KEY,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const titleLevelReportResponse = await axiosInstance.post(payload.url, data, {
    headers: axiosHeaders,
  });

  return titleLevelReportResponse;
}
