import FocusService from '../services/focusService';
import { navigateBack } from '../services/NavigationService';

export const getKeyInfo = (keyNumber: number) => {
  let key = '';

  switch (keyNumber) {
    case 37: // LeftArrow
    case 214: // GamepadLeftThumbstickLeft
    case 205: // GamepadDPadLeft
    case 140: // NavigationLeft
      key = 'Left';
      break;
    case 39: // RightArrow
    case 213: // GamepadLeftThumbstickRight
    case 206: // GamepadDPadRight
    case 141: // NavigationRight
      key = 'Right';
      break;
    case 38: // UpArrow
    case 211: // GamepadLeftThumbstickUp
    case 203: // GamepadDPadUp
    case 138: // NavigationUp
      key = 'Up';
      break;
    case 40: // UpArrow
    case 212: // GamepadLeftThumbstickDown
    case 204: // GamepadDPadDown
    case 139: // NavigationDown
      key = 'Down';
      break;
    case 13:
    case 195: // Enter
      key = 'Enter';
      break;
    case 196:
    case 10009:
    case 65385:
    // case 8: // uncomment for WebBrowser Debug
      key = 'Back';
      break;
    case 229:
      key = 'Cancel';
      break;
    case 8: // comment for WebBrowser Debug
      key = 'Backspace'; // comment for WebBrowser Debug
      break; // comment for WebBrowser Debug
    case 65376:
      key = 'Done';
      break;
    case 207:
      key = 'Info';
      break;
    case 412:
    case 227: // firetv
      key = 'MediaRewind';
      break;
    case 415:
      key = 'MediaPlay';
      break;
    case 19:
      key = 'MediaPause';
      break;
    case 417:
    case 228: // firetv
      key = 'MediaFastForward';
      break;
    case 10252: // tizen
    case 179: // firetv
      key = 'MediaPlayPause';
      break;
    case 413:
      key = 'MediaStop';
      break;
    case 1457:// only firetv. custom keyCode
      key = 'Menu';
      break;
    default:
      // No-op
      break;
  }

  return key;
};

export function onEnterHandler(ev: any, callback: any) {
  const keyInfo = getKeyInfo(ev.keyCode);

  if (keyInfo === 'Enter' && callback) {
    callback();
  }
}

function stopKeyFunc(ev, callback, history) {
  const keyInfo = getKeyInfo(ev.keyCode);

  if (keyInfo === 'MediaStop') {
    callback(history);
  }
}

export function onStopHandler(ev: any, callback: () => void, history: any) {
  stopKeyFunc(ev, callback, history);
}

export function onBackHandler(ev: any, callback: any, args?: any) {
  // Condition for dev work in Chrome
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  const keyInfo = getKeyInfo(ev.keyCode);

  if (keyInfo === 'Back' || (isChrome && ev.keyCode === 8)) {
    callback(args);
  }
}

export const backHandlerForFirstLevelPage = (history: any, shouldGoToNav: boolean, callback?: any) => {
  const focusedNawItem = document.querySelector('.menu-item:focus');

  (shouldGoToNav && !focusedNawItem)
    ? FocusService.setFocusToNavbar()
    : navigateBack(history, callback, !!focusedNawItem);
};

export function getComponents(data, seasons) {
  const seasonGrids: number[] = [];

  data.forEach((item, index) => {
    seasons.forEach((elem) => {
      if (elem === index) {
        seasonGrids.push(item);
      }
    });
  });
  seasons.forEach((elem) => {
    data.splice(elem, 1);
    seasons.forEach((elem) => {
      data.splice(elem, 1);
    });
  });

  const components = [...data];

  if (seasonGrids.length) {
    components.push(seasonGrids);
  }

  return components;
}

export function isTokenExpired(tokenDate: string | number): boolean {
  const expirationTime = typeof tokenDate === 'string' ? parseInt(tokenDate, 10) : tokenDate;

  return !(
    !isNaN(expirationTime) &&
    (expirationTime - Date.now() - 10000) > 0 &&
    expirationTime < Date.now() + 3600000 // token expiration time = not more than current time + 1hour;
  );
}

export function mvpdLoginRequired(status: string): boolean {
  return /mvpd/i.test(status);
}

export function tennisPlusLoginRequired(status: string): boolean {
  return /cleeng/i.test(status);
}

export function isSubscriptionCard(status: string): boolean {
  return /subscription/i.test(status);
}

export function isProviderCard(status: string): boolean {
  return /provider/i.test(status);
}

export function isCitySelectionCard(status: string): boolean {
  return /city.select/i.test(status);
}

export function isAboutCard(status: string): boolean {
  return /about/i.test(status);
}

export const splitTextByUrls = (input:string):Array<{text:string, isUrl:boolean}> => {
  const regex = /(https?:\/\/(?:www\.|(?!www))[^\s\\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;

  return input?.split(regex)?.map(text => ({ text, isUrl: regex.test(text)}));
};

export const isContentWithRestriction = (restrictions: any, stringForPattern): boolean => {
  if (restrictions && restrictions.length) {
    const patternForRegExp = new RegExp(`${stringForPattern}`, 'i');

    return restrictions.some(({content}) => patternForRegExp.test(content));
  }

  return false;
};
