import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import focusService from '../../../services/focusService';
import { getKeyInfo } from '../../../utils';
import { getProperImage } from '../../../utils/commonFunc';
import NoImage from '../../../assets/images/placeholder.png';
import { playerAction } from '../../../stores/actions/player';
import { RootState } from '../../../stores/reducers';

import './RecommendedPlayback.scss';

type RecommendedPlaybackProps = {
  nextPlaybackItem: any;
  onPlaybackFinished: () => void;
  isSeekingToRight: (boolean) => void;
  hasToBeShown: boolean;
  timeLeft: number;
}

const RecommendedPlayback = (props: RecommendedPlaybackProps) => {
  const {
    nextPlaybackItem,
    onPlaybackFinished,
    isSeekingToRight,
    hasToBeShown,
    timeLeft,
  } = props;

  const borderColor = useSelector((state: RootState) => state.config.styles['colors']['accents']['primary']);

  const [optimizedImage, setOptimizedImage] = useState(
    getProperImage(nextPlaybackItem.item['media:content']['media:thumbnail'], 420, 236)
  );
  const dispatch = useDispatch();

  const recommendPlaybackRef = useRef<any>(null);
  const timeForAnimationToEnd = useRef<any>(null);

  useEffect(() => {
    if (hasToBeShown) {
      isSeekingToRight(false);
      timeForAnimationToEnd.current = setTimeout(() => {
        focusService.setFocus(recommendPlaybackRef.current);
      }, 1000);
    }

    return () => {
      timeForAnimationToEnd.current && clearTimeout(timeForAnimationToEnd.current);
    };
  }, [hasToBeShown]);  // eslint-disable-line

  const titleText = `Up Next in ${timeLeft} Second${timeLeft === 1 ? '' : 's'}`;
  const description = nextPlaybackItem.item['media:content']['media:title']['content'];

  const getImageUrl = () => {
    setOptimizedImage({ url: NoImage });
  };

  const onPress = (event: React.KeyboardEvent) => {
    if (getKeyInfo(event.keyCode) === 'Enter') {
      dispatch(playerAction('close'));
      setTimeout(() => {
        onPlaybackFinished();
      }, 100);
    }
  };

  const onUpNextItemGetFocus = (e) => {
    e.target.querySelector('.image').style.border = `3px solid ${borderColor}`;
  };

  const onUpNextItemGetBlur = (e) => {
    e.target.querySelector('.image').style.border = 'none';
  };

  return (
    <div
      className={`recommended-playback${hasToBeShown ? '' : '--hidden'}`}
    >
      <div
        className='recommended-playback-container'
        tabIndex={0}
        data-tv-focus-up='.recommended-playback-container'
        data-tv-focus-right='.recommended-playback-container'
        data-tv-focus-down='.progress-bar-container'
        data-tv-focus-left='.play-pause-container'
        onKeyDown={onPress}
        onFocus={onUpNextItemGetFocus}
        onBlur={onUpNextItemGetBlur}
        aria-label={`${titleText}. ${description}`}
        // eslint-disable-next-line
        role={''}
        ref={recommendPlaybackRef}
      >
        <div className='recommended-playback-content'>
          <p className='title'>{titleText}</p>
          <img
            src={optimizedImage?.url}
            className='image'
            alt='Recommended content'
            role="presentation"
            onError={getImageUrl}
          />
          <p className='description'>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default RecommendedPlayback;
