import * as actionTypes from '../actions/types/cleengService';
import { CleengActions } from '../actions/cleengService';
import { ILoginError } from '../actions/Login';

interface ICleengServiceState {
  isFetched: boolean;
  isDataRefreshed: boolean;
  hasError: boolean;
  error: ILoginError;
  isLoading: boolean;
  purchaseOptions: any;
  activeSubscriptions: any;
  allSubscriptions: any;
  currentSubscriptionDetails: any;
  isCurrentSubscriptionActive: boolean;
}

const initialState: ICleengServiceState = {
  isFetched: false,
  isDataRefreshed: false,
  hasError: false,
  error: {
    errorCode: '',
    details: null,
  },
  isLoading: false,
  purchaseOptions: [],
  activeSubscriptions: [],
  allSubscriptions: [],
  currentSubscriptionDetails: [],
  isCurrentSubscriptionActive: false,
};

const cleengService = (state = initialState, action: CleengActions) => {
  switch (action.type) {
    case actionTypes.LOAD_PURCHASE_DATA:
      return {
        ...state,
        isLoading: true,
        isFetched: false,
        hasError: false,
        isDataRefreshed: false,
      };
    case actionTypes.LOAD_PURCHASE_DATA_SUCCEEDED:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isFetched: true,
        isDataRefreshed: action.payload.isRefresh,
        purchaseOptions: action.payload.purchaseOptions,
        activeSubscriptions: action.payload.activeSubscriptions,
        allSubscriptions: action.payload.allSubscriptions,
        currentSubscriptionDetails: action.payload.currentSubscriptionDetails,
        isCurrentSubscriptionActive: action.payload.isCurrentSubscriptionActive,
      };
    case actionTypes.LOAD_PURCHASE_DATA_FAILED:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isFetched: false,
        isDataRefreshed: false,
        error: action.error,
      };

    case actionTypes.RESET_REFRESH_DATA_STATE:
      return {
        ...state,
        isDataRefreshed: false,
      };

    case actionTypes.CLEAR_PURCHASE_DATA:
      return {
        ...initialState,
        purchaseOptions: state.purchaseOptions,
      };

    default:
      return state;
  }
};

export default cleengService;
