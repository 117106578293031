import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { AvPlayer } from './AvplayPlayer';
import { RootState } from '../../../stores/reducers';
import { PLAYER_CHECK_POSITION_INTERVAL } from '../../../constants/videoPlayer';

const AVPlayWrapper = ({
  onBufferingComplete,
  onCurrentPlaytime,
  onDrmEvent,
  onError,
  onStreamCompleted,
  onSubtitleChange,
  onSuccessLoad,
  onFailedLoad,
  seekToSuccessCb,
  checkPlayStatePosition,
  cleanUpCb,
  onPausedBeforeAd,
  drm,
  children,
}: any) => {

  const currentPlayerAction = useSelector((state: RootState) => state.player.playerAction);
  const playerUrl = useSelector((state: RootState) => state.player.url);
  const pointOfTime = useSelector((state: RootState) => state.player.pointOfTime);

  const prevPlayerActionName = useRef<string>('');
  const timeoutForRestore = useRef<any>(null);
  const positionChangeInterval = useRef<any>(null);

  const cbsForListeners = [
    ['onbufferingcomplete', onBufferingComplete],
    ['oncurrentplaytime', onCurrentPlaytime],
    ['onstreamcompleted', onStreamCompleted],
    ['ondrmevent', onDrmEvent],
    ['onerror', onError],
    ['onsubtitlechange', onSubtitleChange],
  ];

  const launchTimePositionTracker = () => {
    clearInterval(positionChangeInterval.current);
    positionChangeInterval.current = setInterval(() => {
      const playerState = AvPlayer.getState();

      if (['PAUSED'].includes(playerState)) {
        return;
      }

      checkPlayStatePosition(AvPlayer.getCurrentTime());
    }, PLAYER_CHECK_POSITION_INTERVAL);
  };

  const onSuccessLoadWrapper = () => {
    const duration = AvPlayer.getDuration();
    const videoBandWith = AvPlayer.getVideoBandwidth();

    launchTimePositionTracker();

    onSuccessLoad && onSuccessLoad(videoBandWith, duration);
  };

  useEffect(() => {
    // cleanup for the AvPlay on unmount
    return () => {
      clearInterval(positionChangeInterval.current);
      if (AvPlayer.getPlayerIsLive()) {
        AvPlayer.close();
      }
    };
  }, []);

  useEffect(() => {

    function isDeniedCondition() {

      const isRestrictedActionOnLoad = ['destroyDaiStream', 'close'].includes(currentPlayerAction);

      return !currentPlayerAction || (!prevPlayerActionName.current && isRestrictedActionOnLoad);
    }

    if (isDeniedCondition()) {
      return;
    }

    const getPlayerCb = () => {
      const playerCb = {
        initialize: () => {
          AvPlayer.initialize();
        },
        play: () => {
          AvPlayer.play();
        },
        pause: () => {
          AvPlayer.pause();
        },
        suspend: () => {
          AvPlayer.suspend();
        },
        restore: () => {
          AvPlayer.restore();
          timeoutForRestore.current = setTimeout(() => {
            try {
              AvPlayer.play();
            } catch (e) {
              console.log('Player play Error:', e);
            }
          }, 800);
        },
        seek: () => {
          AvPlayer.seekTo(pointOfTime, seekToSuccessCb);
          AvPlayer.play();
        },
        openVOD: () => {
          AvPlayer.open(playerUrl);
          if (drm?.AuthXML && AvPlayer.setDrm) {
            AvPlayer.setDrm({ LicenseServer: drm?.PRserver, CustomData: drm?.AuthXML });
          }
          AvPlayer.prepare(onSuccessLoadWrapper, onFailedLoad, cbsForListeners);
        },
        cleanUp: () => {
          cleanUpCb(AvPlayer.getCurrentTime(), AvPlayer.getDuration());
        },
        close: () => {
          AvPlayer.close();
        },
        pauseBeforeAd: () => {
          onPausedBeforeAd(AvPlayer.getCurrentTime());
          AvPlayer.pause();
          AvPlayer.suspend();
        },
      };

      if (typeof playerCb?.[currentPlayerAction] === 'function') {
        return playerCb?.[currentPlayerAction]();
      }

      return;
    };

    const checkConditionForExecution = () => {

      const isAllowedRepeatedAction = ['seek'].includes(currentPlayerAction);

      return ((currentPlayerAction !== prevPlayerActionName.current) || isAllowedRepeatedAction);
    };

    if (checkConditionForExecution()) {
      if (timeoutForRestore.current) {
        clearTimeout(timeoutForRestore.current);
        timeoutForRestore.current = null;
      }

      getPlayerCb();
      prevPlayerActionName.current = currentPlayerAction;
    }
    // eslint-disable-next-line
  }, [currentPlayerAction]);

  return <>{children}</>;
};

export default AVPlayWrapper;
