// ERROR TEXTS
export const ERROR_RESTART_APP = 'An error has occurred. Please press the button to restart the application.';
export const ERROR_LOADING_SEASON_EPISODES =
  'The content doesn\'t appear to be loading. Please try another season or category.';
export const DEFAULT_SUBSCRIPTION_ERROR_MESSAGE = 'Please come back and try again later.';
export const ERROR_LOADING_TEXT = 'This text failed to load. Please try again later.';

// LIVE
export const LINEAR_LIVE = 'linear';
export const LIVE = 'LIVE';
export const SCHEDULE = 'SCHEDULE';
export const OPEN_SCHEDULE = 'OPEN SCHEDULE';
export const OVERLAY_PRE_PHRASE_STIRR = 'Guide';
export const OVERLAY_PRE_PHRASE_TC = 'Open Guide button 1 of 1';
export const OVERLAY_PRE_PHRASE_COMET_CHARGE = 'Open schedule button 1 of 1';
export const NEXT_DAY_SCHEDULE = 'press down to see schedule for the next day';
export const TODAY_SCHEDULE = 'press up to see schedule for today';

// MVPD
export const MVPD_RESTRICTION = 'mvpd';
export const MVPD = 'MVPD';
export const MVPD_SELECT_PROVIDER_BTN_TEXT = 'SELECT PROVIDER';
export const MVPD_SIGN_OUT_BTN_TEXT = 'SIGN OUT OF TV PROVIDER';
export const MVPD_SIGN_IN_BTN_TEXT = 'SIGN IN TO CABLE PROVIDER';
export const AUTHZ_ERR_TEXT = 'Authorization Error';

// NETWORK ERROR
export const NETWORK_ERROR_HEADER = 'Network Error';
export const NETWORK_ERROR_TEXT = `Something appears to be wrong with your network connection.
Please make sure you are connected and try again.`;

// TC PLUS / Marquee
export const LOGIN = 'Login';
export const SIGN_IN = 'SIGN IN';
export const SIGN_OUT = 'SIGN OUT';
export const TC_PLUS_PAYWALL_SIGN_IN_BTN = 'SIGN INTO TC PLUS';
export const TC_PLUS_FORM_SIGN_IN_BTN = 'ALREADY HAVE AN ACCOUNT?';
export const TC_PLUS_PAYWALL_SIGN_OUT_BTN = 'SIGN OUT OF TC PLUS';
export const TC_PLUS_MARKETING_PAGE_SIGN_OUT_BTN = 'SIGN OUT';
export const SIGN_UP_FORM_SUBMIT_BTN = 'CONTINUE TO PAYMENT';
export const SIGN_IN_FORM_SUBMIT_BTN = 'CONTINUE';
export const TC_PLUS_RESET_PASSWORD_BTN_TEXT = 'RESET TC PLUS PASSWORD';
export const SUCCESSFUL_PAYMENT_BTN_TEXT = 'EXPLORE TC PLUS';
export const SUCCESSFUL_PAYMENT_FROM_PLAYBACK_BTN_TEXT = 'WATCH NOW';
export const BACK_TO_GUIDE_BTN_TEXT = 'BACK TO GUIDE';
export const BACK_BTN_TEXT = 'GO BACK';
export const SUBSCRIBE = 'SUBSCRIBE';
export const PLUS = 'PLUS';
export const USER_NAME_TAG = 'userName';
export const CLEENG_RESTRICTION = 'cleeng';
export const RESET_PASSWORD = 'RESET PASSWORD';
export const SUCCESS_PAYMENT = 'SUCCESS PAYMENT SCREEN';
export const PLUS_PAYWALL_SCREEN = 'PLUS PAYWALL SCREEN';
export const TERMS_OF_USE_SCREEN = 'TERMS OF USE';
export const PRIVACY_POLICY_SCREEN = 'PRIVACY POLICY';
export const FORGOT_PASSWORD = 'FORGOT PASSWORD';
export const USER_NOT_LOGIN_ERROR_TEXT = `You must sign in to your Samsung account to get subscription.
Please sign in and try again`;
export const USER_NOT_LOGIN_ERROR_TEXT_FIRE_TV = `You must sign in to your Amazon account to get subscription.
Please sign in and try again`;

// SEARCH
export const SEARCH_INIT_TEXT_STIRR = 'Search STIRR for latest news, sports, movies and more';
export const SEARCH_INIT_TEXT_TC = 'Search Tennis Channel for players, matches, shows and more. ';

// MODALS
export const DEBUG_MODAL_TITLE = 'You are trying to access debug screen';
export const DEBUG_MODAL_DESCRIPTION = 'To enter debug screen, please enter the secret word';
export const NETWORK_STATUS_MODAL_TITLE = 'Network Error';
export const NETWORK_STATUS_MODAL_DESCRIPTION = `Something appears to be wrong with your network connection.
Please make sure you are connected and try again.`;
export const EXIT_MODAL_TITLE = 'Are you sure you want to exit?';

// DEBUG SCREEN
export const SAVE_AND_RELOAD_BUTTON_TITLE = 'SAVE AND RELOAD';
export const RESTORE_TO_DEFAULTS_BUTTON_TITLE = 'RESTORE TO DEFAULTS';

// OTHER
export const BANNER_TEXT = 'We\'re setting up your local channels just a sec';
export const LINEAR_VOD = 'VOD';
export const TTS_TEXT_TO_ANNOUNCE_LOADING = 'Loading.';
export const CITY_SELECTION_TAG = 'citySelection';
