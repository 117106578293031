import axiosInstance from './axiosInstance';
import { config } from '../config';
import store from './../stores/index';
import { IConfigPayload } from '../stores/actions/config';
import { parseObject } from '../utils/style/styleHelper';
import { convertFeatures } from '../utils/commonFunc';
import { addParametersToURL } from '../utils/commonFunc';
import { OTT_BRAND } from './../constants/structureTypes';

export const getNavigationConfig = async (configData) => {
  let urlForNav;

  if (configData.API.features['city_auto_select']) {
    urlForNav = configData.API['nav_city_auto_select'];
  } else {
    urlForNav = configData.API.nav;
  }

  const state: any = store.getState();
  const { station, city } = state.common;

  urlForNav = addParametersToURL(urlForNav, { station, city });

  const navigationResponse = await axiosInstance.get(urlForNav);

  return navigationResponse.data.navigation;
};

export async function getConfigData(): Promise<IConfigPayload> {
  const configResponse = await axiosInstance.get(config.baseURL);
  const configData = configResponse.data;
  const stylesData = parseObject(configData.API.theme);
  const GA_id = configData.API.GA_id;
  const mvpd = {
    mvpd_text: configData.API['mvpd'],
    mvpd_regcode: configData.API['mvpd_regcode'],
    mvpd_auth: configData.API['mvpd_auth'],
    mvpd_checkauthn: configData.API['mvpd_checkauthn'],
    mvpd_url: configData.API['mvpd_url'],
    mvpd_requestorID: configData.API['mvpd_requestorID'],
    mvpd_logout: configData.API['mvpd_logout'],
    mvpdProvidersLogos: `${configData.API['mvpd_channelDetails']}/${configData.API['mvpd_requestorID']}.json`,
  };
  const features = convertFeatures(configData.API['features']);
  // When branch tc_tizen_dev will be merging into develop,
  // this field below should be changed in config from
  // 'edenContent' to 'preview_content' for Stirr app
  const edenContent = configData.API?.preview_content || configData.API?.edenContent || '';

  const players = configData.API?.players;
  const debugSettings = configData.API?.debug;
  const postUrlsPrefixArr = configData.API?.postURLprefix || [];
  const settings = configData.API['settings'] || {};

  const subscription = {
    firebase_createUser: configData.API['firebase_createUser'] || '',
    firebase_authUser: configData.API['firebase_authUser'] || '',
    firebase_resetPassword: configData.API['firebase_resetPassword'] || '',
    firebase_refreshToken: configData.API['firebase_refreshToken'] || '',
    cleeng_registerCustomer: configData.API['cleeng_registerCustomer'] || '',
    cleeng_generateCustomerToken: configData.API['cleeng_generateCustomerToken'] || '',
    cleeng_getPurchaseOption: configData.API['cleeng_getPurchaseOption'] || '',
    cleeng_receipt: configData.API['cleeng_receipt'] || '',
    cleeng_getAccessStatus: configData.API['cleeng_getAccessStatus'] || '',
    cleeng_listCustomerSubscriptions: configData.API['cleeng_listCustomerSubscriptions'] || '',
    cleeng_entitlements: configData.API['cleeng_entitlements'] || '',
    subscription_promo_slider_slides: configData.API?.['subscribe_promo']?.['slides'],
    paywall_title: configData.API?.subscribe?.['paywall_title'] || '',
    paywall_detail: configData.API?.subscribe?.['paywall_detail'] || '',
    payment_success_title: configData.API?.subscribe?.['payment_success_title'] || '',
    payment_success_detail: configData.API?.subscribe?.['payment_success_detail'] || '',
    title_signed_in: configData.API?.settings?.subscription?.['title_signed_in'] || '',
    detail_signed_in: configData.API?.settings?.subscription?.['detail_signed_in'] || '',
    title_signed_out: configData.API?.settings?.subscription?.['title_signed_out'] || '',
    detail_signed_out: configData.API?.settings?.subscription?.['detail_signed_out'] || '',
    subscribe_tos: configData.API?.subscribe?.['subscribe_tos'] || '',
    subscriptionOptions: configData.API?.subscribe?.['subscriptionOptions'] || [],
    reset_password_title: configData.API?.account?.['reset_password_title'] || '',
    reset_password_detail: configData.API?.account?.['reset_password_detail'] || '',
    reset_password_success_title: configData.API?.account?.['reset_password_success_title'] || '',
    reset_password_success_detail: configData.API?.account?.['reset_password_success_detail'] || '',
    reset_password_failure_title: configData.API?.account?.['reset_password_failure_title'] || '',
    sign_in_error_message: configData.API?.account?.['sign_in_error_message'] || '',
    sign_in_title: configData.API?.account?.['sign_in_title'] || '',
    sign_up_title: configData.API?.account?.['sign_up_title'] || '',
    sign_up_detail: configData.API?.account?.['sign_up_detail'] || '',
    payment_gateways: configData.API?.subscribe?.['payment_gateways'] || [],
    terms_of_use_title: configData.API?.additional_pages?.['terms_of_use']?.title || '',
    terms_of_use_text: configData.API?.additional_pages?.['terms_of_use']?.text || '',
    privacy_policy_title: configData.API?.additional_pages?.['privacy_policy']?.title || '',
    privacy_policy_text: configData.API?.additional_pages?.['privacy_policy']?.text || '',
    newsletter_url: configData.API?.account?.['api']?.['newsletter'] || '',
    newsletter_checkbox_options: configData.API?.account?.['sign_up_options']?.[0],
    tos_checkbox_options: configData.API?.account?.['sign_up_options']?.[1],
  };

  localStorage.setItem('stylesConfig', JSON.stringify(stylesData));
  localStorage.setItem('config', JSON.stringify(configData));
  localStorage.setItem(`${OTT_BRAND}_GA_id`, GA_id);

  return {
    configData,
    stylesData,
    GA_id,
    mvpd,
    players,
    debugSettings,
    postUrlsPrefixArr,
    subscription,
    features,
    edenContent,
    settings,
  };
}
