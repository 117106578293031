import React from 'react';

import './ChevronArrow.scss';

type ChevronArrowProps = {
  top?: number;
  bottom?: number;
  left?: number;
  animationColor?: string;
  rotateValue?: number;
  color?: () => string;
  isOverlayArrow: boolean;
  isAnimated?: boolean;
}

const ChevronArrow = (props: ChevronArrowProps) => (
  <div className={`arrow-container${props.isAnimated ? '-animation' : ''}`}
    style={{
      top: `${props.top}px`,
      bottom: `${props.bottom}px`,
      left: `${props.left}px`,
    }}
  >
    {props.isOverlayArrow
      ? (<svg
        width="25"
        height="22"
        viewBox="0 0 25 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: `rotate(${props.rotateValue}deg)` }}
      >
        <path
          className=''
          // eslint-disable-next-line max-len
          d="M12.4596 22C13.0235 22 13.3956 21.646 13.6392 21.23L24.8523 2.154C25 2 25 1.694 25 1.384C25 0.308001 24.2615 0 23.6727 0L1.39226 0C0.655712 0 0.0649414 0.308001 0.0649414 1.384C0.0649414 1.692 0.0649414 1.846 0.212633 2.154L11.28 21.23C11.5178 21.646 11.9781 22 12.4577 22H12.4596Z"
          fill={props.isAnimated ? props.animationColor : 'white'}
        />
      </svg>)
      : (<svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        width="30px"
        height="30px"
        style={{ transform: `rotate(${props.rotateValue}deg)` }}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 1200 1200"
      >
        <path
        // eslint-disable-next-line max-len
          d="M600.006 989.352l178.709-178.709L1200 389.357l-178.732-178.709L600.006 631.91L178.721 210.648L0 389.369l421.262 421.262l178.721 178.721h.023z"
          fill={(props.color && props.color()) || 'white'}
        />
      </svg>)
    }
  </div>
);

export default ChevronArrow;
