// https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference
// https://developer-tools.jwplayer.com/player-event-inspector/
import { IPlayer } from '../InterfacesAndTypes';
import { jwPlayerConfig } from './jwPlayerConfig';
import { getMilliseconds } from '../../../utils/time';

declare const jwplayer: any;

const JW_KEY = 'ELV0H0ulZ2oLUQLdRI4OT58lerTulbbSVJAj9lsRG1lndyVQ';

export const jwPlayer: IPlayer = {
  player: null,
  playerEl: null,
  playerName: 'jwplayer',
  state: 'NONE',

  states: {
    none: 'NONE',
    idle: 'IDLE',
    buffering: 'BUFFERING',
    ready: 'READY',
    playing: 'PLAYING',
    pause: 'PAUSED',
  },

  initialize() {
    if (typeof jwplayer.key === 'undefined') {
      jwplayer.key = JW_KEY;
    }
    this.player = jwplayer('jwPlayer');
  },

  prepare(sc, ec, cbsForListeners, cbsForVideoEl): void {
    this.playerEl = document.getElementById('jwPlayer');

    // set listeners for ref Element
    if (this.playerEl && cbsForVideoEl?.length) {
      cbsForVideoEl.forEach(([type, Cb]) => this.playerEl.addEventListener(type, Cb));
    }

    // set listeners for player
    this.setListeners(cbsForListeners);
  },

  open(url, sc, ec, arrCbs): void {
    this.player.setup({
      ...jwPlayerConfig,
      'playlist': url,
    });

    if (arrCbs?.length) {
      arrCbs.forEach(([type, cb]) => this.playerEl?.addEventListener(type, cb));
    }
  },

  play() {
    this.player?.play();
    this.state = this.states.playing;
  },

  pause() {
    this.player?.pause();
    this.state = this.states.pause;
  },

  stop() {
    this.player?.stop();
  },

  seekTo(milliseconds, sc?, ec?) {
    const successCallback = sc || (() => true);
    const errorCallback = ec || (() => false);
    const secondsFixed = milliseconds / 1000;

    try {
      this.player?.seek(+secondsFixed.toFixed(6));
      successCallback();
    } catch (err) {
      errorCallback();
      console.log(err);
    }
  },

  close(): void {
    this.player?.remove();
    this.player = null;
  },

  suspend() {
    this.player?.pause();
    this.state = this.states.pause;
  },

  restore() {
    this.player?.play();
    this.state = this.states.playing;
  },

  setListeners(listenersArr): void {
    listenersArr.forEach(([key, cb]) => this.player?.on(key, cb));
  },

  // eslint-disable-next-line
  showCaptions(neededCCTrack, setCurrentCueCb, isCaptionsEnabled): void {
    if (isCaptionsEnabled) {
      this.player?.setCurrentCaptions(1);
    } else {
      this.player?.setCurrentCaptions(0);
    }
  },

  getVideoBandwidth() {
    const videoBandwidth = localStorage.getItem('jwplayer.bandwidthEstimate');

    if (typeof videoBandwidth === 'string') {
      const parsedBandwidth = parseInt(videoBandwidth, 10);

      if (isNaN(parsedBandwidth)) {
        return null;
      }

      return `${parsedBandwidth}`;
    }

    return null;
  },

  setState(state): any {
    this.state = state;
  },

  getState(): any {
    return this.player?.getState();
  },

  getPlayerIsLive(): any {
    return !!this.player;
  },

  getDuration() {
    const currentDurationInSec = this.player?.getDuration();

    return getMilliseconds(currentDurationInSec);
  },

  getCurrentTime() {
    const currentTimeInSec = this.player?.getPosition();

    return getMilliseconds(currentTimeInSec);
  },

  getPlayerName() {
    return this.playerName;
  },
};
