import { TITLE_LEVEL_REPORT, TITLE_LEVEL_REPORT_FAILED, TITLE_LEVEL_REPORT_SUCCEEDED } from './types/titleLevelReport';
import { ITitleLevelReportPayload } from '../../services/titleLevelReport';

export interface ILoadTitleLevelReport {
  type: typeof TITLE_LEVEL_REPORT;
  payload: ITitleLevelReportPayload;
}
export interface ILoadTitleLevelReportSucceededAction {
  type: typeof TITLE_LEVEL_REPORT_SUCCEEDED;
}

export interface ILoadTitleLevelReportFailedAction {
  type: typeof TITLE_LEVEL_REPORT_FAILED;
}

export type ActionTitleLevelReport =
  | ILoadTitleLevelReport
  | ILoadTitleLevelReportSucceededAction
  | ILoadTitleLevelReportFailedAction;

export const sendTitleLevelReport = (payload: ITitleLevelReportPayload): ActionTitleLevelReport => ({
  type: TITLE_LEVEL_REPORT,
  payload,
});
