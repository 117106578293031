export const SEEK_INTERVAL = 10000; // in milliseconds
export const HIDE_OVERLAY_TIMEOUT = 7000; // in milliseconds
export const EPG_CLOSE_TIMER_SEC = 10; // in seconds;
export const REFRESH_INTERVAL = 600000; // in milliseconds (10 minutes)
export const CHECK_REFRESH_INTERVAL = 30000; // in milliseconds (30 seconds)
export const BUFFER_TIME = 3000; // in milliseconds
export const FAST_SEEK_TIMER_INTERVAL = 500; // in milliseconds
export const MAX_FAST_SEEK_INTERVAL_SPEED = 32;
export const FAST_SEEK_MULTIPLIER = 2;
export const SECONDS_TO_THROW_ERROR = 7;
export const EXTREMELY_LONG_BUFFERING_TIMEOUT = 30000;
export const PLAYER_CHECK_POSITION_INTERVAL = 60000;

export const PLAYER_WIDTH = 1920;
export const PLAYER_HEIGHT = 1080;
