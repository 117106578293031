import axiosInstance from './axiosInstance';
import { SuggestionsPayload } from '../stores/actions/OTTSearch';
import { addParametersToURL } from '../utils/commonFunc';

export async function getSuggestionsData(url: string, query: string): Promise<SuggestionsPayload> {
  const {
    data: {
      suggestions,
    },
  } = await axiosInstance.get(addParametersToURL(url, {query}));

  return {
    suggestions,
  };
}

export async function getPageData(url: string) {
  const {
    data: {
      page,
    },
  } = await axiosInstance.get(url);

  return page;
}

export async function getComponentContent(url: string, station: string) {
  const {
    data: {
      rss: {
        channel: {
          pagecomponent: { component },
        },
      },
    },
  } = await axiosInstance.get(addParametersToURL(url, {station}));

  return {
    component,
  };
}
