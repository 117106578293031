import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CLEAR_OTT_SETTINGS } from '../../stores/actions/types/OTTSettings';
import { getSettingsPageData, setPageComponentsData } from '../../stores/actions/OTTSettings';
import { RootState } from '../../stores/reducers';
import { INavigationItem } from '../../stores/reducers/config';
import { backHandlerForFirstLevelPage, onBackHandler } from '../../utils';
import { isEqualArrays, isRefreshedPage } from '../../utils/commonFunc';
import useEventListener from '../../hooks/eventListener';
import { GAScreen } from '../../services/analytics';
import { useAnnouncePageLoading } from '../../services/TTSService';
import FocusService from '../../services/focusService';
import { isStirrTcMarqueeBrand } from '../../constants/structureTypes';
import { SETTINGS } from '../../constants/analyticsTypes';
import { navigateBack } from '../../services/NavigationService';

import NavigationMenu from '../../components/NavigationMenu/NavigationMenu';
import AccountSettings from '../../components/Settings/AccountSettings/AccountSettings';
import CometChargeSettings from '../../components/Settings/CometChargeSettings/CometChargeSettings';
import Spinner from '../../components/Spinner/Spinner';
import DebugSettingsModal from '../../components/Modals/DebugSettingsModal/DebugSettingsModal';
import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';

import './OTTSettings.scss';

type OTTSettingProps = {
  location: {
    state: {
      configData: {
        path: string;
        children: Array<INavigationItem>;
        title?: string;
        type?: string;
        GAPath: string;
      },
      lastData: {
        lastFocusedElement: string;
        defaultTranslateValue: number,
      }
    },
  },
};

const OTTSettings = (props: OTTSettingProps) => {
  const { location: { state: { configData, lastData } } } = props;

  const isLoading = useSelector((state: RootState) => state.OTTSettings.isLoading);
  const settingsData = useSelector((state: RootState) => state.OTTSettings.settingsData);
  const settingsStyles = useSelector((state: RootState) => state.config.styles['settings']);
  const hasError = useSelector((state: RootState) => state.OTTSettings.hasError);
  const pageLoadingError = useSelector((state: RootState) => state.OTTSettings.error);
  const isLoaded = useSelector((state: RootState) => state.OTTSettings.fetched);
  const components = useSelector((state: RootState) => state.OTTSettings.components);
  const isCleengDataLoading = useSelector((state: RootState) => state.cleengService.isLoading);
  const isShownDebugSettings = useSelector((state: RootState) => state.common.isShownDebugSettings);
  const appVersion = useSelector((state: RootState) => state.common.appVersion);
  const ShowDebugModalKeyCombination = useSelector(
    (state: RootState) => state?.config?.debugSettings?.switch_key_combination_multiple
  );

  const lastFocusedElement = useRef<string>(lastData?.lastFocusedElement || '');

  const [isShownDebugAccessModal, setIsShownDebugAccessModal] = useState<boolean>(false);
  const [pressedKeysCombination, setPressedKeysCombination] = useState<string[]>([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const getBackgroundStyle = () =>
    settingsStyles.backgroundImage
      ? `url(${settingsStyles.backgroundImage})`
      : settingsStyles.backgroundColor;

  const clearOTTSettingsData = () => {
    dispatch({ type: CLEAR_OTT_SETTINGS });
    lastFocusedElement.current = '';
  };

  useLayoutEffect(() => {
    if (isStirrTcMarqueeBrand && !components.length) {
      dispatch(getSettingsPageData(configData.path));
    }

    if (!settingsData?.length && configData?.children) {
      dispatch(setPageComponentsData(configData?.children));
    }
    // eslint-disable-next-line
  }, [components, configData, settingsData]);

  useEffect(() => {
    if (components.length || settingsData?.length) {
      FocusService.setFocus(document.getElementById(lastFocusedElement.current));
    }
  }, [components.length, isCleengDataLoading, settingsData.length]);

  useEffect(() => {
    if (!isRefreshedPage(history.location.pathname)) {
      GAScreen(SETTINGS);
    }
  }, [history.location.pathname, configData.GAPath]);

  useEffect(() => {
    if (!isShownDebugSettings && !isShownDebugAccessModal && ShowDebugModalKeyCombination?.length && isStirrTcMarqueeBrand) {
      if (pressedKeysCombination.length > ShowDebugModalKeyCombination[0].length) {
        setPressedKeysCombination(prev => [...prev.slice(1)]);
      } else {
        for (let idx = 0; idx < ShowDebugModalKeyCombination.length; idx++) {
          isEqualArrays(pressedKeysCombination, ShowDebugModalKeyCombination[idx]) &&
          setIsShownDebugAccessModal(true);
        }
      }
    } else {
      setPressedKeysCombination([]);
    }
  }, [pressedKeysCombination.length, isShownDebugSettings, isShownDebugAccessModal]);

  useAnnouncePageLoading(isLoading || isCleengDataLoading);

  useEventListener('keydown', e => {
    if (isLoading || isShownDebugSettings || isShownDebugAccessModal) {
      return;
    }

    onBackHandler(e,
      () => backHandlerForFirstLevelPage(
        history,
        !hasError && !isLoading && isStirrTcMarqueeBrand,
        clearOTTSettingsData
      )
    );
  });

  const renderPage = () =>
    !isStirrTcMarqueeBrand
      ? <CometChargeSettings
        clearOTTSettingsData={clearOTTSettingsData}
      />
      : <AccountSettings
        lastFocusedElement={lastFocusedElement}
        clearOTTSettingsData={clearOTTSettingsData}
        setPressedKeysCombination={setPressedKeysCombination}
      />;

  return (
    <>
      {isLoading || isCleengDataLoading ? <Spinner /> : (
        <>
          {hasError || (!components.length && !settingsData.length) && isLoaded
            ? (<ErrorModal
              error={{ errorCode: pageLoadingError }}
              onBackHandlerCb={() => navigateBack(history, clearOTTSettingsData, true)}
            />)
            : (<>
              {isShownDebugAccessModal
                ? (
                  <DebugSettingsModal setIsShownDebugAccessModal={setIsShownDebugAccessModal} />)
                : (
                  <div className='settings-main-container' style={{background: getBackgroundStyle()}}>
                    <NavigationMenu
                      isMenuFocusedOnStart={!lastData?.lastFocusedElement}
                      focusDownSelector={isStirrTcMarqueeBrand ? '.settings-card-0' : '.settings-key-0'}
                      onEnterCallback={clearOTTSettingsData}
                    />
                    {renderPage()}
                    <span className='app-version'>
                      {appVersion}
                    </span>
                  </div>
                )}
            </>)
          }
        </>
      )}
    </>
  );
};

export default OTTSettings;
