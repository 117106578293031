import axiosInstance from './axiosInstance';
import { DataPayload, ProgramsDataPayload } from '../stores/actions/OTTChannelGuide';
import { addParametersToURL } from '../utils/commonFunc';

export async function getChannelsList(url: string, station: string): Promise<DataPayload> {
  const { data } = await axiosInstance.get(addParametersToURL(url, { station }));

  return data;
}

export async function getProgramsList(
  url: string,
  channelName: string,
  station: string,
): Promise<ProgramsDataPayload> {
  try {
    const buildUrl = `${url}${channelName}`;

    const {
      data,
    } = await axiosInstance.get(addParametersToURL(buildUrl, { station }));

    return data;
  } catch (error) {
    return {
      channel: [],
      programme: [],
    };
  }
}
