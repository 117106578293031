export function base64EncodeUint8Array(input) {
  const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  let output = '';
  let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
  let i = 0;

  while (i < input.length) {
    chr1 = input[i++];
    chr2 = i < input.length ? input[i++] : Number.NaN;
    chr3 = i < input.length ? input[i++] : Number.NaN;

    /* eslint-disable no-bitwise */
    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;
    /* eslint-enable no-bitwise */

    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }
    output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
      keyStr.charAt(enc3) + keyStr.charAt(enc4);
  }

  return output;
}

export function getUrlsPlaylist(playerUrl: string, drm: any): object {

  const isDrm = !!drm?.AuthXML && playerUrl.includes('.mpd');
  let _contentId;

  if (isDrm) {
    return [{
      default: true,
      type: 'dash',
      file: drm.dashUrl,
      drm: {
        widevine: {
          url: drm.WVserver,
          headers: [{
            name: 'customData',
            value: drm.AuthXML,
          }],
        },
        playready: {
          url: drm.PRserver,
          headers: [{
            name: 'customData',
            value: drm.AuthXML,
          }],
        },
      },
    }, {
      file: playerUrl,
      drm: {
        fairplay: {
          certificateUrl: drm.FPcert,
          processSpcUrl: drm.FPserver,
          licenseResponseType: 'text',
          licenseRequestHeaders: [{
            name: 'customData',
            value: drm.AuthXML,
          }],
          licenseRequestMessage: licenseKeyMessage => {
            return `spc=${base64EncodeUint8Array(licenseKeyMessage)}&assetId=${_contentId}`;
          },
          extractContentId: contentId => {
            if (contentId.indexOf('skd://') > -1) {
              _contentId = contentId.split('skd://')[1].substr(0, 32);

              return _contentId;
            }
            // eslint-disable-next-line max-len
            console.log('Invalid Content ID format. The format of the Content ID must be the following: skd://xxx where xxx is the Key ID in hex format.');

            return null;
          },
          extractKey: ckc => {
            let base64EncodedKey = ckc.trim();

            if (base64EncodedKey.substr(0, 5) === '' && base64EncodedKey.substr(-6) === '') {
              base64EncodedKey = base64EncodedKey.slice(5, -6);
            }

            return base64EncodedKey;
          },
        },
      },
    }, {
      file: drm.drmfail,
    }];
  } else {
    return [{
      file: playerUrl,
    }];
  }
}
