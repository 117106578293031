// https://developer.jwplayer.com/jwplayer/docs/jw8-player-configuration-reference
export const jwPlayerConfig = {
  width: '100%',
  height: '100%',
  mute: false,
  controls: false,
  streaming: false,
  autostart: false,
  displaytitle: false,
  displaydescription: false,
  volume: 100,
  renderCaptionsNatively: true, //  Captions render using the renderer of the player
};
