import store from './../stores/index';

export interface SessionData {
  station: string;
  city: string;
}

export const SESSION_DATA_KEY = 'sessionData';

export const initialSessionDataState: SessionData = {
  station: '',
  city: '',
};

export const getSessionDataFromLocalStorage = (): SessionData => {
  const sessionData = localStorage.getItem(SESSION_DATA_KEY);

  if (sessionData) {
    return JSON.parse(sessionData);
  } else {
    localStorage.setItem(SESSION_DATA_KEY, JSON.stringify(initialSessionDataState));

    return { ...initialSessionDataState };
  }
};

export const updateSessionDataInLocalStorage = (): void => {
  const state: any = store.getState();

  const { station, city } = state.common;

  localStorage.setItem(
    SESSION_DATA_KEY,
    JSON.stringify({
      station,
      city,
    })
  );
};
