export const ENV_NAMES = {
  prod: 0,
  mock: 1,
  qa: 2,
  int: 3,
  local: 4,
  demo: 5,
  stage: 6,
  alpha: 7,
  beta: 8,
};

export const LOCAL_STORAGE_DEBUG_ENVIRONMENT_KEY = 'DEBUG_ENVIRONMENT';
export const LOCAL_STORAGE_DEBUG_APP_VERSION_KEY = 'DEBUG_APP_VERSION';
