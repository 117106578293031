import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { config } from '../config';
import { isPostCall } from './helpers';

const axiosInstance = axios.create();

// For global settings in axios requests
axiosInstance.defaults.timeout = 20000; // 20sec

class MyAxios {
  charlesURL(url: string) : string {
    return config.charles_ip ? 'http://' + config.charles_ip + ':8888/;' + url : url;
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return axiosInstance.delete(this.charlesURL(url), config);
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return axiosInstance.get(this.charlesURL(url), config);
  }

  getUri(config?: AxiosRequestConfig): string {
    return axiosInstance.getUri(config);
  }

  head<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return axiosInstance.head(this.charlesURL(url), config);
  }

  options<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return axiosInstance.options(this.charlesURL(url), config);
  }

  patch<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
    return axiosInstance.patch(this.charlesURL(url), data, config);
  }

  post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
    return axiosInstance.post(this.charlesURL(url), data, config);
  }

  put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
    return axiosInstance.put(this.charlesURL(url), data, config);
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return axiosInstance.request(config);
  }

  makeApiCall<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {

    return isPostCall(url)
      ? axiosInstance.post(this.charlesURL(url), data, config)
      : axiosInstance.get(this.charlesURL(url), config);
  }
}

const my = new MyAxios();

export default my;
