// https://developer.samsung.com/smarttv/develop/api-references/samsung-product-api-references/avplay-api.html
import { IPlayer } from '../InterfacesAndTypes';
import { BUFFER_TIME, PLAYER_HEIGHT, PLAYER_WIDTH } from '../../../constants/videoPlayer';

declare const window: any;

export const AvPlayer: IPlayer = {
  player: null,
  playerEl: null,
  playerName: 'avplay',
  state: 'NONE',

  states: {
    none: 'NONE',
    idle: 'IDLE',
    ready: 'READY',
    playing: 'PLAYING',
    pause: 'PAUSED',
  },

  initialize() {
    if (this.player) {
      this.player?.close();
    }
    try {
      this.player = window.webapis?.avplay;
    } catch (e) {
      console.log(e);
    }
  },

  play(): void {
    if (this.player && (this.getState() === this.states.ready
      || this.getState() === this.states.playing
      || this.getState() === this.states.pause)
    ) {
      try {
        this.player?.play();
      } catch (e) {
        console.log('Player play Error:', e);
      }
    }
  },

  pause() {
    if (this.getState() === this.states.playing) {
      try {
        this.player?.pause();
      } catch (e) {
        console.log(e);
      }
    }
  },

  stop() {
    this.player?.stop();
  },

  open(url) {
    if (this.player && (this.getState() === this.states.none)) {
      this.player?.open(url);
      this.player?.setTimeoutForBuffering(BUFFER_TIME / 1000);
      this.player?.setStreamingProperty('ADAPTIVE_INFO', `STARTBITRATE=HIGHEST|SKIPBITRATE=LOWEST`);
      this.player?.setDisplayRect(0, 0, PLAYER_WIDTH, PLAYER_HEIGHT);
    }
  },

  prepare(sc, ec, cbsForListeners) {
    const successCallback = sc || (() => true);
    const errorCallback = ec || (() => false);

    this.player?.prepareAsync(successCallback, errorCallback);

    if (cbsForListeners?.length) {
      this.setListeners(cbsForListeners);
    }
  },

  seekTo(milliseconds, sc?, ec?) {
    const successCallback = sc || (() => true);
    const errorCallback = ec || (() => false);

    this.player?.seekTo(milliseconds, successCallback, errorCallback);
  },

  close() {
    this.player?.close();
    this.player = null;
  },

  suspend() {
    try {
      this.player?.suspend();
    } catch (e) {
      console.log(e);
    }
  },

  restore() {
    if (this.player) {
      try {
        this.player?.restore();
      } catch (e) {
        console.log('Player restore Error:', e);
      }
    }
  },

  setListeners(listenersArr) {
    const listenersObj = listenersArr.reduce((accum, [key, cb]) => ({ ...accum, [key]: cb }), {});

    this.player?.setListener(listenersObj);
  },

  showCaptions() {
    return false;
  },

  getVideoBandwidth() {
    let videoBandwidth = 0;

    try {
      videoBandwidth = this.player?.getStreamingProperty('CURRENT_BANDWIDTH');
    } catch (e) {
      console.log('Error videoBandwidth: ', e);
    }

    return videoBandwidth ? Math.floor(videoBandwidth).toString() : null;
  },

  setState(state): any {
    this.state = state;
  },

  getState() {
    return this.player?.getState();
  },

  getPlayerIsLive(): any {
    return !!this.player;
  },

  getDuration() {
    return this.player?.getDuration();
  },

  getCurrentTime() {
    return this.player?.getCurrentTime();
  },

  setDrm(params) {
    const objParams = { DeleteLicenseAfterUse: true, ...params };

    this.player?.setDrm('PLAYREADY', 'SetProperties', JSON.stringify(objParams));
  },

  getPlayerName() {
    return this.playerName;
  },
};
