import axios from 'axios';
import CryptoJS from 'crypto-js';

import store from '../stores/index';
import { submitReceipt } from './cleengService';
import {
  IBuyItemRequestDetails, IProductsListItem,
  IProductsListRequestDetails, IPurchaseService,
  IPurchasesListItem, IPurchasesListRequestDetails,
  IServersDPIList, ISubmitReceiptTizenRequestData,
} from '../platform/interfaces/purchaseService';
import { OTT_BRAND } from '../constants/structureTypes';
import appConfig from '../assets/config.json';
import { GAEvent } from './analytics';
import { PURCHASE, SETTINGS } from '../constants/analyticsTypes';
import {
  REGUEST_PRODUCTS_LIST_ERROR, REQUEST_PURCHASES_LIST_ERROR,
  REQUEST_BUY_ITEM_ERROR, REQUEST_BUY_ITEM_EXCEPTION_ERROR,
  EMPTY_PRODUCT_LIST_OR_NO_SELECTED_PRODUCT_ID_ERROR,
  SUBMIT_RECEIPT_ERROR, REQUEST_USER_STATUS_ERROR,
  USER_NOT_LOGIN_ERROR_TIZEN,
} from '../constants/errorCodes';

declare const webapis: any;
export class TizenPurchaseService implements IPurchaseService {
  private state: any = store.getState();
  private isFreeTrial: boolean = this.state.config.features.subscription_free_trial;
  private submitReceiptProdURL: string = this.state.config.subscription.cleeng_receipt || '';
  // eslint-disable-next-line max-len
  private submitReceiptTestingURL: string = 'https://us-central1-tizen-subscription-qa.cloudfunctions.net/submitReceiptProdTest';
  private submitReceiptURL = this.isFreeTrial ? this.submitReceiptTestingURL : this.submitReceiptProdURL;
  private securityKeySandBox: string = 'rKLh81XBwovizN/C+wKnXt6Q3ZOhlHk7jW1+a3mRI5Q='; // for sandbox,in app settings
  private securityKeyProd: string = 'rKLh81XBwovizN/C+wKnXt6Q3ZOhlHk7jW1+a3mRI5Q='; // for prod env
  private securityKey: string = this.isFreeTrial ? this.securityKeySandBox : this.securityKeyProd;
  private serverName: string = this.isFreeTrial ? 'DUMMY' : 'PRD';
  private serverDpiUrlList: IServersDPIList = {
    DUMMY: 'https://sbox-checkoutapi.samsungcheckout.com/openapi',
    DEV: 'https://sbox-checkoutapi.samsungcheckout.com/openapi',
    PRD: 'https://checkoutapi.samsungcheckout.com/openapi',
  };
  private urlDPI: string = this.serverDpiUrlList[this.serverName];
  private appId: string = appConfig[OTT_BRAND].appId; // '3202011022324'
  private loginUid: string = '';
  private countryCode: string = typeof webapis !== 'undefined' && webapis.productinfo.getSystemConfig(
    webapis.productinfo.ProductInfoConfigKey.CONFIG_KEY_SERVICE_COUNTRY
  );
  private productsList: IProductsListItem[] = [];
  private purchasesList: IPurchasesListItem[] = [];

  private async getProductsList(): Promise<IProductsListItem[]> {
    const details: IProductsListRequestDetails = {
      AppID: this.appId,
      CountryCode: this.countryCode,
      PageSize: 100,
      PageNumber: 1,
      CheckValue: CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(this.appId + this.countryCode, this.securityKey)),
    };

    return axios.post(`${this.urlDPI}/cont/list`, details)
      .then(res => {
        console.log(`requestProductsList success: `, res);

        return res.data.ItemDetails;
      })
      .catch(() => {
        throw new Error();
        // console.log(`requestProductsList failure ${err.message}`);
      });
  }

  private async getPurchasesList(): Promise<IPurchasesListItem[]> {
    if (this.loginUid) {
      const details: IPurchasesListRequestDetails = {
        AppID: this.appId,
        CustomID: this.loginUid,
        CountryCode: this.countryCode,
        ItemType: '2', // "1" : NON-CONSUMABLE / "2" : ALL
        PageNumber: 1,
        CheckValue: CryptoJS.enc.Base64.stringify(
          CryptoJS.HmacSHA256(this.appId + this.loginUid + this.countryCode + '2' + 1, this.securityKey
          )),
      };

      return axios.post(`${this.urlDPI}/invoice/list`, details)
        .then((res: any) => {

          return res.data.InvoiceDetails;
        })
        .catch(() => {
          throw new Error();
        });
    }

    return [];
  }

  async getSubscriptionStatusFromDevice(selectedProductId: string, errorCallback: any): Promise<boolean | void> {
    let loginStatus: number = 0;

    try {
      loginStatus = webapis.sso.getLoginStatus();

      console.log(`getLoginStatus success: `, loginStatus);

      if (loginStatus === 1) {
        this.loginUid = webapis.sso.getLoginUid();

        return this.getPurchasesList()
          .then((res) => {
            console.log(`requestPurchaseList success: `, res);
            this.purchasesList = res;

            const purchaseDetails = this.purchasesList.find(
              ({ ItemID }) => ItemID === selectedProductId
            );

            // Subscription status:
            // "00": Active
            // "01": Subscription expired
            // "02": Canceled by buyer
            // "03": Canceled for payment failure
            // "04": Canceled by CP
            // "05": Canceled by admin

            return purchaseDetails?.SubscriptionInfo?.SubsStatus === '00' ? true : false;
          })
          .catch(() => {
          // console.log(`requestPurchasesList failure ${err.message}`);
            errorCallback(REQUEST_PURCHASES_LIST_ERROR);
          });
      } else {
        errorCallback && errorCallback(USER_NOT_LOGIN_ERROR_TIZEN);
      }
    } catch (e) {
      console.log(`getLoginStatus failure ${e}`);
      errorCallback && errorCallback(REQUEST_USER_STATUS_ERROR);
    }
  }

  async buyItem(
    selectedProductId: string,
    successCallback: any,
    errorCallback: any,
    setIsLoading: any,
    checkPurchaseStatus: any,
    exceptionErrHandler: any,
    analyticsValue: () => string): Promise<void> {

    if (typeof webapis === 'undefined') {
      return;
    }

    setIsLoading(true);

    this.getProductsList()
      .then((res) => {
        this.productsList = res;

        const cleengToken = JSON.parse(localStorage.getItem('AuthPlus') as string)?.cleengToken || '';
        const firebaseToken = JSON.parse(localStorage.getItem('AuthPlus') as string)?.token || '';
        const selectedProductDetails = this.productsList.find((
          { ItemID }) => ItemID === selectedProductId
        );

        if (selectedProductDetails) {
          const details: IBuyItemRequestDetails = {
            OrderItemID: selectedProductDetails.ItemID,
            OrderTitle: selectedProductDetails.ItemTitle,
            OrderTotal: selectedProductDetails.Price.toString(),
            OrderCurrencyID: selectedProductDetails.CurrencyID,
            OrderCustomID: this.loginUid,
          };

          const paymentDetails = JSON.stringify(details);

          console.log('============ productLists', this.productsList);
          console.log('============ server', this.serverName, this.urlDPI);
          // console.log('============ securityKey', this.securityKey);
          console.log('============ submit receipt', this.submitReceiptURL);

          try {
            setIsLoading(true);
            webapis.billing.buyItem(this.appId, this.serverName, paymentDetails,
              async (data) => {
                const resData = JSON.parse(data.payDetail);
                let tizenReceipt = { ...resData};

                if (!resData.InvoiceID) {
                  try {
                    const purchasesListRes = await this.getPurchasesList();
                    const invoceId = purchasesListRes?.find(
                      ({ ItemID }) => ItemID === selectedProductId
                    )?.InvoiceID;

                    tizenReceipt = { ...tizenReceipt, InvoiceID: invoceId };
                  } catch {
                    errorCallback(REQUEST_PURCHASES_LIST_ERROR);
                  }
                }

                const receiptData: ISubmitReceiptTizenRequestData = {
                  customerToken: cleengToken,
                  tizenReceipt: tizenReceipt,
                };

                const receiptHeaders = {
                  'X-userAuthenticationToken': firebaseToken,
                };

                if (data.payResult === 'SUCCESS') {
                  localStorage.setItem('InvoceId', resData.InvoiceID);
                  submitReceipt(this.submitReceiptURL, receiptData, receiptHeaders)
                    .then(() => {
                      console.log(`submitReceipt success: `, res);
                      setIsLoading(false);
                      checkPurchaseStatus();
                      GAEvent(SETTINGS, PURCHASE, analyticsValue());
                      successCallback();
                    })
                    .catch(() => {
                      // console.log(`submitReceipt error : ${e.message}`);
                      setIsLoading(false);
                      errorCallback(SUBMIT_RECEIPT_ERROR);
                    });
                } else {
                  setIsLoading(false);
                  exceptionErrHandler();
                }
              },
              () => {
              // console.log(`requestBuyItem error : ${e.message}`);
                setIsLoading(false);
                errorCallback(REQUEST_BUY_ITEM_ERROR);
              });
          } catch (e) {
          // console.log(`webapis.billing.buyItem Exception : ${e.message}`);
            setIsLoading(false);
            errorCallback(REQUEST_BUY_ITEM_EXCEPTION_ERROR);
          }
        } else {
          setIsLoading(false);
          // console.log('products list is empty or no selected product id', productsDetails, selectedProductId);
          errorCallback(EMPTY_PRODUCT_LIST_OR_NO_SELECTED_PRODUCT_ID_ERROR);
        }
      })
      .catch(() => {
        setIsLoading(false);
        errorCallback(REGUEST_PRODUCTS_LIST_ERROR);
      // console.log(`requestProductsList failure ${err.message}`);
      });
  }
}
