import * as actionTypes from '../actions/types/config';
import { ActionConfig, Features } from '../actions/config';
import { getDeviceId, getParsedDataFromLocalStorage } from '../../utils/commonFunc';

export interface INavigationItem {
  title: string;
  path: string;
  icon?: string;
  icon_active?: string;
  type: string;
  web_route?: string;
  align?: string;
  focusable?: boolean;
  tag?: string;
  displayTitle?: string;
  children?: INavigationItem[];
}

interface IConfigState {
  allConfigs: any;
  styles: any;
  navigation: INavigationItem[];
  GA_id: string;
  settings: any;
  isConfigFetched: boolean;
  isNavFetched: boolean;
  configHasError: boolean;
  navHasError: boolean;
  configError: string;
  navError: string;
  isLoading: boolean;
  mvpd: {
    mvpd_text: any;
    mvpd_regcode: string;
    mvpd_auth: string;
    mvpd_checkauthn: string;
    mvpd_url: string;
    mvpd_requestorID: string;
    mvpd_logout: string;
    mvpdProvidersLogos: string;
  };
  players: {
    livePlayer: string;
    vodPlayer: string;
  };
  debugSettings: {
    switch_key_combination_multiple: string[];
    passcode: string;
  }
  postUrlsPrefixArr: string[];
  subscription: {
    firebase_createUser: string;
    firebase_authUser: string;
    firebase_resetPassword: string;
    firebase_refreshToken: string;
    cleeng_registerCustomer: string;
    cleeng_generateCustomerToken: string;
    cleeng_getPurchaseOption: string;
    cleeng_receipt: string;
    cleeng_listCustomerSubscriptions: string;
    cleeng_entitlements: string;
    subscription_promo_slider_slides: any;
    paywall_title: string;
    paywall_detail: string;
    payment_success_title: string;
    payment_success_detail: string;
    title_signed_in: string;
    detail_signed_in: string;
    title_signed_out: string;
    detail_signed_out: string;
    subscribe_tos: string;
    subscriptionOptions: any;
    reset_password_title: string;
    reset_password_detail: string;
    reset_password_success_title: string;
    reset_password_success_detail: string;
    reset_password_failure_title: string;
    sign_in_error_message: string;
    sign_in_title: string;
    sign_up_title: string;
    sign_up_detail: string;
    payment_gateways: any;
    terms_of_use_title: string;
    terms_of_use_text: string;
    privacy_policy_title: string;
    privacy_policy_text: string;
    newsletter_url: string;
  };
  deviceId: string;
  features: Features;
  edenContent: string;
}

const initialState: IConfigState = {
  allConfigs: {},
  styles: {},
  navigation: [],
  settings: {},
  mvpd: {
    mvpd_text: {},
    mvpd_regcode: '',
    mvpd_auth: '',
    mvpd_checkauthn: '',
    mvpd_url: '',
    mvpd_requestorID: '',
    mvpd_logout: '',
    mvpdProvidersLogos: '',
  },
  players: {
    livePlayer: '',
    vodPlayer: '',
  },
  debugSettings: {
    switch_key_combination_multiple: [],
    passcode: '',
  },
  postUrlsPrefixArr: [],
  subscription: {
    firebase_createUser: '',
    firebase_authUser: '',
    firebase_resetPassword: '',
    firebase_refreshToken: '',
    cleeng_registerCustomer: '',
    cleeng_generateCustomerToken: '',
    cleeng_getPurchaseOption: '',
    cleeng_receipt: '',
    cleeng_listCustomerSubscriptions: '',
    cleeng_entitlements: '',
    subscription_promo_slider_slides: [],
    paywall_title: '',
    paywall_detail: '',
    payment_success_title: '',
    payment_success_detail: '',
    title_signed_in: '',
    detail_signed_in: '',
    title_signed_out: '',
    detail_signed_out: '',
    subscribe_tos: '',
    subscriptionOptions: [],
    reset_password_title: '',
    reset_password_detail: '',
    reset_password_success_title: '',
    reset_password_success_detail: '',
    reset_password_failure_title: '',
    sign_in_error_message: '',
    sign_in_title: '',
    sign_up_title: '',
    sign_up_detail: '',
    payment_gateways: [],
    terms_of_use_title: '',
    terms_of_use_text: '',
    privacy_policy_title: '',
    privacy_policy_text: '',
    newsletter_url: '',
  },
  GA_id: '',
  deviceId: getParsedDataFromLocalStorage('AuthMVPD')?.deviceId || getDeviceId(),
  edenContent: '',
  isConfigFetched: false,
  isNavFetched: false,
  configHasError: false,
  navHasError: false,
  configError: '',
  navError: '',
  isLoading: false,
  features: {},
};

const config = (state = initialState, action: ActionConfig): IConfigState => {
  switch (action.type) {
    case actionTypes.LOAD_CONFIG:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.LOAD_CONFIG_SUCCEEDED:
      return {
        ...state,
        allConfigs: action.payload.configData,
        styles: action.payload.stylesData,
        mvpd: action.payload.mvpd,
        players: action.payload.players,
        debugSettings: action.payload.debugSettings,
        postUrlsPrefixArr: action.payload.postUrlsPrefixArr,
        subscription: action.payload.subscription,
        GA_id: action.payload.GA_id,
        features: action.payload.features,
        edenContent: action.payload.edenContent,
        settings: action.payload.settings,
        isConfigFetched: true,
        configHasError: false,
        isLoading: false,
      };

    case actionTypes.LOAD_CONFIG_FAILED:
      return {
        ...state,
        isConfigFetched: false,
        configHasError: true,
        configError: action.error,
        isLoading: false,
      };

    case actionTypes.LOAD_NAVIGATION_CONFIG_SUCCEEDED:
      return {
        ...state,
        navigation: action.payload.navigationData,
        isNavFetched: true,
        navHasError: false,
        isLoading: false,
      };

    case actionTypes.LOAD_NAVIGATION_CONFIG_FAILED:
      return {
        ...state,
        isNavFetched: false,
        navHasError: true,
        navError: action.error,
        isLoading: false,
      };

    case actionTypes.REFRESH_DEVICE_ID:
      return {
        ...state,
        deviceId: action.payload,
      };

    default:
      return state;
  }
};

export default config;
