import md5 from 'md5';
import moment from 'moment';

export const getProgramHash = program => md5(`${program?.channel}${program?.start}${program?.stop}`);

export const convertXmltvDate = date => {
  return date ? `${date.slice(0, 8)}T${date.slice(8, -2)}Z` : null;
};

export const formatTimeForVerticalEPG = (time) => {
  return time ? `${moment(time).format('h:mm A')}` : '';
};

export const getDataInFutureAsIOSString = (daysAmountToAdd) => {
  const endTimeDate = daysAmountToAdd ? moment().add(daysAmountToAdd, 'day') : moment();

  return endTimeDate.toISOString();
};

export const fillUpEmptyBlocksInEPG = (programs, id) => {

  const endTime = moment().add(24.5, 'hours');
  const startTime = moment();
  const lastProgram = programs.length && programs[programs.length - 1];

  if (lastProgram.stopLong < endTime || !programs.length) {
    const additionalProgram = {
      id: `${id}abc`,
      title: 'No scheduled programing',
      isLiveProgram: false,
      desc: '',
      start: programs.length ? lastProgram.stop : startTime,
      startLong: programs.length ? lastProgram.stopLong : startTime.unix(),
      stop: endTime,
      stopLong: endTime.unix(),
    };

    programs.push(additionalProgram);
  }

  return programs;
};

export const shouldShowTime = (spoilerPreventionStatus, spoilerPreventionFeatureFlag, isPlusContent) => {
  let showTime = true;

  if (spoilerPreventionFeatureFlag && isPlusContent) {
    showTime = !spoilerPreventionStatus;
  }

  return showTime;
};
