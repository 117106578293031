import React, { useState, useEffect, useCallback, MutableRefObject } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../stores/reducers';
import { primaryColor } from '../../../constants/colors';
import { CLEENG_RESTRICTION } from '../../../constants/text';
import { isContentWithRestriction } from '../../../utils';
import focusService from '../../../services/focusService';

import CardWrapper from '../../Carousel/CardWrapper/CardWrapper';
import Card from '../../Carousel/Card/Card';
import ScrollableList from '../../ScrollableList/ScrollableList';

import './SEASON_GRID_LIST.scss';

interface SEASON_GRID_LIST_Props {
  season: Array<{ item: object }>;
  onCardPress: () => void;
  seasonId: string;
  seasonIndex: number;
  selected: number;
  initialFocusedElement: string;
  translateValueRef: MutableRefObject<number>;
}

let rowLength = 4;

const SEASON_GRID_LIST = (props: SEASON_GRID_LIST_Props) => {
  const {
    season,
    onCardPress,
    seasonId,
    selected,
    seasonIndex,
    initialFocusedElement,
    translateValueRef,
  } = props;

  const [content, setContent] = useState([]);

  const borderColorFocusOn = useSelector((state: RootState) => {
    return state.config.styles?.['teaser']?.['focusBorderColor'] || primaryColor;
  });

  const cardStyles = {
    width: 324,
    height: 183,
    marginRight: 10,
  };

  const cardTitleStyles = {
    marginLeft: -15,
  };

  const child = (item, index) => (
    <Card
      TCPlusContent={
        isContentWithRestriction(item?.item['media:content']['media:restriction'], CLEENG_RESTRICTION)
      }
      width={cardStyles.width}
      height={cardStyles.height}
      key={index}
      src={item.item['media:content']['media:thumbnail']}
      alt={item.item['slug']}
      title={item.item['media:content']['media:title']['content']}
      duration={item.item['media:content']['duration']}
      titleStyles={cardTitleStyles}
      cardId={item.item['guid']['content']}
    />
  );

  const getEpisodes = (item, index) => (
    <div className={`row-${index} carousel-row`} key={`carousel-row-${index}`}>
      <div key={index} className='brandCard'>
        <CardWrapper
          last={(!((index + 1) % 5) || index === season.length - 1)}
          first={((index + 1) % 5) === 1}
          seasonId={seasonId}
          key={`${index}-wrapper`}
          index={index}
          focused={() => null}
          keyPressed={onCardPress}
          child={child(item, index)}
          carouselId={0}
          style={cardStyles}
          carouselTitle={''}
          focusUp={index < 5 ? '.season-list-last-focused-item' : `.index-0-${index - 5}`}
          isLastCarousel={!!((((index + 1) % 5) && index >= season.length - (season.length % 5 || 5))
            || index === season.length - 1)}
          borderColorFocusOn={borderColorFocusOn}
        />
      </div>
    </div>
  );

  const makeRows = useCallback((arr) => {
    const rows: any = [];
    const row: any = [];

    arr?.forEach((item, index) => {
      if (index === arr.length - 1) {
        row.push(item);
        rows.push([...row]);
        row.length = 0;
      } else if (index === rowLength) {
        row.push(item);
        rows.push([...row]);
        row.length = 0;
        rowLength += 5;
      } else if (index < rowLength) {
        row.push(item);
      }
    });

    return rows.map((item, index) => {
      return (
        <div key={index} className='gridrow'>
          {item}
        </div>
      );
    });
  }, []);

  useEffect(() => {
    rowLength = 4;

    const allEpisodes = season?.map((item, idx) => getEpisodes(item, idx));

    setContent(makeRows(allEpisodes));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (content.length) {
      selected === seasonIndex && focusService.setFocus(
        document.getElementById(initialFocusedElement || 'navigationSelectedItem')
      );
    }
  }, [content.length]);

  return (
    <>
      {selected === seasonIndex ? (
        <div className='gridContainer'>
          <ScrollableList
            transition={0.3}
            translateValueRef={translateValueRef}
            isSeasonsPage={true}
          >
            {content}
          </ScrollableList>
        </div>
      ) : null}
    </>
  );
};

export default SEASON_GRID_LIST;
