import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from '../../stores/reducers';
import { setComponentOTTFeedData } from '../../stores/actions/OTTFeed';
import {
  loadChannelsListData,
  loadProgramsListData,
} from '../../stores/actions/OTTChannelGuide';
import { CLEAR_OTTFEED } from '../../stores/actions/types/OTTFeed';
import { SET_ACTIVE_NAV } from '../../stores/actions/types/common';

import { mvpdLoginRequired, tennisPlusLoginRequired } from '../../utils';
import { navigateBack } from '../../services/NavigationService';
import {
  clearHitDimensions,
  GAEvent,
  GAScreen,
  generateAnalyticsErrorDetails,
  setDimensions,
} from '../../services/analytics';
import useGetVideoData from '../../hooks/getVideoData';
import * as analyticsTypes from '../../constants/analyticsTypes';
import {
  isMarqueeBrand,
  isStirrTcMarqueeBrand,
  isTcBrand,
  OTTFeedType,
} from '../../constants/structureTypes';
import { HEARTBEAT_ANALYTICS_INTERVAL } from '../../constants/common';
import { errorDetails as errorsDescription } from '../../constants/errorDetails';
import { DEFAULT_VIDEO_AD_ERROR } from '../../constants/errorCodes';

import LIVE from '../../components/LIVE/LIVE';
import ErrorBoundary from '../../components/Modals/ErrorBoundary/ErrorBoundary';
import TCPlusFlow from '../TCPlusFlow/TCPlusFlow';
import MvpdFlow from '../MvpdFlow/MvpdFlow';

import './OTTFeed.scss';

type OTTFeedProps = {
  location: {
    state: {
      configData: {
        link: string;
        path: string;
        GAPath: string;
        title: string;
        guid: {
          content;
        };
        'media:content': {
          url: string;
        };
      };
    };
  };
}

const OTTFeed = (props: OTTFeedProps) => {
  const { location: { state: { configData } } } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const component = useSelector((state: RootState) => state.OTTFeed.component);
  const adUrl = useSelector((state: RootState) => state.OTTFeed.adUrl);
  const custParams = useSelector((state: RootState) => state.OTTFeed.custParams);
  const standParams = useSelector((state: RootState) => state.OTTFeed.standParams);
  const channels = useSelector((state: RootState) => state.OTTChannelGuide.channels);
  const channelsWithPrograms = useSelector((state: RootState) => state.OTTChannelGuide.channelsSortedByCategories);
  const isShowPromoVideo = useSelector((state: RootState) => state.config.allConfigs.API.paywall?.['show_promo_video']);
  const isProgramsLoading = useSelector((state: RootState) => state.OTTChannelGuide.isProgramsLoading);
  const navigation = useSelector((state: RootState) => state.config.navigation);
  const features = useSelector((state: RootState) => state.config.features);
  const configDrmData = useSelector((state: RootState) => state.OTTFeed.configDrmData);
  const isOTTFeedDataLoading = useSelector((state: RootState) => state.OTTFeed.isLoading);
  const apiError = useSelector((state: RootState) => state.OTTFeed.error);
  const { livePlayer } = useSelector((state: RootState) => state.config.players);

  const [activeChannel, setActiveChannel] = useState<number>(0);
  const [selectedChannelId, setSelectedChannelId] = useState(configData?.['media:content']?.['sinclair:ident'] || '');
  const [initLoad, setInitLoad] = useState<boolean>(false);
  const [showAuthPopUp, setShowAuthPopUp] = useState(false);
  const [isShownEpgOnlyView, setIsShownEpgOnlyView] = useState<boolean>(
    Boolean(configData?.path?.includes('mediaType=EPG') && isTcBrand)
  );

  const currentLive = useRef<any>('');
  const heartbeatAnalyticsEvent = useRef<any>(null);
  const signInBtnPressed = useRef<boolean>(false);
  const isFlowFromEPGOnlyView = useRef<boolean>(isShownEpgOnlyView);
  const activeChannelName = useRef<string>('');

  const {
    item: {
      link = '',
      guid: {
        content: contentId = '',
      } = {},
      'media:content': {
        url = '',
        'media:status': mediaStatus = '',
        'sinclair:url': sinclairUrl = '',
        'sinclair:ident': sinclairIdent = '',
        'media:title': mediaTitle = '',
      } = {},
    } = {},
  } = component || {};

  const refreshPlayback = () => {
    dispatch(setComponentOTTFeedData('', { ...component?.item }));
    setShowAuthPopUp(false);
    setIsShownEpgOnlyView(false);
    signInBtnPressed.current = false;
  };

  const clearOTTFeedData = useCallback(() => {
    setSelectedChannelId('');
    activeChannelName.current = '';
    setActiveChannel(0);
    dispatch({
      type: CLEAR_OTTFEED,
    });
    // eslint-disable-next-line
  }, []);

  const handleBack = () => {
    ((isFlowFromEPGOnlyView.current && !isShownEpgOnlyView && mediaStatus?.state === 'blocked')
      || (isMarqueeBrand && showAuthPopUp)
    )
      ? goBackToGuide()
      : navigateBack(history, clearOTTFeedData, isFlowFromEPGOnlyView.current);
  };

  const goBackToGuide = () => {
    setIsShownEpgOnlyView(true);
    setShowAuthPopUp(false);
  };

  useGetVideoData(
    configData,
    setComponentOTTFeedData,
    true,
    link || url,
    setActiveChannel,
    setSelectedChannelId,
    activeChannelName
  );

  useEffect(() => {
    setDimensions({ contentType: analyticsTypes.LIVE });

    if (!isStirrTcMarqueeBrand) {
      GAScreen(analyticsTypes.HOME);
    }

    GAScreen(analyticsTypes.LINEAR_PLAYER);

    const navIdx = navigation.findIndex(nav => nav.type === OTTFeedType);

    dispatch({ type: SET_ACTIVE_NAV, navIdx: navIdx });

    const isMVPDorPlus = features.cleeng || features.mvpd;
    const hasUlr4Video = link || url || configData?.path;

    if (!channelsWithPrograms.length
      && (((isMVPDorPlus) && ((hasUlr4Video) || configData['media:content']?.url))
      || ((hasUlr4Video) && !(isMVPDorPlus)))) {
      dispatch(loadChannelsListData());
    }

    return () => {
      clearHitDimensions();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (channels.length && !channelsWithPrograms.length && !isProgramsLoading) {
      dispatch(loadProgramsListData(channels));
    }
    // eslint-disable-next-line
  }, [channels.length]);

  useEffect(() => {
    // When we open LIVE first time, we wait for channels to fetch to set custom dimensions for GA
    if (channels.length) {
      if (!initLoad) {
        setInitLoad(true);
      }
    }
    // eslint-disable-next-line
  }, [channels]);

  useEffect(() => {
    if (initLoad) {
      if ((link || url) && component.item) {
        if (mediaStatus?.state === 'blocked') {
          if (tennisPlusLoginRequired(mediaStatus?.reason)) {
            GAEvent(
              analyticsTypes.SUBSCRIPTION_PREVIEW,
              analyticsTypes.PLAY,
              mediaTitle?.content,
            );
          } else if (mvpdLoginRequired(mediaStatus?.reason)) {
            GAEvent(
              analyticsTypes.MVPD_PREVIEW,
              analyticsTypes.PLAY,
              mediaTitle?.content,
            );
          }
        } else {
          const currentChannel =
            channels.find(channel => channel.id === sinclairIdent)?.displayName;

          currentLive.current = mediaTitle?.content;
          setDimensions({
            contentUuid: contentId,
            itemTitle: mediaTitle?.content,
            showTitle: mediaTitle?.content,
            channelUrl: sinclairUrl,
            channelId: currentChannel,
          });
          GAEvent(
            analyticsTypes.LIVESTREAM,
            analyticsTypes.PLAY,
            mediaTitle?.content,
          );
          heartbeatAnalyticsEvent.current = setInterval(() => {
            GAEvent(
              analyticsTypes.LINEAR_HEARTBEAT,
              analyticsTypes.IS_WATCHING,
              contentId,
              { nonInteraction: true },
            );
          }, HEARTBEAT_ANALYTICS_INTERVAL);

          return () => {
            GAEvent(
              analyticsTypes.LIVESTREAM,
              analyticsTypes.EXIT,
              currentLive.current,
            );
            if (heartbeatAnalyticsEvent.current) {
              clearInterval(heartbeatAnalyticsEvent.current);
            }
          };
        }
      }
    }
    // eslint-disable-next-line
  }, [component.item, link, url, initLoad]);

  useEffect(() => {
    if (apiError.errorCode) {
      const errorDetails = generateAnalyticsErrorDetails(
        livePlayer,
        { item: apiError.requestData },
        false
      );

      setDimensions({
        errorDetail: errorDetails,
        adType: '',
        errorMessage: errorsDescription[apiError.errorCode] || analyticsTypes.API_ERROR,
      });
      GAEvent(analyticsTypes.ERROR_PLAYER, analyticsTypes.API, component.item.guid['content'], {
        nonInteraction: true,
      });
      clearHitDimensions(['errorDetail', 'adType', 'errorMessage']);
    }
  }, [apiError.errorCode]);

  return (
    <ErrorBoundary
      error={{errorCode: DEFAULT_VIDEO_AD_ERROR}}
      onBackHandlerCb={() => navigateBack(history, clearOTTFeedData)}
    >
      <div className='ott-feed'>
        {(mediaStatus?.state === 'blocked' && !isOTTFeedDataLoading &&
          !isShowPromoVideo && !isShownEpgOnlyView) || showAuthPopUp
          ? (
            <>
              {tennisPlusLoginRequired(mediaStatus?.reason) &&
                <TCPlusFlow
                  isFlowFromPlayback={true}
                  successCallback={refreshPlayback}
                  uuid={selectedChannelId}
                  goBackFromPlayback={handleBack}
                />}
              {mvpdLoginRequired(mediaStatus?.reason) &&
                <MvpdFlow
                  isFlowFromPlayback={true}
                  successCallback={refreshPlayback}
                  uuid={selectedChannelId}
                  goBackFromPlayback={handleBack}
                  signInBtnPressed={signInBtnPressed}
                />}
            </>
          ) : (
            <LIVE
              source={link || url}
              includeAds={!!adUrl}
              adUrl={adUrl}
              activeChannel={activeChannel}
              setActiveChannel={setActiveChannel}
              activeChannelName={activeChannelName}
              logInRequestor={mediaStatus?.reason}
              custParams={custParams}
              standParams={standParams}
              accessDisabled={mediaStatus?.state === 'blocked'}
              selectedChannelId={selectedChannelId}
              setSelectedChannelId={setSelectedChannelId}
              initialId={selectedChannelId}
              item={component.item}
              currentLive={
                component.item?.['media:content']?.['media:title']
                  ? component.item['media:content']['media:title']['content']
                  : !isStirrTcMarqueeBrand
                    ? link || url
                    : ''
              }
              showAuthPopUp={showAuthPopUp}
              setShowAuthPopUp={setShowAuthPopUp}
              signInBtnPressed={signInBtnPressed}
              assetKey={(configDrmData.dashAssetKey || sinclairUrl)?.replace('dai://', '') || ''}
              isShownEpgOnlyView={isShownEpgOnlyView}
              setIsShownEpgOnlyView={setIsShownEpgOnlyView}
              goBack={handleBack}
              clearOTTFeedData={clearOTTFeedData}
              isFlowFromEPGOnlyView={isFlowFromEPGOnlyView}
              sinclairIdent={sinclairIdent}
            />
          )}
      </div>
    </ErrorBoundary>
  );
};

export default OTTFeed;
