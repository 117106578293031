import * as actionTypes from '../actions/types/OTTText';
import { Action } from '../actions/OTTText';

interface OTTSettingsState {
  pageText: string;
  fetched: boolean;
  hasError: boolean;
  error: string;
  isLoading: boolean;
}

const initialState: OTTSettingsState = {
  pageText: '',
  fetched: false,
  hasError: false,
  error: '',
  isLoading: false,
};

const OTTText = (state = initialState, action: Action): OTTSettingsState => {
  switch (action.type) {
    case actionTypes.LOAD_OTT_TEXT_DATA:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.LOAD_OTT_TEXT_DATA_SUCCEEDED:
      return {
        ...state,
        pageText: action.payload,
        fetched: true,
        hasError: false,
        isLoading: false,
      };

    case actionTypes.LOAD_OTT_TEXT_DATA_FAILED:
      return {
        ...state,
        fetched: false,
        hasError: true,
        error: action.error,
        isLoading: false,
      };

    case actionTypes.CLEAR_OTT_TEXT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default OTTText;
