import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../stores/reducers';
import { speakTTS } from '../../services/TTSService';

import './ToggleSwitch.scss';

type SpoilerPreventionSwitcherProps = {
  handleSwitch: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

const ToggleSwitch = (props: SpoilerPreventionSwitcherProps) => {
  const { handleSwitch } = props;

  const toggleStatus = useSelector((state: RootState) => state.common.isSpoilerPreventionEnabled);
  const stateOnTitle = useSelector((state: RootState) =>
    state.config.settings.options?.spoiler_prevention?.button_on);
  const stateOffTitle = useSelector((state: RootState) =>
    state.config.settings.options?.spoiler_prevention?.button_off);

  const onFocusChanged = (e: React.FocusEvent<HTMLDivElement>, hasFocus: boolean = false) => {
    const switcher = e.target.querySelector('.switch') as HTMLDivElement;

    switcher.style.border = `${hasFocus ? '1' : 0}px solid #FFFFFF`;
  };

  useEffect(() => {
    const ttsText = `${toggleStatus ? stateOffTitle : stateOnTitle} Press to switch`;

    speakTTS(ttsText);
  }, [toggleStatus]);

  return (
    <div
      className='toggle-switch'
      onFocus={(e: React.FocusEvent<HTMLDivElement>) => onFocusChanged(e, true)}
      onBlur={(e: React.FocusEvent<HTMLDivElement>) => onFocusChanged(e, false)}
      onKeyDown={handleSwitch}
      role=''
      tabIndex={0}
      aria-label={`${toggleStatus ? stateOnTitle : stateOffTitle} Press to switch`}>
      <input
        type='checkbox'
        className='checkbox'
        id='toggle'
        onChange={() => {}}
        checked={toggleStatus}
      />
      <label className='label' htmlFor='toggle'>
        <span className='switch'/>
        <span className='inner' data-on={stateOnTitle} data-off={stateOffTitle}/>
      </label>
    </div>
  );
};

export default ToggleSwitch;
