import { GeneralPlatform, webOSVersion } from '../GeneralPlatform';
import { IExtendedPlatform, Captions } from '../interfaces/interfaces';
import { TizenPurchaseService } from '../../services/tizenPurchaseService';
import { IPurchaseService } from '../interfaces/purchaseService';
import TizenCaptions from './TizenCaptions';
import { INetworkInitParams } from '../interfaces/network';
import { TizenPreviewService } from '../../services/tizenPreviewService';
import { IPreviewService } from '../interfaces/preview';
import { OTT_BRAND } from '../../constants/structureTypes';
import config from '../../assets/config.json';

declare const tizen: any;
declare const webapis: any;

export class TizenPlatform extends GeneralPlatform implements IExtendedPlatform {
  captions: Captions = new TizenCaptions();
  deviceBrand = 'Samsung';
  multiTaskingEvent = 'visibilitychange';
  idType = 'tifa';

  get deviceModel(): string {
    if (typeof webapis !== 'undefined') {
      return webapis.productinfo.getModel();
    }

    return '';
  }

  get deviceUuid(): string {
    if (typeof webapis !== 'undefined') {
      return webapis.productinfo.getDuid();
    }

    return '';
  }

  get isLoadedInBrowser(): boolean {
    return (typeof webapis === 'undefined');
  }

  get osVersion(): string {
    if (typeof tizen !== 'undefined' && tizen?.systeminfo?.getCapabilities()?.platformVersion) {
      // getCapabilities() is deprecated and will be removed from next release.
      // Use getCapability() instead. 2.3 ==> There's message but getCapability hasn't worked yet.
      const tizenVersion: string = tizen?.systeminfo?.getCapabilities()?.platformVersion;

      return tizenVersion;
    }

    return webOSVersion();
  }

  get wrapperVersion(): string {
    return config[OTT_BRAND]?.tizenWrapperVersion || '';
  }

  getPurchaseService(): IPurchaseService {
    return new TizenPurchaseService();
  }

  exitFromApp = (): void => {
    if (typeof tizen !== 'undefined') {
      tizen.application.getCurrentApplication().exit();
    }
  };

  getAdvertisementId(): string {
    let tifa: string = '';

    try {
      if (typeof webapis !== 'undefined') {
        tifa = this.isEnabledLimitationAdTracking() ? '' : webapis.adinfo.getTIFA();
      }
    } catch (e: any) {
      console.log(`Error requesting platform adId - ${e?.name}`);
    }

    return tifa;
  }

  // Check whether ad tracking is restricted. 1 - tracking is restricted / 0 - tracking is not restricted
  isEnabledLimitationAdTracking(): number {
    let lat: number = 0;

    try {
      if (typeof webapis !== 'undefined') {
        lat = webapis.adinfo.isLATEnabled() ? 1 : 0;
      }
    } catch (e: any) {
      console.log(`Error requesting platform adLat - ${e.name}`);
    }

    return lat;
  }

  getIp(): string {
    let deviceIp: string = '';

    try {
      if (typeof webapis !== 'undefined') {
        deviceIp = webapis.network.getIp();
      }
    } catch (e: any) {
      console.log(`Error requesting deviceIp from platform - ${e.message}`);
    }

    return deviceIp;
  }

  addNetworkHandler(networkUtils: INetworkInitParams): void {
    const { setToStoreIsNetworkConnected, setIsModalErrorShown } = networkUtils;

    const onChange = value => {
      if (value === webapis.network.NetworkState.GATEWAY_DISCONNECTED) {
        setToStoreIsNetworkConnected(false);
        setIsModalErrorShown(true);
      } else if (value === webapis.network.NetworkState.GATEWAY_CONNECTED) {
        setToStoreIsNetworkConnected(true);
        setIsModalErrorShown(false);
      }
    };

    try {
      if (typeof webapis !== 'undefined') {
        webapis.network.addNetworkStateChangeListener(onChange);
      }
    } catch (e: any) {
      console.log(`addNetworkStateChangeListener exception - ${e.message}`);
    }
  }

  getPreviewService(): IPreviewService {
    return new TizenPreviewService();
  }
  registerKeys() {
    const PlayerControlsKeys = [
      'MediaRewind',
      'MediaFastForward',
      'MediaPlay',
      'MediaPause',
      'MediaPlayPause',
      'MediaStop',
    ];

    if (typeof tizen !== 'undefined') {
    // if multiple keys need to be registered, registerKeyBatch method is preferable, it improves app launch time
      tizen.tvinputdevice?.registerKeyBatch(PlayerControlsKeys);
    } else {
      console.log('Error: Special Media keys for Tizen can not be registered.');
    }
  }

  async requestAdditionalAdsParams() {
    return;
  }

  getPackageName() {
    return this.brand;
  }
}
