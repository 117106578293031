import * as actionTypes from '../actions/types/liverampCollector';

export interface IDataFromConfig {
  urlLiveramp: string,
}

export interface ILoadLiverampAction {
  type: typeof actionTypes.LOAD_LIVERAMP;
  payload: IDataFromConfig;
}

export interface ILoadLiverampSucceededAction {
  type: typeof actionTypes.LOAD_LIVERAMP_SUCCEEDED;
}

interface ILoadLiverampFailedAction {
  type: typeof actionTypes.LOAD_LIVERAMP_FAILED;
}

export type ActionLiveramp = ILoadLiverampAction | ILoadLiverampSucceededAction | ILoadLiverampFailedAction;

export const setLiverampAction = (dataFromConfig: IDataFromConfig): ILoadLiverampAction => ({
  type: actionTypes.LOAD_LIVERAMP,
  payload: dataFromConfig,
});
