import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { hlsPlayer } from './HlsPlayer';
import { RootState } from '../../../stores/reducers';
import { getMilliseconds } from '../../../utils/time';
import { PLAYER_CHECK_POSITION_INTERVAL } from '../../../constants/videoPlayer';
import useLatest from '../../../hooks/useLatest';

type HLSWrapperProps = {
  onStreamPlay: any;
  onStreamPause: any;
  onStreamCompleted: any;
  onCanPlay: any;
  onParsingMetadata: any;
  changeCCFontStyle: any;
  onSuccessPrepared: any;
  onSuccessLoad: any;
  onFailedLoad: any;
  destroyDaiStream: any;
  neededCCTrack: any;
  setCurrentCue: any;
  seekToSuccessCb: any;
  checkPlayStatePosition: any;
  cleanUpCb: any;
  onPausedBeforeAd: any;
  drm?: any;
  onError: any;
  onCurrentPlaytime: any;
  onBufferingComplete: any;
  typeContent: string;
  onDurationChange?: any;
  isCaptionsEnabled?: boolean;
  children: any;
};

declare const Hls: any;

const HLSWrapper = ({
  onStreamPlay,
  onStreamPause,
  onStreamCompleted,
  onCanPlay,
  onParsingMetadata,
  changeCCFontStyle,
  onSuccessPrepared,
  onSuccessLoad,
  onFailedLoad,
  onError,
  destroyDaiStream,
  neededCCTrack,
  setCurrentCue,
  seekToSuccessCb,
  onPausedBeforeAd,
  checkPlayStatePosition,
  cleanUpCb,
  drm,
  onCurrentPlaytime,
  onBufferingComplete,
  onDurationChange,
  typeContent,
  isCaptionsEnabled,
  children,
}: HLSWrapperProps) => {
  const currentPlayerAction = useSelector((state: RootState) => state.player.playerAction);
  const playerUrl = useSelector((state: RootState) => state.player.url);
  const pointOfTime = useSelector((state: RootState) => state.player.pointOfTime);
  const refElement = useSelector((state: RootState) => state.player.el);
  const captionsStatusRef = useLatest(isCaptionsEnabled);

  const positionChangeInterval = useRef<any>(null);

  const prevPlayerActionName = useRef<string>('');
  const currentTimeUpdate = (event) => {
    const time = event.target?.currentTime;

    onCurrentPlaytime(getMilliseconds(time));
  };
  const onCanPlayWrapper = () => {
    const videoBand = hlsPlayer.getVideoBandwidth();
    const duration = hlsPlayer.getDuration();

    launchTimePositionTracker();

    onCanPlay && onCanPlay(videoBand, duration);
  };

  const launchTimePositionTracker = () => {
    clearInterval(positionChangeInterval.current);
    positionChangeInterval.current = setInterval(() => {
      const playerState = hlsPlayer.getState();

      if (['BUFFERING', 'PAUSED', 'IDLE'].includes(playerState)) {
        return;
      }

      checkPlayStatePosition(hlsPlayer.getCurrentTime());
    }, PLAYER_CHECK_POSITION_INTERVAL);
  };

  const onSuccessPreparedWrapper = () => {
    console.log('video and hls.js are now bound together !');
    onSuccessPrepared && onSuccessPrepared();
  };

  const onSuccessWrapper = () => {
    const videoBand = hlsPlayer.getVideoBandwidth();
    const duration = hlsPlayer.getDuration();

    onSuccessLoad && onSuccessLoad(videoBand, duration);
  };

  const openFailed = (e, data) => {
    console.log('Hls ERROR details', data);
    console.log('Hls ERROR event', e);

    if (data.details === Hls.ErrorDetails.BUFFER_STALLED_ERROR) {
      hlsPlayer.setState('BUFFERING');
    }

    onFailedLoad && onFailedLoad(e, data);
    onError && onError(e, data);
  };

  const onParsingMetadataWrapper = () => {
    captionsStatusRef.current && hlsPlayer.showCaptions(neededCCTrack, setCurrentCue);
    onParsingMetadata && onParsingMetadata();
  };

  const onFragBuffered = () => {
    hlsPlayer.setState('PLAYING');
  };

  // Events for ref Element
  const cbsVideoElListeners = [
    ['canplay', onCanPlayWrapper],
    ['ended', onStreamCompleted],
  ];

  const openLiveCbs = [
    ...cbsVideoElListeners,
    ['play', onStreamPlay],
    ['pause', onStreamPause],
  ];

  const openVODCbs = [
    ...cbsVideoElListeners,
    ['timeupdate', currentTimeUpdate],
    ['durationchange', onDurationChange],
  ];

  // Events for player
  const cbsCommonListeners = [
    ['BUFFER_APPENDED', onBufferingComplete],
    ['MEDIA_ATTACHED', onSuccessPreparedWrapper],
    ['ERROR', openFailed],
  ];

  const cbsVODListeners = [
    ['MANIFEST_PARSED', onSuccessWrapper],
    ...cbsCommonListeners,
  ];

  const cbsLiveListeners = [
    ['FRAG_BUFFERED', onFragBuffered],
    ['MANIFEST_PARSED', onSuccessLoad],
    ['FRAG_PARSING_METADATA', onParsingMetadataWrapper],
    ['SUBTITLE_TRACKS_UPDATED', changeCCFontStyle],
    ['NON_NATIVE_TEXT_TRACKS_FOUND', changeCCFontStyle],
    ['LEVEL_LOADED', changeCCFontStyle],
    ...cbsCommonListeners,
  ];

  useEffect(() => {
    // cleanup at unmount of the component
    return () => {
      clearInterval(positionChangeInterval.current);
      if (hlsPlayer.getPlayerIsLive()) {
        if (typeContent === 'live') {
          destroyDaiStream();
          hlsPlayer.close(openLiveCbs, cbsLiveListeners);
        } else {
          hlsPlayer.close(openVODCbs, cbsVODListeners);
        }
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const isRestrictedActionOnLoad = ['destroyDaiStream', 'close'].includes(currentPlayerAction);

    if (!currentPlayerAction || (isRestrictedActionOnLoad && !prevPlayerActionName.current)) {
      return;
    }

    const getPlayerCb = () => {
      const playerCb = {
        initialize: () => {
          const drmObj = drm?.AuthXML ? { LicenseServer: drm.WVserver, CustomData: drm.AuthXML } : {};

          hlsPlayer.initialize(refElement, drmObj);
        },
        play: () => {
          hlsPlayer.play();
        },
        pause: () => {
          hlsPlayer.pause();
        },
        suspend: () => {
          hlsPlayer.suspend();
        },
        restore: () => {
          hlsPlayer.restore();
        },
        seek: () => {
          hlsPlayer.seekTo(pointOfTime, seekToSuccessCb);
        },
        showCaptions: () => {
          hlsPlayer.showCaptions(neededCCTrack, setCurrentCue);
        },
        openVOD: () => {
          hlsPlayer.prepare(null, null, cbsVODListeners);
          hlsPlayer.open(playerUrl, null, null, openVODCbs);
        },
        openLive: () => {
          hlsPlayer.prepare(null, null, cbsLiveListeners);
          hlsPlayer.open(playerUrl, null, null, openLiveCbs);
        },
        cleanUp: () => {
          cleanUpCb(hlsPlayer.getCurrentTime(), hlsPlayer.getDuration());
        },
        close: () => {
          if (typeContent === 'live') {
            hlsPlayer.close(openLiveCbs, cbsLiveListeners);
          } else {
            hlsPlayer.close(openVODCbs, cbsVODListeners);
          }
        },
        pauseBeforeAd: () => {
          onPausedBeforeAd(hlsPlayer.getCurrentTime());
          hlsPlayer.pause();
        },
        destroyDaiStream: () => {
          hlsPlayer.getPlayerIsLive() && destroyDaiStream();
          hlsPlayer.close(openLiveCbs, cbsLiveListeners);
        },
      };

      if (typeof playerCb?.[currentPlayerAction] === 'function') {
        return playerCb?.[currentPlayerAction]();
      }

      return;
    };

    const checkConditionForExecution = () => {

      const isAllowedRepeatedAction = ['seek'].includes(currentPlayerAction);

      return ((currentPlayerAction !== prevPlayerActionName.current) || isAllowedRepeatedAction);
    };

    if (checkConditionForExecution()) {
      getPlayerCb();
      prevPlayerActionName.current = currentPlayerAction;
    }
    // eslint-disable-next-line
  }, [currentPlayerAction]);

  return <>{children}</>;
};

export default HLSWrapper;
