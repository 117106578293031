import * as actionTypes from './types/common';

export interface SetStationAction {
  type: typeof actionTypes.SET_STATION;
  station: string;
}

export interface RemoveStationAction {
  type: typeof actionTypes.REMOVE_STATION;
}

export interface SetCityAction {
  type: typeof actionTypes.SET_CITY;
  city: string;
}

export interface RemoveCityAction {
  type: typeof actionTypes.REMOVE_CITY;
}

interface SetActiveNavIdx {
  type: typeof actionTypes.SET_ACTIVE_NAV;
  navIdx: number;
}

interface SetAppVersionAction {
  type: typeof actionTypes.SET_APP_VERSION;
  appVersion: string;
}

interface NetworkStatus {
  type: typeof actionTypes.NETWORK_CONNECTED;
  isConnected: boolean;
}

export interface ITTSStatus {
  type: typeof actionTypes.SET_TTS_STATUS;
  status: boolean;
}

export interface ICCStatus {
  type: typeof actionTypes.SET_CC_STATUS;
  status: boolean;
}

interface ToggleExitModal {
  type: typeof actionTypes.TOGGLE_EXIT_MODAL;
}

interface ToggleDebugModal {
  type: typeof actionTypes.TOGGLE_DEBUG_SETTINGS;
}

interface IToggleSpoilerPreventionStatus {
  type: typeof actionTypes.TOGGLE_SPOILER_PREVENTION_SETTINGS;
}

type CityAction = SetCityAction | RemoveCityAction;

type StationAction = SetStationAction | RemoveStationAction;

type SystemAction
  = ICCStatus
  | ITTSStatus
  | NetworkStatus
  | ToggleExitModal
  | ToggleDebugModal
  | IToggleSpoilerPreventionStatus;

export type Action = SystemAction | CityAction | StationAction | SetActiveNavIdx | SetAppVersionAction;

export const setStation = (station: string): Action => ({
  type: actionTypes.SET_STATION,
  station,
});

export const removeStation = (): Action => ({
  type: actionTypes.REMOVE_STATION,
});

export const setCity = (city: string): Action => ({
  type: actionTypes.SET_CITY,
  city,
});

export const removeCity = (): Action => ({
  type: actionTypes.REMOVE_CITY,
});

export const setActiveNavIdx = (navIdx: number): Action => ({
  type: actionTypes.SET_ACTIVE_NAV,
  navIdx,
});

export const networkConnection = (isConnected: boolean): Action => ({
  type: actionTypes.NETWORK_CONNECTED,
  isConnected,
});

export const setTTSStatus = (status: boolean): Action => ({
  type: actionTypes.SET_TTS_STATUS,
  status,
});

export const setCaptionsStatus = (status: boolean): Action => ({
  type: actionTypes.SET_CC_STATUS,
  status,
});

export const toggleExitModal = (): Action => ({
  type: actionTypes.TOGGLE_EXIT_MODAL,
});

export const toggleDebugModal = (): Action => ({
  type: actionTypes.TOGGLE_DEBUG_SETTINGS,
});

export const setAppVersion = (appVersion: string): Action => ({
  type: actionTypes.SET_APP_VERSION,
  appVersion,
});

export const toggleSpoilerPreventionStatus = (): Action => ({
  type: actionTypes.TOGGLE_SPOILER_PREVENTION_SETTINGS,
});
