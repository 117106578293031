import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from '../../stores/reducers';
import {
  CHARGE_OTT_BRAND,
  OTT_BRAND,
  OTTSubscribeType,
  TV_PROVIDER_TAG,
  OTTSettingsType,
  isStirrTcMarqueeBrand,
  isStirrBrand,
  TV_PROVIDER_ID_TO_SHOW,
} from '../../constants/structureTypes';
import FocusService from '../../services/focusService';
import { INavigationItem } from '../../stores/reducers/config';
import { setActiveNavIdx } from '../../stores/actions/common';
import { navigate, navigateWithReset } from '../../services/NavigationService';
import { IJson, openPage } from '../../utils/OpenPage';
import { CITY_SELECTION_TAG, USER_NAME_TAG } from '../../constants/text';
import { GAEvent } from '../../services/analytics';
import { NAVIGATE, OPEN } from '../../constants/analyticsTypes';

import './NavigationMenu.scss';

type NavigationMenuProps = {
  focusDownSelector?: string;
  onEnterCallback?: () => void;
  isNavBarVisible?: boolean;
  isMenuFocusedOnStart?: boolean;
};

const NavigationMenu = (props: NavigationMenuProps) => {
  const {
    isNavBarVisible = true,
    isMenuFocusedOnStart = true,
    onEnterCallback,
    focusDownSelector = '',
  } = props;

  const navigationConfig = useSelector((state: RootState) => state.config.navigation);
  const navigationStyles = useSelector((state: RootState) => state.config.styles.topnav);
  const brandLogo = useSelector((state: RootState) => state.config.styles.general.logoColor);
  const activeNavigationItemIndex = useSelector((state: RootState) => state.common.activeNavIdx);
  const storedStation = useSelector((state: RootState) => state.common.station);
  const commonCity = useSelector((state: RootState) => state.common.city);
  const cleengFeature = useSelector((state: RootState) => state.config.features).cleeng || false;
  const isTCUserLogin = useSelector((state: RootState) => state.Login.isUserTCLogin);
  const isCurrentSubscriptionActive = useSelector(
    (state: RootState) => state.cleengService.isCurrentSubscriptionActive
  );
  const providerLogo = useSelector((state: RootState) => state.Login.mvpd.mvpdLogo);
  const mvpdProviderId = useSelector((state: RootState) => state.Login.mvpd.mvpdId);
  const userEmail = useSelector((state: RootState) => state.Login.subscription?.email);

  const [isMenuShown, setIsMenuShown] = useState<boolean>(false);
  const [focusedItemIndex, setFocusedItemIndex] = useState<number>(
    isMenuFocusedOnStart
      ? activeNavigationItemIndex
      : -1
  );
  const [shouldAnnounceCityName, setShouldAnnounceCityName] = useState<boolean>(true);

  const initialFocusItem = useRef<HTMLLIElement>(null);
  const focusTimerRef = useRef<any>(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const navigationData = useMemo((): INavigationItem[] => {
    return (cleengFeature && isTCUserLogin && isCurrentSubscriptionActive)
      ? navigationConfig.filter(navItem => navItem.type !== OTTSubscribeType)
      : navigationConfig;
  }, [cleengFeature, navigationConfig, isTCUserLogin]);

  const navOnlyFocusableItems = useMemo(
    () => navigationData.filter(item => Boolean(item?.focusable)), [navigationData]
  );

  useEffect(() => {
    isNavBarVisible && isMenuFocusedOnStart && FocusService.setFocus(initialFocusItem.current);
    if (navigationStyles?.icons) {
      const loadImage = (imageURL:string) => {
        return new Promise((resolve) => {
          const loadImg = new Image();

          loadImg.src = imageURL;
          loadImg.onload = () => resolve(imageURL);
          loadImg.onerror = () => resolve(imageURL);
        });
      };

      Promise.all(([].concat(Object.values(navigationStyles.icons))
        .map(Object.values)).flat()
        .map(loadImage))
        .catch(err => console.log('Failed to load images', err));
    }

    return () => FocusService.setFocusDelay(0);
    // eslint-disable-next-line
  }, []);

  const selectNavItem = (item: INavigationItem, index: number):void => {
    const navigationMethod = index !== 0 ? navigate : navigateWithReset;

    dispatch(setActiveNavIdx(index));
    navigationMethod(
      history,
      openPage(
        item as unknown as IJson,
        {
          station: storedStation,
          navIdx: index,
          page: item.type,
          isFirstLevelPage: true,
        },
      ),
      onEnterCallback,
    );
    GAEvent(NAVIGATE, OPEN, item.title);
  };

  const isFocusableItem = (item: INavigationItem): boolean => {
    // TODO: remove this further (update all brands navigation response)
    const hasFocusableProp: boolean = Object.prototype.hasOwnProperty.call(item, 'focusable');

    return item.focusable || !hasFocusableProp;
  };

  const generateClassName = (item: INavigationItem, index: number): string => {
    return [
      'menu-item',
      `menu-item-${index}`,
      focusedItemIndex === index && 'navigation-menu-item-focused',
      item.icon ? 'menu-icon-item' : `menu-title-item menu-title-item-${OTT_BRAND}`,
      item.align === 'right' && 'menu-right-aligned-item',
      !isFocusableItem(item) && 'menu-unfocusable-item',
      item.tag === TV_PROVIDER_TAG && mvpdProviderId === TV_PROVIDER_ID_TO_SHOW && 'menu-unfocusable-item-logo',
      item.tag === USER_NAME_TAG && 'menu-item-user-name',
    ].filter(Boolean).join(' ');
  };

  const onItemFocus = (item: INavigationItem, index: number):void => {
    if (!isMenuShown) {
      setShouldAnnounceCityName(true);
      setIsMenuShown(true);
    }

    setFocusedItemIndex(index);
    FocusService.setFocusDelay(0);
    clearTimeout(focusTimerRef.current);

    focusTimerRef.current = setTimeout(() => {
      index !== activeNavigationItemIndex &&
        selectNavItem(item, index);
      clearTimeout(focusTimerRef.current);
    }, 2000);
  };

  const onItemBlur = (): void => {
    setShouldAnnounceCityName(false);
    setFocusedItemIndex(-1);
    clearTimeout(focusTimerRef.current);
    FocusService.setFocusDelay(0);
    setIsMenuShown(false);
  };

  const getNavItemContent = (item: INavigationItem, isXfinityTvProvider:boolean):string|React.ReactElement => {
    if (isXfinityTvProvider) {
      return (<img alt='tv provider logo' src={providerLogo} />);
    } else if (item?.tag === CITY_SELECTION_TAG) {
      return commonCity.toUpperCase();
    } else if (item.type === OTTSettingsType && userEmail) {
      return (<p className ='menu-icon-text'>{userEmail[0].toUpperCase()}</p>);
    } else if (item?.icon) {
      return '';
    } else {
      return item.title?.toUpperCase();
    }
  };

  const getNavItemStyle = (item, index:number) => {
    const isActive = activeNavigationItemIndex === index;
    const isFocused = focusedItemIndex === index;
    let style = {};

    if (!isStirrTcMarqueeBrand) {
      style = {
        color: `${navigationStyles.textColor}`,
        fontFamily: isActive
          ? navigationStyles.fontFaceFocus.fontFamily
          : navigationStyles.fontFace.fontFamily,
        borderBottom: (focusedItemIndex === index)
          ? `${navigationStyles.buttonBorderSizeSelect}px solid ${navigationStyles.buttonColorSelect}`
          : '',
      };
    } else {
      if ((item?.icon_name || item?.icon) && navigationStyles.icons[item.icon_name]) {
        const icon = navigationStyles.icons[item.icon_name];
        let state = 'default';

        if (item.type === OTTSettingsType && userEmail) {
          state = `signedIn${isFocused ? 'Focus' : isActive ? 'Active' : 'Default'}`;
        } else {
          state = isFocused ? 'focus' : isActive ? 'active' : 'default';
        }
        style = {
          backgroundImage: `url(${icon?.[state] || item.icon || ''})`,
          color: item.type === OTTSettingsType && userEmail
            ? isActive && !isFocused
              ? navigationStyles.buttonColorSelect : navigationStyles.textColorFocus
            : 'transparent',
        };
      } else {
        style = {
          color: `${ isFocused
            ? isStirrBrand ? 'black' : navigationStyles.textColorFocus
            : navigationStyles.textColor}`,
          fontFamily: isActive
            ? navigationStyles.fontFaceFocus.fontFamily
            : navigationStyles.fontFace.fontFamily,
          backgroundColor: isFocused ? navigationStyles.buttonColorSelect : '',
        };
      }
    }

    return style;
  };

  return (
    <div
      className={`navigation-menu-container${OTT_BRAND === CHARGE_OTT_BRAND ? '-charge' : ''}
      navigation-menu-container${(isMenuShown || isNavBarVisible) ? '-show' : '-hide'}`}
      style={{ background: navigationStyles.background.image || navigationStyles.background}}
    >
      {brandLogo &&
      (<div className="navigation-menu-logo-wrapper">
        <img src={brandLogo} className="navigation-menu-logo"/>
      </div>
      )}
      <ul className={`navigation-menu-list navigation-menu-list-${OTT_BRAND}`}>
        {navigationData.map((item, index) => {
          const isXfinityTvProvider = item.tag === TV_PROVIDER_TAG && mvpdProviderId === TV_PROVIDER_ID_TO_SHOW;
          const isActive = activeNavigationItemIndex === index;

          return (
            <>
              {(item.title || isXfinityTvProvider) ? (
                <li
                  key={index}
                  ref={isActive ? initialFocusItem : null}
                  id={isActive ? 'navigationSelectedItem' : ''}
                  // eslint-disable-next-line
                  role=''
                  aria-label={`${item.title}. ${isActive && shouldAnnounceCityName ? commonCity : ''}`}
                  className={generateClassName(item, index)}
                  style ={getNavItemStyle(item, index)}
                  data-tv-focus-top={`.menu-item-${index}`}
                  data-tv-focus-left={`.menu-item-${index - (index === 0 ? 0 : 1)}`}
                  data-tv-focus-right={`.menu-item-${index + (index === navOnlyFocusableItems.length - 1 ? 0 : 1)}`}
                  data-tv-focus-down={focusDownSelector}
                  {...(isFocusableItem(item) && {
                    tabIndex: 0,
                    onFocus: () => onItemFocus(item, index),
                    onBlur: () => onItemBlur(),
                  })}
                >
                  {getNavItemContent(item, isXfinityTvProvider)}
                </li>
              ) : null}
            </>);
        })}
      </ul>
    </div>
  );
};

export default React.memo(NavigationMenu);
