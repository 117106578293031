import {
  apply,
  contains,
  fromPairs,
  ifElse,
  map,
  pipe,
  test,
  toPairs,
} from 'ramda';
import {getLinearGradient, getColor} from './color';
import {getFont} from './font';

const snakeToCamel = str => {
  try {
    return str.replace(
      /([-_][a-zA-Z])/g,
      group => group.toUpperCase()
        .replace('-', '')
        .replace('_', ''),
    );

  } catch (e) {
    return str;
  }
};

const isFontName = test(/(^|[-_])face([-_]|$)/);

const processFont = (key, val) => [snakeToCamel(key), getFont(val)];

export const parseObject = item => {

  if (typeof item !== 'object') {
    return item;
  }

  const keys = Object.keys(item);

  if (contains('linear_gradient', keys)) {

    return getLinearGradient(item);
  }

  if (contains('rgba', keys)) {
    return getColor(item);
  }

  return pipe(
    toPairs,
    map(ifElse(
      apply(isFontName),
      apply(processFont),
      apply((key, val) => [snakeToCamel(key), parseObject(val)]),
    )),
    fromPairs,
  )(item);
};
