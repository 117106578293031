import React, { forwardRef, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { getKeyInfo } from '../../../utils';
import FocusService from '../../../services/focusService';
import { RootState } from '../../../stores/reducers';

interface ICSSProperties {
  height?: string | number;
  width?: string | number;
  marginLeft?: string | number;
  marginRight?: string | number;
  marginTop?: string | number;
  marginBottom?: string | number;
}

type CardWrapperProps = {
  style: ICSSProperties;
  focused: (e: React.KeyboardEvent, index: number, carouselId: number) => void;
  index: number;
  child: any;
  keyPressed: (index: number, e: React.KeyboardEventHandler, season?: string) => void;
  first: boolean;
  last: boolean;
  dynamic?: boolean;
  focusUp?: string | null;
  focusDown?: string;
  seasonId?: string;
  carouselId: number;
  isMoveLeft?: boolean;
  isLastCarousel?: boolean;
  carouselTitle?: string;
  isCitySelectionCard?: boolean;
  borderColorFocusOn: string;
  lastFocusedSeasonItem?: number;
};

const CardWrapper = (props: CardWrapperProps, ref) => {
  const {
    style,
    focused,
    index,
    child,
    keyPressed,
    first,
    seasonId,
    last,
    dynamic,
    carouselId,
    focusDown,
    focusUp,
    isMoveLeft,
    isLastCarousel,
    carouselTitle,
    isCitySelectionCard = false,
    borderColorFocusOn,
    lastFocusedSeasonItem,
  } = props;

  const TTSEnabled = useSelector((state: RootState) => state.common.TTSEnabled);

  const initFocus = useRef<any>(null);

  const wrapperStyle = {
    height: `${style.height}px`,
    width: !dynamic ? `${style.width}px` : 'auto',
    marginLeft: first && !dynamic ? 15 : style.marginLeft,
    marginRight: style.marginRight,
    marginTop: style.marginTop,
    marginBottom: style.marginBottom,
  };

  const cardTitleForTTS = () => {
    if (child.props.isEpgOpen) {
      return `${child.props.isLiveProgram ? 'Live' : ''}. ${child.props.title} ${child.props.programInfoForTTS}`;
    } else {
      return `${child.props.isLiveVideo === 'true' ? 'Live' : ''}. ${child.props.title ? child.props.title : ''}`;
    }
  };

  const textForFirstOrLastItem = () => {
    let ttsText = '';
    const ttsTextForRow = isLastCarousel ? 'last ' : '';

    if (first) {
      ttsText += `first item in the ${ttsTextForRow}row`;
    } else if (last) {
      ttsText += `last item in the ${ttsTextForRow}row`;
    } else {
      ttsText += isLastCarousel ? 'item in the last row' : '';
    }

    return ttsText;
  };

  const carouselTitleForTTS = `${child.props?.isEpgOpen ? '' : carouselTitle}`;

  const TTS_Text = `${carouselTitleForTTS}. ${isCitySelectionCard && child.props.cityDescription
    ? child.props.cityDescription
    : ''} ${cardTitleForTTS()}. ${child.props?.TCPlusContent ? 'PLUS' : ''}. ${textForFirstOrLastItem()}`;

  const onKeyDown = (e) => {
    if (keyPressed && getKeyInfo(e.keyCode) === 'Enter') {
      keyPressed(index, e, seasonId);
    }
  };

  const onCardWrapperGetFocus = (e) => {
    focused(e, index, carouselId);

    FocusService.setFocusDelay(500);

    const selectedCardImage = e.target.querySelector('img.card-image');
    const selectedSeasonItem = e.target.querySelector('div.season-item-wrapper');

    if (selectedCardImage) {
      selectedCardImage.style.borderColor = borderColorFocusOn;
    } else if (selectedSeasonItem) {
      e.target.style.borderBottom = `5px solid ${borderColorFocusOn}`;
    }
  };

  const onCardWrapperGetBlur = (e) => {
    e.target.style.borderBottom = '5px solid transparent';
  };

  useEffect(() => {
    if (isCitySelectionCard && !TTSEnabled) {
      FocusService.setFocus(initFocus.current);
    }
    // eslint-disable-next-line
  }, [isCitySelectionCard]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.classList.add(`carousel-selected-${carouselId}`);
    }
  }, [carouselId, ref]);

  return (
    <div style={wrapperStyle}>
      <div
        id={`card-wrapper-${child.props.cardId}-${index}`}
        ref={carouselId === 1 && index === 0 ? initFocus : ref}
        onFocus={onCardWrapperGetFocus}
        onBlur={onCardWrapperGetBlur}
        onKeyDown={onKeyDown}
        // eslint-disable-next-line
        role={''}
        tabIndex={0}
        className={
          `card-main index-${carouselId}-${index} 
          ${carouselId}rowIndex
          ${lastFocusedSeasonItem === index && dynamic ? 'season-list-last-focused-item' : ''}`
        }
        aria-label={TTS_Text}
        data-tv-focus-up={focusUp}
        data-tv-focus-left={
          first ? !isMoveLeft ? `.index-${carouselId}-${index}` : '' : `.index-${carouselId}-${index - 1}`
        }
        data-tv-focus-down={
          isLastCarousel ? `.index-${carouselId}-${index}` : focusDown
        }
        data-tv-focus-right={
          last ? `.index-${carouselId}-${index}` : `.index-${carouselId}-${index + 1}`
        }
      >
        {child}
      </div>
    </div>
  );
};

export default forwardRef(CardWrapper);
