import {equals, pathOr} from 'ramda';
import {convertXmltvDate, getProgramHash} from './utils';
import { isStirrTcMarqueeBrand } from '../../constants/structureTypes';
import moment from 'moment';

const isVerticalEPG = !isStirrTcMarqueeBrand;

export const normalizeProgram = program => {

  return {
    id: getProgramHash(program),
    title: program?.title?.value || program?.program?.title || null,
    isLiveProgram: equals('true', pathOr(null, ['sinclair:isLiveProgram'], program)),
    desc: program?.desc?.value || program?.program?.longDescription || null,
    date: convertXmltvDate(program?.date),
    rating: program?.rating?.value || null,
    categories: program?.category,
    start: isVerticalEPG
      ? program?.start || program?.startTime
      : convertXmltvDate(program?.start),
    startLong: moment(convertXmltvDate(program?.start) || program?.startTime, 'YYYY-MM-DD HH:mm Z').unix(),
    stop: isVerticalEPG
      ? program?.stop || program?.endTime
      : convertXmltvDate(program?.stop),
    stopLong: moment(convertXmltvDate(program?.stop) || program?.endTime, 'YYYY-MM-DD HH:mm Z').unix(),
    channel: program?.channel || '',
  };
};
