import React, { MutableRefObject, useEffect, useRef } from 'react';

import STANDARD_TEASER_LIST from '../../STANDARD_TEASER_LIST/STANDARD_TEASER_LIST';
import ScrollableList from '../../ScrollableList/ScrollableList';

interface SEASON_TEASER_LIST_Props {
  content: any;
  onCardPress: () => null;
  isCarouselExisted: boolean;
  selected: number;
  seasonIndex: number;
  seasonId: string;
  translateValueRef: MutableRefObject<number>;
  defaultCardIndex?: number;
  seasonDefaultCarouselID?: string;
}

const SEASON_TEASER_LIST = (props: SEASON_TEASER_LIST_Props) => {
  const {
    content,
    onCardPress,
    selected,
    seasonId,
    seasonIndex,
    translateValueRef,
    defaultCardIndex,
    seasonDefaultCarouselID,
  } = props;

  const isFirstFocusedOnMount = useRef<boolean>(true);

  useEffect(() => {
    isFirstFocusedOnMount.current = false;
  }, []);

  return (
    <>
      {selected === seasonIndex ? (
        <ScrollableList
          transition={0.3}
          translateValueRef={translateValueRef}
          isSeasonsPage={true}
        >
          {content.map((item, index) => (
            <div key={index}>
              <STANDARD_TEASER_LIST
                content={item.component}
                onSelect={() => null}
                seasonId={seasonId[index]}
                index={index + 2}
                title={item?.displayTitle}
                onPress={onCardPress}
                isLastCarousel={index === content.length - 1}
                defaultCardIndex={defaultCardIndex}
                seasonDefaultCarouselID={seasonDefaultCarouselID}
                carouselUUID={item?.pageComponentUuid}
              />
            </div>
          ))}
        </ScrollableList>
      ) : null}
    </>
  );
}
;

export default SEASON_TEASER_LIST;
