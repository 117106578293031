import { REACT_APP_DEVICE_NAME } from '../constants/structureTypes';

import { FireTVPlatform } from './FireTVPlatform/FireTVPlatform';
import { TizenPlatform } from './TizenPlatform/TizenPlatform';
import { WebPlatform } from './WebPlatform';

const currentNamePlatform = REACT_APP_DEVICE_NAME.toLowerCase();
let currentPlatformObj: any;

if (currentNamePlatform === 'web') {
  currentPlatformObj = new WebPlatform();
} else if (currentNamePlatform === 'tizen') {
  currentPlatformObj = new TizenPlatform();
} else if (currentNamePlatform === 'firetv') {
  currentPlatformObj = new FireTVPlatform();
} else {
  currentPlatformObj = new WebPlatform();
}

export default currentPlatformObj;
