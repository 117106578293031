import * as actionTypes from '../actions/types/OTTText';

export type Message = string | object;

interface LoadPageAction {
    type: typeof actionTypes.LOAD_OTT_TEXT_DATA;
    url: string;
  }

interface LoadPageSucceededAction {
  type: typeof actionTypes.LOAD_OTT_TEXT_DATA_SUCCEEDED;
  payload: string;
}

interface LoadPageFailedAction {
  type: typeof actionTypes.LOAD_OTT_TEXT_DATA_FAILED;
  error: string;
}

interface ClearOTTText {
  type: typeof actionTypes.CLEAR_OTT_TEXT;
}

type PageAction = LoadPageAction | LoadPageSucceededAction | LoadPageFailedAction;

export type Action = PageAction | ClearOTTText;

export const setPageData = (url: string): Action => {
  return ({
    type: actionTypes.LOAD_OTT_TEXT_DATA,
    url,
  });
};
