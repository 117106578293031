import {
  PLAYER_CHANGE_ACTION,
  PLAYER_CLOSE,
  PLAYER_DESTROY_DAI_STREAM,
  PLAYER_OPEN_VOD_URL,
  PLAYER_OPEN_LIVE_URL,
  PLAYER_SEEK_TO, PLAYER_INITIALIZE,
} from '../actions/types/player';

const initialState: any = {
  playerAction: '',
  url: '',
  pointOfTime: 0,
  el: null,
};

const player = (state = initialState, action) => {
  switch (action.type) {
    case PLAYER_CHANGE_ACTION:
      return { ...state, playerAction: action.actionName };
    case PLAYER_OPEN_VOD_URL:
      return { ...state, playerAction: 'openVOD', url: action.url };
    case PLAYER_OPEN_LIVE_URL:
      return { ...state, playerAction: 'openLive', url: action.url };
    case PLAYER_SEEK_TO:
      return { ...state, playerAction: 'seek', pointOfTime: action.pointOfTime };
    case PLAYER_INITIALIZE:
      return { ...state, playerAction: 'initialize', el: action.el };
    case PLAYER_DESTROY_DAI_STREAM:
      return { ...initialState, playerAction: 'destroyDaiStream' };
    case PLAYER_CLOSE:
      return { ...initialState, playerAction: 'close' };
    default:
      return state;
  }
};

export default player;
