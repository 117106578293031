import React from 'react';
import Carousel from '../../components/Carousel/Carousel';
import Card from '../../components/Carousel/Card/Card';
import { TEASER_LIST_PROPS } from '../STANDARD_TEASER_LIST/STANDARD_TEASER_LIST';
import { THREE_SLIDES_VALUE } from '../../constants/structureTypes';
import { isContentWithRestriction } from '../../utils';
import { CLEENG_RESTRICTION } from '../../constants/text';

import './THREE_COLUMN_TEASER_LIST.scss';

const THREE_COLUMN_TEASER_LIST = (props: TEASER_LIST_PROPS) => {
  const { content, index, title, onSelect, onPress, isLastCarousel } = props;

  const cardStyles = {
    width: 550,
    height: 340,
    marginLeft: 20,
    marginRight: 35,
  };

  return (
    <div className="three-column-teaser-list">
      <p className="three-column-teaser-list_title">{title}</p>
      <Carousel
        cardStyles={cardStyles}
        carouselId={index}
        onCardSelection={onSelect}
        onCardPressed={onPress}
        slides={THREE_SLIDES_VALUE}
        carouselTitle={title}
        isCitySelectionCard={true}
        isLastCarousel={isLastCarousel}
      >
        {content.map((item) => (
          <Card
            TCPlusContent={
              isContentWithRestriction(item?.item['media:content']['media:restriction'], CLEENG_RESTRICTION)
            }
            width={cardStyles.width}
            alt={item.item['slug']}
            height={cardStyles.height}
            key={index}
            src={item.item['media:content']['media:thumbnail']}
            title={item.item['media:content']['media:title']['content']}
            cityDescription={item.item['media:content']['media:description']['content']}
            cardId={item.item['guid']['content']}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default THREE_COLUMN_TEASER_LIST;
