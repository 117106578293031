import axiosInstance from './axiosInstance';
import { PagePayload, ComponentPayload } from '../stores/actions/OTTPage';
import { addParametersToURL } from '../utils/commonFunc';
import { X_BRAND_HEADER } from '../constants/structureTypes';

export async function getPage(url: string): Promise<PagePayload> {
  const { data } = await axiosInstance.get(url);

  return {
    data,
  };
}

export async function getComponentContent(url: string, station: string): Promise<ComponentPayload | undefined> {

  try {
    const {
      data: {
        rss: {
          channel: {
            pagecomponent: { component },
          },
        },
      },
    } = await axiosInstance.get(addParametersToURL(url, {station}));

    return {
      component,
    };
  } catch (error) {
    console.log('Error in getting OTTpage components');
  }
}

export async function updateComponentContentFree(url: string, station: string) {
  const {
    data: {
      rss: {
        channel: {
          item,
        },
      },
    },
  } = await axiosInstance.get(addParametersToURL(url, {station}));

  return {
    item,
  };
}

export async function updateComponentContentSubscription(url: string) {
  const options = {
    headers: {
      'X-brand': X_BRAND_HEADER,
    },
  };
  const {
    data: {
      rss: {
        channel: {
          item,
        },
      },
    },
  } = await axiosInstance.get(url, options);

  return {
    item,
  };
}
