import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  FORGOT_PASSWORD,
  RESET_PASSWORD, SIGN_IN, SIGN_IN_FORM_SUBMIT_BTN,
  SIGN_UP_FORM_SUBMIT_BTN, SUBSCRIBE,
  TC_PLUS_FORM_SIGN_IN_BTN,
} from '../../../constants/text';
import { loginEmail, createUser } from '../../../stores/actions/Login';
import { RootState } from '../../../stores/reducers';
import FocusService from '../../../services/focusService';
import { GAEvent } from '../../../services/analytics';
import { ABANDON, AUTH_FAIL, CREATE_ACCOUNT, SUBSCRIPTION } from '../../../constants/analyticsTypes';
import { isTcBrand } from '../../../constants/structureTypes';
import { handleTSSMoveFocusToFirstEl } from '../../../utils/commonFunc';
import { stopTTS } from '../../../services/TTSService';
import { getCustomStyledSymbol, getTOSText } from '../../../utils/ui';
import EmailIcon from './../../../assets/Tennis_channel/icons/email_icon.svg';
import PasswordIcon from './../../../assets/Tennis_channel/icons/create_password_icon.svg';
import backArrowIcon from './../../../assets/icons/GoBackArrow.svg';

import Button from '../../Button/Button';
import Input from '../../Input/Input';
import Checkbox from '../../Checkbox/Checkbox';

import './TCSubscriptionForm.scss';

export type UserMetadata = {
  emailState?: string,
  passwordState?: string,
  newsletterState: boolean,
  tosState: boolean,
}

interface LoginScreenProps {
  setCurrPageType: (pageType: string) => void;
  setFlowFromCreateAccount: (isFromCreateAccount: boolean) => void;
  analyticsValue: () => string;
  goBack?: () => void;
  pageType: string,
  initFocusedEl: string;
  updateFocusedElInHistory: (focusedEl?: string) => void;
  userMetadata: UserMetadata;
  setUserMetadata: React.Dispatch<SetStateAction<UserMetadata>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const TCSubscriptionForm = ({
  setCurrPageType,
  analyticsValue,
  goBack,
  pageType,
  initFocusedEl,
  updateFocusedElInHistory,
  userMetadata: userMetadata,
  setUserMetadata: setUserMetadata,
  setIsLoading,
}: LoginScreenProps) => {

  const dispatch = useDispatch();

  const TTSStatus = useSelector((state: RootState) => state.common.TTSEnabled);
  const logoBrandPlus = useSelector((state: RootState) => state.config.styles?.['general']?.['logoPlus']);
  const errorColor = useSelector((state: RootState) => state.config.styles?.['general']?.['failureColor']);
  const headingStyles = useSelector((state: RootState) => state.config.styles.general?.heading);
  const subscribeStyles = useSelector((state: RootState) => state.config.styles['subscribe']);
  const focusTOSColor = useSelector((state: RootState) => state.config.styles?.colors?.accents?.primary);
  const signInBgImg = useSelector((state: RootState) => state.config.styles?.account?.signInImageFull);
  const signUpBgImg = useSelector((state: RootState) => state.config.styles?.account?.signUpImageFull);
  const signInTitle = useSelector((state: RootState) => state.config.subscription['sign_in_title']);
  const signUpTitle = useSelector((state: RootState) => state.config.subscription['sign_up_title']);
  const signInDetails = useSelector((state: RootState) => state.config.subscription['sign_up_detail']);
  const isTCUserLogin = useSelector((state: RootState) => state.Login.isUserTCLogin);
  const signInOrUpError = useSelector((state: RootState) => state.Login.subscription.signInOrUpError);
  const titleFont = useSelector((state: RootState) => state.config.styles.general?.heading?.titleFont);
  const fontFamily = useSelector((state: RootState) => state.config.styles?.['font']?.['face']?.['fontFamily']);
  const newsletterCheckboxOptions = useSelector(
    (state: RootState) => state.config.subscription['newsletter_checkbox_options']
  );
  const tosCheckboxOptions = useSelector((state: RootState) => state.config.subscription['tos_checkbox_options']);

  const isSignInForm = pageType === SIGN_IN;

  const pageData = {
    [SUBSCRIBE]: {
      title: signUpTitle,
      bgImg: signUpBgImg,
      subtitle: '',
      btnText: TC_PLUS_FORM_SIGN_IN_BTN,
      submitBtnText: SIGN_UP_FORM_SUBMIT_BTN,
      inputPlaceholderText: 'Create password',
      pageDetails: signInDetails,
      logo: '',
    },
    [SIGN_IN]: {
      title: signInTitle,
      bgImg: signInBgImg,
      subtitle: 'Sign in to access your account',
      btnText: FORGOT_PASSWORD,
      submitBtnText: SIGN_IN_FORM_SUBMIT_BTN,
      inputPlaceholderText: 'Enter password',
      pageDetails: '',
      logo: logoBrandPlus,
    },
  };

  const isSubmitBtnDisabled = useMemo(() =>
    !userMetadata?.emailState?.trim()
    || !userMetadata?.passwordState?.trim()
    || (!isSignInForm && tosCheckboxOptions['required'] && !userMetadata?.tosState),
  [userMetadata?.emailState?.trim(), userMetadata?.passwordState?.trim(), userMetadata?.tosState, isSignInForm]);

  useEffect(() => {
    const targetElement = !isSubmitBtnDisabled
      ? '.btn-acc-0'
      : userMetadata?.newsletterState && !userMetadata?.tosState
        ? '.checkbox-acc-1'
        : '';

    if (targetElement) {
      FocusService.setFocus(document.querySelector(targetElement));
    }
  }, [userMetadata?.newsletterState, userMetadata?.tosState]);

  const submitForm = () => {
    if (userMetadata?.emailState?.trim() && userMetadata?.passwordState?.trim()) {
      updateFocusedElInHistory(document.activeElement?.id);
      setIsLoading(true);
      dispatch(isSignInForm
        ? loginEmail({
          email: userMetadata?.emailState,
          password: userMetadata?.passwordState,
        })
        : createUser({
          email: userMetadata?.emailState,
          password: userMetadata?.passwordState,
          newsletter: userMetadata?.newsletterState,
        })
      );
    }
  };

  const changeForm = () => {
    setCurrPageType(isSignInForm ? RESET_PASSWORD : SIGN_IN);
  };

  useEffect(() => {
    return () => {
      stopTTS();
    };
  }, [isTCUserLogin]);

  useEffect(() => {
    if (signInOrUpError) {
      if (isSignInForm) {
        GAEvent(SUBSCRIPTION, AUTH_FAIL, analyticsValue());
      } else {
        GAEvent(CREATE_ACCOUNT, ABANDON, analyticsValue());
      }

    }
  }, [signInOrUpError]);

  useEffect(() => {
    const firstInput = initFocusedEl
      ? document.getElementById(initFocusedEl)
      : document.querySelector('.input-wrapper-acc-0');

    TTSStatus && firstInput ? FocusService.setFocusToSilentEl() : FocusService.setFocus(firstInput);

    const phraseToSpeak = `${pageData[pageType]?.title}.
                           ${signInOrUpError ? signInOrUpError : pageData[pageType]?.subtitle}.
                           ${pageData[pageType]?.pageDetails || ''}`;

    handleTSSMoveFocusToFirstEl(TTSStatus, phraseToSpeak, firstInput);
    // eslint-disable-next-line
  }, [pageType, initFocusedEl]);

  return (
    <div
      className='subscription-form-screen'
      style={{ backgroundImage: 'url(' + pageData[pageType]?.bgImg + ')' }}
    >
      <div
        // eslint-disable-next-line
        role=''
        className='subscription-form-wrapper'
      >
        {pageData[pageType]?.logo && <img
          src={logoBrandPlus}
          className='logo' alt='Tennis Channel Subscribe Logo' />}
        <form
          // eslint-disable-next-line
          role=''
          action=''
          method=''
          autoComplete='on'
        >
          <div
            className='form-inputs-wrapper'
            // eslint-disable-next-line
            role=''
          >
            <h2
              className='subscription-form-title'
              style={{ fontFamily: titleFont }}
              // eslint-disable-next-line
              role=''
            >
              {pageData[pageType]?.title}{isTcBrand && getCustomStyledSymbol('.', headingStyles.accentColor)}
            </h2>
            <p
              className={`subscription-form-subtitle ${signInOrUpError && 'sign-in-error-text'}`}
              style={{color: signInOrUpError ? errorColor : ''}}
            >
              {signInOrUpError ? signInOrUpError : pageData[pageType]?.subtitle}
            </p>
            <Input
              type={'email'}
              value={userMetadata?.emailState || ''}
              onChangeCb={emailState => {
                setUserMetadata(userMetadata => ({
                  ...userMetadata,
                  emailState,
                }));
              }}
              placeholder='Enter email'
              ariaLabel={'Enter email'}
              index={0}
              dataTvFocusUp={'.input-wrapper-acc-0'}
              dataTvFocusRight={'.input-wrapper-acc-0'}
              dataTvFocusDown={
                isSubmitBtnDisabled
                  ? '.input-wrapper-acc-1'
                  : '.btn-acc-0'
              }
              dataTvFocusLeft={'.input-wrapper-acc-0'}
              hasValueToBeTrimmed={false}
              styleWrapper={{
                fontStyle: 'italic',
              }}
            >
              <img
                className='email-icon'
                src={EmailIcon}
                alt=""
              />
            </Input>
            <Input
              type={'password'}
              value={userMetadata?.passwordState || ''}
              onChangeCb={passwordState => {
                setUserMetadata(userMetadata => ({
                  ...userMetadata,
                  passwordState,
                }));
              }}
              placeholder={pageData[pageType]?.inputPlaceholderText}
              ariaLabel={pageData[pageType]?.inputPlaceholderText}
              index={1}
              dataTvFocusUp={'.input-wrapper-acc-0'}
              dataTvFocusRight={'.input-wrapper-acc-1'}
              dataTvFocusDown={
                isSubmitBtnDisabled
                  ? isSignInForm
                    ? '.btn-acc-1'
                    : '.checkbox-acc-0'
                  : '.btn-acc-0'
              }
              dataTvFocusLeft={'.input-wrapper-acc-1'}
              hasValueToBeTrimmed={false}
              styleWrapper={{
                fontStyle: 'italic',
              }}
            >
              <img
                src={PasswordIcon}
                className='password-icon'
                alt=""
              />
            </Input>
            {!isSignInForm && <div className='checkboxes-container'>
              <div className='checkbox-wrapper'>
                <Checkbox
                  index={0}
                  checked={userMetadata?.newsletterState}
                  onChangeCb={newsletterState => {
                    setUserMetadata(userMetadata => ({
                      ...userMetadata,
                      newsletterState,
                    }));
                  }}
                  focusUp='.input-wrapper-acc-1'
                  focusDown='.checkbox-acc-1'
                  focusLeft='.checkbox-acc-0'
                  focusRight='.checkbox-acc-0'
                />
                <div className='checkbox-label' style={{ fontFamily, color: subscribeStyles.textColor }}>
                  <p>{newsletterCheckboxOptions?.content}</p>
                </div>
              </div>
              <div className='checkbox-wrapper'>
                <Checkbox
                  index={1}
                  checked={userMetadata?.tosState}
                  onChangeCb={tosState => {
                    setUserMetadata(userMetadata => ({
                      ...userMetadata,
                      tosState,
                    }));
                  }}
                  focusUp='.checkbox-acc-0'
                  focusDown={`.btn-acc-${isSubmitBtnDisabled ? 1 : 0}`}
                  focusLeft='.checkbox-acc-1'
                  focusRight='.tos-text'
                />
                <div className='checkbox-label' style={{ fontFamily, color: subscribeStyles.textColor }}>
                  <p>
                    {getTOSText(
                      tosCheckboxOptions?.content,
                      focusTOSColor,
                      subscribeStyles?.textColor,
                      setCurrPageType
                    )}
                  </p>
                </div>
              </div>
            </div>}
          </div>
          <Button
            btnID='plus-submit-form-btn'
            title={pageData[pageType]?.submitBtnText}
            disabled={isSubmitBtnDisabled}
            focusUp={isSignInForm ? '.input-wrapper-acc-1' : '.checkbox-acc-1'}
            focusDown='.btn-acc-1'
            focusLeft='.btn-acc-0'
            focusRight='.btn-acc-0'
            index={0}
            onKeyDown={submitForm}
          />
        </form>
        <Button
          title={pageData[pageType]?.btnText}
          index={1}
          focusUp={
            isSubmitBtnDisabled
              ? isSignInForm
                ? '.input-wrapper-acc-1'
                : '.checkbox-acc-1'
              : `.btn-acc-0`}
          focusDown='.btn-acc-2'
          focusLeft='.btn-acc-1'
          focusRight='.btn-acc-1'
          btnID={`plus-form-${isSignInForm ? 'reset-password' : 'sign-in'}-btn`}
          onKeyDown={changeForm}
        />
        <Button
          title='GO BACK'
          index={2}
          btnID='plus-form-back-btn'
          focusUp='.btn-acc-1'
          focusDown='.btn-acc-2'
          focusLeft='.btn-acc-2'
          focusRight='.btn-acc-2'
          onKeyDown={goBack}
        >
          <>
            <img src={backArrowIcon} alt='back arrow' style={{marginRight: '16px'}}/>
          </>
        </Button>
      </div>
    </div>
  );
};

export default TCSubscriptionForm;
