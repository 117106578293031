import React, { Children, useRef, useState, MutableRefObject } from 'react';
import { DEFAULT_OFFSET_GAP, PAGE_OFFSET_GAP } from '../../constants/common';

import './ScrollableList.scss';

type ScrollableList = {
  children: JSX.Element[];
  transition?: number;
  translateValueRef?: MutableRefObject<number>;
  initialTranslateValue?: number;
  isSeasonsPage?: boolean;
  isMainPageScroll?: boolean;
}

const ScrollableList = (props: ScrollableList) => {
  const {
    children,
    transition,
    initialTranslateValue = 0,
    translateValueRef,
    isSeasonsPage,
    isMainPageScroll,
  } = props;

  const filteredChildren = Children.toArray(children).filter(Boolean);

  const previousRowIndex = useRef<number>(0);
  const listWrapperRef = useRef<HTMLDivElement>(null);

  const [translateValue, setTranslateValue] = useState(translateValueRef?.current || initialTranslateValue);

  const offsetGap = isMainPageScroll ? PAGE_OFFSET_GAP : DEFAULT_OFFSET_GAP;

  const onSelectedRow = (e, rowIdx: number) => {
    let newTranslateValue;
    const targetClientRect = e.target.getBoundingClientRect().top;
    const focusUpSelector = e.target.getAttribute('data-tv-focus-up');

    if (focusUpSelector === '#navigationSelectedItem' || rowIdx === 0) {
      newTranslateValue = 0;
    } else if (filteredChildren.length - rowIdx - 1 <= 0 && listWrapperRef?.current) {
      const listHeight = listWrapperRef?.current?.scrollHeight;

      if (isSeasonsPage) {
        if (isMainPageScroll) {
          newTranslateValue = (document.querySelector('.hero-wrapper')?.clientHeight || 640) - 60;
        } else {
          newTranslateValue = Math.abs(
            (-listHeight > translateValue) && listHeight
              ? listHeight
              : translateValue
          );
        }
      } else {
        const maxTranslateValue = -(listWrapperRef?.current?.scrollHeight - listWrapperRef?.current?.clientHeight);

        newTranslateValue = Math.abs(maxTranslateValue - 72);
      }
    } else {
      if (rowIdx > previousRowIndex.current) {
        // eslint-disable-next-line max-len
        newTranslateValue = Math.abs(translateValue) + (targetClientRect > offsetGap || targetClientRect < 0 ? targetClientRect - offsetGap : 0);
      } else {
        newTranslateValue = Math.abs(translateValue) - (targetClientRect < 0 ? Math.abs(targetClientRect) + offsetGap : 0);
      }
    }

    setTranslateValue(-newTranslateValue);
    previousRowIndex.current = rowIdx;

    if (translateValueRef) {
      translateValueRef.current = -newTranslateValue;
    }
  };

  return (
    <div className='scrollable-list-container'>
      <div
        className='scrollable-list'
        ref={listWrapperRef}
        style={{
          WebkitTransform: `translate3d(0,${translateValue}px, 0)`,
          transition: `transform 
          ${filteredChildren.length - previousRowIndex.current - 1 <= 0 && !isSeasonsPage ? 0 : transition}s 
          ease-out`,
        }}
      >
        {filteredChildren.map((el, idx) => (
          <div
            key={`row-${idx}`}
            className='row'
            onFocus={(e) => onSelectedRow(e, idx)}
          >
            {el}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollableList;
