import * as actionTypes from '../actions/types/config';
import { INavigationItem } from '../reducers/config';

export interface IError {
  title: string;
  message: string;
}

export interface Features {
  cleeng?: boolean;
  mvpd?: boolean;
  subscription_free_trial?: boolean;
  city_auto_select?: boolean;
  spoiler_prevention?: boolean;
}

export interface INavigationConfigPayload {
  navigationData: INavigationItem[];
}

export interface IConfigPayload {
  configData: object;
  stylesData: object;
  GA_id: string;
  settings: object;
  mvpd: {
    mvpd_text: any;
    mvpd_regcode: string;
    mvpd_auth: string;
    mvpd_checkauthn: string;
    mvpd_url: string;
    mvpd_requestorID: string;
    mvpd_logout: string;
    mvpdProvidersLogos: string;
  };
  players: {
    livePlayer: string;
    vodPlayer: string;
  };
  debugSettings: {
    switch_key_combination_multiple: string[];
    passcode: string;
  }
  postUrlsPrefixArr: string[];
  subscription: {
    firebase_createUser: string;
    firebase_authUser: string;
    firebase_resetPassword: string;
    firebase_refreshToken: string;
    cleeng_registerCustomer: string;
    cleeng_generateCustomerToken: string;
    cleeng_getPurchaseOption: string;
    cleeng_receipt: string;
    cleeng_listCustomerSubscriptions: string;
    cleeng_entitlements: string;
    subscription_promo_slider_slides: any;
    paywall_title: string;
    paywall_detail: string;
    payment_success_title: string;
    payment_success_detail: string;
    title_signed_in: string;
    detail_signed_in: string;
    title_signed_out: string;
    detail_signed_out: string;
    subscribe_tos: string;
    subscriptionOptions: any;
    reset_password_title: string;
    reset_password_detail: string;
    reset_password_success_title: string;
    reset_password_success_detail: string;
    reset_password_failure_title: string;
    sign_in_error_message: string;
    sign_in_title: string;
    sign_up_title: string;
    sign_up_detail: string;
    payment_gateways: any;
    terms_of_use_title: string;
    terms_of_use_text: string;
    privacy_policy_title: string;
    privacy_policy_text: string;
    newsletter_url: string;
  };
  features: Features;
  edenContent: string;
}

interface LoadConfigAction {
  type: typeof actionTypes.LOAD_CONFIG;
}

interface RefreshDeviceIdAction {
  type: typeof actionTypes.REFRESH_DEVICE_ID;
  payload: string;
}

interface LoadConfigSucceededAction {
  type: typeof actionTypes.LOAD_CONFIG_SUCCEEDED;
  payload: IConfigPayload;
}

interface LoadConfigFailedAction {
  type: typeof actionTypes.LOAD_CONFIG_FAILED;
  error: string;
}

interface LoadNavigationConfigSucceededAction {
  type: typeof actionTypes.LOAD_NAVIGATION_CONFIG_SUCCEEDED;
  payload: {
    navigationData: INavigationItem[];
  }
}

interface LoadNavigationConfigFailedAction {
  type: typeof actionTypes.LOAD_NAVIGATION_CONFIG_FAILED;
  error: string;
}

type LoadConfig = LoadConfigAction | LoadConfigSucceededAction | LoadConfigFailedAction;

type LoadNavigation = LoadNavigationConfigSucceededAction | LoadNavigationConfigFailedAction;

export type ActionConfig = LoadConfig | RefreshDeviceIdAction | LoadNavigation;

export const setConfigs = (): ActionConfig => ({
  type: actionTypes.LOAD_CONFIG,
});
