import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../../stores/reducers';
import { toggleExitModal } from '../../../stores/actions/common';
import { EXIT_MODAL_TITLE } from '../../../constants/text';
import currentPlatform from '../../../platform/currentPlatform';

import Modal from '../Modal';

const ExitFromAppModal = () => {
  const isExitFromAppModalOpen = useSelector((state: RootState) => state.common.isExitModalShown);

  const dispatch = useDispatch();

  const onBackHandlerCb = () => {
    isExitFromAppModalOpen && dispatch(toggleExitModal());
  };

  return (
    <>
      {isExitFromAppModalOpen ? (
        <Modal
          idContainer='Exit-From-App-Modal'
          title={EXIT_MODAL_TITLE}
          onBackHandlerCb={onBackHandlerCb}
          buttons={[
            {
              id: 'modal-yes-button',
              text: 'Yes',
              onPress: currentPlatform.exitFromApp,
            },
            {
              id: 'modal-no-button',
              text: 'No',
              onPress: () => {
                dispatch(toggleExitModal());
              },
            },
          ]}
        />
      ) : null}
    </>
  );
};

export default React.memo(ExitFromAppModal);
