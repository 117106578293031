import { put, call, takeLatest, select, all } from 'redux-saga/effects';
import { sortBy, prop } from 'ramda';

import * as actionTypes from '../actions/types/OTTChannelGuide';
import { RootState } from '../reducers';
import { getChannelsList, getProgramsList } from '../../services/OTTChannelGuideService';
import { normalizeProgram } from '../schemas/normalizeProgram';
import { fillUpEmptyBlocksInEPG, getDataInFutureAsIOSString } from '../schemas/utils';
import { CHANNEL_LIST_REQUEST_ERROR } from '../../constants/errorCodes';
import { isStirrBrand, isStirrTcMarqueeBrand } from '../../constants/structureTypes';

function* loadOTTChannelsList() {
  const store: RootState = yield select();
  const {
    common: {
      station,
    },
    config: {
      allConfigs: {
        API: {
          channel_list,
        },
      },
    },
  } = store;

  try {
    yield put({
      type: actionTypes.CLEAR_OTTCHANNELGUIDE,
    });

    let filteredCategories = [];

    const { channel, categories } = yield call(() => getChannelsList(channel_list, station));

    const formattedChannels = channel.map(channel => ({
      id: channel.id,
      displayName: channel['display-name'],
      icon: channel.icon.src,
      number: channel.num,
      mediaRestriction: channel['media:restriction'],
      categories: categories && isStirrBrand ? channel['categories'].map(c => c.uuid) : [],
    }));

    if (categories) {
      filteredCategories = categories.filter(category => {
        return formattedChannels.find(({ categories }) => {
          return categories.includes(category.uuid);
        });
      });
    }

    yield put({
      type: actionTypes.LOAD_OTTCHANNELGUIDE_LIST_SUCCEEDED,
      payload: {
        channels: formattedChannels,
        categories: categories ? sortBy(prop('order'), filteredCategories) : [],
      },
    });
  } catch (e: any) {
    yield put({
      type: actionTypes.LOAD_OTTCHANNELGUIDE_LIST_FAILED,
      error: CHANNEL_LIST_REQUEST_ERROR,
    });
  }
}

function* loadOTTProgramsList(data) {
  const { channels } = data;
  const store: RootState = yield select();
  const {
    common: {
      station,
    },
    config: {
      allConfigs: {
        API: {
          channel_info,
        },
      },
    },
    OTTChannelGuide: {
      categories,
    },
  } = store;

  const programListURL: string = isStirrTcMarqueeBrand
    ? channel_info
    : `${channel_info}&startDateTime=${getDataInFutureAsIOSString()}&endDateTime=${getDataInFutureAsIOSString(1)}`;

  try {
    let channelsSortedByCategories: any = [];
    let categoriesMap = {};
    let focusIndex = 0;

    const channelsWithPrograms = yield all(
      channels.map(channel => getProgramsList(
        programListURL,
        isStirrTcMarqueeBrand ? channel?.displayName : '',
        station
      ).then(res => {
        const channelPrograms: any = res?.programme ? res.programme : res;
        const normalizedPrograms = channelPrograms.map(prog => normalizeProgram(prog));
        const formattedPrograms = fillUpEmptyBlocksInEPG(normalizedPrograms, channel.id);

        return { ...channel, programs: formattedPrograms };
      })),
    );

    if (categories.length && isStirrBrand) {
      // eslint-disable-next-line
      categories.map((category, categoryIndex) => {
        const categoryId: string = category.uuid || '';

        categoriesMap = {
          ...categoriesMap,
          [categoryId]: focusIndex,
        };

        channelsWithPrograms.forEach(channel => {
          if (channel.categories.includes(category.uuid)) {
            channelsSortedByCategories.push({
              ...channel,
              id: `${channel.id}${focusIndex}`,
              focusIndex,
              categoryId: category.uuid,
              categoryIndex,
              programs: channel.programs.map(program => {
                return {...program, id: `${program.id}${focusIndex}`};
              }),
            });
            focusIndex++;
          }
        });
      });
    } else {
      channelsSortedByCategories = channelsWithPrograms.map((channel, index) => ({
        ...channel,
        focusIndex: index,
      }));
    }

    yield put({
      type: actionTypes.LOAD_OTTPROGRAMS_LIST_SUCCEEDED,
      payload: {
        channelsWithPrograms,
        channelsSortedByCategories,
        categoriesMap,
      },
    });
  } catch (e: any) {
    yield put({
      type: actionTypes.LOAD_OTTPROGRAMS_LIST_FAILED,
    });
  }
}

function* watcher() {
  yield takeLatest(actionTypes.LOAD_OTTCHANNELGUIDE_LIST, loadOTTChannelsList);
  yield takeLatest(actionTypes.LOAD_OTTPROGRAMS_LIST, loadOTTProgramsList);
}

export default watcher;
