import axiosInstance from './axiosInstance';
import { AxiosResponse } from 'axios';
import { TENNIS_CHANNEL } from '../constants/structureTypes';
import { errorDetails } from '../constants/errorDetails';
import {
  REGISTER_CLEENG_CUSTOMER_ERROR, GENERATE_CUSTOMER_TOKEN_ERROR,
  GET_ACTIVE_USER_SUBSCRIPTIONS_ERROR,
  GET_ALL_USER_SUBSCRIPTIONS_ERROR,
  SUBMIT_RECEIPT_ERROR,
} from '../constants/errorCodes';

export async function registerCleengCustomer(url: string, accessToken: string): Promise<AxiosResponse> {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'X-userAuthenticationToken': accessToken,
    },
  };

  try {
    const { data } = await axiosInstance.post(url, {}, options);

    return data;
  } catch (error: any) {
    console.log('cleengService::registerCleengCustomer error: ', error?.response?.data?.error);

    const defaultErrorMsg = `${REGISTER_CLEENG_CUSTOMER_ERROR}:
    ${errorDetails[REGISTER_CLEENG_CUSTOMER_ERROR].title}.
    ${errorDetails[REGISTER_CLEENG_CUSTOMER_ERROR].message}`;

    const errorFromResponse = `${error?.response?.data?.error?.code || REGISTER_CLEENG_CUSTOMER_ERROR}: 
    ${error?.response?.data?.error?.message}`;

    throw Error(error?.response?.data?.error?.message ? errorFromResponse : defaultErrorMsg);
  }
}

export async function generateCustomerToken(url, token): Promise<AxiosResponse> {
  const options = {
    headers: {
      'X-userAuthenticationToken': token,
    },
  };

  try {
    const { data } = await axiosInstance.get(url, options);

    return data.result;
  } catch (error: any) {
    console.log('cleengService::generateCustomerToken error: ', error?.response?.data?.error);

    const defaultErrorMsg = `${GENERATE_CUSTOMER_TOKEN_ERROR}:
    ${errorDetails[GENERATE_CUSTOMER_TOKEN_ERROR].title}.
    ${errorDetails[GENERATE_CUSTOMER_TOKEN_ERROR].message}`;

    const errorFromResponse = `${error?.response?.data?.error?.code || GENERATE_CUSTOMER_TOKEN_ERROR}: 
    ${error?.response?.data?.error?.message}`;

    throw Error(error?.response?.data?.error?.message ? errorFromResponse : defaultErrorMsg);
  }
}

export async function getPurchaseOptions(url: string, postData): Promise<AxiosResponse | undefined> {

  try {
    const { data } = await axiosInstance.post(url, postData);

    return data.subscriptions;
  } catch (error: any) {
    console.log('cleengService::getPurchaseOptions error', error?.response?.data?.error);
  }
}

export async function submitReceipt(url: string, postData, headers: object = {}): Promise<AxiosResponse> {

  const options = {
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      'X-brand': TENNIS_CHANNEL,
    },
  };

  try {
    const { data } = await axiosInstance.post(url, postData, options);

    return data;
  } catch (error) {
    // console.log('cleengService::submitReceipt error', error.response.data.error);

    throw Error(SUBMIT_RECEIPT_ERROR);
  }
}

export async function getActiveSubscriptions(url, token): Promise<AxiosResponse> {
  const options = {
    headers: {
      'x-userAuthenticationToken': token,
    },
  };

  try {
    const { data } = await axiosInstance.get(url, options);

    return data.result.items;
  } catch (error) {

    throw Error(GET_ACTIVE_USER_SUBSCRIPTIONS_ERROR);
  }
}

export async function getAllSubscriptions(url, token): Promise<AxiosResponse> {
  const options = {
    headers: {
      'x-userAuthenticationToken': token,
    },
  };

  try {
    const { data } = await axiosInstance.get(url, options);

    return data.result.data;
  } catch (error) {

    throw Error(GET_ALL_USER_SUBSCRIPTIONS_ERROR);
  }
}

export async function getAccessStatus(url: string, token): Promise<AxiosResponse | undefined> {

  const options = {
    headers: {
      'X-cleengCustomerToken': token,
    },
  };

  try {
    const { data } = await axiosInstance.get(url, options);

    if (!data.result || !data.result.accessGranted) {
      await axiosInstance.get(url, options);
    }

    return data.result;
  } catch (error: any) {
    const { data } = await axiosInstance.get(url, options);

    if (!data.result || !data.result.accessGranted) {
      console.log('cleengService::getAccessStatus(subscription status) error', error.response.data.error);
    }
  }
}
