import { put, call, takeLatest, select, race, take } from 'redux-saga/effects';

import * as actionTypes from '../actions/types/OTTFeed';
import { RootState } from '../reducers';
import {
  getOTTFeedComponentContent,
  getExtraAdParams,
  requestOTTFeedComponentContent,
  getDRMAccesses,
} from '../../services/OTTFeedService';
import {
  getCustomParams,
  getStandartParams,
  getParamsStringForAds,
  getQueryStringFromAps,
} from '../../utils/advertisement';
import { OTTFeedType } from '../../constants/structureTypes';
import { CLEENG_RESTRICTION, MVPD_RESTRICTION } from '../../constants/text';
import { getDataForPlayback } from './sagas_common';
import currentPlatform from '../../platform/currentPlatform';
import { isContentWithRestriction } from '../../utils';
import { VIDEO_API_ERROR } from '../../constants/errorCodes';

function* setComponentOTTFeedData(data) {
  const store: RootState = yield select();
  const {
    common: { station },
    config: {
      allConfigs: {
        API: {
          vmap_generator,
          cust_params_extras,
        },
      },
    },
    Login: {
      mvpd: {
        authTokenMVPD,
      },
      subscription: {
        authTokenPlus,
      },
    },
  } = store;

  let component: any = {};
  let item: any = {};
  let mediaContent: any = {};
  let configDrmData = {
    url: '',
    dashUrl: '',
    AuthXML: '',
    FPserver: '',
    FPcert: '',
    PRserver: '',
    WVserver: '',
    drmfail: '',
    drmfailMP4: '',
  };
  let drmFail = {
    drmFailUrl: '',
    drmFailTitle: '',
  };
  let adUrl : string = '';
  let adParamsString: string = '';
  let standartParams: string = '';
  let basicParams: any = {};
  let refreshedMvpdToken: string = '';
  let refreshedPlusToken: string = '';
  let isMVPDRestricted: boolean = false;
  let isCleengRestricted: boolean = false;
  let isContentWithoutRestriction: boolean = true;
  let mvpdToken: string = '';
  let plusToken: string = '';

  try {
    const dataForPlayback = yield call(
      getDataForPlayback, data, getOTTFeedComponentContent, requestOTTFeedComponentContent, true,
    );

    component = dataForPlayback.component;
    refreshedMvpdToken = dataForPlayback.refreshedMvpdToken;
    refreshedPlusToken = dataForPlayback.refreshedPlusToken;
    item = component?.item;
    mediaContent = item['media:content'];
    isMVPDRestricted = isContentWithRestriction(mediaContent?.['media:restriction'], MVPD_RESTRICTION);
    isCleengRestricted = isContentWithRestriction(mediaContent?.['media:restriction'], CLEENG_RESTRICTION);
    isContentWithoutRestriction = !isMVPDRestricted && !isCleengRestricted;
    mvpdToken = refreshedMvpdToken || authTokenMVPD;
    plusToken = refreshedPlusToken || authTokenPlus;

  } catch (e: any) {
    yield put({
      type: actionTypes.LOAD_OTTFEED_COMPONENT_FAILED,
      error: {
        errorCode: VIDEO_API_ERROR,
        requestData: data.url || data.content,
      },
    });
  }

  try {
    if (mediaContent['sinclair:ad_preroll']) {
      const extraAdParams = yield call(getExtraAdParams, cust_params_extras, station);
      const customAdParams = getCustomParams(
        mediaContent['sinclair:ident'],
        OTTFeedType,
        item['guid']['content'],
      );

      const adParams = {
        ...extraAdParams,
        ...customAdParams,
      };

      basicParams = getStandartParams();
      adParamsString = getParamsStringForAds(adParams);
      standartParams = getParamsStringForAds(basicParams);

      adUrl += `${vmap_generator}?`;

      mediaContent['sinclair:ad_preroll'] &&
        (adUrl += `preroll=${encodeURIComponent(mediaContent['sinclair:ad_preroll'])}&`);

      adUrl += `${standartParams}cust_params=${encodeURIComponent(adParamsString)}`;

      // Adding APS from fireTV WebView to the adUrl
      if (currentPlatform.appPlatform === 'firetv') {
        let resultFromAPSCall;

        try {
          resultFromAPSCall = yield call(currentPlatform.requestAdditionalAdsParams, { apsContentType: 'preroll' });
        } catch (e: any) {
          resultFromAPSCall = {error: 'Error from Live APSCall: ' + (e?.error || e?.message)};
          console.log(resultFromAPSCall.error);
        }

        if (!resultFromAPSCall?.error) {
          const { imaResponse = '', imaDaiResponse = '' } = resultFromAPSCall;

          if (imaResponse) {
            const stringifyImaRes = JSON.parse(imaResponse);
            const imaResData = getQueryStringFromAps(stringifyImaRes);

            adUrl += encodeURIComponent(imaResData);
          }

          if (imaDaiResponse) {
            const stringifyImaDaiRes = JSON.parse(imaDaiResponse);
            const imaDaiResData = getQueryStringFromAps(stringifyImaDaiRes);

            adParamsString += imaDaiResData;
          }
        }
      }
    }
  } catch (e: any) {
    console.log('ERROR in getting ads params');
  }

  if (mediaContent['sinclair:drm'] && mediaContent['sinclair:drm_url']) {

    const requestBody = {
      'sinclair:drm': mediaContent['sinclair:drm'],
      guid: item['guid']['content'],
    };

    if (!isContentWithoutRestriction) {
      if (isMVPDRestricted && mvpdToken) {
        requestBody['mvpdMediaToken'] = mvpdToken;
      }

      if (isCleengRestricted && plusToken) {
        requestBody['x-userauthenticationtoken'] = plusToken;
      }
    }

    if (isContentWithoutRestriction ||
      (isMVPDRestricted && mvpdToken) ||
      (isCleengRestricted && plusToken)
    ) {
      console.log('LIVE DRM playback');

      try {
        const drmData = yield call(() =>
          getDRMAccesses(
            mediaContent['sinclair:drm_url'],
            station,
            requestBody,
          ),
        );

        if (drmData?.AuthXML) {
          configDrmData = { ...drmData };
        } else {
          drmFail = { ...drmData };
          adUrl = '';
        }
      } catch (e: any) {
        console.log('OTTFeed: DRM API ERROR: ', e);
      }
    }
  }

  if (component?.item) {
    yield put({
      type: actionTypes.LOAD_OTTFEED_COMPONENT_SUCCEEDED,
      payload: {
        component,
        configDrmData,
        drmFail,
        adUrl,
        custParams: adParamsString,
        standParams: basicParams,
      },
    });
  }
}

function* watcher() {
  yield takeLatest(actionTypes.LOAD_OTTFEED_COMPONENT, function* (args) {
    yield race({
      saga: call(setComponentOTTFeedData, args),
      cancel: take(actionTypes.CLEAR_OTTFEED),
    });
  });
}

export default watcher;
