import * as actionTypes from '../actions/types/OTTChannelGuide';
import { ChannelsAction, ProgramsAction, ICategory, IChannel} from '../actions/OTTChannelGuide';
import { Dates } from '../../utils/dateProgrammInitializer';
import { filterProgramsByDate } from '../../utils/dateProgrammInitializer';
import { isStirrTcMarqueeBrand } from '../../constants/structureTypes';

interface OTTChannelGuideState {
  channels: Array<IChannel>,
  categories: ICategory[];
  channelsWithPrograms: any,
  channelsSortedByCategories: any,
  categoriesMap,
  programsByDate: Dates,
  fetched: boolean;
  hasError: boolean;
  error: string;
  isLoading: boolean;
  isProgramsLoading: boolean;
}

const initialState: OTTChannelGuideState = {
  channels: [],
  categories: [],
  channelsWithPrograms: [],
  channelsSortedByCategories: [],
  categoriesMap: {},
  programsByDate: {},
  fetched: false,
  hasError: false,
  error: '',
  isLoading: false,
  isProgramsLoading: false,
};

const OTTChannelGuide = (
  state = initialState, action: ChannelsAction | ProgramsAction): OTTChannelGuideState => {
  switch (action.type) {
    case actionTypes.LOAD_OTTCHANNELGUIDE_LIST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.LOAD_OTTCHANNELGUIDE_LIST_SUCCEEDED: {

      return {
        ...state,
        channels: action.payload.channels,
        categories: action.payload.categories,
        fetched: true,
        hasError: false,
        isLoading: false,
      };
    }

    case actionTypes.LOAD_OTTCHANNELGUIDE_LIST_FAILED:
      return {
        ...state,
        fetched: false,
        hasError: true,
        error: action.error,
        isLoading: false,
      };

    case actionTypes.LOAD_OTTPROGRAMS_LIST:
      return {
        ...state,
        isProgramsLoading: true,
      };

    case actionTypes.LOAD_OTTPROGRAMS_LIST_FAILED:
      return {
        ...state,
        fetched: false,
        hasError: true,
        isProgramsLoading: false,
      };

    case actionTypes.LOAD_OTTPROGRAMS_LIST_SUCCEEDED: {

      return {
        ...state,
        channelsWithPrograms: action.payload.channelsWithPrograms,
        programsByDate: !isStirrTcMarqueeBrand
          ? { ...state.programsByDate, ...filterProgramsByDate(action.payload.channelsWithPrograms[0].programs)}
          : { ...state.programsByDate},
        channelsSortedByCategories: action.payload.channelsSortedByCategories,
        categoriesMap: action.payload.categoriesMap,
        fetched: true,
        hasError: false,
        isProgramsLoading: false,
      };
    }

    case actionTypes.CLEAR_OTTCHANNELGUIDE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default OTTChannelGuide;
