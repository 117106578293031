import AppConfig from '../assets/config.json';
import { IPlatform } from './interfaces/interfaces';
import { OTT_BRAND, REACT_APP_BRAND, REACT_APP_DEVICE_NAME } from '../constants/structureTypes';
import { LOCAL_STORAGE_DEBUG_APP_VERSION_KEY, LOCAL_STORAGE_DEBUG_ENVIRONMENT_KEY } from '../constants/debugSettings';
import { config } from '../config';

const CONFIG_URI = 'https://ott-config.sinclairstoryline.com/configapi/';
const currentBrand = AppConfig[OTT_BRAND];

export const webOSVersion = (): string => {
  function getBrowser() {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];

      return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return { name: 'Opera', version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }

    return {
      name: M[0],
      version: M[1],
    };
  }

  const browser = getBrowser();

  return browser.name + browser.version;
};

export class GeneralPlatform implements IPlatform {
  deviceName = REACT_APP_DEVICE_NAME;
  appVersion = currentBrand.appVersion;
  envName = currentBrand.envName;
  previousConfigKey = currentBrand.previousConfigKey;
  apiVersion = currentBrand.apiVersion;
  brand = REACT_APP_BRAND.toLowerCase().replace('_', '');
  analyticsAppName = currentBrand.analyticsAppName;
  appPlatform = this.deviceName.toLowerCase();
  jsVersion = currentBrand.jsVersion;
  charles_ip = currentBrand.charles_ip ? currentBrand.charles_ip : null;
  deviceBrand = 'Web';
  idType = '';

  get osVersion(): string {
    return webOSVersion();
  }

  get deviceModel(): string {
    return '';
  }

  get deviceUuid(): string {
    return '';
  }

  get isLoadedInBrowser(): boolean {
    return true;
  }

  get wrapperVersion(): string {
    return '';
  }

  get baseURL() {
    const storedAppVersion = localStorage.getItem(LOCAL_STORAGE_DEBUG_APP_VERSION_KEY);
    const storedEnvName = localStorage.getItem(LOCAL_STORAGE_DEBUG_ENVIRONMENT_KEY);
    const appVersion = storedAppVersion ? JSON.parse(storedAppVersion) : config.appVersion;
    const appEnv = storedEnvName ? JSON.parse(storedEnvName) : config.envName;
    const nameForPlatform = (this.appPlatform === 'firetv')
      ? `${this.appPlatform}-js` : this.appPlatform;

    return (
      CONFIG_URI +
      `${this.previousConfigKey}/${this.apiVersion}/` +
      `${this.brand}/${nameForPlatform}/${this.deviceName}/` +
      `${appVersion}.${appEnv}/${this.osVersion}`
    );
  }
}
