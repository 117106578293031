import {
  APP_CONFIG_REQUEST_ERROR,
  APP_NAVIGATION_REQUEST_ERROR,
  CHANNEL_LIST_REQUEST_ERROR,
  CODE_GENERATION_MVPD_ERROR,
  DEFAULT_CLEENG_ERROR,
  DEFAULT_ERROR,
  DEFAULT_MVPD_ERROR,
  DEFAULT_PURCHASE_ERROR,
  DEFAULT_VIDEO_AD_ERROR,
  EMPTY_PRODUCT_LIST_OR_NO_SELECTED_PRODUCT_ID_ERROR,
  GENERATE_CUSTOMER_TOKEN_ERROR,
  GET_ACTIVE_USER_SUBSCRIPTIONS_ERROR,
  GET_ALL_USER_SUBSCRIPTIONS_ERROR,
  GET_PURCHASE_OPTIONS_ERROR,
  LOGIN_MVPD_ERROR,
  PAGE_COMPONENTS_REQUEST_ERROR,
  PAGE_LAYOUT_REQUEST_ERROR,
  PROVIDER_MVPD_LIST_ERROR,
  REGISTER_CLEENG_CUSTOMER_ERROR,
  REGUEST_PRODUCTS_LIST_ERROR,
  REQUEST_BUY_ITEM_ERROR,
  REQUEST_BUY_ITEM_EXCEPTION_ERROR,
  REQUEST_PURCHASES_LIST_ERROR,
  SUBMIT_RECEIPT_ERROR,
  REQUEST_USER_STATUS_ERROR,
  VIDEO_API_ERROR,
  VIDEO_ERROR,
  PAGE_CONTENT_REQUEST_ERROR,
  RESET_PLUS_PASSWORD_ERROR,
  STILL_ACTIVE_SUBSCRIPTION_IN_CLEENG,
  STILL_ACTIVE_SUBSCRIPTION_IN_STORE,
  USER_NOT_LOGIN_ERROR_FIRE_TV,
  USER_NOT_LOGIN_ERROR_TIZEN,
} from './errorCodes';

export interface IError {
  errorCode?: string;
  title?: string;
  message?: string;
}

export const errorDetails = {
  [DEFAULT_CLEENG_ERROR]: {
    title: 'Error',
    message: 'Whoops, something\'s gone wrong. Please try again later.',
  },
  [REGISTER_CLEENG_CUSTOMER_ERROR]: {
    title: 'Registration was unsuccessful',
    message: 'Unfortunately your registration failed. Please try again.',
  },
  // cleeng token for sign in flow
  [GENERATE_CUSTOMER_TOKEN_ERROR]: {
    title: 'Your login failed',
    message: 'Please try to login again so that we can retrieve the correct information.',
  },
  [GET_PURCHASE_OPTIONS_ERROR]: {
    title: 'Unable to submit purchase',
    message: 'Unfortunately your purchase failed. Please try again.',
  },
  // user subscriptions list(only active)
  [GET_ACTIVE_USER_SUBSCRIPTIONS_ERROR]: {
    title: 'Unable to access subscription information',
    message: 'We\'re having problems retriving your subscription information. Please try again.',
  },
  // user subscriptions list(all)
  [GET_ALL_USER_SUBSCRIPTIONS_ERROR]: {
    title: 'Unable to access subscription information',
    message: 'We\'re having problems retrieving your subscription information. Please try again.',
  },
  [RESET_PLUS_PASSWORD_ERROR]: {
    title: 'We had trouble resetting your password',
    message: 'We were unable to reset your password. Please try again.',
  },
  [STILL_ACTIVE_SUBSCRIPTION_IN_CLEENG]: {
    title: 'Error',
    message: 'Your subscription is still active.',
  },
  [DEFAULT_PURCHASE_ERROR]: {
    title: 'Error',
    message: 'Whoops, something\'s gone wrong. Please try again later.',
  },
  [REGUEST_PRODUCTS_LIST_ERROR]: {
    title: 'Unable to access subscription options',
    message: 'We\'re having problems retrieving subscription types. Please try again.',
  },
  // products list from samsung
  [REQUEST_PURCHASES_LIST_ERROR]: {
    title: 'Unable to access subscription information',
    message: 'We\'re having problems retriving your subscription information. Please try again.',
  },
  [REQUEST_BUY_ITEM_ERROR]: {
    title: 'Unable to complete transaction',
    message: 'We\'re having problems purchasing your subscription. Please try again.',
  },
  [REQUEST_BUY_ITEM_EXCEPTION_ERROR]: {
    title: 'Unable to complete transaction',
    message: 'We\'re having problems purchasing your subscription. Please try again.',
  },
  [EMPTY_PRODUCT_LIST_OR_NO_SELECTED_PRODUCT_ID_ERROR]: {
    title: 'Unable to access subscription information',
    message: 'We\'re having problems retrieving purchase options. Please try again. ',
  },
  [SUBMIT_RECEIPT_ERROR]: {
    title: 'Unable to log subscription information',
    message: 'We\'re having problems retrieving your subscription information. Please try again. ',
  },
  [REQUEST_USER_STATUS_ERROR]: {
    title: 'Unable to complete transaction',
    message: 'We\'re having problems purchasing your subscription on this device. Please try again. ',
  },
  [STILL_ACTIVE_SUBSCRIPTION_IN_STORE]: {
    title: 'A Tennis Channel Subscription Has Already Been Purchased with This Device.',
    message: 'Make sure and use the login you created when purchasing Tennis Channel Plus.',
  },
  [USER_NOT_LOGIN_ERROR_TIZEN]: {
    title: 'Error',
    message: 'You must sign in to your Samsung account to get subscription. Please sign in and try again.',
  },
  [USER_NOT_LOGIN_ERROR_FIRE_TV]: {
    title: 'Error',
    message: 'You must sign in to your Amazon account to get subscription. Please sign in and try again.',
  },
  [DEFAULT_MVPD_ERROR]: {
    title: 'Error',
    message: 'Whoops, something\'s gone wrong. Please try again later.',
  },
  [LOGIN_MVPD_ERROR]: {
    title: 'Unable to log in to your cable provider',
    message: 'It appears your cable authentication failed. Please try again.',
  },
  [CODE_GENERATION_MVPD_ERROR]: {
    title: 'Cable provider authentication has failed',
    message: 'It appears your cable authentication failed. Please try again.',
  },
  [PROVIDER_MVPD_LIST_ERROR]: {
    title: 'Unable to retrieve cable provider information',
    message: 'We\'re having problems receiving provider information. Please refresh and try again.',
  },
  [DEFAULT_VIDEO_AD_ERROR]: {
    title: ' Video failed to load',
    message: 'Sorry, the content you\'re trying to access failed to load. Please try again or choose another option.',
  },
  [VIDEO_API_ERROR]: {
    title: 'Video failed to load',
    message: 'Whoops! Apparently we\'re having issues. Please try again later.',
  },
  // Player issues + while server stitched ads in case no ad end event
  [VIDEO_ERROR]: {
    title: 'There has been a player error',
    message: 'Whoops! Our player has run into an issue. Please try again later.',
  },
  [DEFAULT_ERROR]: {
    title: 'Error',
    message: 'Whoops, something\'s gone wrong. Please try again later.',
  },
  [APP_CONFIG_REQUEST_ERROR]: {
    title: 'App configuration issue',
    message: 'Something\'s gone wrong with the page configuration. Please back out and give it another try.',
  },
  [APP_NAVIGATION_REQUEST_ERROR]: {
    title: 'We\'ve run into a navigation issue',
    message: 'Something\'s gone wrong with navigation. Please back out and give it another try.',
  },
  [PAGE_LAYOUT_REQUEST_ERROR]: {
    title: 'Page load failure',
    message: 'The page you\'re trying to access failed to load. Please back out and give it another try.',
  },
  [PAGE_COMPONENTS_REQUEST_ERROR]: {
    title: 'Page content failing to load ',
    message: 'The contents of this page are failing to load. Please back out and give it another try.',
  },
  [CHANNEL_LIST_REQUEST_ERROR]: {
    title: 'Page content failing to load ',
    message: 'The contents of this page are failing to load. Please back out and give it another try.',
  },
  [PAGE_CONTENT_REQUEST_ERROR]: {
    title: 'Page content failing to display',
    message: 'The contents of this page are failing to display. Please back out and give it another try.',
  },
};
