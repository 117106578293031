export const LOAD_OTTSEARCH_SUGGESTIONS = 'OTTSearch::LOAD_SUGGESTIONS';
export const LOAD_OTTSEARCH_SUGGESTIONS_SUCCEEDED = 'OTTSearch::LOAD_SUGGESTIONS_SUCCEEDED';
export const LOAD_OTTSEARCH_SUGGESTIONS_FAILED = 'OTTSearch::LOAD_SUGGESTIONS_FAILED';

export const SET_OTTSEARCH_PAGE_DATA = 'OTTSearch::SET_PAGE_DATA';

export const LOAD_OTTSEARCH_COMPONENT = 'OTTSearch::LOAD_COMPONENT';
export const LOAD_OTTSEARCH_COMPONENT_SUCCEEDED = 'OTTSearch::LOAD_COMPONENT_SUCCEEDED';
export const LOAD_OTTSEARCH_COMPONENT_FAILED = 'OTTSearch::LOAD_COMPONENT_FAILED';

export const CLEAR_OTTSEARCH_SUGGESTIONS = 'OTTSearch::CLEAR_SUGGESTIONS';
export const CLEAR_OTTSEARCH_SUGGESTIONS_CONTENT = 'OTTSearch::CLEAR_SUGGESTIONS_CONTENT';
export const CLEAR_OTTSEARCH = 'OTTSearch::CLEAR_OTTSearch';
