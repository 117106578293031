import * as actionTypes from '../actions/types/OTTSettings';
import { Page } from './OTTPage';

interface ISettingsPage extends Page {
  description?: string;
  imageURL?: string;
  path?: string;
}

export type ISettingsPayload = Array<ISettingsPage>;

interface LoadPageAction {
  type: typeof actionTypes.LOAD_OTT_SETTINGS_DATA;
  settingsData: ISettingsPayload;
}

interface LoadPageSucceededAction {
  type: typeof actionTypes.LOAD_OTT_SETTINGS_DATA_SUCCEEDED;
  payload: ISettingsPayload;
}

interface LoadPageFailedAction {
  type: typeof actionTypes.LOAD_OTT_SETTINGS_DATA_FAILED;
  error: '';
}

interface LoadPageDataAction {
  type: typeof actionTypes.LOAD_OTT_SETTINGS_PAGE_DATA;
  url: string;
}

interface LoadPageDataSucceededAction {
  type: typeof actionTypes.LOAD_OTT_SETTINGS_PAGE_DATA_SUCCEEDED;
  payload: Array<Page>;
}

interface LoadPageDataFailedAction {
  type: typeof actionTypes.LOAD_OTT_SETTINGS_PAGE_DATA_FAILED;
  error: '';
}

interface ClearOTTSettings {
  type: typeof actionTypes.CLEAR_OTT_SETTINGS;
}

type PageAction = LoadPageAction | LoadPageSucceededAction | LoadPageFailedAction;

type PageDataAction = LoadPageDataAction | LoadPageDataSucceededAction | LoadPageDataFailedAction;

export type Action = PageAction | PageDataAction | ClearOTTSettings;

export const setPageComponentsData = (settingsData: any): Action => {
  return ({
    type: actionTypes.LOAD_OTT_SETTINGS_DATA,
    settingsData,
  });
};

export const getSettingsPageData = (url: string): Action => {
  return ({
    type: actionTypes.LOAD_OTT_SETTINGS_PAGE_DATA,
    url,
  });
};
