import { IStack } from '../services/NavigationService';
import { addParametersToURL, getCapitaliseAnalyticsValue } from './commonFunc';
import { STATION_SELECT } from '../constants/analyticsTypes';
import {
  OTTPageType,
  OTTButtonType,
  OTTTextType,
  CITY_SELECTION,
} from '../constants/structureTypes';
import store from './../stores/index';

export interface IJson {
  path: string;
  title?: string;
  type: string;
  category?: string;
  tag?: string;
}

export function openPage(json: IJson, data: any = {}): IStack {
  let GAPath: string = '';
  let type: string;
  let link: string;
  const itemPath: string = json['title']
    ? getTrimmedString(json['title'])
    : getTrimmedString(json['guid']['content']);

  switch (json.type || json.category) {
    case OTTPageType:
      type = json['type'] ?? json['category'];
      link = json['path'] ?? json['link'];
      GAPath = getPath(json);
      break;

    case OTTButtonType:
      type = OTTPageType;
      link = json['media:content']['url'];
      break;

    case OTTTextType:
      type = OTTTextType;
      link = json['path'];
      GAPath = getPath(json);
      break;

    default:
      type = json['type'] ?? json['category'];
      link = json['path'] ?? json['link'];
      GAPath = getPath(json);
  }

  return OTT({ type, itemPath, GAPath, link }, json, data);
}

function OTT(template: any, json: IJson, data: any): IStack {
  if (!json) {
    throw new Error('Error in openPage');
  }

  const state: any = store.getState();

  const { station: stationFromStore, city: cityFromStore } = state.common;

  const station = Object.prototype.hasOwnProperty.call(data, 'station') ? data.station : stationFromStore;
  const city = Object.prototype.hasOwnProperty.call(data, 'city') ? data.city : cityFromStore;

  const url = addParametersToURL(template.link, { station, city });

  const analyticsPath = template.GAPath.trim() || template.itemPath;

  const page: IStack = {
    pathname: `/${template.type}/${template.itemPath}`,
    state: {
      lastData: { ...data },
      configData: { ...json, path: url, GAPath: analyticsPath },
    },
  };

  return page;
}

function getTrimmedString(str: string): string {
  return str.replace(/\s/g, '');
}

export function getPath(page: any): string {
  if (page?.tag === CITY_SELECTION) {
    return STATION_SELECT;
  } else if (page['media:content']) {
    return page['media:content']['media:title']['content'];
  } else if (/caption/i.test(page.title)) {
    return page.title;
  } else {
    // left for further investigation (page.title.split(' ')[0] || '';)
    return getCapitaliseAnalyticsValue(page);
  }
}
