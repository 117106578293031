import * as actionTypes from '../actions/types/OTTFeed';
import { DRMFail, DRMAccesses, IErrorAPI } from './OTTEpisode';

export interface OTTFeedComponent {
  item: {
    link: string;
    guid: any;
    'media:content': any;
  }
}

export interface ComponentPayload {
  item: OTTFeedComponent;
}

export interface DRMAccessesLive extends DRMAccesses {
  assetKey: string;
  dashAssetKey: string;
}

export interface ComponentSuccessPayload {
  component: OTTFeedComponent;
  configDrmData: DRMAccessesLive;
  drmFail: DRMFail;
  adUrl: string;
  custParams: string;
  standParams: any;
}

interface LoadComponentAction {
  type: typeof actionTypes.LOAD_OTTFEED_COMPONENT;
  url: string;
  content: any;
}

interface LoadComponentSucceededAction {
  type: typeof actionTypes.LOAD_OTTFEED_COMPONENT_SUCCEEDED;
  payload: ComponentSuccessPayload;
}

interface LoadComponentFailedAction {
  type: typeof actionTypes.LOAD_OTTFEED_COMPONENT_FAILED;
  error: IErrorAPI;
}

interface ClearOTTFeed {
  type: typeof actionTypes.CLEAR_OTTFEED;
}

type ComponentAction = LoadComponentAction | LoadComponentSucceededAction | LoadComponentFailedAction;

export type Action = ComponentAction | ClearOTTFeed;

export const setComponentOTTFeedData = (url: string, content?: any): Action => ({
  type: actionTypes.LOAD_OTTFEED_COMPONENT,
  url,
  content,
});
