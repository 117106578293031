import React, { useEffect } from 'react';
import { Route, Redirect, Switch, HashRouter as Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  OTTPageType,
  OTTSearchType,
  OTTFeedType,
  OTTEpisodeType,
  OTTSettingsType,
  OTTTextType,
  OTTAboutType,
  OTTSubscribeType,
  MvpdFlowType,
} from './constants/structureTypes';
import FocusService from './services/focusService';
import { isEnabledTTS } from './services/TTSService';
import { setCity, setStation, setTTSStatus } from './stores/actions/common';
import { getSessionDataFromLocalStorage } from './utils/session';
import { RootState } from './stores/reducers';
import currentPlatformObj from './platform/currentPlatform';
import { fontFamily as defaultFontFamily } from './constants/colors';

import OTTPage from './scenes/OTTPage/OTTPage';
import OTTFeed from './scenes/OTTFeed/OTTFeed';
import OTTEpisode from './scenes/OTTEpisode/OTTEpisode';
import OTTSearch from './scenes/OTTSearch/OTTSearch';
import OTTSettings from './scenes/OTTSettings/OTTSettings';
import OTTAbout from './scenes/OTTAbout/OTTAbout';
import OTTText from './scenes/OTTText/OTTText';
import SplashScreen from './scenes/SplashScreen/SplashScreen';
import Spinner from './components/Spinner/Spinner';
import NetworkStatusModal from './components/Modals/NetworkStatusModal/NetworkStatusModal';
import TCPlusFlow from './scenes/TCPlusFlow/TCPlusFlow';
import MvpdFlow from './scenes/MvpdFlow/MvpdFlow';
import ConfirmExitFromAppModal from './components/Modals/ExitFromAppModal/ExitFromAppModal';
import ErrorBoundary from './components/Modals/ErrorBoundary/ErrorBoundary';
import DebugSettings from './components/DebugSettings/DebugSettings';

import './App.scss';

const App = () => {
  const navigation = useSelector((state: RootState) => state.config.navigation);
  const isShownDebugSettings = useSelector((state: RootState) => state.common.isShownDebugSettings);
  const features = useSelector((state: RootState) => state.config.features);
  const isEnabledTTSStatus = useSelector((state: RootState) => state.common.TTSEnabled);
  const fontFamily = useSelector((state: RootState) => state.config.styles?.['font']?.['face']?.['fontFamily']);

  const dispatch = useDispatch();

  const changeTTSStatus = () => {
    if (isEnabledTTS()) {
      if (!isEnabledTTSStatus) {
        dispatch(setTTSStatus(true));
      }
    } else {
      if (isEnabledTTSStatus) {
        dispatch(setTTSStatus(false));
      }
    }
  };

  useEffect(() => {
    const { station, city } = getSessionDataFromLocalStorage();

    FocusService.startFocusService();
    FocusService.setFocusToSilentEl();
    currentPlatformObj.registerKeys();
    dispatch(setCity(city));
    dispatch(setStation(station));
    document.addEventListener('keydown', changeTTSStatus);

    return () => {
      document.removeEventListener('keydown', changeTTSStatus);
    };
    // eslint-disable-next-line
  }, []);

  const addRoute = (item, createdRoutes, navigationRoutes) => {
    if (!createdRoutes[item.type]) {
      navigationRoutes.push(item);
      createdRoutes[item.type] = true;
    }
  };

  const getComponent = (type) => {
    switch (type) {
      case OTTPageType:
        return OTTPage;
      case OTTFeedType:
        return OTTFeed;
      case OTTEpisodeType:
        return OTTEpisode;
      case OTTSearchType:
        return OTTSearch;
      case OTTSettingsType:
        return OTTSettings;
      case OTTTextType:
        return OTTText;
      case OTTAboutType:
        return OTTAbout;
      case OTTSubscribeType:
        return TCPlusFlow;
      default:
        return Spinner;
    }
  };

  const createRoutes = () => {
    const navigationRoutes: any = [{ type: OTTEpisodeType }];
    const createdRoutes = {};

    navigation.forEach((item) => {
      if (item.children) {
        item.children.forEach((item) => {
          addRoute(item, createdRoutes, navigationRoutes);
        });
      }
      addRoute(item, createdRoutes, navigationRoutes);
    });

    return navigationRoutes.map((item) => {
      return (
        <Route
          key={`${item.type}:${item.title}`}
          path={`/${item.type}/:id`}
          component={getComponent(item.type)}
        />
      );
    });
  };

  return (
    <div className='app' tabIndex={-1}>
      <ErrorBoundary>
        <Router>
          <main className='main' style={{fontFamily: fontFamily || defaultFontFamily}}>
            <Switch>
              {features?.cleeng && <Route path='/TCPlusFlow/:id' component={TCPlusFlow} /> }
              {navigation?.length && createRoutes()}
              {features?.mvpd && <Route path={`/${MvpdFlowType}`} component={MvpdFlow} />}
              <Route path='/OTTText/:id' component={OTTText} />
              <Route path='/OTTAbout/:id' component={OTTAbout} />
              <Route path='/SplashScreen' component={SplashScreen} />
              <Redirect from='/' to='/SplashScreen' />
            </Switch>
          </main>
        </Router>
        <NetworkStatusModal />
        <ConfirmExitFromAppModal />
        {isShownDebugSettings && <DebugSettings />}
      </ErrorBoundary>
    </div>
  );
};

export default App;
