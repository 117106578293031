import axiosInstance from './axiosInstance';
import { config } from '../config';
import currentPlatform from '../platform/currentPlatform';

const LIVERAMP_COLLECTOR_KEY = 'AIzaSyAz013URjOP3o6xl3QALIFPHJqXMWEVOHQ';

interface IAxiosHeaders {
  'Content-Type': string;
  'X-Api-Key': string;
}

export interface ILiverampPayload {
  'deviceId': string;
  'deviceType': string;
  'ipAddress': string;
  'timestamp': number;
  'useragent': string;
}

export async function setLiverampData(url: string) {

  const data: ILiverampPayload = {
    deviceId: currentPlatform.getAdvertisementId(),
    deviceType: config.deviceName,
    ipAddress: currentPlatform.getIp(),
    timestamp: Math.floor(Date.now() / 1000),
    useragent: window.navigator.userAgent,
  };

  const axiosHeaders: IAxiosHeaders = {
    'Content-Type': 'application/json',
    'X-Api-Key': LIVERAMP_COLLECTOR_KEY,
  };

  // Basically it isn't necessary to manage the result of below request
  const liverampResponse = await axiosInstance.post(url, data, {
    headers: axiosHeaders,
  });

  return liverampResponse.data;
}
