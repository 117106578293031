import moment from 'moment';
import { useEffect } from 'react';
import { TTS_TEXT_TO_ANNOUNCE_LOADING } from '../constants/text';

declare const webapis: any;
declare const window: any;

let ignoreSpeakTTSCalls: boolean = false;

export const setIgnoreSpeakTTSCalls = (value: boolean) => (ignoreSpeakTTSCalls = value || false);

export const speakTTS = (
  text: string,
  callback?: any,
  arg?: any,
  stopPreviousTTS: boolean = true
): void => {
  if (window.webapis && isEnabledTTS() && !ignoreSpeakTTSCalls) {
    stopPreviousTTS && stopTTS();

    const utterance = new SpeechSynthesisUtterance();

    utterance.text = text;
    window.speechSynthesis?.speak(utterance || '');
    // left this line for testing purpose
    // console.log('==============>TTS', utterance.text);
    utterance.onend = function() {
      callback && callback(arg);
    };
  }
};

export const stopTTS = () : void => {
  if (window.speechSynthesis?.speaking) {
    window.speechSynthesis?.cancel();
  }
};

export const isEnabledTTS = (): boolean => {
  if (typeof webapis !== 'undefined') {
    return !!webapis.tvinfo.getMenuValue(webapis.tvinfo.TvInfoMenuKey.VOICE_GUIDE_KEY);
  }

  return false;
};

export const convertIntToTimeStringForTTS = (time) => {
  const duration = moment.duration(time);
  const hours = duration.get('hours') ? `${duration.get('hours')}hour` : '';
  const minutes = duration.get('minutes') ? `${duration.get('minutes')}minute` : '';
  const seconds = duration.get('seconds') ? `${duration.get('seconds')}second` : '';

  return `${hours}${minutes}${seconds}`;
};

export const getDurationString = (duration) => {
  const time = convertIntToTimeStringForTTS(duration);

  return time
    ? ' out of ' + convertIntToTimeStringForTTS(duration)
    : '';
};

export const getCurrentTimeString = (currentTime) => {
  const currentTimeString = convertIntToTimeStringForTTS(currentTime);

  return currentTimeString ? currentTimeString : 'start of video';
};

export const useAnnouncePageLoading = (isLoading: boolean, loadingText?: string): void => {
  useEffect(() => {
    if (isLoading) {
      speakTTS(loadingText || TTS_TEXT_TO_ANNOUNCE_LOADING);
    }
  }, [isLoading]);
};
