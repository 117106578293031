import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleDebugModal } from '../../../stores/actions/common';
import { RootState } from '../../../stores/reducers';
import { DEBUG_MODAL_DESCRIPTION, DEBUG_MODAL_TITLE } from '../../../constants/text';

import Modal from '../Modal';

interface IDebugSettingsModal {
  setIsShownDebugAccessModal: (value: boolean) => void;
}

const DebugSettingsModal = ({ setIsShownDebugAccessModal }: IDebugSettingsModal) => {
  const DEBUG_MODAL_PASSWORD = useSelector((state: RootState) => state.config.debugSettings.passcode);

  const [debugPassword, setDebugPassword] = useState<string>('');
  const [isWrongDebugPassword, setIsWrongDebugPassword] = useState<boolean>(false);

  const dispatch = useDispatch();

  const checkDebugAccessPassword = () => {
    if (debugPassword === DEBUG_MODAL_PASSWORD) {
      setIsShownDebugAccessModal(false);
      dispatch(toggleDebugModal());
    } else {
      setIsWrongDebugPassword(true);
    }
  };

  const onBackHandlerCb = () => {
    setIsShownDebugAccessModal(false);
  };

  return (
    <Modal
      idContainer='Debug-App-Modal'
      title={DEBUG_MODAL_TITLE}
      description={DEBUG_MODAL_DESCRIPTION}
      onBackHandlerCb={onBackHandlerCb}
      isDebugModal={true}
      isWrongDebugPassword={isWrongDebugPassword}
      setDebugPassword={setDebugPassword}
      debugPassword={debugPassword}
      buttons={[
        {
          id: 'modal-yes-button',
          text: 'Submit',
          onPress: checkDebugAccessPassword,
        },
        {
          id: 'modal-no-button',
          text: 'Exit',
          onPress: () => setIsShownDebugAccessModal(false),
        },
      ]}
    />
  );
};

export default DebugSettingsModal;
