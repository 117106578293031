import React, { ReactElement } from 'react';
import { onEnterHandler, splitTextByUrls } from '.';
import { PRIVACY_POLICY_SCREEN, TERMS_OF_USE_SCREEN } from '../constants/text';

interface ITOSOptions {
  id: string,
  title: string,
  itemSpacings: number,
  isUnderline: boolean,
}

export const getCustomStyledSymbol = (symbol:string, color = 'inherit'):ReactElement => {
  return (
    <span style={{color}}>{symbol}</span>
  );
};

export const getTextWithBoldUrls = (inputText:string):ReactElement | null => {
  if (!inputText) {return null;}

  return <>{(splitTextByUrls(inputText).map(({text, isUrl}, idx)=> isUrl
    ? <b key={idx}>{text}</b>
    : <span key={idx}>{text}</span>)
  )}</>;
};

export const getTOSText = (
  tosOptions: Array<Array<ITOSOptions>>,
  focusColor: string,
  blurColor: string,
  setCurrPageType: (pageType: string) => void,
): Array<Array<ReactElement>> | null => {
  if (!tosOptions.length) {
    return null;
  }

  return tosOptions.map((line: ITOSOptions[]) => {
    return line.map((item: ITOSOptions) => {
      switch (item.id) {
        case 'terms_of_use':
          return <span
            className={'underlined-text tos-text'}
            tabIndex={0}
            id={'subscription-form-checkbox-tos-text'}
            data-tv-focus-left={'.checkbox-acc-1'}
            data-tv-focus-right={'.pp-text'}
            data-tv-focus-down={'.btn-acc-0'}
            data-tv-focus-up={'.checkbox-acc-0'}
            onFocus={(e) => e.target.style.color = focusColor}
            onBlur={(e) => e.target.style.color = blurColor}
            onKeyDown={
              e => onEnterHandler(e, () => setCurrPageType(TERMS_OF_USE_SCREEN))
            }
          >
            {item.title}
          </span>;
          break;
        case 'privacy_policy':
          return <span
            className={'underlined-text pp-text'}
            tabIndex={0}
            id={'subscription-form-checkbox-pp-text'}
            data-tv-focus-left={'.tos-text'}
            data-tv-focus-right={'.pp-text'}
            data-tv-focus-down={'.btn-acc-0'}
            data-tv-focus-up={'.checkbox-acc-0'}
            onFocus={(e) => e.target.style.color = focusColor}
            onBlur={(e) => e.target.style.color = blurColor}
            onKeyDown={
              e => onEnterHandler(e, () => setCurrPageType(PRIVACY_POLICY_SCREEN))
            }
          >
            {item.title}
          </span>;
          break;
        case 'ampersand':
          return <span> {item.title} </span>;
          break;
        default:
          return <span>{item.title} </span>;
      }
    });
  });
};
