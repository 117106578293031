import React from 'react';

import CAROUSEL_TEASER_LIST from '../components/CAROUSEL_TEASER_LIST/CAROUSEL_TEASER_LIST';
import FEED_TEASER_LIST from '../components/FEED_TEASER_LIST/FEED_TEASER_LIST';
import STANDARD_TEASER_LIST from '../components/STANDARD_TEASER_LIST/STANDARD_TEASER_LIST';
import HERO_TESER from '../components/HERO_TEASER/HERO_TEASER';
import SEASON_GRID from '../components/SEASON_GRID_TEASER_LIST/SEASON_GRID_TEASER_LIST';
import TEXT_TEASER from '../components/TEXT_TEASER/TEXT_TEASER';
import THREE_COLUMN_TEASER_LIST from '../components/THREE_COLUMN_TEASER_LIST/THREE_COLUMN_TEASER_LIST';
import FOUR_COLUMN_TEASER_LIST from '../components/FOUR_COLUMN_TEASER_LIST/FOUR_COLUMN_TEASER_LIST';
import FULL_SCREEN_CARD from '../components/FULL_SCREEN_CARD/FULL_SCREEN_CARD';

class UIGenerator {
  static getCarouselTeaserList(config) {
    return <CAROUSEL_TEASER_LIST {...config} />;
  }

  static getFeedTeaserList(config) {
    return <FEED_TEASER_LIST {...config} />;
  }

  static getStandardTeaserList(config) {
    return <STANDARD_TEASER_LIST {...config} />;
  }

  static getHeroTeaser(config) {
    return <HERO_TESER {...config} />;
  }

  static getSeasonGrid(config) {
    return <SEASON_GRID {...config} />;
  }

  static getTextTeaser(config) {
    return <TEXT_TEASER {...config} />;
  }

  static getThreeColumnTeaserList(config) {
    return <THREE_COLUMN_TEASER_LIST {...config} />;
  }

  static getFourColumnTeaserList(config) {
    return <FOUR_COLUMN_TEASER_LIST {...config} />;
  }

  static getFullScreenCard(config) {
    return <FULL_SCREEN_CARD {...config}/>;
  }

}

export default UIGenerator;
