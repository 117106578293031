import React from 'react';

import Carousel from '../../components/Carousel/Carousel';
import Card from '../../components/Carousel/Card/Card';
import { THREE_SLIDES_VALUE } from '../../constants/structureTypes';
import { CLEENG_RESTRICTION } from '../../constants/text';
import { isContentWithRestriction } from '../../utils';

import './FEED_TEASER_LIST.scss';

type FEED_TEASER_LIST_PROPS = {
  content: Array<{ item: object }>,
  index: number,
  title: string,
  onSelect: () => void,
  onPress: () => void,
  defaultCardIndex: number;
  defaultCarouselID: string;
  carouselUUID: any,
};

const FEED_TEASER_LIST = (props: FEED_TEASER_LIST_PROPS) => {
  const { content, index, title, onSelect, onPress, defaultCardIndex, defaultCarouselID, carouselUUID } = props;

  const cardStyles = {
    width: 560,
    height: 300,
    marginLeft: 25,
    marginRight: 30,
    additionalHeight: 135,
  };

  const cardTitleContainerStyles = {
    padding: '15px 25px',
    backgroundColor: 'rgb(40, 45, 52)',
  };

  return (
    <div className="feed-teaser-list">
      <p className="list-title">{title}</p>
      <Carousel
        cardStyles={cardStyles}
        carouselId={index}
        onCardSelection={onSelect}
        onCardPressed={onPress}
        slides={THREE_SLIDES_VALUE}
        additionalHeight={135}
        carouselTitle={title}
        defaultCardIndex={content.length > 3 ? defaultCardIndex : 0}
        defaultCarouselID={defaultCarouselID}
        carouselUUID={carouselUUID}
      >
        {content.map((item) => (
          <Card
            TCPlusContent={
              isContentWithRestriction(item?.item['media:content']['media:restriction'], CLEENG_RESTRICTION)
            }
            duration={item.item['media:content']['duration']}
            width={cardStyles.width}
            height={cardStyles.height}
            key={index}
            src={item.item['media:content']['media:thumbnail']}
            title={item.item['media:content']['media:title']['content']}
            logo={item.item['media:content']['sinclair:logo']}
            alt={item.item['slug']}
            isLiveVideo={item.item['media:content']['sinclair:isLiveProgram']}
            titleContainerStyles={cardTitleContainerStyles}
            cardId={item.item['guid']['content']}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default FEED_TEASER_LIST;
