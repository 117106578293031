import * as actionTypes from '../actions/types/OTTChannelGuide';

export interface IMediaRestriction {
  content: string;
  relationship: string;
  type: string;
}

export interface ICategory {
  order: number;
  name?: string;
  uuid: string;
  icon?: string;
}

export interface IChannel {
  displayName: string;
  mediaRestriction?: IMediaRestriction;
  icon: string;
  id: string;
  focusIndex: number;
  programs: Array<any>;
  categories: ICategory[];
  number: string;
  categoryIndex?: number,
  categoryId?: string,
  category?: string,
}

interface RawChannel {
  'display-name': string;
  icon: {
    src: string,
  };
  id: string;
  mediaRestriction?: IMediaRestriction;
}

export type ICategoriesMap = {
  [key: string]: number,
}

export interface RawProgram {
  title: {
    value: string,
    lang: string
  },
  'sinclair:isLiveProgram': boolean,
  desc: {
    value: string,
    lang: string
  },
  start: string,
  stop: string,
  startLong: number,
  stopLong: number,
  channel: string,
  category: {
      value: string,
      lang: string
    }[]
}

export interface DataPayload {
  channel: Array<RawChannel>;
}

export interface ProgramsDataPayload {
  channel: Array<RawChannel>;
  programme: Array<RawProgram>;
}

interface LoadChannelsListAction {
  type: typeof actionTypes.LOAD_OTTCHANNELGUIDE_LIST;
}

interface LoadChannelsListSucceededAction {
  type: typeof actionTypes.LOAD_OTTCHANNELGUIDE_LIST_SUCCEEDED;
  payload: {
    channels: IChannel[];
    categories: ICategory[],
  }
}

interface LoadChannelsListFailedAction {
  type: typeof actionTypes.LOAD_OTTCHANNELGUIDE_LIST_FAILED;
  error: string;
}

interface LoadProgramsAction {
  type: typeof actionTypes.LOAD_OTTPROGRAMS_LIST;
  channels: Array<IChannel>;
}

interface LoadProgramsSucceededAction {
  channels: Array<IChannel>;
  type: typeof actionTypes.LOAD_OTTPROGRAMS_LIST_SUCCEEDED;
  payload: {
    channelsWithPrograms: IChannel[],
    channelsSortedByCategories: IChannel[],
    categoriesMap: ICategoriesMap,
  },
}
interface LoadProgramsFailedAction {
  type: typeof actionTypes.LOAD_OTTPROGRAMS_LIST_FAILED;
}

interface ClearOTTChannelGuide {
  type: typeof actionTypes.CLEAR_OTTCHANNELGUIDE;
}

type ChannelListAction = LoadChannelsListAction | LoadChannelsListSucceededAction | LoadChannelsListFailedAction;

export type ChannelsAction = ChannelListAction | ClearOTTChannelGuide;

type ProgramsLoadAction = LoadProgramsAction | LoadProgramsSucceededAction | LoadProgramsFailedAction;

export type ProgramsAction = ProgramsLoadAction | ClearOTTChannelGuide;

export const loadChannelsListData = (): ChannelsAction => {
  return ({
    type: actionTypes.LOAD_OTTCHANNELGUIDE_LIST,
  });
};

export const loadProgramsListData = (channels): ProgramsAction => {
  return ({
    type: actionTypes.LOAD_OTTPROGRAMS_LIST,
    channels,
  });
};
