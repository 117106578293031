import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../stores/reducers';

import './HERO_TEASER.scss';

interface IHeroTeaserProps {
  content: Array<{ item: object }>;
}

const HERO_TEASER = ({ content }: IHeroTeaserProps) => {
  const TTSStatus = useSelector((state: RootState) => state.common.TTSEnabled);

  const getBrandScreenStyle = (item) => ({
    backgroundImage: `url(${item['media:content']['media:thumbnail']['url']})`,
  });

  const getAriaLabel = (item) => {

    return `${item['media:content']['media:title']['content']}.` +
      `${item['media:content']['media:description']['content']}`;
  };

  return (
    <div className="hero-teaser">
      {content.map(({ item }, index) => (
        <div key={index} className="hero-wrapper">
          <div className="brand-container">
            <div style={getBrandScreenStyle(item)} className="brand-main">
              <div className="brand-main-background-gradient" />
              <div
                className="brand-info"
                data-tv-focus-left='.brand-info'
                data-tv-focus-right='.brand-info'
                data-tv-focus-down='.season-list-last-focused-item'
                // eslint-disable-next-line
                role=''
                tabIndex={TTSStatus ? 0 : -1}
                aria-label={getAriaLabel(item)}
              >
                <h1 className="brand-title">
                  {item['media:content']['media:title']['content']}
                </h1>
                <p className="brand-description">
                  {item['media:content']['media:description']['content']}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HERO_TEASER;
