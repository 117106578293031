import { Dispatch, MutableRefObject, SetStateAction, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../stores/reducers';
import { mvpdLoginRequired, tennisPlusLoginRequired } from '../utils';
import { isStirrTcMarqueeBrand } from '../constants/structureTypes';

const useGetVideoData = (
  configData: any,
  setComponentCallback: any,
  isLive: boolean = false,
  link: string = '',
  setActiveChannel?: Dispatch<SetStateAction<number>>,
  setSelectedChannelId?: Dispatch<SetStateAction<string>>,
  activeChannelName?: MutableRefObject<string>,
) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const features = useSelector((state: RootState) => state.config.features);
  const channelsWithPrograms = useSelector((state: RootState) => state.OTTChannelGuide.channelsSortedByCategories);
  const channelStatusUrl = useSelector((state: RootState) => state.config.allConfigs.API.channel_status);
  const isUserMVPDLogin = useSelector((state: RootState) => state.Login.isUserMVPDLogin);
  const isUserTCLogin = useSelector((state: RootState) => state.Login.isUserTCLogin);
  const isCurrentSubscriptionActive = useSelector(
    (state: RootState) => state.cleengService.isCurrentSubscriptionActive
  );

  useEffect(() => {
    const content = { ...configData };

    // Getting data for TC and Marquee
    if (features.cleeng || features.mvpd) {
      if (!link && (channelsWithPrograms.length || !isLive)) {
        // Live content from teaser and VOD
        if (configData['media:content']) {
          dispatch(setComponentCallback('', content));
        // Live content from EPG and ON NOW
        } else if (configData.path) {
          let initialChannel;

          if (isLive) {
            if (isUserMVPDLogin) {
              initialChannel = channelsWithPrograms.find((
                { mediaRestriction }) => mvpdLoginRequired(mediaRestriction[0].content
              ));
            } else if (isUserTCLogin && isCurrentSubscriptionActive) {
              initialChannel = channelsWithPrograms.find((
                { mediaRestriction }) => tennisPlusLoginRequired(mediaRestriction[0].content
              ));
            } else {
              initialChannel = channelsWithPrograms.find(channel => !channel?.mediaRestriction);
            }

            if (setActiveChannel && setSelectedChannelId && activeChannelName) {
              const activeChannelIndex = channelsWithPrograms.findIndex(channel => channel?.id === initialChannel?.id);

              setActiveChannel(activeChannelIndex > 0 ? activeChannelIndex : 0);
              setSelectedChannelId(
                configData?.['media:content']?.['sinclair:ident'] ||
                initialChannel?.id ||
                channelsWithPrograms[0].id
              );
              activeChannelName.current = initialChannel?.displayName || channelsWithPrograms[0].displayName;
            }

            const contentURL: string = initialChannel
              ? `${channelStatusUrl}${initialChannel?.displayName}`
              : `${channelStatusUrl}${channelsWithPrograms[0]?.displayName}`;

            dispatch(setComponentCallback(contentURL));
          }
        }
      }
      // Getting data for STIRR and COMET/CHARGE
    } else {
      if (configData.path) {
        if (configData.path.indexOf('.m3u8') === -1) {
          // check !link prevent from channel changing during EPG refreshing
          if (channelsWithPrograms.length && isLive && !link) {
            // set first channel of the first category as default channel for Live
            const channelName = channelsWithPrograms[0].displayName;

            const nextLink = configData?.isFlowFromTeaser || !isStirrTcMarqueeBrand
              ? configData.path
              : configData.path.replace(
                /[^?]*/, `${channelStatusUrl}${channelName}`
              );

            dispatch(setComponentCallback(nextLink));
          } else if (!isLive) {
            // only for VOD
            dispatch(setComponentCallback(configData.path));
          }
        } else {
          dispatch(setComponentCallback('', content));
        }
      }
    }
    // eslint-disable-next-line
  }, [configData?.path, channelsWithPrograms, history.location.pathname]);
};

export default useGetVideoData;
