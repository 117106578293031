import {
  PLAYER_CHANGE_ACTION,
  PLAYER_CLOSE,
  PLAYER_DESTROY_DAI_STREAM, PLAYER_INITIALIZE,
  PLAYER_OPEN_LIVE_URL,
  PLAYER_OPEN_VOD_URL,
  PLAYER_SEEK_TO,
} from './types/player';

export interface IPlayerChangeAction {
  type: typeof PLAYER_CHANGE_ACTION;
  actionName: string;
}

export interface IPlayerOpenVODUrl {
  type: typeof PLAYER_OPEN_VOD_URL;
  url: string;
}

export interface IPlayerOpenLiveUrl {
  type: typeof PLAYER_OPEN_LIVE_URL;
  url: string;
}

export interface IPlayerSeekTo {
  type: typeof PLAYER_SEEK_TO;
  pointOfTime: number;
}

export interface IPlayerClose {
  type: typeof PLAYER_CLOSE;
}

export interface IPlayerDestroyDaiStream {
  type: typeof PLAYER_DESTROY_DAI_STREAM;
}

export const playerAction = (actionName: string): IPlayerChangeAction => ({
  type: PLAYER_CHANGE_ACTION,
  actionName,
});

export const playerOpenVODUrl = (url: string): IPlayerOpenVODUrl => ({
  type: PLAYER_OPEN_VOD_URL,
  url,
});

export const playerOpenLiveUrl = (url: string): IPlayerOpenLiveUrl => ({
  type: PLAYER_OPEN_LIVE_URL,
  url,
});

export const playerSeekTo = (pointOfTime: number): IPlayerSeekTo => ({
  type: PLAYER_SEEK_TO,
  pointOfTime,
});

export const playerClose = (): IPlayerClose => ({
  type: PLAYER_CLOSE,
});

export const playerDestroyDaiStream = (): IPlayerDestroyDaiStream => ({
  type: PLAYER_DESTROY_DAI_STREAM,
});

export const playerInitialize = (el: any) => ({
  type: PLAYER_INITIALIZE,
  el,
});
