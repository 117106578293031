import React, { useEffect, useRef} from 'react';
import {useSelector } from 'react-redux';

import { jwPlayer } from './JWPlayer';
import {RootState} from '../../../stores/reducers';
import { getUrlsPlaylist } from './jwhelper';
import { getMilliseconds } from '../../../utils/time';
import { PLAYER_CHECK_POSITION_INTERVAL } from '../../../constants/videoPlayer';

type JWPlayerWrapperProps = {
  onStreamPlay: any;
  onStreamPause: any;
  onStreamCompleted: any;
  onCanPlay: any;
  onParsingMetadata: any;
  onSuccessPrepared: any;
  onSuccessLoad: any;
  onFailedLoad: any;
  onError: any;
  destroyDaiStream: any;
  neededCCTrack: any;
  setCurrentCue: any;
  seekToSuccessCb: any;
  onPausedBeforeAd: any;
  checkPlayStatePosition: any;
  cleanUpCb: any;
  onCurrentPlaytime: any;
  onBufferingStart: any;
  onBufferingComplete: any;
  children: any;
  typeContent: string;
  drm?: any;
};

const JWPlayerWrapper = ({
  onStreamPlay,
  onStreamPause,
  onSuccessPrepared,
  onFailedLoad,
  destroyDaiStream,
  neededCCTrack,
  setCurrentCue,
  onStreamCompleted,
  onCanPlay,
  onSuccessLoad,
  onError,
  seekToSuccessCb,
  onPausedBeforeAd,
  onParsingMetadata,
  checkPlayStatePosition,
  cleanUpCb,
  drm,
  onCurrentPlaytime,
  onBufferingStart,
  onBufferingComplete,
  typeContent,
  children,
}: JWPlayerWrapperProps) => {

  const currentPlayerAction = useSelector((state: RootState) => state.player.playerAction);
  const playerUrl = useSelector((state: RootState) => state.player.url);
  const pointOfTime = useSelector((state: RootState) => state.player.pointOfTime);
  const refElement = useSelector((state: RootState) => state.player.el);
  const captionsStatusFromStore = useSelector((state: RootState) => state.common.isCCEnabled);

  const prevPlayerActionName = useRef<string>('');
  const prevBuffered = useRef<boolean>(false);
  const ccSetOnMount = useRef<boolean>(false);
  const bufferCheckTimer = useRef<any>(null);
  const positionChangeInterval = useRef<any>(null);

  const currentTimeUpdate = (event) => {
    onCurrentPlaytime && onCurrentPlaytime(getMilliseconds(event.position));
  };

  const onErrorWrapper = (e) => {
    console.log('jwplayer ERROR details', e);
    onError ? onError(e) : onFailedLoad(e, { ...e, fatal: true });
  };

  const onCanPlayWrapper = () => {
    const videoBand = jwPlayer.getVideoBandwidth();
    const duration = jwPlayer.getDuration();

    launchTimePositionTracker();

    onCanPlay && onCanPlay(videoBand, duration);
  };

  const launchTimePositionTracker = () => {
    clearInterval(positionChangeInterval.current);
    positionChangeInterval.current = setInterval(() => {
      const playerState = jwPlayer.getState();

      if (['buffering', 'paused'].includes(playerState)) {
        return;
      }

      checkPlayStatePosition(jwPlayer.getCurrentTime());
    }, PLAYER_CHECK_POSITION_INTERVAL);
  };

  const toggleCConLoad = (obj) => {
    if ((obj?.tracks.length > 1) && !ccSetOnMount.current) {
      jwPlayer.showCaptions(neededCCTrack, setCurrentCue, captionsStatusFromStore);
      ccSetOnMount.current = true;
    }
  };

  const onBufferingCompletedWrapper = () => {
    if (prevBuffered.current && (jwPlayer.getState() !== 'buffering')) {
      onBufferingComplete();
      prevBuffered.current = false;
    }
  };

  const onSuccessPreparedWrapper = () => {
    console.log('jwplayer ready !');

    onSuccessPrepared && onSuccessPrepared();
  };

  const onPlayStreamWrapper = () => {
    onStreamPlay();
    onBufferingCompletedWrapper();
  };

  const commonCbsListeners = [
    ['time', currentTimeUpdate],
    ['complete', onStreamCompleted],
    ['error', onErrorWrapper],
    ['setupError', onErrorWrapper],
    ['captionsList', toggleCConLoad],
    ['bufferChange', onBufferingCompletedWrapper],
    ['buffer', (obj) => {
      if (obj?.newstate === 'buffering') {
        onBufferingStart();
        prevBuffered.current = true;
        clearTimeout(bufferCheckTimer.current);

        bufferCheckTimer.current = setTimeout(() => {
          onBufferingCompletedWrapper();
        }, 300);
      }
    }],
  ];

  // Events for player
  const cbsVODListeners = [
    ...commonCbsListeners,
    ['play', onCanPlayWrapper],
    ['ready', onSuccessLoad],
  ];

  const cbsLiveListeners = [
    ...commonCbsListeners,
    ['ready', onSuccessPreparedWrapper],
    ['playlist', onSuccessLoad],
    ['beforePlay', onCanPlayWrapper],
    ['play', onPlayStreamWrapper],
    ['pause', onStreamPause],
    ['meta', onParsingMetadata],
  ];

  useEffect(() => {
    return () => {
      clearInterval(positionChangeInterval.current);
      if (jwPlayer.getPlayerIsLive()) {
        if (typeContent === 'vod') {
          cleanUpCb(jwPlayer.getCurrentTime(), jwPlayer.getDuration());
        }

        clearTimeout(bufferCheckTimer.current);
        jwPlayer.close();
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const isRestrictedActionOnLoad = ['destroyDaiStream', 'close'].includes(currentPlayerAction);

    if (!currentPlayerAction || (isRestrictedActionOnLoad && !prevPlayerActionName.current)) {
      return;
    }

    const checkConditionForExecution = () => {
      const isAllowedRepeatedAction = ['seek'].includes(currentPlayerAction);

      return ((currentPlayerAction !== prevPlayerActionName.current) || isAllowedRepeatedAction);
    };

    const getPlayerCb = () => {

      const playerCb = {
        initialize: () => {
          jwPlayer.initialize(refElement);
        },
        openVOD: () => {
          const urlPlaylist = getUrlsPlaylist(playerUrl, drm);

          jwPlayer.open(urlPlaylist, null, null, null);
          jwPlayer.prepare(null, null, cbsVODListeners);
        },
        openLive: () => {
          const urlPlaylist = getUrlsPlaylist(playerUrl, drm);

          jwPlayer.open(urlPlaylist, null, null, null);
          jwPlayer.prepare(null, null, cbsLiveListeners);
        },
        play: () => {
          jwPlayer.play();
        },
        pause: () => {
          jwPlayer.pause();
        },
        suspend: () => {
          jwPlayer.suspend();
        },
        restore: () => {
          jwPlayer.restore();
        },
        seek: () => {
          jwPlayer.seekTo(pointOfTime, seekToSuccessCb);
        },
        cleanUp: () => {
          cleanUpCb(jwPlayer.getCurrentTime(), jwPlayer.getDuration());
        },
        close: () => {
          jwPlayer.close();
        },
        pauseBeforeAd: () => {
          onPausedBeforeAd(jwPlayer.getCurrentTime());
          jwPlayer.pause();
        },
        destroyDaiStream: () => {
          jwPlayer.getPlayerIsLive() && destroyDaiStream();
          jwPlayer.close();
        },
      };

      if (typeof playerCb?.[currentPlayerAction] === 'function') {
        return playerCb?.[currentPlayerAction]();
      }

      return;
    };

    if (checkConditionForExecution()) {
      getPlayerCb();
      prevPlayerActionName.current = currentPlayerAction;
    }

    // eslint-disable-next-line
  }, [currentPlayerAction]);

  useEffect(() => {
    if (jwPlayer.getPlayerIsLive()) {
      jwPlayer.showCaptions(neededCCTrack, setCurrentCue, captionsStatusFromStore);
    }
  }, [captionsStatusFromStore]);

  return (<>{children}</>);
};

export default JWPlayerWrapper;
