import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { RootState } from '../../../stores/reducers/index';
import FocusService from '../../../services/focusService';
import { speakTTS, stopTTS } from '../../../services/TTSService';
import {
  OTT_BRAND,
  COMET_OTT_BRAND,
  CHARGE_OTT_BRAND,
  isStirrTcMarqueeBrand,
} from '../../../constants/structureTypes';
import {
  OPEN_SCHEDULE,
  OVERLAY_PRE_PHRASE_COMET_CHARGE,
  OVERLAY_PRE_PHRASE_STIRR,
} from '../../../constants/text';
import { getKeyInfo } from '../../../utils';
import { calculateTime, getTextTimeLeft } from '../../../utils/time';

import './LiveOverlay.scss';

type LiveOverlayProps = {
  visible: boolean;
  channel: any;
  channelID: string;
  isConnectedToNetwork: boolean;
  isEpgOpen: boolean;
}

const LiveOverlay = (props: LiveOverlayProps) => {
  const {
    visible,
    channelID,
    channel,
    isConnectedToNetwork,
    isEpgOpen,
  } = props;

  const isProgramsLoading = useSelector((state: RootState) => state.OTTChannelGuide.isProgramsLoading);

  const [channelLogoInternal, setChannelLogoInternal] = useState('');
  const initialState = {
    label: '',
    title: '',
    description: '',
    start: '',
    stop: '',
  };

  const [currentMetaInt, setCurrentMetaInt] = useState(initialState);
  const [nextMetaInt, setNextMetaInt] = useState({ ...initialState});

  const playerStyles = useSelector((state: RootState) => state.config.styles['player']);
  const teaserStyles = useSelector((state: RootState) => state.config.styles['teaser']);
  const buttonStyles = useSelector((state: RootState) => state.config.styles['button']);

  const openEPGRef = useRef(null);
  const openScheduleRef = useRef(null);
  const refreshTimerRef = useRef<any>(null);
  const speakTTSTimerRef = useRef<any>(null);
  const focusTimerRef = useRef<any>(null);

  const INTERVAL_TIMEOUT: number = 30000;

  const start = moment(currentMetaInt.start);
  const end = moment(currentMetaInt.stop);

  const currentStart = start.format('h:mm a');
  const currentStop = end.format('h:mm a');

  const nextStart = moment(nextMetaInt.start).format('h:mm a');
  const nextStop = moment(nextMetaInt.stop).format('h:mm a');
  const {
    percentage,
    minutes,
    hours,
  } = calculateTime(start, end);

  useEffect(() => {
    const setDataToOverlay = () => {
      if (!isProgramsLoading && channel.programs) {

        const filteredPrograms = channel.programs.filter(program => program.stopLong > moment().unix());
        const currentMetaInternal = filteredPrograms[0];
        const nextMetaInternal = filteredPrograms[1];

        setMetaOverlay(channel.icon, currentMetaInternal, nextMetaInternal);
      }
    };

    setDataToOverlay();

    refreshTimerRef.current = setInterval(setDataToOverlay, INTERVAL_TIMEOUT);

    return () => {
      clearInterval(refreshTimerRef.current);
    };
    // eslint-disable-next-line
  }, [channelID, channel.programs.length, !isProgramsLoading, channel.icon]);

  const clearTimerFunc = (timerRef: any): void => {
    if (timerRef !== null) {
      clearTimeout(timerRef);
    }
  };

  useEffect(() => {
    const silentElem = document.querySelector('#appTitle');
    const overlayElem = isStirrTcMarqueeBrand ? openEPGRef.current : openScheduleRef.current;

    if (!isEpgOpen) {
      clearTimeout(focusTimerRef.current);
      focusTimerRef.current = setTimeout(() => {
        FocusService.setFocus(visible ? overlayElem : silentElem);
      }, 0);
    }

    return () => {
      clearTimerFunc(speakTTSTimerRef.current);
      clearTimeout(focusTimerRef.current);
    };
    // eslint-disable-next-line
  }, [visible, isEpgOpen]);

  const setMetaOverlay = (channelLogo, channelFirstProgram, channelSecondProgram) => {

    setChannelLogoInternal(channelLogo);
    setCurrentMetaInt(prev => ({
      ...prev,
      label: 'NOW PLAYING',
      title: channelFirstProgram?.title,
      description: channelFirstProgram?.desc,
      start: channelFirstProgram?.start,
      stop: channelFirstProgram?.stop,
    }));
    setNextMetaInt(prev => ({
      ...prev,
      label: 'UP NEXT',
      title: channelSecondProgram?.title,
      description: channelSecondProgram?.desc,
      start: channelSecondProgram?.start,
      stop: channelSecondProgram?.stop,
    }));
  };

  const speakTTSMetaData = () => {
    const getStringTTSPhrase = () => {
      const getBeginningPhrase = () => {
        switch (OTT_BRAND) {
          case COMET_OTT_BRAND:
          case CHARGE_OTT_BRAND: return OVERLAY_PRE_PHRASE_COMET_CHARGE;
          default: return OVERLAY_PRE_PHRASE_STIRR;
        }
      };

      return `${getBeginningPhrase()} ${currentMetaInt.label} ${currentMetaInt.title} ` +
        `starts at ${currentStart}, end in ${currentStop} ${getTextTimeLeft(hours, minutes)} ` +
        `${currentMetaInt.description} ` +
        `${nextMetaInt.label} ${nextMetaInt.title} starts at ` +
        `${nextStart}, end in ${nextStop}, ${nextMetaInt.description}`;
    };

    clearTimerFunc(speakTTSTimerRef.current);
    stopTTS();
    speakTTSTimerRef.current = setTimeout(()=>{
      speakTTS(getStringTTSPhrase());
    }, 2500);
  };

  const buttonGetFocused = (e) => {
    e.target.style.backgroundColor = `${buttonStyles.colorOn}`;
    e.target.style.border = `${buttonStyles.borderSize}px solid ${buttonStyles.borderColorOn}`;
    e.target.style.color = `${buttonStyles.textFocusColor}`;
    speakTTSMetaData();
  };

  const buttonGetBlur = (e) => {
    e.target.style.backgroundColor = `${buttonStyles.colorOff}`;
    e.target.style.border = `${buttonStyles.borderSize}px solid ${buttonStyles.borderColorOff}`;
    e.target.style.color = `${buttonStyles.textColor}`;
  };

  const keyDownFunction = (event) => {
    const keyInfo = getKeyInfo(event.keyCode);

    if ((keyInfo === 'Enter') || (keyInfo === 'Up')) {
      clearTimerFunc(speakTTSTimerRef.current);
      stopTTS();
    }
  };

  const containerWidth = {
    STIRR: '78%',
    COMET: '58%',
    CHARGE: '58%',
    TENNIS_CHANNEL: '78%',
  };

  return (
    <>
      {
        !channel?.programs ? null :
          (<div
            className='live-overlay'
            style={{
              opacity: visible && isConnectedToNetwork ? 1 : 0,
              backgroundColor: `${playerStyles.overlayBgColor}`,
            }}>
            <div className='live-overlay-container'>
              <div className='logo-container'>
                <img
                  aria-hidden={true}
                  src={channelLogoInternal}
                  className='channel-logo'
                  alt='channel-logo'
                />
              </div>
              <div className={`meta-info-container`}>
                <div
                  className='main-container'
                  style={{ width: containerWidth[OTT_BRAND] }}
                >
                  <div className={`progress-info-container progress-info-container-${OTT_BRAND}`}>
                    <div className='progress-meta'>
                      <span
                        className='meta-status meta-status-active'
                        style={{
                          color: `${teaserStyles['streamTagColor']}`,
                          fontFamily: `${teaserStyles['streamFontFace']['fontFamily']}`,
                          fontWeight: teaserStyles['streamFontWeight'],
                        }}
                      >
                        {currentMetaInt.label}
                      </span>
                      <span
                        className='meta-time'
                        style={{
                          color: `${teaserStyles['streamTextColor']}`,
                          fontFamily: `${teaserStyles['streamFontFace']['fontFamily']}`,
                        }}
                      >
                        {(currentStart && currentStop) ? `${currentStart} - ${currentStop}` : 'Data is loading'}
                      </span>
                    </div>
                    <div className='progress-bar-container'>
                      <div
                        className='progress-bar'
                        style={{
                          backgroundColor: `${teaserStyles.streamLiveTagColor}`,
                        }}
                      >
                        { isNaN(percentage)
                          ? null
                          : (<div
                            className='progress-line'
                            style={{
                              width: `${percentage > 100 ? 100 : percentage}%`,
                              backgroundColor: `${playerStyles['scrubberBarColor']}`,
                            }}>
                          </div>)}
                      </div>
                    </div>
                    <span
                      className='meta-time progress-bar-time'
                      style={{
                        color: `${teaserStyles['streamTextColor']}`,
                        fontFamily: `${teaserStyles['streamFontFace']['fontFamily']}`,
                      }}
                    >
                      {getTextTimeLeft(hours, minutes)}
                    </span>
                  </div>
                  <div className='description-container'>
                    <h3
                      className={`meta-title meta-title-${OTT_BRAND}`}
                      style={{
                        color: `${teaserStyles['headlineColor']}`,
                        fontFamily: `${teaserStyles['streamLiveTagFontFace']['fontFamily']}`,
                      }}
                    >
                      {currentMetaInt.title}
                    </h3>
                    <h5
                      className={`meta-description meta-description-${OTT_BRAND}`}
                      style={{
                        color: `${teaserStyles['headlineColor']}`,
                        fontFamily: `${teaserStyles['descriptionFontFace']['fontFamily']}`,
                      }}
                    >
                      {currentMetaInt.description}
                    </h5>
                  </div>
                  {isStirrTcMarqueeBrand &&
                    <div
                      className='interaction-info'
                      tabIndex={visible && isConnectedToNetwork ? 0 : -1}
                      data-tv-focus-up='#navigationSelectedItem'
                      data-tv-focus-left='.interaction-info'
                      data-tv-focus-right='.interaction-info'
                      data-tv-focus-down='.interaction-info'
                      onFocus={speakTTSMetaData}
                      onKeyDown={keyDownFunction}
                      ref={openEPGRef}
                      // eslint-disable-next-line
                      role=''
                      aria-label={'Live '}
                    >
                      Press
                      <span
                        className='active-text'
                        style={{ color: `${playerStyles['scrubberBarColor']}` }}
                      >
              &apos;Select&apos;
                      </span>
                      for Channel Guide
                    </div>
                  }
                </div>
                <div className={`meta-container meta-container-${OTT_BRAND}`} style={{ width: '22%' }}>
                  <div className='progress-meta'>
                    <span
                      className='meta-status'
                      style={{
                        color: `${teaserStyles['streamTextColor']}`,
                        fontFamily: `${teaserStyles['streamFontFace']['fontFamily']}`,
                      }}
                    >
                      {nextMetaInt.label}
                    </span>
                    <span
                      className='meta-time'
                      style={{
                        color: `${teaserStyles['streamTextColor']}`,
                        fontFamily: `${teaserStyles['streamFontFace']['fontFamily']}`,
                      }}
                    >
                      {(nextStart && nextStop) ? `${nextStart} - ${nextStop}` : 'Data is loading'}
                    </span>
                  </div>
                  <h3
                    className={`meta-title meta-title-${OTT_BRAND}`}
                    style={{
                      color: `${teaserStyles['headlineColor']}`,
                      fontFamily: `${teaserStyles['streamLiveTagFontFace']['fontFamily']}`,
                    }}
                  >
                    {nextMetaInt.title}
                  </h3>
                  <h5
                    className={`meta-description meta-description-${OTT_BRAND}`}
                    style={{
                      color: `${teaserStyles['headlineColor']}`,
                      fontFamily: `${teaserStyles['descriptionFontFace']['fontFamily']}`,
                    }}
                  >
                    {nextMetaInt.description}
                  </h5>
                </div>
                {!isStirrTcMarqueeBrand &&
                  <div className='button-container' style={{ width: '20%' }}>
                    <button
                      // eslint-disable-next-line
                      role=''
                      aria-label={'Live '}
                      className='overlay-button'
                      ref={openScheduleRef}
                      data-tv-focus-left='.overlay-button'
                      data-tv-focus-right='.overlay-button'
                      data-tv-focus-down='.overlay-button'
                      style={{
                        backgroundColor: `${buttonStyles.colorOff}`,
                        border: `${buttonStyles.borderSize}px solid ${buttonStyles.borderColorOff}`,
                        color: buttonStyles.textColor,
                        fontFamily: `${teaserStyles['streamLogoFontFace']['fontFamily']}`,
                      }}
                      onFocus={buttonGetFocused}
                      onBlur={buttonGetBlur}
                      onKeyDown={keyDownFunction}
                      data-tv-focus-up='#navigationSelectedItem'
                    >
                      {OPEN_SCHEDULE}
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>)
      }
    </>
  );
}
;

export default LiveOverlay;
