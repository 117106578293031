import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../stores/reducers';
import { setDimensions } from '../../services/analytics';
import { speakTTS, stopTTS } from '../../services/TTSService';
import { setStation, setCity } from '../../stores/actions/common';
import { getKeyInfo } from '../../utils';
import useEventListener from '../../hooks/eventListener';

import './FullScreenCard.scss';

interface IFullScreenData {
  background?: string,
  button?: any,
  componentInstanceId?: string,
  content?: string
  displayTitle?: string,
  link?: string,
  logo: [string],
  pageComponentUuid?: string,
  promoText?: string,
  stationText?: string,
  subTitle?: string,
  timer: any,
  type?: string,
}

export type FULL_SCREEN_CARD_PROPS = {
  data: IFullScreenData;
  onFullScreenCardFinish: (args: { station: string, city: string }) => void;
};

const FULL_SCREEN_CARD_COMPONENT = (props: FULL_SCREEN_CARD_PROPS) => {
  const { data, onFullScreenCardFinish } = props;

  const configTimer = useSelector((state: RootState) => state.config.allConfigs.API['city_auto_select'].timer);
  const isTTSEnabled = useSelector((state: RootState) => state.common.TTSEnabled);

  const dispatch = useDispatch();

  const responseTimer = data.timer.time;
  const autoSelectedStation = data.timer['media:content']['sinclair:action_config']['station'][0];
  const autoSelectedCity = data.timer['media:content']['sinclair:action_config']['city'];
  const getTimer = useMemo(() => responseTimer || configTimer, [responseTimer, configTimer]);

  const [timeLeft, setTimeLeft] = useState(getTimer);
  const [isTTSPlayed, setIsTTSPlayed] = useState<boolean>(false);

  useEffect(() => {
    const style = document.createElement('style');
    const progressBarStyles = `
      .fsc-progress-bar {
        animation: progress ${getTimer}s linear forwards;
      }
  `;

    style.innerHTML = progressBarStyles;
    (document.querySelector('head') || document.documentElement).appendChild(style);

    if (isTTSEnabled) {
      speakTTS(
        `${data.displayTitle} ${data.subTitle} ${data.stationText} START WATCHING ${data.promoText}`,
        () => setIsTTSPlayed(true),
      );

      // if callback provided to `speakTTS` function won't work,
      // we call it anyway after certain timeout
      const timerId = setTimeout(() => setIsTTSPlayed(true), getTimer * 1000 + 8000);

      return () => {
        clearTimeout(timerId);
        stopTTS();
      };
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      return;
    }

    const timerId = setTimeout(() => setTimeLeft((prevValue) => prevValue - 1), 1000);

    return () => clearTimeout(timerId);
  }, [timeLeft]);

  useEffect(() => {
    dispatch(setCity(autoSelectedCity));
    dispatch(setStation(autoSelectedStation));
    setDimensions({ stationId: autoSelectedStation });
    // eslint-disable-next-line
  }, [autoSelectedStation, autoSelectedCity]);

  useEffect(() => {
    if (isTTSPlayed || (!isTTSEnabled && timeLeft === 0)) {
      onFullScreenCardFinish({ station: autoSelectedStation, city: autoSelectedCity });
    }
    // eslint-disable-next-line
  }, [onFullScreenCardFinish, autoSelectedStation, autoSelectedCity, isTTSPlayed, timeLeft]);

  const calculateTime = () => `00:${timeLeft < 10 ? `0${timeLeft}` : timeLeft}`;

  const openHomePage = (event) => {
    if (getKeyInfo(event.keyCode) === 'Enter') {
      onFullScreenCardFinish({ station: autoSelectedStation, city: autoSelectedCity });
    }
  };

  useEventListener('keydown', openHomePage);

  return (
    <div
      className='fsc-container'
      style={{
        background: `url('${data.background}')`,
      }}
    >
      <p className='display-title'>{data.displayTitle}</p>
      <p className='sub-title'>{data.subTitle}</p>
      <div className='fsc-image-container'>
        <img
          src={`${data.logo[0]}`}
          alt='alt'
          className='fsc-image'
        />
      </div>

      <div className='station-text'>
        <p>{data.stationText}</p>
      </div>
      <div className='progress-wrapper'>
        <button className='fsc-button'>START WATCHING</button>
        <div className='fsc-progress-bar' />
      </div>
      <p className='timer'>{calculateTime()}</p>
      <p className='promo-text'>{data.promoText}</p>
    </div>
  );
};

export default FULL_SCREEN_CARD_COMPONENT;
