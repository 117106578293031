import React, { Children, useState, MutableRefObject } from 'react';

import './List.scss';

const defaultOffsetHeight = 950;

type ListProps = {
  children: JSX.Element[];
  transition?: number;
  offset?: number;
  incrementReducer?: number;
  lastTranslatedRowIndex?: number;
  translateValueRef?: MutableRefObject<number>;
  initialTranslateValue?: number;
}

const List = (props: ListProps) => {
  const {
    children,
    transition,
    offset = defaultOffsetHeight,
    incrementReducer = 1,
    lastTranslatedRowIndex = 0,
    initialTranslateValue = 0,
    translateValueRef,
  } = props;

  const filteredChildren = Children.toArray(children).filter(Boolean);

  const [translateValue, setTranslateValue] = useState(translateValueRef?.current || initialTranslateValue);

  const onSelectedRow = (rowIdx: number) => {
    if (!lastTranslatedRowIndex || (filteredChildren.length - rowIdx - lastTranslatedRowIndex > 0)) {
      const increment = rowIdx > 0 ? rowIdx - incrementReducer : 0;
      const newTranslateValue = -(increment * offset / 2);

      setTranslateValue(newTranslateValue);

      if (translateValueRef) {
        translateValueRef.current = newTranslateValue;
      }
    }
  };

  return (
    <div className='list-container'>
      <div
        className='list'
        style={{
          WebkitTransform: `translate3d(0,${translateValue}px, 0)`,
          transition: `transform ${translateValue === -1 ? 0 : transition}s`,
        }}
      >
        {filteredChildren.map((el, idx) => (
          <div
            key={`row-${idx}`}
            className='row'
            onFocus={() => onSelectedRow(idx)}
          >
            {el}
          </div>
        ))}
      </div>
    </div>
  );
};

export default List;
