import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setConfigs } from '../../stores/actions/config';
import { RootState } from '../../stores/reducers';
import { setStation } from '../../stores/actions/common';
import Spinner from '../../components/Spinner/Spinner';
import { openPage, IJson } from '../../utils/OpenPage';
import { navigate, pushPageToHistory, navigator } from '../../services/NavigationService';
import { initializeAnalytic } from '../../services/analytics';
import { speakTTS } from '../../services/TTSService';
import appConfig from '../../assets/config.json';
import {
  OTT_BRAND,
  STIRR_OTT_BRAND,
  OTTPageType,
  OTTFeedType,
  OTTEpisodeType,
  COMET_OTT_BRAND,
  CHARGE_OTT_BRAND,
  TENNIS_CHANNEL_OTT_BRAND,
  MARQUEE_OTT_BRAND,
  isStirrBrand,
} from '../../constants/structureTypes';
import { config } from '../../config';
import { SPLASH_SCREEN_TIMEOUT } from '../../constants/common';
import { getDataForEden } from '../../utils/commonFunc';
import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';
import { BANNER_TEXT } from '../../constants/text';
import { CLEAR_OTTPAGE } from '../../stores/actions/types/OTTPage';
import { CLEAR_OTTCHANNELGUIDE } from '../../stores/actions/types/OTTChannelGuide';
import { CLEAR_OTTFEED } from '../../stores/actions/types/OTTFeed';
import { CLEAR_OTTEPISODE } from '../../stores/actions/types/OTTEpisode';
import currentPlatform from '../../platform/currentPlatform';
import { IPreviewService } from '../../platform/interfaces/preview';
import { getPurchaseData } from '../../stores/actions/cleengService';

import cometSplash from '../../assets/Comet/images/comet-splash.webp';
import chargeSplash from '../../assets/Charge/images/Charge-Splash.webp';
import stirrSplash from '../../assets/Stirr/images/stirr-splashscreen.webp';
import TCSplash from '../../assets/Tennis_channel/images/TC-splashscreen.webp';
import MQSplash from '../../assets/Marquee_sports/images/marquee_splashscreen.webp';

import './SplashScreen.scss';

const SplashScreen = () => {
  const navigation = useSelector((state: RootState) => state.config.navigation);
  const isConfigLoadedWithError = useSelector((state: RootState) => state.config.configHasError);
  const isNavLoadedWithError = useSelector((state: RootState) => state.config.navHasError);
  const configError = useSelector((state: RootState) => state.config.configError);
  const navError = useSelector((state: RootState) => state.config.navError);
  const isShownAutoCitySelectBanner = useSelector((state: RootState) => {
    return state.config.allConfigs.API?.showAutoCitySelectBanner;
  });
  const station = useSelector((state: RootState) => state.common.station);
  const city = useSelector((state: RootState) => state.common.city);
  const edenContent = useSelector((state: RootState) => state.config.edenContent);
  const GA_id = useSelector((state: RootState) => state.config.GA_id);
  const purchaseOptions = useSelector((state: RootState) => state.cleengService.purchaseOptions);
  const isUserTCLogin = useSelector((state: RootState) => state.Login.isUserTCLogin);
  const isPurchaseDataFetched = useSelector((state: RootState) => state.cleengService.isFetched);
  const isPurchaseDataLoadedWithError = useSelector((state: RootState) => state.cleengService.hasError);

  const [isBannerDisplayed, setIsBannerDisplayed] = useState<boolean>(false);
  const [splashFinished, setSplashFinished] = useState<boolean>(false);
  const [isSplashLoaded, setIsSplashLoaded] = useState<boolean>(false);
  const [shouldLoadCleengData, setShouldLoadCleengData] = useState<boolean>(appConfig[OTT_BRAND].inAppPurchase);
  const [shouldOpenDeepLink, setShouldOpenDeepLink] = useState<boolean>(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const deepLinkItem = useRef<any>(null);
  const splashScreenRef = useRef<any>(null);
  const navigateTimer = useRef<any>(null);
  const platformPreviewService = useRef<IPreviewService>(currentPlatform.getPreviewService());

  const OTT_BRANDDeepLinkClearCb = {
    [OTTPageType]: () => dispatch({
      type: CLEAR_OTTPAGE,
    }),
    [OTTFeedType]: () => {
      dispatch({
        type: CLEAR_OTTCHANNELGUIDE,
      });
      dispatch({
        type: CLEAR_OTTFEED,
      });
    },
    [OTTEpisodeType]: () => dispatch({
      type: CLEAR_OTTEPISODE,
    }),
  };

  function deepLink() {
    const appControl = platformPreviewService.current?.validateDeepLink();

    if (!appControl) {
      localStorage.setItem('preview', JSON.stringify(null));
    } else {
      try {
        const previewInfo = localStorage.getItem('preview');
        const previewItem = JSON.parse(previewInfo as string);
        const appLaunched = sessionStorage.getItem('appLaunched');
        const isAppLaunched = appLaunched ? JSON.parse(appLaunched) : false;

        // we need to delete processed deeplink
        localStorage.setItem('preview', JSON.stringify(null));

        if (typeof previewItem?.edenPreview === 'number') {
          getDataForEden(`${edenContent}?station=national`)
            .then(res => {
              const previews = res.rss.channel.pagecomponent.component;

              deepLinkItem.current = previews[previewItem.edenPreview - 1]['item'];
              setShouldOpenDeepLink(true);

              const navIdx = navigation.findIndex(nav => nav.type === deepLinkItem.current?.category) || 0;

              if (appConfig[OTT_BRAND].isStationSupport) {
                if (!station) {
                  if (previews[previewItem.edenPreview - 1]['item'].category !== OTTPageType) {
                    dispatch(setStation('national'));
                    if (isAppLaunched) {
                      navigate(
                        history,
                        openPage(deepLinkItem.current as unknown as IJson,
                          { station: 'national', navIdx }),
                      );
                    }
                  } else {
                    deepLinkItem.current = null;
                  }
                } else {
                  if (isAppLaunched) {
                    navigate(
                      history,
                      openPage(deepLinkItem.current as unknown as IJson,
                        { station, city, navIdx }),
                    );
                  }
                }
              } else {
                if (isAppLaunched) {
                  navigate(
                    history,
                    openPage(deepLinkItem.current as unknown as IJson,
                      { station, city, navIdx }),
                    OTT_BRANDDeepLinkClearCb[deepLinkItem.current?.category],
                  );
                }
              }
            });
        } else {
          setShouldOpenDeepLink(false);
        }
      } catch (e: any) {
        console.log(e.message);
      }
    }
  }

  useEffect(() => {
    if (isSplashLoaded) {
      const splashTimeout = setTimeout(() => {
        setSplashFinished(true);

      }, SPLASH_SCREEN_TIMEOUT);

      return function cleanup() {
        clearTimeout(splashTimeout);
        clearTimeout(navigateTimer.current);
      };
    }
  }, [isSplashLoaded]);

  useEffect(() => {
    if (GA_id) {
      initializeAnalytic(GA_id);

      isUserTCLogin && dispatch(getPurchaseData());
    } else {
      dispatch(setConfigs());
    }
    // eslint-disable-next-line
  }, [GA_id]);

  useEffect(() => {
    if (edenContent) {
      platformPreviewService.current?.setPreviews(edenContent, deepLink);
    }
    // eslint-disable-next-line
  }, [edenContent]);

  useEffect(() => {
    if (config.appPlatform === 'tizen' || isPurchaseDataLoadedWithError || !isUserTCLogin) {
      setShouldLoadCleengData(false);
    } else if (config.appPlatform === 'firetv' && isPurchaseDataFetched) {
      currentPlatform.getPurchaseService()?.validatePurchases(purchaseOptions)
        .catch((e) => console.log('Error while validating purchases - ', e))
        .finally(() => setShouldLoadCleengData(false));
    }
  }, [isPurchaseDataLoadedWithError, isPurchaseDataFetched]);

  useEffect(() => {
    if (navigation && navigation.length && splashFinished && !shouldLoadCleengData) {
      const isLaunchedFromEden = sessionStorage.getItem('isLaunchedFromEdenPreview');
      const shouldOpen = isLaunchedFromEden ? JSON.parse(isLaunchedFromEden) : false;
      const lastSessionData = localStorage.getItem('sessionData');
      const { station } = lastSessionData ? JSON.parse(lastSessionData as string) : '';

      if (!station && isShownAutoCitySelectBanner) {
        setIsBannerDisplayed(true);
        speakTTS(BANNER_TEXT);
      }

      if (!shouldOpen) {
        if (isStirrBrand) {
          const navigateTime = station ? 0 : 4000;

          navigateTimer.current = setTimeout(() => {
            navigate(history, openPage(
              navigation[0] as unknown as IJson,
              { station, city, navIdx: 0, isFirstLevelPage: true }),
            );
          }, navigateTime);

        } else {
          navigate(
            history,
            openPage(navigation[0] as unknown as IJson,
              { station: '', city: '', navIdx: 0, isFirstLevelPage: true }
            )
          );
        }
      } else {
        if (shouldOpenDeepLink) {
          !navigator.stack.length &&
          pushPageToHistory(openPage(navigation[0] as unknown as IJson,
            { station, city, navIdx: 0 }),
          );
          navigate(
            history,
            openPage(deepLinkItem.current as unknown as IJson,
              { station, city, navIdx: 0 }),
          );
        }
      }
      sessionStorage.setItem('appLaunched', JSON.stringify(true));
    }

    // eslint-disable-next-line
  }, [splashFinished, navigation, shouldOpenDeepLink, shouldLoadCleengData]);

  const splashImages = {
    [STIRR_OTT_BRAND]: stirrSplash,
    [COMET_OTT_BRAND]: cometSplash,
    [CHARGE_OTT_BRAND]: chargeSplash,
    [TENNIS_CHANNEL_OTT_BRAND]: TCSplash,
    [MARQUEE_OTT_BRAND]: MQSplash,
  };

  if (!splashFinished || isBannerDisplayed) {
    return (
      <div tabIndex={-1}>
        <div
          className={`splash-screen ${isSplashLoaded && 'fade-in-and-zoom-in'}`}
          ref={splashScreenRef}
        >
          <img
            src={splashImages[OTT_BRAND]}
            onLoad={() => setIsSplashLoaded(true)}
            alt='Splash screen'
          />
        </div>
        <div className='image-banner' style={{ opacity: isBannerDisplayed ? 1 : 0 }} />
      </div>
    );
  }

  if (isConfigLoadedWithError || isNavLoadedWithError) {
    const error = isConfigLoadedWithError
      ? configError
      : navError;

    if (isConfigLoadedWithError) {
      const GA_id = localStorage.getItem(`${OTT_BRAND}_GA_id`) || '';

      GA_id && initializeAnalytic(GA_id);
    }

    return (
      <div tabIndex={-1}>
        <ErrorModal error={{ errorCode: error}} />
      </div>
    );
  }

  return (
    <div tabIndex={-1}>
      <Spinner />
    </div>
  );
};

export default SplashScreen;
