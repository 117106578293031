import * as actionTypes from '../actions/types/OTTFeed';

import { Action, DRMAccessesLive, OTTFeedComponent } from '../actions/OTTFeed';
import { DRMFail, IErrorAPI } from '../actions/OTTEpisode';

interface OTTFeedState {
  component: OTTFeedComponent;
  adUrl: string;
  custParams: string;
  fetched: boolean;
  hasError: boolean;
  error: IErrorAPI;
  configDrmData: DRMAccessesLive;
  drmFail: DRMFail;
  standParams: any;
  isLoading: boolean;
}

const initialState: OTTFeedState = {
  component: {
    item: {
      link: '',
      guid: {},
      'media:content': {},
    },
  },
  configDrmData: {
    url: '',
    dashUrl: '',
    AuthXML: '',
    FPserver: '',
    FPcert: '',
    PRserver: '',
    WVserver: '',
    drmfail: '',
    drmfailMP4: '',
    assetKey: '',
    dashAssetKey: '',
  },
  drmFail: {
    drmFailUrl: '',
    drmFailTitle: '',
  },
  adUrl: '',
  custParams: '',
  standParams: {},
  fetched: false,
  hasError: false,
  error: {
    errorCode: '',
    requestData: {},
  },
  isLoading: false,
};

const OTTFeed = (state = initialState, action: Action): OTTFeedState => {
  switch (action.type) {
    case actionTypes.LOAD_OTTFEED_COMPONENT:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.LOAD_OTTFEED_COMPONENT_SUCCEEDED:
      return {
        ...state,
        component: action.payload.component,
        configDrmData: action.payload.configDrmData,
        drmFail: action.payload.drmFail,
        adUrl: action.payload.adUrl,
        custParams: action.payload.custParams,
        standParams: action.payload.standParams,
        fetched: true,
        hasError: false,
        isLoading: false,
      };

    case actionTypes.LOAD_OTTFEED_COMPONENT_FAILED:
      return {
        ...state,
        fetched: false,
        hasError: true,
        error: action.error,
        isLoading: false,
      };

    case actionTypes.CLEAR_OTTFEED:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default OTTFeed;
