export const MVPD_AUTH = 'LOGIN::MVPD_AUTH';
export const MVPD_AUTH_SUCCEEDED = 'LOGIN::MVPD_AUTH_SUCCEEDED';
export const MVPD_AUTH_FAILED = 'LOGIN::MVPD_AUTH_FAILED';
export const MVPD_REGCODE_REFRESH = 'LOGIN::MVPD_REGCODE_REFRESH';
export const MVPD_REGCODE_REFRESH_SUCCEEDED = 'LOGIN::MVPD_REGCODE_REFRESH_SUCCEEDED';
export const MVPD_REGCODE_REFRESH_FAILED = 'LOGIN::MVPD_REGCODE_REFRESH_FAILED';
export const MVPD_TOKEN_REFRESH = 'LOGIN::MVPD_TOKEN_REFRESH';
export const MVPD_TOKEN_REFRESH_SUCCEEDED = 'LOGIN::MVPD_TOKEN_REFRESH_SUCCEEDED';
export const MVPD_TOKEN_REFRESH_FAILED = 'LOGIN::MVPD_TOKEN_REFRESH_FAILED';
export const MVPD_LOGOUT = 'LOGIN::MVPD_LOGOUT';
export const MVPD_LOGOUT_SUCCEEDED = 'LOGIN::MVPD_LOGOUT_SUCCEEDED';
export const MVPD_LOGOUT_FAILED = 'LOGIN::MVPD_LOGOUT_FAILED';
export const MVPD_AUTHZ_ERROR = 'LOGIN::MVPD_AUTHZ_ERROR';
export const MVPD_CLEAR_AUTHZ_ERROR = 'LOGIN::MVPD_CLEAR_AUTHZ_ERROR';
export const MVPD_SET_PROVIDER_LOGO = 'LOGIN::MVPD_SET_PROVIDER_LOGO';

export const CREATE_USER = 'LOGIN::CREATE_USER';
export const CREATE_USER_SUCCEEDED = 'LOGIN::CREATE_USER_SUCCEEDED';
export const CREATE_USER_FAILED = 'LOGIN::CREATE_USER_FAILED';

export const CLEAR_ERROR = 'LOGIN::CLEAR_ERROR';

export const EMAIL_AUTH = 'LOGIN::EMAIL_AUTH';
export const EMAIL_AUTH_SUCCEEDED = 'LOGIN::EMAIL_AUTH_SUCCEEDED';
export const EMAIL_AUTH_FAILED = 'LOGIN::EMAIL_AUTH_FAILED';
export const EMAIL_SIGN_OUT = 'LOGIN::EMAIL_SIGN_OUT';
export const EMAIL_SIGN_OUT_SUCCEEDED = 'LOGIN::EMAIL_SIGN_OUT_SUCCEEDED';
export const EMAIL_SIGN_OUT_FAILED = 'LOGIN::EMAIL_SIGN_OUT_FAILED';
export const PLUS_TOKEN_REFRESH = 'LOGIN::PLUS_TOKEN_REFRESH';
export const PLUS_TOKEN_REFRESH_SUCCEEDED = 'LOGIN::PLUS_TOKEN_REFRESH_SUCCEEDED';
export const PLUS_TOKEN_REFRESH_FAILED = 'LOGIN::PLUS_TOKEN_REFRESH_FAILED';

export const RESET_PASSWORD = 'LOGIN::RESET_PASSWORD';
export const RESET_PASSWORD_SUCCEEDED = 'LOGIN::RESET_PASSWORD_SUCCEEDED';
export const RESET_PASSWORD_FAILED = 'LOGIN::RESET_PASSWORD_FAILED';
export const CLEAR_RESET_PASSWORD = 'LOGIN::CLEAR_RESET_PASSWORD';

export const CLEAR_USER_DATA = 'LOGIN::CLEAR_USER_DATA';

export const AGREEMENT_TEXT_LOAD = 'LOGIN::AGREEMENT_TEXT';
export const AGREEMENT_TEXT_LOAD_SUCCEEDED = 'LOGIN::AGREEMENT_TEXT_LOAD_SUCCEEDED';
export const AGREEMENT_TEXT_LOAD_FAILED = 'LOGIN::AGREEMENT_TEXT_LOAD_FAILED';
