import React, { useEffect } from 'react';

import { GAEvent } from '../../services/analytics';
import { getTime } from '../../utils/time';
import { COMPLETE, PLAY } from '../../constants/analyticsTypes';

import './Advertisement.scss';

type AdvertisementProps = {
  adsContainerRef: React.RefObject<HTMLDivElement>;
  adsVideoRef: React.RefObject<HTMLVideoElement>;
  adPlaying: boolean;
  adCurrentTime: number;
  adDuration: number;
  adBuffering: boolean;
  adsLoading: any;
  currentAdPosition: number;
  adsAmount: number;
  isIMAAdPlaying: boolean;
  GA_media: string;
  GA_category: string;
}

const Advertisement = (props: AdvertisementProps) => {
  const {
    adsContainerRef,
    adsVideoRef,
    adPlaying,
    adCurrentTime,
    adDuration,
    adsLoading,
    adBuffering,
    currentAdPosition,
    adsAmount,
    isIMAAdPlaying,
    GA_media = '',
    GA_category,
  } = props;

  useEffect(() => {
    if (isIMAAdPlaying && adPlaying && adDuration > 0) {
      if (adsLoading.current) {
        adsLoading.current = false;
        GAEvent(GA_category, PLAY, GA_media, { nonInteraction: true });
      } else if (!(adDuration - adCurrentTime)) {
        GAEvent(GA_category, COMPLETE, GA_media, { nonInteraction: true });
      }
    }
  }, [adPlaying, adDuration, adsLoading, adCurrentTime, GA_media, GA_category, isIMAAdPlaying]);

  return (
    <>
      <div id='ads-container' ref={adsContainerRef}></div>
      <video id='ads-player' className='ads-player' ref={adsVideoRef} />
      {adPlaying && (
        <>
          {(adDuration <= 0 || adBuffering) && (
            <div className='ad-background-blur' style={{ zIndex: adDuration > 0 ? 100 : 998 }}></div>
          )}
          {(adDuration - adCurrentTime) > 0 && (
            <div className='ad-progress'>
              {`Ad ${currentAdPosition} of ${adsAmount} : (${getTime(adDuration - adCurrentTime, 'seconds', 'm:ss')})`}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(Advertisement);
