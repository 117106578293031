import React, { forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { COMET_OTT_BRAND, OTT_BRAND, TENNIS_CHANNEL_OTT_BRAND } from '../../../constants/structureTypes';
import { RootState } from '../../../stores/reducers';
import { TButton } from '../Modal';
import CometNetworkErrorBg from '../../../assets/Comet/images/Comet-Blurred-BG.png';
import TCModalBg from '../../../assets/Tennis_channel/images/tc-modal-bg.webp';

import Input from '../../Input/Input';
import Button from '../../Button/Button';

import './DefaultModal.scss';

type TModalProps = {
  title?: string;
  description?: string;
  buttons?: Array<TButton>;
  idContainer?: string;
  textTTS: string;
  setDebugPassword?: (value: string) => void;
  debugPassword?: string;
  isDebugModal?: boolean;
  isWrongDebugPassword?: boolean;
};

const DefaultModal = (props: TModalProps, initialFocusItemRef) => {
  const {
    title,
    description,
    buttons = [],
    idContainer,
    textTTS,
    setDebugPassword,
    debugPassword,
    isDebugModal,
    isWrongDebugPassword,
  } = props;

  const [isInitialTTSPlayed, setIsInitialTTSPlayed] = useState<boolean>(false);

  const btnStyles = useSelector((state: RootState) => state.config.styles['button']);
  const generalStyles = useSelector((state: RootState) => state.config.styles['general']);
  const isConnectedToNetwork = useSelector((state: RootState) => state.common.isConnectedToNetwork);

  const networkErrorBackgrounds = {
    [COMET_OTT_BRAND]: CometNetworkErrorBg,
    [TENNIS_CHANNEL_OTT_BRAND]: TCModalBg,
  };

  const btnGetFocused = (e) => {
    e.target.style.background = btnStyles.colorOn;
    e.target.style.borderColor = btnStyles.borderColorOn;
    e.target.style.color = btnStyles.textFocusColor;
  };

  const btnGetBlurred = (e) => {
    e.target.style.background = btnStyles.colorOff;
    e.target.style.borderColor = btnStyles.borderColorOff;
    e.target.style.color = btnStyles.textColor;

    setIsInitialTTSPlayed(true);
  };

  const onFocusInputWrapperCb = (e) => {
    e.target.style.border = `2px solid ${generalStyles?.input?.borderColorActive}`;
  };

  const onBlurInputWrapperCb = (e) => {
    e.target.style.border = `2px solid ${generalStyles?.input?.borderColor}`;
  };

  return (
    <div
      id={idContainer}
      className={`modal-content modal-content-${OTT_BRAND}`}
      ref={!buttons.length ? initialFocusItemRef : null}
      // eslint-disable-next-line
      role=''
      tabIndex={-1}
      aria-label={textTTS}
      {...(generalStyles && generalStyles['background']) && {
        style: {
          backgroundImage: !isConnectedToNetwork ? `url(${networkErrorBackgrounds[OTT_BRAND]})`
            : `url(${OTT_BRAND === TENNIS_CHANNEL_OTT_BRAND ? TCModalBg : generalStyles['background']})`,
        },
      }}
    >
      {title && (
        <h1 aria-hidden={true} className='modal-title modal-title'>
          {title}
        </h1>
      )}
      <p aria-hidden={true} className='modal-description modal-description'>
        {description}
      </p>
      {isDebugModal && isWrongDebugPassword && (
        <p className='modal-description-wrong-password'>
          Wrong password. Try again!
        </p>
      )}
      {isDebugModal && (
        <Input
          type="password"
          value={debugPassword!}
          ref={initialFocusItemRef}
          onChangeCb={setDebugPassword!}
          placeholder='Enter password'
          ariaLabel=''
          index={0}
          styleWrapper={{
            border: `2px solid ${generalStyles?.input?.borderColor}`,
          }}
          onFocusInputWrapperCb={onFocusInputWrapperCb}
          onBlurInputWrapperCb={onBlurInputWrapperCb}
          dataTvFocusUp={'.input-wrapper-acc-0'}
          dataTvFocusRight={'.input-wrapper-acc-0'}
          dataTvFocusDown={'.btn-acc-0'}
          dataTvFocusLeft={'.input-wrapper-acc-0'}
        />
      )}
      {buttons.length ? (
        <div className='modal-buttons'>
          {buttons.map((button: TButton, index: number) => {
            const isFirstButton = index === 0;
            const isLastButton = index === buttons.length - 1;
            const ref = isFirstButton && !isDebugModal ? initialFocusItemRef : null;
            const ariaLabel = isFirstButton && !isInitialTTSPlayed ? textTTS : button.text;
            const isVerticalButtonsAlignment = OTT_BRAND === TENNIS_CHANNEL_OTT_BRAND;

            return (
              <Button
                title={button.text}
                TTSText={ariaLabel}
                key={button.id}
                ref={ref}
                index={index}
                onFocusFunc={btnGetFocused}
                onBlurFunc={btnGetBlurred}
                onKeyDown={() => button.onPress()}
                focusLeft={(isFirstButton || isVerticalButtonsAlignment) ?
                  `.btn-acc-${index}` : `.btn-acc-${index - 1}`}
                focusRight={(isLastButton || isVerticalButtonsAlignment) ?
                  `.btn-acc-${index}` : `.btn-acc-${index + 1}`}
                focusDown={(isLastButton || !isVerticalButtonsAlignment) ?
                  `.btn-acc-${index}` : `.btn-acc-${index + 1}`}
                focusUp={(isFirstButton || !isVerticalButtonsAlignment) ? isDebugModal ?
                  '.input-wrapper' : `.btn-acc-${index}` : `.btn-acc-${index - 1}`}
              >
                <>
                  {button.displayChildren || null}
                </>
              </Button>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default forwardRef<HTMLDivElement, TModalProps>(DefaultModal);
