import axiosInstance from './axiosInstance';
import currentPlatform from '../platform/currentPlatform';
import { getTimestampSeconds } from '../utils/time';
import store from './../stores/index';

type AxiosHeaders = {
  'Content-Type': string,
  'X-Api-Key': string,
}

export type LinearEventType = 'play' | 'heartbeat' | 'stop';
export type LinearContentType = 'linear' | 'VOD';

export type LinearFields = {
  app_version: string;
  browser: string;
  content_type: LinearContentType;
  drm_required: boolean;
  duration: number;
  event_type: LinearEventType;
  page_url: string;
  platform: string;
  player: string;
  sdk_version: string;
  session_id: string;
  timestamp: number;
  user_id: string;
  uuid: string;
}

export type LinearTelemetryPayload = {
  content_type: LinearContentType;
  drm_required: boolean;
  duration: number;
  event_type: LinearEventType;
  page_url?: string;
  player: string;
  session_id: string;
  uuidFeedOrChannel: string;
}

export type LinearTelemetryURLType = string | undefined;

export const getLinearTelemetryURL = (): LinearTelemetryURLType => {
  const state: any = store.getState();
  const { allConfigs } = state.config;

  return allConfigs['API']['livestream_events_track'];
};

export async function postLinearTelemetry({ content_type, drm_required, duration, event_type,
  player, page_url = '', session_id, uuidFeedOrChannel,
} : LinearTelemetryPayload) {

  const STREAMING_SERVICE_KEY = 'AIzaSyA61KBeBCSGWQPIsnIpt7FCA6suFI-r9zg';
  const linerUrl:LinearTelemetryURLType = getLinearTelemetryURL();

  if (!linerUrl) {
    return;
  }

  const dataLinear: LinearFields = {
    app_version: currentPlatform.appVersion,
    browser: '',
    content_type: content_type,
    drm_required: drm_required,
    duration: duration,
    event_type: event_type,
    page_url: page_url,
    platform: currentPlatform.appPlatform,
    player: player,
    sdk_version: window['google']?.ima?.VERSION || '',
    session_id: session_id,
    timestamp: getTimestampSeconds(),
    user_id: currentPlatform.deviceUuid,
    uuid: uuidFeedOrChannel,
  };

  const axiosHeaders: AxiosHeaders = {
    'Content-Type': 'application/json',
    'X-Api-Key': STREAMING_SERVICE_KEY,
  };

  const linearTelemData = await axiosInstance.post(linerUrl, dataLinear, {
    headers: axiosHeaders,
  });

  return linearTelemData.data;
}
