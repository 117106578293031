import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { MVPD, BACK_BTN_TEXT } from '../../../constants/text';
import { refreshRegCode, checkMVPDAuth } from '../../../stores/actions/Login';
import focusService from '../../../services/focusService';
import { GAEvent } from '../../../services/analytics';
import { AUTH_FAIL, AUTHENTICATES } from '../../../constants/analyticsTypes';
import { getCustomStyledSymbol } from '../../../utils/ui';
import { speakTTS } from '../../../services/TTSService';
import { MVPD_AUTH_CHECK_INTERVAL, MVPD_MAX_AGE_AUTH_CHECK } from '../../../constants/common';
import { isTcBrand } from '../../../constants/structureTypes';
import { useSelector } from '../../../stores';
import backArrowIcon from '../../../assets/icons/GoBackArrow.svg';

import Button from '../../../components/Button/Button';

import './CodeScreen.scss';

interface ICodeScreen {
  successCallback: () => void;
  analyticsValue: string;
  goToAccountPage: () => void;
  handleBack: () => void;
}

const CodeScreen = (props: ICodeScreen) => {
  const {
    successCallback,
    analyticsValue,
    goToAccountPage,
    handleBack,
  } = props;

  const authError = useSelector((state) => state.Login.mvpd.authZError);
  const mvpdRegCode = useSelector((state) => state.Login.mvpd.regCode);
  const mvpdAuthToken = useSelector((state) => state.Login.mvpd.authTokenMVPD);
  const deviceId = useSelector((state) => state.config.deviceId);
  const mvpdId = useSelector((state) => state.Login.mvpd.mvpdId);
  const mvpdText = useSelector((state) => state.config.mvpd['mvpd_text']);
  const codeScreenStyles = useSelector((state) => state.config.styles.mvpd);
  const headingStyles = useSelector((state) => state.config.styles.general?.heading);

  const checkAuthTimeout = useRef<any>(null);
  const clearCheckAuthTimeout = useRef<any>(null);
  const ttsTimeout = useRef<any>(null);

  const dispatch = useDispatch();

  const clearTimers = () => {
    ttsTimeout.current && clearTimeout(ttsTimeout.current);
    checkAuthTimeout.current && clearInterval(checkAuthTimeout.current);
    clearCheckAuthTimeout.current && clearTimeout(clearCheckAuthTimeout.current);
  };

  useEffect(() => {
    focusService.setFocus(document.getElementById('mvpd-code-back-btn'));

    return () => {
      clearTimers();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mvpdRegCode && (mvpdAuthToken || authError)) {
      const ID = localStorage.getItem('AuthMVPD') ?
        JSON.parse(localStorage.getItem('AuthMVPD') as string)?.deviceId : deviceId;
      const user = {
        isAuth: true,
        token: mvpdAuthToken,
        deviceId: ID,
        mvpdProviderId: mvpdId,
      };

      localStorage.setItem('AuthMVPD', JSON.stringify(user));

      if (authError) {
        GAEvent(MVPD, AUTH_FAIL, analyticsValue);
      } else {
        GAEvent(MVPD, AUTHENTICATES, analyticsValue);

        if (successCallback) {
          successCallback();
        } else {
          goToAccountPage();
        }
      }
    }
    // eslint-disable-next-line
  }, [mvpdAuthToken, authError, mvpdRegCode]);

  useEffect(() => {
    if (mvpdRegCode) {
      clearTimers();

      const ttsText = `${mvpdText['activate_title']}. ${mvpdText['activate_detail']}.
      ${mvpdText['activate_step1_title']}, ${mvpdText['activate_step1_detail']},
      ${mvpdText['activate_step2_title']}, ${mvpdText['activate_step2_detail']}.
      Your code is ${mvpdRegCode.split('')}`;

      if (ttsText) {
        ttsTimeout.current = setTimeout(() => speakTTS(ttsText), 2000);
      }

      checkAuthTimeout.current = setInterval(() => dispatch(checkMVPDAuth()), MVPD_AUTH_CHECK_INTERVAL);
      clearCheckAuthTimeout.current = setTimeout(() => {
        dispatch(refreshRegCode());
        clearInterval(checkAuthTimeout.current);
        GAEvent(MVPD, AUTH_FAIL, analyticsValue);
      }, MVPD_MAX_AGE_AUTH_CHECK);
    }
    // eslint-disable-next-line
  }, [mvpdRegCode]);

  return (
    <div className='mvpd-main-code-container'>
      <h1
        style={{ fontFamily: headingStyles.titleFont }}
        className='mvpd-main-code-text'
      >
        {mvpdText['activate_title']}
        {isTcBrand && getCustomStyledSymbol('.', headingStyles.accentColor)}
      </h1>
      <p className='mvpd-secondary-code-text'>
        {mvpdText['activate_detail']}
      </p>
      <div className='mvpd-code-step' style={{background: codeScreenStyles?.stepBackgroundColor}}>
        {mvpdText['activate_step1_title']}
      </div>
      <p className='mvpd-step-code-text'>
        {mvpdText['activate_step1_detail']}
      </p>
      <div className='mvpd-code-step' style={{background: codeScreenStyles?.stepBackgroundColor}}>
        {mvpdText['activate_step2_title']}
      </div>
      <p className='mvpd-step-code-text'>
        {mvpdText['activate_step2_detail']}
      </p>
      <div className='mvpd-code-container'>
        {mvpdRegCode.split('').map((item, index) => (
          <span
            key={`${item}-${index}`}
            className='mvpd-code-item'
            style={{ border: `2px solid ${codeScreenStyles?.providersCellBorderColor}` }}
          >
            {item}
          </span>
        ))}
      </div>
      <Button
        title={BACK_BTN_TEXT}
        TTSText={`${BACK_BTN_TEXT} button`}
        btnID='mvpd-code-back-btn'
        onKeyDown={handleBack}
        focusUp='.btn-acc-0'
        focusDown='.btn-acc-0'
        focusLeft='.btn-acc-0'
        focusRight='.btn-acc-0'
        index={0}
      >
        <img src={backArrowIcon} alt='back arrow' style={{marginRight: '16px'}}/>
      </Button>
    </div>
  );
};

export default CodeScreen;
