import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from '../../stores/reducers';
import { setComponentOTTEpisodeData } from '../../stores/actions/OTTEpisode';
import * as OTTEpisodeActionTypes from '../../stores/actions/types/OTTEpisode';
import { mvpdLoginRequired, onBackHandler, tennisPlusLoginRequired } from '../../utils/index';
import { navigateBack } from '../../services/NavigationService';
import {
  clearHitDimensions,
  GAEvent,
  GAScreen,
  generateAnalyticsErrorDetails,
  setDimensions,
} from '../../services/analytics';
import useEventListener from '../../hooks/eventListener';
import * as analyticsTypes from '../../constants/analyticsTypes';
import { LINEAR_VOD } from '../../constants/text';
import { isTcBrand } from '../../constants/structureTypes';
import focusService from '../../services/focusService';
import useGetVideoData from '../../hooks/getVideoData';
import { errorDetails as errorsDescription} from '../../constants/errorDetails';
import { DEFAULT_VIDEO_AD_ERROR, VIDEO_API_ERROR } from '../../constants/errorCodes';

import ErrorBoundary from '../../components/Modals/ErrorBoundary/ErrorBoundary';
import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';
import VOD from '../../components/VOD/VOD';
import Spinner from '../../components/Spinner/Spinner';
import TCPlusFlow from '../TCPlusFlow/TCPlusFlow';
import MvpdFlow from '../MvpdFlow/MvpdFlow';

import './OTTEpisode.scss';

type OTTEpisodeProps = {
  location: {
    state: {
      configData: {
        path: string,
        GAPath: string,
      },
      lastData: {
        station: string,
        seasonTitle: string,
        seriesTitle: string,
        navIdx?: number,
        showTitle: string,
      },
    }
  }
}

const OTTEpisode = (props: OTTEpisodeProps) => {
  const { location: { state: { configData, lastData } } } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const component = useSelector((state: RootState) => state.OTTEpisode.component);
  const streams = useSelector((state: RootState) => state.OTTEpisode.streams);
  const adUrl = useSelector((state: RootState) => state.OTTEpisode.adUrl);
  const midrollBreaks = useSelector((state: RootState) => state.OTTEpisode.midrollBreaks);
  const recommendedPlaylist = useSelector((state: RootState) => state.OTTEpisode.recommendedPlaylist);
  const currentPlaylistItemIndex = useSelector((state: RootState) => state.OTTEpisode.currentPlaylistItemIndex);
  const station = useSelector((state: RootState) => state.common.station);
  const isLoading = useSelector(((state: RootState) => state.OTTEpisode.isLoading));
  const hasError = useSelector((state: RootState) => state.OTTEpisode.hasError);
  const apiError = useSelector((state: RootState) => state.OTTEpisode.error);
  const { vodPlayer } = useSelector((state: RootState) => state.config.players);

  const currentEpisode = useRef<string>('');
  const currentShow = useRef<string>('');
  const signInBtnPressed = useRef<boolean>(false);
  const shouldSendAnalyticsOnEnd = useRef<boolean>(true);

  const [showAuthPopUp, setShowAuthPopUp] = useState(false);

  useGetVideoData(configData, setComponentOTTEpisodeData);

  const {
    item: {
      link = '',
      'media:content': mediaContent = {},
    } = {},
  } = component || {};

  useEffect(() => {
    if (mediaContent['url'] && !mediaContent['url'].includes('.m3u8')) {
      dispatch(setComponentOTTEpisodeData('',
        {...configData, 'media:content': mediaContent },
      ));
    }
    // eslint-disable-next-line
  }, [mediaContent]);

  const clearOTTEpisodeData = () => {
    dispatch({
      type: OTTEpisodeActionTypes.CLEAR_OTTEPISODE,
    });
  };

  useEffect(() => {
    setDimensions({ contentType: LINEAR_VOD });

    return () => {
      clearHitDimensions();
    };
  }, []);

  useEffect(() => {
    if (Object.keys(mediaContent).length) {
      if (mediaContent['media:status']?.state === 'blocked') {
        if (tennisPlusLoginRequired(mediaContent['media:status']?.reason)) {
          GAEvent(
            analyticsTypes.SUBSCRIPTION_PREVIEW,
            analyticsTypes.PLAY,
            component.item['media:content']['media:title']['content'],
          );
        } else if (mvpdLoginRequired(mediaContent['media:status']?.reason)) {
          GAEvent(
            analyticsTypes.MVPD_PREVIEW,
            analyticsTypes.PLAY,
            component.item['media:content']['media:title']['content'],
          );
        }
      } else {
        currentEpisode.current = configData['media:content']['media:title']['content'];
        currentShow.current = lastData?.showTitle ? lastData.showTitle : currentShow.current;
        shouldSendAnalyticsOnEnd.current = true;
        setDimensions({
          contentUuid: configData['guid']['content'],
          itemTitle: configData['media:content']['media:title']['content'],
          showTitle: currentShow.current,
          channelId: configData['guid']['content'],
          channelUrl: configData['link'],
          vodTimeWatched: '0',
          vodPctComplete: '0',
        });
        GAEvent(
          analyticsTypes.ON_DEMAND,
          analyticsTypes.PLAY,
          configData['media:content']['media:title']['content'],
        );
        GAScreen(configData.GAPath);

        return () => {
          if (currentEpisode.current.trim()) {
            if (shouldSendAnalyticsOnEnd.current) {
              GAEvent(
                analyticsTypes.ON_DEMAND,
                analyticsTypes.COMPLETE,
                currentEpisode.current,
                { nonInteraction: true },
              );
            } else {
              GAEvent(
                analyticsTypes.ON_DEMAND,
                analyticsTypes.ABANDON,
                currentEpisode.current,
              );
            }
          }
        };
      }
    }
    // eslint-disable-next-line
  }, [configData, lastData, Object.keys(component?.item || []).length]);

  useEffect(() => {
    if (apiError.errorCode) {
      const errorDetails = generateAnalyticsErrorDetails(
        vodPlayer,
        { item: apiError.requestData },
        false
      );

      setDimensions({
        errorDetail: errorDetails,
        errorMessage: errorsDescription[apiError.errorCode] || analyticsTypes.API_ERROR,
        adType: '',
      });
      GAEvent(analyticsTypes.ERROR_PLAYER, analyticsTypes.API, configData['guid']['content'], { nonInteraction: true });
      clearHitDimensions(['errorDetail', 'adType', 'errorMessage']);
    }
  }, [apiError.errorCode]);

  const refreshPlayback = () => {
    setShowAuthPopUp(false);
    dispatch(setComponentOTTEpisodeData('', { ...configData }));
    signInBtnPressed.current = false;
  };

  const backFunc = (ev: React.KeyboardEvent) => {
    (!isLoading && (!link && !mediaContent?.['url']) && !showAuthPopUp) && onBackHandler(ev, handleBack, history);
  };

  const handleBack = () => {
    navigateBack(history, clearOTTEpisodeData);
  };

  useEventListener('keydown', backFunc);

  useEffect(() => {
    focusService.setFocusToSilentEl();
  }, []);

  return (
    <ErrorBoundary error={{errorCode: DEFAULT_VIDEO_AD_ERROR}} onBackHandlerCb={handleBack}>
      <div className='ott-episode'>
        {isLoading
          || !(link?.length || Object.keys(mediaContent).length) && !hasError ? (<Spinner />) :
          (mediaContent['media:status']?.state === 'blocked' && isTcBrand) || showAuthPopUp
            ? (
              <>
                {tennisPlusLoginRequired(mediaContent['media:status']?.reason) &&
                  <TCPlusFlow
                    isFlowFromPlayback={true}
                    successCallback={refreshPlayback}
                    uuid={configData['guid']['content']}
                    goBackFromPlayback={handleBack}
                  />
                }
                {mvpdLoginRequired(mediaContent['media:status']?.reason) &&
                  <MvpdFlow
                    isFlowFromPlayback={true}
                    successCallback={refreshPlayback}
                    uuid={configData['guid']['content']}
                    goBackFromPlayback={handleBack}
                    signInBtnPressed={signInBtnPressed}
                  />}
              </>
            ) : (
              (link?.includes('.m3u8') || mediaContent?.['url']) && !hasError ? (
                <VOD
                  configData={configData}
                  dataForLiveRamp={lastData}
                  source={link || mediaContent?.['url']}
                  streams={streams}
                  refreshStreamLimit={streams.length}
                  includeAds={!!adUrl}
                  adUrl={adUrl}
                  midrollBreaks={midrollBreaks}
                  mediaContent={mediaContent}
                  logInRequestor={mediaContent['media:status']?.reason}
                  accessDisabled={mediaContent['media:status']?.state === 'blocked'}
                  recommendedPlaylist={recommendedPlaylist}
                  currentPlaylistItemIndex={currentPlaylistItemIndex}
                  station={station}
                  clearPage={clearOTTEpisodeData}
                  showAuthPopUp={showAuthPopUp}
                  setShowAuthPopUp={setShowAuthPopUp}
                  signInBtnPressed={signInBtnPressed}
                  shouldSendAnalyticsOnEnd={shouldSendAnalyticsOnEnd}
                />
              ) : (
                <ErrorModal
                  error={{ errorCode: hasError ? VIDEO_API_ERROR : DEFAULT_VIDEO_AD_ERROR }}
                  onBackHandlerCb={handleBack}
                />
              )
            )
        }
      </div>
    </ErrorBoundary>
  );
};

export default OTTEpisode;
