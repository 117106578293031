import React, { Component, ErrorInfo } from 'react';

import { TButton } from '../Modal';
import { GAException } from '../../../services/analytics';
import ErrorModal from '../ErrorModal/ErrorModal';

import './ErrorBoundary.scss';

interface IState {
  hasError: boolean;
}

interface IProps {
  error?: {
    errorCode: string;
  };
  onBackHandlerCb?: () => void;
  buttons?: Array<TButton>;
}

class ErrorBoundary extends Component<IProps, IState> {
  btnRef: React.RefObject<any>;
  setIntervalRef: any;

  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.btnRef = React.createRef();
    this.setIntervalRef = React.createRef();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): IState {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const getTrimmedString = (string): string => {
      const trimmedString:any = string.trim().replace(/\s\s+/g, ';');

      return trimmedString.replaceAll(' (', '(');
    };

    const getErrorMessageForGA = (): string => {
      const componentStackError = errorInfo.componentStack || '';
      const DESCRIPTION_LENGTH: number = 96;

      return `${error.name}:${error.message}=>${getTrimmedString(componentStackError)}`.slice(0, DESCRIPTION_LENGTH);
    };

    GAException(getErrorMessageForGA(), true);
  }

  componentDidUpdate() {
    if (this.state.hasError) {
      this.setIntervalRef.current = setInterval(() => {
        const iframeEl = document.querySelector('iframe');

        if (iframeEl) {

          const zIndexHideIframe = '1200';

          if (iframeEl.style.zIndex === zIndexHideIframe) {
            clearInterval(this.setIntervalRef.current);
          } else {
            iframeEl.style.display = 'none';
            iframeEl.style.zIndex = zIndexHideIframe;
          }
        }
      }, 500);
    }
  }

  componentWillUnmount() {
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          className='error-container'
          id={'error-boundary'}
        >
          <ErrorModal error={this.props?.error} onBackHandlerCb={this.props?.onBackHandlerCb}/>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
