import ReactGA from 'react-ga';
import { config } from '../../config';
import {
  DATA_SOURCE,
  APP_NAME,
  APP,
  FREE,
  PLUS,
  dimensionsDictionary,
  APP_OPEN,
  OPEN,
  SPLASH,
  NOT_MVPD,
  APP_VERSION,
  ERROR_PLAYER,
  CLIENT_STITCHED,
  EMPTY_ADS,
  AD,
} from '../../constants/analyticsTypes';
import currentPlatform from '../../platform/currentPlatform';
import store from '../../stores/index';

declare const google: any;
interface IErrorDetails {
  playerName?: string;
  playerErrorCode?: string;
  playerErrorMessage?: string;
  IMAErrorCode?: string;
  IMAErrorMessage?: string;
  customErrorInfo?: string;
}

export interface IAnalyticsDetails {
  playbackItemIdentifier: string;
  playerName: string;
  currentAd?: any;
}

export const clearHitDimensions = (dimensionsToClear?: string[]) => {
  const dimensionsDictionaryKeys = Object.keys(dimensionsDictionary);
  const hitDimensionsToClear = dimensionsDictionaryKeys
    .map(key => dimensionsToClear?.includes(key) && dimensionsDictionary[key])
    .filter(Boolean);
  const hitDimensions = dimensionsDictionaryKeys
    .map(key => dimensionsDictionary[key])
    .filter(d => d.scope === 'hit');

  const fieldsObject = (hitDimensionsToClear.length ? hitDimensionsToClear : hitDimensions).reduce((obj, item) => {
    obj[item.id] = null;

    return obj;
  }, {});

  ReactGA.set(fieldsObject);
};

const getDefaultCustomDimensions = () => {
  const state: any = store.getState();

  const { station } = state.common;

  const storageAuthMVPD = localStorage.getItem('AuthMVPD');
  const storageAuthPlus = localStorage.getItem('AuthPlus');
  const authMVPD = storageAuthMVPD ? JSON.parse(storageAuthMVPD) : null;
  const authPlus = storageAuthPlus ? JSON.parse(storageAuthPlus) : null;
  const fieldsObj = {};
  const defaultDimensions = {
    memberType: authPlus ? PLUS : FREE,
    mvpd: authMVPD ? authMVPD.mvpdProviderId : NOT_MVPD,
    apiVersion: config.apiVersion,
    brand: config.brand,
    appPlatform: config.appPlatform,
    app_Version: config.appVersion,
    appOsVersion: config.osVersion,
    userAuthToken: authPlus ? authPlus.token : '',
    deviceUuid: currentPlatform.deviceUuid,
    mvpdMediaToken: authMVPD ? authMVPD.token : '',
    cleengCustomerToken: authPlus ? authPlus.cleengToken : '',
    deviceBrand: currentPlatform.deviceBrand,
    deviceModel: currentPlatform.deviceModel,
    screenWidth: window.innerWidth?.toString(),
    screenHeight: window.innerHeight?.toString(),
    stationId: station || null,
    [APP_NAME]: config.analyticsAppName,
    [APP_VERSION]: config.appVersion,
    [DATA_SOURCE]: APP,
  };

  for (const [key, value] of Object.entries(defaultDimensions)) {
    if (dimensionsDictionary[key]) {
      fieldsObj[dimensionsDictionary[key]['id']] = value;
    } else {
      fieldsObj[key] = value;
    }
  }

  ReactGA.set(fieldsObj);
};

export const initializeAnalytic = (GA_id: string) => {
  ReactGA.initialize(GA_id, {
    debug: false,
    titleCase: false,
  });
  ReactGA.ga('set', 'checkProtocolTask', null);
  getDefaultCustomDimensions();
  GAEvent(APP_OPEN, OPEN);
  GAScreen(SPLASH);
};

export const GAEvent = (category: string, action: string, label?: string, options?: any) => {
  try {
    ReactGA.event({
      category,
      action,
      label,
      ...options,
    });
  } catch (e: any) {
    console.log('Error in sending GA event: ', e.message);
  }
};

export const GAScreen = (screen: string) => {
  ReactGA.ga('send', 'screenview', {
    screenName: screen,
  });
};

export const GAException = (description: string, fatal: boolean): void => {
  ReactGA.exception({ description, fatal });
};

export const setDimensions = (dimensions: any) => {
  const fieldsObj = {};

  Object.keys(dimensions).forEach(key => {
    if (dimensionsDictionary[key]) {
      fieldsObj[dimensionsDictionary[key].id] = dimensions[key];
    }
  });

  ReactGA.set(fieldsObj);
};

export const generateAnalyticsErrorDetails = (playerName: string, errorObj: any, isIMA: boolean): string => {
  const errorDetails: IErrorDetails = {
    playerName,
    customErrorInfo: errorObj,
  };

  if (isIMA) {
    errorDetails.IMAErrorCode = errorObj.errorCode || '';
    errorDetails.IMAErrorMessage = errorObj.errorMessage || '';
  } else {
    errorDetails.playerErrorCode = errorObj.code || '';
  }

  return JSON.stringify(errorDetails);
};

export const sendAdErrorDetails = (
  analyticsDetails: IAnalyticsDetails,
  errorObj: any,
): void => {
  const {playerName, playbackItemIdentifier} = analyticsDetails;
  const errorCode = errorObj.getErrorCode();
  const error = {
    ...errorObj,
    errorCode,
  };

  let action: string = AD;

  // if we retrieve empty VAST response
  if (errorCode === google.ima.AdError.ErrorCode.VAST_EMPTY_RESPONSE) {
    action = EMPTY_ADS;
  }

  const adErrorDetails = generateAnalyticsErrorDetails(playerName, error, true);

  setDimensions({
    errorDetail: adErrorDetails,
    adType: CLIENT_STITCHED,
    errorMessage: errorObj.getMessage() || '',
  });
  GAEvent(ERROR_PLAYER, action, playbackItemIdentifier, { nonInteraction: true });
  clearHitDimensions(['errorDetail', 'adType', 'errorMessage']);
};
