import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setPageData } from '../../stores/actions/OTTText';
import { RootState } from '../../stores/reducers/index';
import { CLEAR_OTT_TEXT } from '../../stores/actions/types/OTTText';
import useEventListener from '../../hooks/eventListener';
import { backHandlerForFirstLevelPage, onBackHandler } from '../../utils/index';
import { VIEW } from '../../constants/analyticsTypes';
import {
  OTT_BRAND,
  COMET_OTT_BRAND,
  TENNIS_CHANNEL_OTT_BRAND,
  CHARGE_OTT_BRAND,
  MARQUEE_OTT_BRAND,
  isStirrTcMarqueeBrand,
} from '../../constants/structureTypes';
import { GAEvent, GAScreen } from '../../services/analytics';
import { speakTTS, useAnnouncePageLoading} from '../../services/TTSService';
import { getCapitaliseAnalyticsValue } from '../../utils/commonFunc';
import { navigateBack } from '../../services/NavigationService';

import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';
import NavigationMenu from '../../components/NavigationMenu/NavigationMenu';
import Spinner from '../../components/Spinner/Spinner';

import './OTTText.scss';

type OTTTextProps = {
  location: {
    state: {
      configData: {
        title?: string;
        path: string;
        type?: string;
        web_route?: string;
        GAPath: string;
      }
    }
  }
}

const OTTText = (props: OTTTextProps) => {
  const { location: { state: { configData } } } = props;

  const pageText = useSelector((state: RootState) => state.OTTText.pageText);
  const isLoading = useSelector((state: RootState) => state.OTTText.isLoading);
  const isError = useSelector((state: RootState) => state.OTTText.hasError);
  const pageError = useSelector((state: RootState) => state.OTTText.error);
  const textStyles = useSelector((state: RootState) => state.config.styles['settings']);
  const generalStyles = useSelector((state: RootState) => state.config.styles['general']);
  const navigation = useSelector((state: RootState) => state.config.navigation);
  const selectedNav = useSelector((state: RootState) => state.common.activeNavIdx);
  const titleFont = useSelector((state: RootState) => state.config.styles.general?.heading?.titleFont);

  const [isDescriptionTextFocused, setIsDescriptionTextFocused] = useState<boolean>(false);
  const [isTextScrollable, setIsTextScrollable] = useState<boolean>(false);
  const [isScrollBarVisible, setIsScrollBarVisible] = useState<boolean>(true);

  const speakTTSTimerRef = useRef<any>(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const backgroundStyle = {
    [COMET_OTT_BRAND]: `url(${generalStyles['background']})`,
    [TENNIS_CHANNEL_OTT_BRAND]: `url(${generalStyles['background']})`,
    [CHARGE_OTT_BRAND]: textStyles['backgroundColor'],
    [MARQUEE_OTT_BRAND]: `url(${generalStyles['background']})`,
  };

  useEffect(() => {
    dispatch(setPageData(configData.path));
    if (navigation[selectedNav].title === configData.GAPath.toUpperCase()) {
      GAScreen(getCapitaliseAnalyticsValue(navigation[selectedNav]));
    } else {
      GAEvent(navigation[selectedNav].title, VIEW, configData.GAPath.split(' ')[0]);
    }
    // eslint-disable-next-line
  }, [configData]);

  useEffect(() => {
    const scrollableText = document.querySelector('.settings-key-text');

    if (scrollableText && setIsScrollBarVisible) {
      setIsScrollBarVisible(scrollableText?.scrollHeight > scrollableText?.clientHeight);
    }
  }, [pageText]);

  useEffect(() => {
    if (!isDescriptionTextFocused && pageText) {
      speakTTSTimerRef.current = setTimeout(() => {
        speakTTS(`${configData.title}. ${pageText}`);
      }, 2500);
    }

    return () => {
      clearTimeout(speakTTSTimerRef.current);
    };
  }, [isDescriptionTextFocused, pageText]);

  const handleScroll = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLParagraphElement;

    if (target.scrollTop === 0 && isDescriptionTextFocused) {
      setIsDescriptionTextFocused(false);
    }
  };

  const onTextBlur = () => setIsDescriptionTextFocused(false);

  const onTextFocus = (e) => {
    setIsDescriptionTextFocused(true);
    setIsTextScrollable(e.target.offsetWidth > e.target.clientWidth);
  };

  const clearOTTTextData = () => {
    dispatch({ type: CLEAR_OTT_TEXT });
  };

  useEventListener('keydown', (e: React.KeyboardEvent) => {
    onBackHandler(e,
      () => backHandlerForFirstLevelPage(
        history,
        !isLoading && isStirrTcMarqueeBrand,
        clearOTTTextData
      )
    );
  });

  useAnnouncePageLoading(isLoading);

  return (
    <>
      {isLoading ? <Spinner /> : (
        <> {isError
          ? <ErrorModal
            error={{errorCode: pageError}}
            onBackHandlerCb={() => navigateBack(history, clearOTTTextData, true)}
          />
          : <>
            <NavigationMenu
              isMenuFocusedOnStart={true}
              focusDownSelector={isScrollBarVisible ? '.settings-key-text' : '#navigationSelectedItem'}
              onEnterCallback={clearOTTTextData}
            />
            <div
              className='ott-text'
              style={{
                background: backgroundStyle[OTT_BRAND],
              }}
            >
              <div className='text-container'>
                <p
                  className='heading'
                  style={{
                    color: `${textStyles.headlineColor}`,
                    fontFamily: `${titleFont || textStyles.focusFontFace.fontFamily}`,
                    fontSize: OTT_BRAND === CHARGE_OTT_BRAND ? '67px' : '64px',
                  }}
                >
                  {configData.title}
                </p>
                <p
                  className={`description settings-key-text description-${OTT_BRAND}`}
                  style={{
                    color: `${textStyles.textColor}`,
                    fontFamily: `${textStyles.focusFontFace.fontFamily}`,
                  }}
                  tabIndex={0}
                  // eslint-disable-next-line
                  role=''
                  aria-label={`${configData.title}. ${pageText}`}
                  data-scroll-enabled={true}
                  data-tv-focus-up={
                    (!isDescriptionTextFocused || !isTextScrollable)
                      ? '#navigationSelectedItem'
                      : '.settings-key-text'}
                  onScroll={handleScroll}
                  onFocus={(e) => onTextFocus(e)}
                  onBlur={onTextBlur}
                >
                  {pageText}
                </p>
              </div>
            </div>
          </>}
        </>
      )}
    </>
  );
};

export default OTTText;
