import * as actionTypes from '../actions/types/Login';

export interface ILoginError {
  errorCode?: string;
  details?: any;
}

export interface ILoginPayload {
  code: string,
  expires: string,
}

export interface ILoginUserResponse {
  accessToken: string,
  email: string,
  emailVerified: boolean,
  expirationTime: number,
  refreshToken: string,
  uid: string,
  cleengToken: string,
}

interface IAuthPayload {
  mvpdId: string,
  serializedToken: string,
  expires: string,
}

export interface IRefreshPlusTokenPayload {
  cleengToken: string;
  access_token: string;
  expires_in: string;
  refresh_token: string;
  expiresTokenPlus: number;
}

interface RefreshRegCode {
  type: typeof actionTypes.MVPD_REGCODE_REFRESH;
}

interface RefreshRegCodeSucceededAction {
  type: typeof actionTypes.MVPD_REGCODE_REFRESH_SUCCEEDED;
  payload: ILoginPayload;
}

interface RefreshRegCodeFailedAction {
  type: typeof actionTypes.MVPD_REGCODE_REFRESH_FAILED;
  error: ILoginError;
}

interface loadAgreementText {
  type: typeof actionTypes.AGREEMENT_TEXT_LOAD;
}

interface loadAgreementTextSucceededAction {
  type: typeof actionTypes.AGREEMENT_TEXT_LOAD_SUCCEEDED;
  payload: {
    termsOfUse: string;
    privacyPolicy: string;
  },
}

interface loadAgreementTextFailedAction {
  type: typeof actionTypes.AGREEMENT_TEXT_LOAD_FAILED;
  error: ILoginError;
}

interface CheckMVPDAuth {
  type: typeof actionTypes.MVPD_AUTH;
}

interface MVPDAuthSucceededAction {
  type: typeof actionTypes.MVPD_AUTH_SUCCEEDED;
  payload: IAuthPayload;
}

interface MVPDAuthFailedAction {
  type: typeof actionTypes.MVPD_AUTH_FAILED;
  error: ILoginError;
}

interface RefreshToken {
  type: typeof actionTypes.MVPD_TOKEN_REFRESH;
  refresh: boolean;
}

interface RefreshTokenFailedAction {
  type: typeof actionTypes.MVPD_TOKEN_REFRESH_FAILED;
  error: ILoginError;
}

interface RefreshTokenSucceededAction {
  type: typeof actionTypes.MVPD_TOKEN_REFRESH_SUCCEEDED;
  payload: IAuthPayload;
}

interface LogoutMVPD {
  type: typeof actionTypes.MVPD_LOGOUT;
}

interface LogoutMVPDFailedAction {
  type: typeof actionTypes.MVPD_LOGOUT_FAILED;
  error: ILoginError;
}

interface LogoutMVPDSucceededAction {
  type: typeof actionTypes.MVPD_LOGOUT_SUCCEEDED;
  payload: any;
}

interface MVPDAuthZError {
  type: typeof actionTypes.MVPD_AUTHZ_ERROR;
  message: string;
}

interface MVPDClearAuthZError {
  type: typeof actionTypes.MVPD_CLEAR_AUTHZ_ERROR;
}

interface MVPDSetProviderLogo {
  type: typeof actionTypes.MVPD_SET_PROVIDER_LOGO;
  logoUrl: string;
}

interface LoginEmail {
  type: typeof actionTypes.EMAIL_AUTH;
  data: {
    email: string,
    password: string,
  };
}

interface LoginEmailFailed {
  type: typeof actionTypes.EMAIL_AUTH_FAILED;
  signInOrUpError: string;
  error?: ILoginError;
}

interface LoginEmailSucceeded {
  type: typeof actionTypes.EMAIL_AUTH_SUCCEEDED;
  payload: ILoginUserResponse;
}

interface LogoutEmail {
  type: typeof actionTypes.EMAIL_SIGN_OUT;
}

interface LogoutEmailSucceed {
  type: typeof actionTypes.EMAIL_SIGN_OUT_SUCCEEDED;
  payload: any;
}

interface LogoutEmailFailed {
  type: typeof actionTypes.EMAIL_SIGN_OUT_FAILED;
  error: ILoginError;
}

interface CreateUserAction {
  type: typeof actionTypes.CREATE_USER;
  data: {
    email: string,
    password: string,
  }
}

interface CreateUserSucceededAction {
  type: typeof actionTypes.CREATE_USER_SUCCEEDED;
  payload: ILoginUserResponse;
}

interface CreateUserFailedAction {
  type: typeof actionTypes.CREATE_USER_FAILED;
  signInOrUpError: string;
  error: ILoginError;
}

interface ResetPasswordAction {
  type: typeof actionTypes.RESET_PASSWORD;
  email: string,
}

interface ResetPasswordSuccessAction {
  type: typeof actionTypes.RESET_PASSWORD_SUCCEEDED;
  email: string,
}

interface ResetPasswordFailAction {
  type: typeof actionTypes.RESET_PASSWORD_FAILED;
  error: ILoginError;
}

interface RefreshFirebaseToken {
  type: typeof actionTypes.PLUS_TOKEN_REFRESH;
}

interface RefreshFirebaseTokenFailedAction {
  type: typeof actionTypes.PLUS_TOKEN_REFRESH_FAILED;
  error: ILoginError;
}

interface RefreshFirebaseTokenSucceededAction {
  type: typeof actionTypes.PLUS_TOKEN_REFRESH_SUCCEEDED;
  payload: IRefreshPlusTokenPayload;
}

export interface ClearErrorAction {
  type: typeof actionTypes.CLEAR_ERROR;
}

interface ClearUserData {
  type: typeof actionTypes.CLEAR_USER_DATA;
}

interface ClearResetPassword {
  type: typeof actionTypes.CLEAR_RESET_PASSWORD;
}

type MVPDRegCode =
  RefreshRegCode |
  RefreshRegCodeSucceededAction |
  RefreshRegCodeFailedAction;

type MVPDAuth =
  CheckMVPDAuth |
  MVPDAuthSucceededAction |
  MVPDAuthFailedAction;

type AgreementTextLoad =
  loadAgreementText |
  loadAgreementTextSucceededAction |
  loadAgreementTextFailedAction

type MVPDLogout =
  LogoutMVPD |
  LogoutMVPDFailedAction |
  LogoutMVPDSucceededAction;

type MVPDRefreshToken =
  RefreshToken |
  RefreshTokenSucceededAction |
  RefreshTokenFailedAction;

type FirebaseRefreshToken =
  RefreshFirebaseToken |
  RefreshFirebaseTokenFailedAction |
  RefreshFirebaseTokenSucceededAction

type MVPDAction =
  MVPDRegCode |
  MVPDAuth |
  MVPDLogout |
  MVPDRefreshToken |
  MVPDAuthZError |
  MVPDClearAuthZError |
  MVPDSetProviderLogo;

type SubscriptionCreateUserAction =
  CreateUserAction |
  CreateUserSucceededAction |
  CreateUserFailedAction;

type SubscriptionResetPasswordAction =
  ResetPasswordAction |
  ResetPasswordSuccessAction |
  ResetPasswordFailAction |
  ClearResetPassword;

type SubscriptionLogin =
  LoginEmail |
  LoginEmailFailed |
  LoginEmailSucceeded;

type SubscriptionLogout =
  LogoutEmail |
  LogoutEmailSucceed |
  LogoutEmailFailed;

type SubscriptionUserData = ClearUserData;

type SubscriptionAction =
  SubscriptionLogin |
  SubscriptionResetPasswordAction |
  SubscriptionCreateUserAction |
  SubscriptionLogout |
  SubscriptionUserData |
  FirebaseRefreshToken |
  AgreementTextLoad |
  ClearErrorAction;

export type LoginAction = MVPDAction | SubscriptionAction;

export const checkMVPDAuth = (): LoginAction => ({
  type: actionTypes.MVPD_AUTH,
});

export const refreshRegCode = (): LoginAction => ({
  type: actionTypes.MVPD_REGCODE_REFRESH,
});

export const refreshToken = (refresh: boolean): LoginAction => ({
  type: actionTypes.MVPD_TOKEN_REFRESH,
  refresh,
});

export const logoutMVPD = (): LoginAction => ({
  type: actionTypes.MVPD_LOGOUT,
});

export const clearMVPDAuthZError = (): LoginAction => ({
  type: actionTypes.MVPD_CLEAR_AUTHZ_ERROR,
});

export const loginEmail = (data): LoginAction => ({
  type: actionTypes.EMAIL_AUTH,
  data,
});

export const logoutPlus = (): LoginAction => ({
  type: actionTypes.EMAIL_SIGN_OUT,
});

export const clearResetPassword = (): LoginAction => ({
  type: actionTypes.CLEAR_RESET_PASSWORD,
});

export const createUser = (userData): LoginAction => ({
  type: actionTypes.CREATE_USER,
  data: userData,
});

export const resetPassword = (email): LoginAction => ({
  type: actionTypes.RESET_PASSWORD,
  email,
});

export const clearError = (): LoginAction => ({
  type: actionTypes.CLEAR_ERROR,
});

export const refreshUserToken = (): LoginAction => ({
  type: actionTypes.PLUS_TOKEN_REFRESH,
});

export const getAgreementText = (): LoginAction => ({
  type: actionTypes.AGREEMENT_TEXT_LOAD,
});
