import React, { useEffect } from 'react';

import SlickSlider from '../../components/SlickSlider/SlickSlider';

import { Item } from '../SlickSlider/SlickSlider';

import './CAROUSEL_TEASER_LIST.scss';

type CAROUSEL_TEASER_LIST_PROPS = {
  onMount: () => void,
  onPress: () => void,
  carouselExist: boolean,
  content: Item[],
  index: number;
  defaultCardIndex?: number;
};

const CAROUSEL_TEASER_LIST = (props: CAROUSEL_TEASER_LIST_PROPS) => {
  const { onMount, onPress, carouselExist, content, index, defaultCardIndex } = props;

  useEffect(() => {
    !carouselExist && onMount();
  });

  return (
    <div className="carousel-teaser-list">
      {content && content.length && (
        <SlickSlider
          sliderId={index}
          items={content}
          onPress={onPress}
          defaultCardIndex={defaultCardIndex}
        ></SlickSlider>
      )}
    </div>
  );
};

export default CAROUSEL_TEASER_LIST;
