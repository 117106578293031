export const SET_STATION = 'STATION::SET_STATION';
export const REMOVE_STATION = 'STATION::REMOVE_STATION';

export const SET_CITY = 'COMMON::SET_CITY';
export const REMOVE_CITY = 'COMMON::REMOVE_CITY';

export const SET_TTS_STATUS = 'COMMON::SET_TTS_STATUS';
export const SET_CC_STATUS = 'COMMON::SET_CC_STATUS';

export const SET_ACTIVE_NAV = 'COMMON::SET_ACTIVE_NAV';

export const SET_APP_VERSION = 'COMMON::SET_APP_VERSION';

export const NETWORK_CONNECTED = 'COMMON::NETWORK_CONNECTED';

export const TOGGLE_EXIT_MODAL = 'COMMON:TOGGLE_EXIT_MODAL';

export const TOGGLE_DEBUG_SETTINGS = 'COMMON:TOGGLE_DEBUG_SETTINGS';

export const TOGGLE_SPOILER_PREVENTION_SETTINGS = 'COMMON:TOGGLE_SPOILER_PREVENTION_SETTINGS';
