import { getKeyInfo } from '../../utils';
import { isFireTVPlatform } from '../../constants/structureTypes';

declare const TVJS: any;

class FocusService {
  private static instance: FocusService;
  public activeElem: HTMLElement | null = null;
  public focusRoot: HTMLElement = document.body;
  private disableFocusSelectors: string[] = ['focus-disable'];
  public focusOnElementThrottle: number = Date.now();
  private focusDelay: number = 0;

  constructor() {
    this.pushFocusDisableSelector = this.pushFocusDisableSelector.bind(this);
    this.setFocus = this.setFocus.bind(this);
    this.moveFocus = this.moveFocus.bind(this);
    this.setFocusRoot = this.setFocusRoot.bind(this);
    this.setFocusRootToBody = this.setFocusRootToBody.bind(this);
    this.keyDownHandler = this.keyDownHandler.bind(this);
  }

  static getInstance() {
    if (!FocusService.instance) {
      FocusService.instance = new FocusService();
    }

    return FocusService.instance;
  }

  pushFocusDisableSelector(selectors: string[]): void {
    selectors.map((el) => {
      if (!this.disableFocusSelectors.includes(el)) {
        this.disableFocusSelectors.push(el);
      }

      return null;
    });
  }

  startFocusService(): void {
    TVJS.DirectionalNavigation.enabled = false;
    TVJS.DirectionalNavigation.focusableSelectors.push('.focusable');
    document.addEventListener('keydown', this.keyDownHandler, false);
    // Uncomment for the debugging purpose (triggers on every focus event)
    // document.addEventListener('focusin', () => console.log('document.activeElement - ', document.activeElement));
  }

  setFocus(el: any): void {
    if (el && this?.focusRoot?.contains(el)) {
      el.focus();
      this.activeElem = el;
    }
  }

  setFocusToSilentEl(): void {
    const silentElem = document.getElementById('appTitle');

    silentElem && silentElem.focus();
  }

  moveFocus(direction: string): void {
    const nextEl = this.findNextFocusElement(direction);

    this.setFocus(nextEl);
  }

  setFocusToNavbar():void {
    const activeNavItem = document.getElementById('navigationSelectedItem');

    activeNavItem && this.setFocus(activeNavItem);
  }

  findNextFocusElement(direction: string): Element {
    return TVJS.DirectionalNavigation.findNextFocusElement(direction);
  }

  setFocusRoot(el: any): void {
    TVJS.DirectionalNavigation.focusRoot = el;
    this.focusRoot = el;
  }

  setFocusRootToBody(): void {
    TVJS.DirectionalNavigation.focusRoot = document.body;
    this.focusRoot = document.body;
  }

  setFocusDelay(delay: number) {
    this.focusDelay = delay;
  }

  keyDownHandler(ev: any) {
    const keyInfo = getKeyInfo(ev.keyCode).toLowerCase();

    if (keyInfo) {
      let nextFocus = TVJS.DirectionalNavigation.findNextFocusElement(keyInfo);
      const isNextFocusDisabled =
        nextFocus &&
        this.disableFocusSelectors.some(el => {
          return nextFocus.classList.contains(el);
        });

      if (isFireTVPlatform && (nextFocus || (!nextFocus && !ev.target.dataset?.scrollEnabled))) {
        ev.preventDefault();
      }

      if (nextFocus) {
        if (isNextFocusDisabled) {
          nextFocus = TVJS.DirectionalNavigation.findNextFocusElement(keyInfo, {
            referenceElement: nextFocus,
          });
        }

        const currentTimeForThrottle = Date.now();

        if (this.focusDelay < currentTimeForThrottle - this.focusOnElementThrottle) {
          this.focusOnElementThrottle = currentTimeForThrottle;
          this.setFocus(nextFocus);
        }
      }
    }
  }
}

export default FocusService.getInstance();
