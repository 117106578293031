import React from 'react';

import Carousel from '../../Carousel/Carousel';
import Card from '../../Carousel/Card/Card';

import './SEASON_LIST.scss';

interface ISEASON_LIST {
  onPress: (idx: number) => void;
  selectedSeason: number;
  content: Array<string>;
  index: number;
}

const SEASON_LIST = (props: ISEASON_LIST) => {
  const { content, selectedSeason, onPress, index } = props;

  const cardStyles = {
    width: 130,
    height: 55,
    marginLeft: 0,
    marginRight: 50,
  };

  return (
    <div className='seasons'>
      <Carousel
        dynamic={true}
        selected={selectedSeason}
        cardStyles={cardStyles}
        carouselId={index}
        onCardSelection={() => null}
        onCardPressed={() => null}
        onSeasonSelect={onPress}
        carouselTitle={''}
      >
        {content.map((item, index) => (
          <Card
            width={'auto'}
            height={cardStyles.height}
            index={index}
            first={index === 0}
            key={index}
            last={index === content.length - 1}
            selected={selectedSeason}
            title={item}
            cardId={'content'}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default SEASON_LIST;
