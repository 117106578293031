import store from './../stores/index';
import { config } from '../config';

export function isPostCall(url) {
  const { config: { postUrlsPrefixArr } }: any = store.getState();

  return postUrlsPrefixArr.some(item => url.includes(item));
}

export function getDefaultHeaders() {
  return {
    'Content-Type': 'application/json',
    'x-apiversion': config.apiVersion,
    'x-appplatform': config.appPlatform,
    'x-brand': config.brand,
  };
}
