import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  BACK_BTN_TEXT,
  PRIVACY_POLICY_SCREEN,
  TERMS_OF_USE_SCREEN,
} from '../../../constants/text';
import { RootState } from '../../../stores/reducers';
import focusService from '../../../services/focusService';

import backArrowIcon from '../../../assets/icons/GoBackArrow.svg';
import ScrollableText from '../../ScrollableText/ScrollableText';
import Button from '../../Button/Button';

import './SignUpAgreementScreen.scss';

interface SignUpAgreementScreen {
  pageType: string,
  goBack: () => void;
}

const SignUpAgreementScreen = ({
  pageType,
  goBack,
}: SignUpAgreementScreen) => {

  const termsOfUseTitle = useSelector((state: RootState) => state.config.subscription?.terms_of_use_title);
  const privacyPolicyTitle = useSelector((state: RootState) => state.config.subscription?.privacy_policy_title);
  const termsOfUseDetails = useSelector((state: RootState) => state.Login.agreementPages?.termsOfUse);
  const privacyPolicyDetails = useSelector((state: RootState) => state.Login.agreementPages?.privacyPolicy);
  const settingsStyles = useSelector((state: RootState) => state.config.styles['settings']);
  const titleFont = useSelector((state: RootState) => state.config.styles.general?.heading?.titleFont);

  const pageData = useMemo(() => {
    return {
      [PRIVACY_POLICY_SCREEN]: {
        title: privacyPolicyTitle,
        details: privacyPolicyDetails,
      },
      [TERMS_OF_USE_SCREEN]: {
        title: termsOfUseTitle,
        details: termsOfUseDetails,
      },
    };
  }, [pageType]);

  // TODO what analytic event to set here
  const sendAnalytics = (itemIndex: number) => {
    console.log(itemIndex);
    // GAEvent(SETTINGS, VIEW, `${itemIndex}`);
  };

  useEffect(() => {
    focusService.setFocus(document.querySelector('.btn-acc-0'));
  }, []);

  return (
    <div className='agreement-screen' style={{ background: `url(${settingsStyles['backgroundImage']})`}}>
      <div className='agreement-screen__title-and-button-wrapper'>
        <div className='agreement-screen__title' style={{ fontFamily: titleFont }}>{pageData[pageType]?.title}</div>
        <Button
          title={BACK_BTN_TEXT}
          index={0}
          focusUp='.btn-acc-0'
          focusDown='.btn-acc-0'
          focusLeft='.btn-acc-0'
          focusRight='.settings-key-text'
          onKeyDown={goBack}
        >
          {<img src={backArrowIcon} alt='back arrow' style={{marginRight: '16px'}}/>}
        </Button>
      </div>
      <div className='agreement-screen__scrollable-text'>
        <ScrollableText
          text={pageData[pageType]?.details}
          sendAnalytics={sendAnalytics}
          isDescriptionTextFocused={true}
        />
      </div>
    </div>
  );
};

export default SignUpAgreementScreen;
