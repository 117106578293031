import store from '../stores/index';
import { getAccessStatus, submitReceipt } from './cleengService';
import {
  IPurchaseService,
  ISubmitReceiptFireTvRequestData,
  IPurchaseUpdatesReceiptDataFireTv,
} from '../platform/interfaces/purchaseService';
import { GAEvent } from './analytics';
import { PURCHASE, SETTINGS } from '../constants/analyticsTypes';
import {
  REQUEST_PURCHASES_LIST_ERROR,
  REQUEST_BUY_ITEM_EXCEPTION_ERROR,
  SUBMIT_RECEIPT_ERROR,
  REQUEST_USER_STATUS_ERROR,
  USER_NOT_LOGIN_ERROR_FIRE_TV,
  DEFAULT_CLEENG_ERROR,
} from '../constants/errorCodes';
import { callbackAndroidAsync } from '../platform/FireTVPlatform/FireTVHelper';

declare const Android: any;

export class FireTvPurchaseService implements IPurchaseService {
  private state: any = store.getState();
  private submitReceiptProdURL: string = this.state.config.subscription.cleeng_receipt || '';
  private userId = '';
  private subscriptionStatusFromDevice: boolean = false;
  private purchasesList: IPurchaseUpdatesReceiptDataFireTv[] = [];

  private async getPurchasesList(): Promise<IPurchaseUpdatesReceiptDataFireTv[]> {
    return callbackAndroidAsync('purchaseUpdatesRequest')
      .then((res: any) => {
        const data = JSON.parse(res.purchaseResponse);

        return data.receipts;
      })
      .catch((e) => {
        throw new Error(`Error in request 'purchaseUpdatesRequest' - ${e}`);
      });
  }

  private async getUserStatus(): Promise<any> {
    return callbackAndroidAsync('amazonUserRequest')
      .then((res: any) => res)
      .catch((e) => {
        throw new Error(`Error in request 'userStatus' - ${e}`);
      });
  }

  private async submitCleengReceipt(
    receiptData,
    receiptHeaders,
    modalErrorCallback,
    successCallback: () => void,
    errorCallback: () => void) {

    return await submitReceipt(this.submitReceiptProdURL, receiptData, receiptHeaders)
      .then(async (res) => {
        console.log(`submitReceipt success: `, res);
        Android.fulFillPurchase(receiptData.receipt.receiptId, true);

        const accessStatusUrl = this.state.config.subscription.cleeng_getAccessStatus;

        await getAccessStatus(`${accessStatusUrl}${receiptData.offerId}/status`, receiptData.customerToken);

        successCallback && successCallback();
      })
      .catch((e) => {
        console.log(`submitReceipt error : ${e.message}`);
        Android.fulFillPurchase(receiptData.receipt.receiptId, false);
        modalErrorCallback && modalErrorCallback(SUBMIT_RECEIPT_ERROR);
        errorCallback && errorCallback();
      });
  }

  private async getUserDataWithPurchases(errorCallback?: (error) => void) {
    try {
      const { userId } = await this.getUserStatus();

      console.log(`amazonUserRequest success: `, userId);

      this.userId = userId;
    } catch (e) {
      console.log(`amazonUserRequest failure ${e}`);
      errorCallback && errorCallback(REQUEST_USER_STATUS_ERROR);
    }

    try {
      if (this.userId) {
        const purchases = await this.getPurchasesList();

        console.log(`requestPurchaseList success: `, purchases);

        this.purchasesList = purchases;
      } else {
        errorCallback && errorCallback(USER_NOT_LOGIN_ERROR_FIRE_TV);
      }
    } catch (e) {
      console.log(`requestPurchasesList failure ${e}`);
      errorCallback && errorCallback(REQUEST_PURCHASES_LIST_ERROR);
    }
  }

  async getSubscriptionStatusFromDevice(selectedProductId: string, errorCallback: any): Promise<boolean | void> {
    await this.getUserDataWithPurchases(errorCallback);

    if (this.purchasesList.length) {
      await this.validatePurchases(this.state.cleengService.purchaseOptions, selectedProductId, errorCallback);
    } else {
      this.subscriptionStatusFromDevice = false;
    }

    return this.subscriptionStatusFromDevice;
  }

  async validatePurchases(subscriptions: Array<any>, selectedProductId?: string, errorCallback?: (error) => void) {
    let selectedReceiptId, productId;

    await this.getUserDataWithPurchases();

    if (!this.userId || !this.purchasesList.length) {
      return;
    }

    try {
      if (selectedProductId) {
        const selectedProductRegExp = new RegExp(selectedProductId, 'g');

        // Left for the debug purposes to check canceled purchases
        // this.purchasesList[0].cancelDate = 'random any text';

        selectedReceiptId = this.purchasesList.find(
          ({ sku, cancelDate }) => sku.match(selectedProductRegExp) && !cancelDate,
        )?.receiptId;

        productId = selectedProductId;
        console.log('productId - ', productId, 'selectedReceiptId - ', selectedReceiptId);
      } else {
        const skusRegExp = subscriptions.map(item => new RegExp(item.id, 'g'));

        // Left for the debug purposes to check canceled purchases
        // this.purchasesList[0].cancelDate = 'random any text';

        this.purchasesList.forEach(({ sku, cancelDate, receiptId }) => {
          const purchaseIndex = skusRegExp.findIndex(item => sku.match(item) && !cancelDate);

          if (purchaseIndex !== -1) {
            selectedReceiptId = receiptId;
            productId = subscriptions[purchaseIndex].id;
          }
        });
      }

      if (selectedReceiptId &&
        productId &&
        this.state.cleengService.currentSubscriptionDetails?.status !== 'active'
      ) {
        const {
          receiptData,
          receiptHeaders,
        } = this.generateCleengReceiptData(productId, this.userId, selectedReceiptId);

        return this.submitCleengReceipt(
          receiptData,
          receiptHeaders,
          errorCallback,
          () => {
            this.subscriptionStatusFromDevice = true;
          },
          () => {
            this.subscriptionStatusFromDevice = false;
          },
        );
      } else {
        this.subscriptionStatusFromDevice = false;
      }
    } catch (e) {
      console.log('Error - ', e);
    }
  }

  private generateCleengReceiptData(offerId, userId, receiptId) {
    const cleengToken = JSON.parse(localStorage.getItem('AuthPlus') as string)?.cleengToken || '';
    const firebaseToken = JSON.parse(localStorage.getItem('AuthPlus') as string)?.token || '';

    const receiptHeaders = {
      'X-userAuthenticationToken': firebaseToken,
    };

    const receiptData: ISubmitReceiptFireTvRequestData = {
      customerToken: cleengToken,
      offerId: offerId,
      locale: 'en_US',
      country: 'US',
      currency: 'USD',
      receipt: {
        receiptId: receiptId,
        userId: userId,
      },
    };

    return {
      receiptData,
      receiptHeaders,
    };
  }

  async buyItem(
    selectedProductId: string,
    successCallback: any,
    errorCallback: any,
    setIsLoading: any,
    checkPurchaseStatus: any,
    exceptionErrHandler: any,
    analyticsValue: () => string): Promise<void> {

    if (typeof Android === 'undefined') {
      return;
    }

    setIsLoading(true);

    const requestPurchaseData = {
      purchaseSkus: selectedProductId,
    };

    try {
      callbackAndroidAsync('requestPurchase', requestPurchaseData)
        .then(async (data: any) => {
          const resData = JSON.parse(data.purchaseResponse);

          // console.log(resData);

          const fireTvReceipt = {
            ...resData,
          };

          if (!resData.receipt.receiptId) {
            try {
              const purchasesListRes = await this.getPurchasesList();

              const skuRegExp = new RegExp(selectedProductId, 'g');

              const receiptId = purchasesListRes?.find(
                ({ sku }) => sku.match(skuRegExp))?.receiptId;

              fireTvReceipt.receipt.receiptId = receiptId;
            } catch {
              errorCallback(REQUEST_PURCHASES_LIST_ERROR);
            }
          }

          const firebaseToken = JSON.parse(localStorage.getItem('AuthPlus') as string)?.token || '';

          if (firebaseToken) {
            const {
              receiptData,
              receiptHeaders,
            } = this.generateCleengReceiptData(
              fireTvReceipt.receipt.termSku,
              fireTvReceipt.userData.userId,
              fireTvReceipt.receipt.receiptId,
            );

            if (resData.requestStatus === 'SUCCESSFUL') {
              await this.submitCleengReceipt(
                receiptData,
                receiptHeaders,
                errorCallback,
                () => {
                  setIsLoading(false);
                  GAEvent(SETTINGS, PURCHASE, analyticsValue());
                  checkPurchaseStatus();
                  successCallback();
                },
                () => {
                  setIsLoading(false);
                });
            } else {
              setIsLoading(false);
              successCallback();
            }
          } else {
            console.log(`buyItem: Refresh Plus Token Error`);
            setIsLoading(false);
            errorCallback(DEFAULT_CLEENG_ERROR);
          }
        })
        .catch((e) => {
          console.log(`requestBuyItem error : ${e}`);
          exceptionErrHandler();
          setIsLoading(false);
        });
    } catch (e) {
      console.log(`requestPurchase Exception : ${e}`);
      setIsLoading(false);
      errorCallback(REQUEST_BUY_ITEM_EXCEPTION_ERROR);
    }
  }
}
