import * as actionTypes from '../actions/types/Login';
import { ILoginError, LoginAction } from '../actions/Login';
import { getParsedDataFromLocalStorage } from '../../utils/commonFunc';

interface ILoginState {
  mvpd: {
    authTokenMVPD: string;
    mvpdId: string;
    mvpdLogo: string;
    expiresTokenMVPD: string;
    regCode: string;
    authZError: string;
    error: ILoginError;
  },
  subscription: {
    authTokenPlus: string;
    emailVerified: boolean;
    isResetPasswordSuccess: boolean;
    expiresTokenPlus: string | number;
    email: string;
    signInOrUpError: string;
    cleengToken: string;
    refreshTokenPlus: string;
    isResetPasswordError: boolean;
  },
  agreementPages: {
    termsOfUse: string;
    privacyPolicy: string;
  },
  isUserMVPDLogin: boolean;
  isUserTCLogin: boolean;
  hasError: boolean;
  error: ILoginError;
  isLoading: boolean;
}

const initialState: ILoginState = {
  mvpd: {
    authTokenMVPD: getParsedDataFromLocalStorage('AuthMVPD')?.token || '',
    mvpdId: getParsedDataFromLocalStorage('AuthMVPD')?.mvpdProviderId || '',
    mvpdLogo: localStorage.getItem('mvpdProviderLogo') || '',
    expiresTokenMVPD: '',
    regCode: '',
    authZError: '',
    error: {
      errorCode: '',
      details: null,
    },
  },
  subscription: {
    authTokenPlus: getParsedDataFromLocalStorage('AuthPlus')?.token || '',
    emailVerified: false,
    isResetPasswordSuccess: false,
    expiresTokenPlus: getParsedDataFromLocalStorage('AuthPlus')?.expiresTokenPlus || '',
    email: getParsedDataFromLocalStorage('AuthPlus')?.email || '',
    signInOrUpError: '',
    refreshTokenPlus: getParsedDataFromLocalStorage('AuthPlus')?.refreshToken || '',
    cleengToken: getParsedDataFromLocalStorage('AuthPlus')?.cleengToken || '',
    isResetPasswordError: false,
  },
  agreementPages: {
    termsOfUse: '',
    privacyPolicy: '',
  },
  isUserMVPDLogin: getParsedDataFromLocalStorage('AuthMVPD')?.isAuth || false,
  isUserTCLogin: getParsedDataFromLocalStorage('AuthPlus')?.isAuth || false,
  hasError: false,
  error: {
    errorCode: '',
    details: null,
  },
  isLoading: false,
};

const Login = (state = initialState, action: LoginAction): ILoginState => {
  switch (action.type) {
    case actionTypes.MVPD_REGCODE_REFRESH:
    case actionTypes.CREATE_USER:
    case actionTypes.EMAIL_AUTH:
    case actionTypes.PLUS_TOKEN_REFRESH:
    case actionTypes.RESET_PASSWORD:
    case actionTypes.AGREEMENT_TEXT_LOAD:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.MVPD_REGCODE_REFRESH_SUCCEEDED:
      return {
        ...state,
        mvpd: { ...state.mvpd, regCode: action.payload.code },
        hasError: false,
        isLoading: false,
      };

    case actionTypes.MVPD_REGCODE_REFRESH_FAILED:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        mvpd: {
          ...state.mvpd,
          error: action.error,
        },
      };

    case actionTypes.AGREEMENT_TEXT_LOAD_FAILED:
      return {
        ...state,
        hasError: true,
        error: action.error,
        isLoading: false,
      };

    case actionTypes.AGREEMENT_TEXT_LOAD_SUCCEEDED:
      return {
        ...state,
        agreementPages: {...action.payload},
        hasError: false,
        isLoading: false,
      };

    case actionTypes.MVPD_TOKEN_REFRESH_FAILED:
    case actionTypes.MVPD_AUTH_FAILED:
      return {
        ...state,
        isUserMVPDLogin: false,
        mvpd: { ...state.mvpd, authTokenMVPD: '', error: action.error },
      };

    case actionTypes.MVPD_TOKEN_REFRESH_SUCCEEDED:
    case actionTypes.MVPD_AUTH_SUCCEEDED:
      return {
        ...state,
        isUserMVPDLogin: true,
        mvpd: {
          ...state.mvpd,
          authTokenMVPD: action.payload?.serializedToken || '',
          mvpdId: action.payload.mvpdId,
          expiresTokenMVPD: action.payload.expires,
          authZError: '',
        },
        error: {
          errorCode: '',
          details: null,
        },
      };

    case actionTypes.MVPD_LOGOUT:
    case actionTypes.EMAIL_SIGN_OUT:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.MVPD_LOGOUT_SUCCEEDED:
      return {
        ...state,
        isUserMVPDLogin: false,
        mvpd: {
          ...state.mvpd,
          authTokenMVPD: '',
          mvpdId: '',
          mvpdLogo: '',
          expiresTokenMVPD: '',
          authZError: '',
        },
        isLoading: false,
      };

    case actionTypes.MVPD_LOGOUT_FAILED:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isLoading: false,
      };

    case actionTypes.MVPD_SET_PROVIDER_LOGO:
      return {
        ...state,
        mvpd: {
          ...state.mvpd,
          mvpdLogo: action.logoUrl,
        },
      };

    case actionTypes.MVPD_AUTHZ_ERROR:
      return {
        ...state,
        mvpd: {
          ...state.mvpd,
          authTokenMVPD: '',
          mvpdId: '',
          expiresTokenMVPD: '',
          regCode: '',
          authZError: action.message,
        },
        hasError: true,
        isUserMVPDLogin: false,
        isLoading: false,
      };

    case actionTypes.MVPD_CLEAR_AUTHZ_ERROR:
      return {
        ...state,
        hasError: false,
        mvpd: {
          ...state.mvpd,
          authZError: '',
          error: {
            errorCode: '',
            details: null,
          },
        },
      };

    case actionTypes.EMAIL_AUTH_FAILED:
    case actionTypes.CREATE_USER_FAILED:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          signInOrUpError: action.signInOrUpError,
        },
        hasError: true,
        isLoading: false,
      };

    case actionTypes.RESET_PASSWORD_FAILED:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          isResetPasswordError: true,
        },
        error: action.error,
        hasError: true,
        isLoading: false,
      };

    case actionTypes.EMAIL_SIGN_OUT_FAILED:
      return {
        ...state,
        subscription: {
          ...state.subscription,
        },
        hasError: true,
        isLoading: false,
        error: action.error,
      };

    case actionTypes.PLUS_TOKEN_REFRESH_FAILED:
    case actionTypes.EMAIL_SIGN_OUT_SUCCEEDED:
      return {
        ...state,
        isUserTCLogin: false,
        subscription: {
          ...state.subscription,
          authTokenPlus: '',
          email: '',
          expiresTokenPlus: '',
          emailVerified: false,
          cleengToken: '',
        },
        isLoading: false,
      };

    case actionTypes.RESET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          isResetPasswordSuccess: true,
        },
        isLoading: false,
      };

    case actionTypes.CREATE_USER_SUCCEEDED:
    case actionTypes.EMAIL_AUTH_SUCCEEDED:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          email: action.payload.email,
          emailVerified: action.payload.emailVerified,
          expiresTokenPlus: action.payload.expirationTime,
          authTokenPlus: action.payload.accessToken,
          refreshTokenPlus: action.payload.refreshToken,
          cleengToken: action.payload.cleengToken,
          signInOrUpError: '',
        },
        isUserTCLogin: true,
        isLoading: false,
        hasError: false,
        error: {
          errorCode: '',
          details: null,
        },
      };

    case actionTypes.PLUS_TOKEN_REFRESH_SUCCEEDED:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          expiresTokenPlus: action.payload.expiresTokenPlus,
          authTokenPlus: action.payload['access_token'],
          refreshTokenPlus: action.payload['refresh_token'],
          cleengToken: action.payload.cleengToken,
        },
        isUserTCLogin: true,
        isLoading: false,
        hasError: false,
      };

    case actionTypes.CLEAR_USER_DATA:
      return {
        ...state,
        subscription: {
          ...initialState.subscription,
        },
      };

    case actionTypes.CLEAR_RESET_PASSWORD:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          isResetPasswordSuccess: false,
        },
      };

    case actionTypes.CLEAR_ERROR:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          signInOrUpError: '',
          isResetPasswordError: false,
        },
        hasError: false,
        isLoading: false,
        error: {
          errorCode: '',
          details: null,
        },
      };

    default:
      return state;
  }
};

export default Login;
