import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import { RootState } from '../../stores/reducers';
import CustomCalendar from './CustomCalendar/CustomCalendar';
import Spinner from '../Spinner/Spinner';
import focusService from '../../services/focusService';
import { OTT_BRAND, COMET_OTT_BRAND } from '../../constants/structureTypes';
import { GAEvent, GAScreen } from '../../services/analytics';
import { CHANGE_DAY, EPG, HIDE_EPG, SCROLL_END } from '../../constants/analyticsTypes';
import appConfig from '../../assets/config.json';
import { SCHEDULE } from '../../constants/text';

import './VerticalEpg.scss';

const VerticalEpg = () => {
  const programsByDate = useSelector((state: RootState) => state.OTTChannelGuide.programsByDate);
  const programsFetched = useSelector((state: RootState) => state.OTTChannelGuide.fetched);
  const epgStyles = useSelector((state: RootState) => state.config.styles['epg']);

  const [focusedIndex, setFocusedIndex] = useState<number>(0);
  const [isProgramFocused, setIsProgramFocused] = useState(false);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [programmeStartDate, setProgrammeStartDate] = useState<string>(moment().format('DD/MM/YYYY'));
  const [showDownArrow, setShowDownArrow] = useState<boolean>(true);

  const focusedProgram = useRef<string>('');

  useEffect(() => {
    focusService.setFocus(document.querySelector('.date-container'));
    GAScreen(EPG);

    return () => {
      GAEvent(EPG, HIDE_EPG, (focusedProgram.current || `${appConfig[OTT_BRAND].brandName} ${EPG}`));
    };
  }, []);

  const handleProgramFocus = (e, index): void => {
    setFocusedIndex(index);
    setIsProgramFocused(true);
    if (programsByDate && programsByDate[programmeStartDate]) {
      focusedProgram.current = programsByDate[programmeStartDate][index].title.value;
      if ((programsByDate[programmeStartDate].length - 1) === index) {
        GAEvent(EPG, SCROLL_END, focusedProgram.current);
      }
    }
  };

  const handleProgramBlur = (): void => {
    setIsProgramFocused(false);
  };

  const sliceText = (text, index) => {
    if (!text) {
      return null;
    }
    if (text.length < 50) {
      return text;
    }

    return focusedIndex === index ? text : `${text.slice(0, 50)}...`;
  };

  const changeDateForChannels = (selectedDate) => {
    if (Object.prototype.hasOwnProperty.call(programsByDate, selectedDate)) {
      setShowDownArrow(prev => !prev);
      setProgrammeStartDate(selectedDate);
      GAEvent(EPG, CHANGE_DAY, focusedProgram.current);

      return true;
    } else {
      setShowDownArrow(prev => !prev);

      return false;
    }
  };

  const objStyleFromConfig = {
    programItemFocused: {
      borderColor: epgStyles.cellColorBorderOn,
      borderWidth: epgStyles.cellSizeBorderOn + 'px',
      backgroundColor: epgStyles.cellBgColorOn,
    },
    programItemUnFocused: {
      borderColor: epgStyles.cellColorBorderOff,
      borderWidth: epgStyles.cellSizeBorderOff + 'px',
      backgroundColor: epgStyles.cellBgColorOff,
    },
    timeContainer: {
      fontFamily: epgStyles.cellTimeSlotFontFace.fontFamily,
      fontSize: `${epgStyles.cellTimeSlotFontSize}px`,
      fontWeight: epgStyles.cellTimeSlotFontWeight,
    },
    titleFont: {
      fontFamily: epgStyles.cellTitleFontFace.fontFamily,
      fontSize: `${epgStyles.cellTitleFontSize}px`,
      fontWeight: epgStyles.cellTitleFontWeight,
    },
    descriptionTextFocused: {
      fontFamily: epgStyles.cellFontFace.fontFamily,
      color: epgStyles.cellColorOn,
      fontSize: `${epgStyles.cellFontSize}px`,
    },
    descriptionTextUnFocused: {
      fontFamily: epgStyles.cellFontFace.fontFamily,
      color: epgStyles.cellColorOff,
      fontSize: `${epgStyles.cellFontSize}px`,
    },
  };

  const renderPrograms = () => (
    <>
      {programsFetched ? (
        programsByDate[moment().format('DD/MM/YYYY')] &&
          programsByDate[programmeStartDate].map((item, index) => {
            return (
              <div
                className={`programDescription program-${index}`}
                style={(focusedIndex === index && isProgramFocused)
                  ? objStyleFromConfig.programItemFocused
                  : objStyleFromConfig.programItemUnFocused
                }
                // eslint-disable-next-line
                role=''
                aria-label={`${moment(item.start).format('h:mm A')}
              ${item.title} ${sliceText(item.desc, index)}`
                }
                tabIndex={0}
                data-tv-focus-up={focusedIndex === 0 && '.program-0'}
                data-tv-focus-left='.date-container'
                data-tv-focus-down={(focusedIndex === (programsByDate[programmeStartDate].length - 1))
                  ? `.program-${index}`
                  : ''
                }
                onFocus={(e) => handleProgramFocus(e, index)}
                onBlur={() => handleProgramBlur()}
                key={item.start}
              >
                <div className="time-container">
                  <p
                    style={objStyleFromConfig.timeContainer}
                  >
                    {moment(item.start).format('h:mm A')}
                  </p>
                </div>
                <p
                  className="description-title"
                  style={(focusedIndex === index && isProgramFocused && OTT_BRAND === COMET_OTT_BRAND)
                    ? objStyleFromConfig.titleFont
                    : {
                      ...objStyleFromConfig.titleFont,
                      color: epgStyles.heroHeadlineTextColor,
                    }}
                >
                  {item.title}
                </p>
                <p
                  className="descriptionText"
                  style={(focusedIndex === index && isProgramFocused)
                    ? objStyleFromConfig.descriptionTextFocused
                    : objStyleFromConfig.descriptionTextUnFocused
                  }
                >
                  {sliceText(item.desc, index)}
                </p>
              </div>
            );
          })
      ) : <Spinner />}
    </>
  );

  return (
    <div className="VerticalEpg">
      <div
        className="main"
        style={{
          color: `${epgStyles.heroHeadlineTextColor}`,
        }}
      >
        <div className="date-picker-container">
          <h1
            className={`date-picker-title date-picker-title-${OTT_BRAND}`}
            style={{
              fontFamily: epgStyles.dateTitleFontFace.fontFamily,
              fontSize: `${epgStyles.dateTitleFontSize}px`,
            }}
          >
            {SCHEDULE}
          </h1>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            customInput={
              <CustomCalendar
                changeDateForChannels={changeDateForChannels}
                showDownArrow={showDownArrow}
                value={startDate}
              />
            }
          />
        </div>
        <div className="rightSchedule" style={{backgroundColor: epgStyles.cellBgColorOff}}>
          <div className="programs">
            {renderPrograms()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalEpg;
