import { select, put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../actions/types/config';
import * as commonActionTypes from '../actions/types/common';
import { setLiverampAction } from '../actions/liverampCollector';
import { INavigationItem } from '../reducers/config';
import { getConfigData, getNavigationConfig } from '../../services/ConfigService';
import { APP_CONFIG_REQUEST_ERROR, APP_NAVIGATION_REQUEST_ERROR } from '../../constants/errorCodes';

function* setConfigs() {
  try {
    const configData: any = yield call(getConfigData);

    yield put({
      type: actionTypes.LOAD_CONFIG_SUCCEEDED,
      payload: configData,
    });

    if (configData?.configData?.API?.liveramp_collector) {
      const { liveramp_collector } = configData.configData.API;

      yield put(
        setLiverampAction({ urlLiveramp: liveramp_collector })
      );
    }
  } catch (e: any) {
    yield put({
      type: actionTypes.LOAD_CONFIG_FAILED,
      error: APP_CONFIG_REQUEST_ERROR,
    });
  }
}

function* loadNavigationMenuConfig() {
  try {
    const {
      config: { allConfigs: configData },
    } = yield select();

    const isConfigDataLoaded = configData && Object.keys(configData).length;

    if (!isConfigDataLoaded) {
      return;
    }

    const navigationConfigData: INavigationItem[] = yield call(getNavigationConfig, configData);

    yield put({
      type: actionTypes.LOAD_NAVIGATION_CONFIG_SUCCEEDED,
      payload: {
        navigationData: navigationConfigData,
      },
    });
  } catch (e: any) {
    yield put({
      type: actionTypes.LOAD_NAVIGATION_CONFIG_FAILED,
      error: APP_NAVIGATION_REQUEST_ERROR,
    });
  }
}

function* configWatcher() {
  yield takeLatest(actionTypes.LOAD_CONFIG, setConfigs);
  yield takeLatest(
    [ commonActionTypes.SET_STATION, actionTypes.LOAD_CONFIG_SUCCEEDED ],
    loadNavigationMenuConfig
  );
}

export default configWatcher;
