import { useEffect, useRef } from 'react';

const useEventListener = (eventName: string, cb: (ev) => void, el?: any, options?: any) => {
  const element = el || document;
  const savedHandler = useRef<any>(null);

  useEffect(() => {
    savedHandler.current = cb;
  }, [cb]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;

    if (!isSupported) {
      return;
    }

    const eventListener = event => {
      savedHandler.current(event);
    };

    element.addEventListener(eventName, eventListener, options);

    return () => {
      element.removeEventListener(eventName, eventListener, options);
    };
  // eslint-disable-next-line
  }, [eventName, element]);
};

export default useEventListener;
