import {
  TITLE_LEVEL_REPORT, TITLE_LEVEL_REPORT_FAILED, TITLE_LEVEL_REPORT_SUCCEEDED,
} from '../actions/types/titleLevelReport';

interface ITitleLevelReportState {
  isLoading: boolean;
  hasError: boolean;
}

const initialState: ITitleLevelReportState = {
  isLoading: false,
  hasError: false,
};

const titleLevelReport = (state = initialState, action) => {
  switch (action.type) {
    case TITLE_LEVEL_REPORT:
      return { ...state, isLoading: true, hasError: false };
    case TITLE_LEVEL_REPORT_SUCCEEDED:
      return { ...state, isLoading: false };
    case TITLE_LEVEL_REPORT_FAILED:
      return { ...state, isLoading: false, hasError: true };
    default:
      return state;
  }
};

export default titleLevelReport;
