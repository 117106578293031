export const FEED_TEASER_LIST = 'FEED_TEASER_LIST';
export const TEXT_TEASER = 'TEXT_TEASER';
export const CAROUSEL_TEASER_LIST = 'CAROUSEL_TEASER_LIST';
export const HERO_TEASER = 'HERO_TEASER';
export const SEASON_GRID_TEASER_LIST = 'SEASON_GRID_TEASER_LIST';
export const SEASON_TEASER_LIST = 'SEASON_TEASER_LIST';
export const THREE_COLUMN_TEASER_LIST = 'THREE_COLUMN_TEASER_LIST';
export const FOUR_COLUMN_TEASER_LIST = 'FOUR_COLUMN_TEASER_LIST';
export const FULL_SCREEN_CARD = 'FULL_SCREEN_CARD';

export const OTTFeedType = 'OTTFeed';
export const OTTEpisodeType = 'OTTEpisode';
export const OTTPageType = 'OTTPage';
export const OTTSearchType = 'OTTSearch';
export const OTTButtonType = 'OTTButton';
export const OTTSettingsType = 'OTTSettings';
export const OTTTextType = 'OTTText';
export const OTTAboutType = 'OTTAbout';
export const OTTSubscribeType = 'OTTSubscribe';
export const TCPlusFlowType = 'TCPlusFlow';
export const MvpdFlowType = 'MvpdFlow';

export const ResetPasswordType = 'ResetPassword';

export const CITY_SELECTION = 'CITY_SELECT_ITEM';
export const TV_PROVIDER_TAG = 'mvpdProvider';

export const TV_PROVIDER_ID_TO_SHOW = 'Comcast_SSO';

export const DEFAULT_SLIDES_VALUE = 4;
export const THREE_SLIDES_VALUE = 3;

export const STIRR_OTT_BRAND = 'STIRR';
export const COMET_OTT_BRAND = 'COMET';
export const TENNIS_CHANNEL_OTT_BRAND = 'TENNIS_CHANNEL';
export const CHARGE_OTT_BRAND = 'CHARGE';
export const MARQUEE_OTT_BRAND = 'MARQUEE_SPORTS';

export const TENNIS_CHANNEL = 'tennischannel';
export const MARQUEE_SPORTS = 'marqueesports';

const MARQUEE_SPORTS_LIVE = 'marqueesportslive';

export const OTT_BRAND = process.env.REACT_APP_OTT_SITE || 'STIRR';
export const REACT_APP_DEVICE_NAME = process.env.REACT_APP_PLATFORM || 'Tizen';
export const REACT_APP_BRAND = process.env.REACT_APP_OTT_SITE || 'stirr';

export const isFireTVPlatform = REACT_APP_DEVICE_NAME.toLowerCase() === 'firetv';

export const isStirrTcMarqueeBrand = [TENNIS_CHANNEL_OTT_BRAND, STIRR_OTT_BRAND, MARQUEE_OTT_BRAND].includes(OTT_BRAND);
export const isStirrBrand = OTT_BRAND === STIRR_OTT_BRAND;
export const isTcBrand = OTT_BRAND === TENNIS_CHANNEL_OTT_BRAND;
export const isMarqueeBrand = OTT_BRAND === MARQUEE_OTT_BRAND;

export const X_BRAND_HEADER = isTcBrand ? TENNIS_CHANNEL : MARQUEE_SPORTS_LIVE;
