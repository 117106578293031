declare const Android: any;

// The function is used for async call from Android (APS, payments, etc)

export const callbackAndroidAsync = (javaFuncName: string, params?: object): Promise<any> => {
  if (typeof Android !== 'undefined') {

    const rand = 'asyncJava_' + Math.floor(Math.random() * 1000000);

    window[rand] = {};

    // func called from android
    window[rand].callback = (responseData) => {

      if (!responseData.error) {
        window[rand].resolve(responseData);
      } else {
        window[rand].reject(responseData);
      }

      delete window[rand]; // finally clean up
    };

    const stringifiedObj = params ? JSON.stringify(params) : null;

    Android?.runAsync(rand, javaFuncName, stringifiedObj);

    return new Promise((resolve, reject) => {
      window[rand].resolve = (data) => resolve(data);
      window[rand].reject = (err) => reject(err);
    });
  }

  return Promise.reject(new Error(`Request callbackAndroidAsync failed - ${javaFuncName}`));
};
