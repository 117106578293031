export function getFont(font) {
  if (typeof font === 'string') {

    return {
      fontFamily: font,
      fontWeight: convertFontWeight(font.toLowerCase().trim()),
    };
  }

  return '';
}

export function convertFontWeight(weight) {
  const lower = weight.toLowerCase();
  const map = {
    thin: 100,
    extralight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  };

  return map[lower] ? map[lower] : map.regular;
}
