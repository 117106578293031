import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';

import { goToSpecificNavItem, navigateBack } from '../../services/NavigationService';
import useEventListener from '../../hooks/eventListener';
import { RootState } from '../../stores/reducers';
import { getPurchaseData } from '../../stores/actions/cleengService';
import { clearError, getAgreementText, logoutPlus, refreshUserToken } from '../../stores/actions/Login';
import { RESET_REFRESH_DATA_STATE } from '../../stores/actions/types/cleengService';
import { SET_ACTIVE_NAV } from '../../stores/actions/types/common';
import { backHandlerForFirstLevelPage, onBackHandler } from '../../utils';
import { GAEvent, GAScreen } from '../../services/analytics';
import currentPlatform from '../../platform/currentPlatform';
import { stopTTS, useAnnouncePageLoading } from '../../services/TTSService';
import {
  SETTINGS, VIDEO,
  SUBSCRIPTION, LOG_OUT,
  CREATE_ACCOUNT,
  SUCCESS, ABANDON,
  SUBSCRIBE_TO_PLUS,
} from '../../constants/analyticsTypes';
import {
  LOGIN, PLUS_PAYWALL_SCREEN,
  PRIVACY_POLICY_SCREEN,
  RESET_PASSWORD, SIGN_IN, SUBSCRIBE,
  SUCCESS_PAYMENT, TERMS_OF_USE_SCREEN,
} from '../../constants/text';
import {
  OTTPageType,
  OTTSubscribeType,
} from '../../constants/structureTypes';
import {
  DEFAULT_CLEENG_ERROR,
  GET_ACTIVE_USER_SUBSCRIPTIONS_ERROR,
  GET_ALL_USER_SUBSCRIPTIONS_ERROR,
  STILL_ACTIVE_SUBSCRIPTION_IN_CLEENG,
  STILL_ACTIVE_SUBSCRIPTION_IN_STORE,
} from '../../constants/errorCodes';

import PlusPaywallScreen from '../../components/TCPlus/PlusPaywallScreen/PlusPaywallScreen';
import SuccessfulPaymentScreen from '../../components/TCPlus/SuccessfulPaymentScreen/SuccessfulPaymentScreen';
import TCSubscriptionForm, { UserMetadata } from '../../components/TCPlus/TCSubscriptionForm/TCSubscriptionForm';
import ResetPassword from '../../components/TCPlus/ResetPassword/ResetPassword';
import Spinner from '../../components/Spinner/Spinner';
import SignUpAgreementScreen from '../../components/TCPlus/SignUpAgreementScreen/SignUpAgreementScreen';
import OTTSubscribe from '../../components/TCPlus/MarketingPage/MarketingPage';
import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';

import './TCPlusFlow.scss';

interface ITCPlusFlowProps {
  successCallback: () => void;
  goBackFromPlayback: () => void;
  location?: {
    state: {
      lastData: {
        page: string;
        isFlowFromSettings: boolean;
      }
    }
  };
  isFlowFromPlayback?: boolean;
  uuid?: string;
}

interface ITCFlowPage {
  pageType: string;
  defaultFocusedEl?: string;
}

const TCPlusFlow = (props: ITCPlusFlowProps) => {
  const {
    location,
    successCallback,
    isFlowFromPlayback = false,
    goBackFromPlayback,
    uuid,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const isTCUserLogin = useSelector((state: RootState) => state.Login.isUserTCLogin);
  const subscriptionOptions = useSelector((state: RootState) => state.config.subscription['subscriptionOptions']);
  const isCurrentSubscriptionActive = useSelector(
    (state: RootState) => state.cleengService.isCurrentSubscriptionActive
  );
  const purchaseOptions = useSelector((state: RootState) => state.cleengService.purchaseOptions);
  const isPurchaseDataFetched = useSelector((state: RootState) => state.cleengService.isFetched);
  const isDataRefreshed = useSelector((state: RootState) => state.cleengService.isDataRefreshed);
  const signInOrUpError = useSelector((state: RootState) => state.Login.subscription.signInOrUpError);
  const isSubscriptionError = useSelector((state: RootState) => state.Login.hasError);
  const subscriptionError = useSelector((state: RootState) => state.Login.error);
  const isCleengError = useSelector((state: RootState) => state.cleengService.hasError);
  const errorFromCleeng = useSelector((state: RootState) => state.cleengService.error);
  const isResetPasswordSuccess = useSelector((state: RootState) => state.Login.subscription.isResetPasswordSuccess);
  const resetPasswordErrorDetails = useSelector((state: RootState) => state.Login.subscription.isResetPasswordError);
  const newsletterState: boolean = useSelector(
    (state: RootState) => state.config.subscription['newsletter_checkbox_options']?.['selected'] || false
  );
  const tosState: boolean = useSelector(
    (state: RootState) => state.config.subscription['tos_checkbox_options']?.['selected'] || false
  );

  const [pageHistory, updatePageHistory] = useState<Array<ITCFlowPage>>([{
    pageType: location?.state?.lastData?.page || PLUS_PAYWALL_SCREEN,
    defaultFocusedEl: '',
  }]);
  const [selectedProductSKU, setSelectedProductSKU] = useState<string>('');
  const [isFlowFromCreateAccount, setFlowFromCreateAccount] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [purchaseError, setPurchaseError] = useState<string>('');
  const [userMetadata, setUserMetadata] = useState<UserMetadata>({
    newsletterState,
    tosState,
  });

  const purchaseService = useRef<any>(null);
  const isFlowFromSettings = useRef<boolean>(location?.state?.lastData?.isFlowFromSettings || false);
  const isSubscriptionDetailsError = useRef<boolean>(false);
  const shouldCleanUserMetadata = useRef<boolean>(true);

  const availableSubscriptions = useMemo(() => {
    const availableSubsDetails: any = [];

    purchaseOptions?.forEach(purchase => {
      subscriptionOptions.forEach(sub => {
        if (sub.id === purchase.id) {
          availableSubsDetails.push({...purchase, ...sub});
        }
      });
    });

    return availableSubsDetails;
  }, [purchaseOptions, subscriptionOptions]);

  const checkPurchaseStatus = (): void => {
    dispatch(getPurchaseData(true));
  };

  const analyticsValue = (): string => {
    return uuid ? VIDEO : SETTINGS;
  };

  const changeTCFlowPage = (
    newPageHistory: ITCFlowPage[],
    isDefaultFocusedElChanged: boolean = true,
    focusedElement: string = '',
  ): void => {
    if (isDefaultFocusedElChanged) {
      (pageHistory[pageHistory.length - 1].defaultFocusedEl = focusedElement || document.activeElement?.id);
    }
    updatePageHistory(newPageHistory);
  };

  const updateFocusedElInHistory = (el?: string) => {
    changeTCFlowPage(pageHistory, true, el);
  };

  const buyItemSuccessCb = (): void => {
    changeTCFlowPage([{ pageType: SUCCESS_PAYMENT }]);
  };

  const setCurrPageType = (page: string): void => {
    if (pageHistory[pageHistory.length - 1].pageType !== page) {
      changeTCFlowPage([...pageHistory, { pageType: page }]);
    }
  };

  const buySubscription = (selectedProductId: string): void => {
    console.log('===== buySubscription selectedProductId', selectedProductId);

    setSelectedProductSKU(selectedProductId);
    setIsLoading(true);
    purchaseService.current?.getSubscriptionStatusFromDevice(
      selectedProductId,
      setPurchaseError,
    ).then((subscriptionStatusFromDevice) => {
      if (isTCUserLogin && !subscriptionStatusFromDevice) {
        if (isCurrentSubscriptionActive) {
          setIsLoading(false);
          // when cleeng offer status true
          setPurchaseError(STILL_ACTIVE_SUBSCRIPTION_IN_CLEENG);
        } else if (!isCurrentSubscriptionActive && subscriptionStatusFromDevice === false) {
          dispatch(refreshUserToken());
          purchaseService.current?.buyItem(
            selectedProductId,
            buyItemSuccessCb,
            setPurchaseError,
            setIsLoading,
            checkPurchaseStatus,
            handleBack,
            analyticsValue,
          );
        }
      } else if (subscriptionStatusFromDevice) {
        setIsLoading(false);
        setPurchaseError(STILL_ACTIVE_SUBSCRIPTION_IN_STORE);
      } else if (subscriptionStatusFromDevice === false && !isTCUserLogin) {
        setIsLoading(false);
        setCurrPageType(SUBSCRIBE);
      }
    });
  };

  const handleBack = useCallback(() => {
    const pageType = pageHistory[pageHistory.length - 1]?.pageType;
    const previousPageType = pageHistory[pageHistory.length - 2]?.pageType;

    if (pageHistory[pageHistory.length - 1]?.pageType === SIGN_IN) {
      GAEvent(SUBSCRIPTION, ABANDON, analyticsValue());
    }

    if (pageType === TERMS_OF_USE_SCREEN || pageType === PRIVACY_POLICY_SCREEN) {
      shouldCleanUserMetadata.current = false;
    }

    if (pageHistory.length - 1
      && pageType !== SUCCESS_PAYMENT
      && (!isTCUserLogin
        || (isTCUserLogin &&
          !(previousPageType === SUBSCRIBE || previousPageType === SIGN_IN) &&
          pageType !== RESET_PASSWORD &&
          !isSubscriptionDetailsError.current
        )
        || pageType === RESET_PASSWORD && isTCUserLogin && !isResetPasswordSuccess
        || isSubscriptionDetailsError.current && !isFlowFromSettings.current && !isFlowFromPlayback
      )
    ) {

      changeTCFlowPage(pageHistory.slice(0, -1), false);
      // case for Marketing page as first level page
    } else if (pageType === OTTSubscribeType) {
      backHandlerForFirstLevelPage(history, true, clearTCPlusData);
    } else {
      clearTCPlusData();
      isFlowFromPlayback
        ? goBackFromPlayback()
        : navigateBack(history);
    }
  }, [history, pageHistory.length, updatePageHistory, isResetPasswordSuccess, purchaseError, isTCUserLogin]);

  const signOutFromTCPlus = (): void => {
    GAEvent(SUBSCRIPTION, LOG_OUT, SETTINGS); // TODO: Shall we set here My Account?
    dispatch(logoutPlus());
    isFlowFromSettings.current ? goToAccountPage() : goToHomePage();
  };

  const handleErrorBtn = (): void => {
    handleBack();
    dispatch(clearError());
    if (purchaseError) {
      // log out user when we get error retrieving his subscription info
      if (isSubscriptionDetailsError.current) {
        dispatch(logoutPlus());
      }
      setPurchaseError('');
      isSubscriptionDetailsError.current = false;
    }
  };

  const goToSpecificNavItemCb = (navIndex: number): void => {
    dispatch({ type: SET_ACTIVE_NAV, navIdx: navIndex });
    clearTCPlusData();
  };

  const goToHomePage = (): void => goToSpecificNavItem(OTTPageType, history, goToSpecificNavItemCb);

  const goToAccountPage = (): void => {
    return navigateBack(history);
  };

  const clearTCPlusData = (): void => {
    updatePageHistory([]);
  };

  const clearUserMetadata = (): void => {
    setUserMetadata({
      newsletterState,
      tosState,
    });
  };

  useEffect(() => {
    const pageType = pageHistory[pageHistory.length - 1]?.pageType;

    if ((pageType === SUBSCRIBE || pageType === SIGN_IN) && shouldCleanUserMetadata.current) {
      dispatch(clearError());
      clearUserMetadata();
    } else {
      shouldCleanUserMetadata.current = true;
    }
  }, [pageHistory[pageHistory.length - 1]?.pageType]);

  useAnnouncePageLoading(isLoading);

  useEffect(() => {
    purchaseService.current = currentPlatform?.getPurchaseService();
    dispatch(getAgreementText());
    GAScreen(SUBSCRIBE_TO_PLUS);

    return () => {
      stopTTS();
      dispatch(clearError());
      setIsLoading(false);
      dispatch({ type: RESET_REFRESH_DATA_STATE });
    };
  }, []); // eslint-disable-line

  useLayoutEffect(() => {
    if (isPurchaseDataFetched) {
      if (isTCUserLogin && isCurrentSubscriptionActive && pageHistory[pageHistory.length - 1]?.pageType === SIGN_IN) {
        isFlowFromPlayback ? successCallback() : isFlowFromSettings.current ? goToAccountPage() : goToHomePage();
      } else if (
        isTCUserLogin &&
        !isCurrentSubscriptionActive &&
        pageHistory[pageHistory.length - 1]?.pageType !== OTTSubscribeType
      ) {
        if (isFlowFromCreateAccount) {
          buySubscription(selectedProductSKU);
        } else {
          isDataRefreshed && setIsLoading(false);
          if (pageHistory[pageHistory.length - 1]?.pageType !== PLUS_PAYWALL_SCREEN) {
            changeTCFlowPage([
              ...!isFlowFromSettings.current && !isFlowFromPlayback
                ? [pageHistory[0]]
                : [],
              { pageType: PLUS_PAYWALL_SCREEN },
            ]);
          }
        }
      } else if (isDataRefreshed) {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [isTCUserLogin, isCurrentSubscriptionActive, isPurchaseDataFetched, signInOrUpError, isFlowFromCreateAccount]);

  useEffect(() => {
    if (isTCUserLogin) {
      if (pageHistory[pageHistory.length - 1]?.pageType === SIGN_IN) {
        GAEvent(SUBSCRIPTION, LOGIN, analyticsValue());
      } else if (pageHistory[pageHistory.length - 1]?.pageType === SUBSCRIBE) {
        GAEvent(CREATE_ACCOUNT, SUCCESS, analyticsValue());
      }
    }
  }, [isTCUserLogin]);

  useEffect(() => {
    dispatch(getPurchaseData(true));
  }, [isTCUserLogin]);

  useEffect(() => {
    const pageType = pageHistory[pageHistory.length - 1]?.pageType;

    if (isTCUserLogin && pageType === SUBSCRIBE) {
      setFlowFromCreateAccount(true);
    }
  }, [isTCUserLogin]);

  useEffect(() => {
    if ((isCleengError || isSubscriptionError) && !signInOrUpError) {
      const currentError = subscriptionError?.errorCode || errorFromCleeng?.errorCode || DEFAULT_CLEENG_ERROR;

      setPurchaseError(currentError);
      setIsLoading(false);
      isSubscriptionDetailsError.current = currentError === GET_ACTIVE_USER_SUBSCRIPTIONS_ERROR
        || currentError === GET_ALL_USER_SUBSCRIPTIONS_ERROR;
    }
  }, [signInOrUpError, isSubscriptionError, resetPasswordErrorDetails, isCleengError]);

  useEventListener('keydown', (event) => {
    event.stopImmediatePropagation();
    if (document.querySelector('input:focus')) {
      return null; // cancel goBack to previous page when we need to delete sms from input
    } else {
      onBackHandler(event, handleBack, history);
    }
  });

  const renderCurrentPage = (page) => {

    switch (page) {
      case SUBSCRIBE:
      case SIGN_IN:
        return (<TCSubscriptionForm
          setCurrPageType={setCurrPageType}
          setFlowFromCreateAccount={setFlowFromCreateAccount}
          goBack={handleBack}
          pageType={pageHistory[pageHistory.length - 1]?.pageType}
          analyticsValue={analyticsValue}
          initFocusedEl={pageHistory[pageHistory.length - 1]?.defaultFocusedEl || ''}
          updateFocusedElInHistory={updateFocusedElInHistory}
          userMetadata={userMetadata}
          setUserMetadata={setUserMetadata}
          setIsLoading={setIsLoading}
        />);
      case RESET_PASSWORD:
        return <ResetPassword
          goBack={handleBack}
          analyticsValue={analyticsValue}
        />;
      case SUCCESS_PAYMENT:
        return <SuccessfulPaymentScreen
          watchNowHandler={successCallback}
          exploreTCPlusHandler={isFlowFromSettings.current ? goToAccountPage : goToHomePage}
          isFlowFromPlayback={isFlowFromPlayback}
        />;
      case TERMS_OF_USE_SCREEN:
      case PRIVACY_POLICY_SCREEN:
        return <SignUpAgreementScreen
          pageType={pageHistory[pageHistory.length - 1]?.pageType}
          goBack={handleBack}
        />;
      case OTTSubscribeType:
        return <OTTSubscribe
          initFocusedEl={pageHistory[pageHistory.length - 1]?.defaultFocusedEl || ''}
          setCurrPageType={setCurrPageType}
          signOutFromTCPlus={signOutFromTCPlus}
        />;
      default:
        return <PlusPaywallScreen
          goBack={handleBack}
          signOutFromTCPlus={signOutFromTCPlus}
          isFlowFromPlayback={isFlowFromPlayback}
          availableSubscriptions={availableSubscriptions}
          isFlowFromCreateAccount={isFlowFromCreateAccount}
          buySubscription={buySubscription}
          initFocusedEl={pageHistory[pageHistory.length - 1]?.defaultFocusedEl || ''}
          goToHomePage={goToHomePage}
          setCurrPageType={setCurrPageType}
          updateFocusedElInHistory={updateFocusedElInHistory}
        />;
    }
  };

  return (
    <>
      {isLoading
        ? (<div className='tc-plus-spinner-wrapper'>
          <Spinner />
        </div>)
        : (<>
          {purchaseError
            ? <ErrorModal error={{ errorCode: purchaseError }} onBackHandlerCb={handleErrorBtn} />
            : renderCurrentPage(pageHistory[pageHistory.length - 1]?.pageType)}
        </>)}
    </>
  );
};

export default TCPlusFlow;
