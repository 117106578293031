import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import FocusService from '../../../services/focusService';
import { convertIntToTimeStringForTTS, getDurationString, speakTTS } from '../../../services/TTSService';
import { AddMidrollBreaks } from '../../../stores/actions/OTTEpisode';
import { RootState } from '../../../stores/reducers';
import { ReactImageTint } from 'react-image-tint';
import { getTime } from '../../../utils/time';

import Play from '../../../assets/icons/PlayButton-idle.svg';
import PlaySelected from '../../../assets/icons/PlayButton-selected.svg';
import Pause from '../../../assets/icons/PauseButton-idle.svg';
import PauseSelected from '../../../assets/icons/PauseButton-selected.svg';
import Backward from '../../../assets/icons/Backward.png';
import Forward from '../../../assets/icons/Forward.png';

import './Controls.scss';

type ControlsProps = {
  paused: boolean;
  currentTime: number;
  duration: number;
  isTimeVisible: boolean;
  isRecommendedPlaybackAvailable: boolean;
  midrollBreaks: AddMidrollBreaks;
  visible: boolean;
  seekSpeed: number;
  onPlayPause: (event: React.KeyboardEvent) => void;
  onSeek: (event: React.KeyboardEvent) => void;
  onKeyUp: (event: React.KeyboardEvent) => void;
  isConnectedToNetwork: boolean;
  isOverlayInfoAnnounced: boolean;
  isRecommendedPlaybackVisible: boolean;
  isSeeked: boolean;
}

const Controls = (props: ControlsProps) => {
  const {
    paused,
    currentTime = 0,
    duration = 0,
    isTimeVisible = true,
    isRecommendedPlaybackAvailable = false,
    midrollBreaks,
    visible,
    seekSpeed,
    onPlayPause,
    onSeek,
    onKeyUp,
    isConnectedToNetwork,
    isOverlayInfoAnnounced,
    isRecommendedPlaybackVisible,
    isSeeked,
  } = props;

  const playerStyles = useSelector((state: RootState) => state.config.styles['player']);

  const playPauseRef = useRef(null);

  const [isButtonShortAnnounced, setIsButtonShortAnnounced] = useState<boolean>(false);

  useEffect(() => {
    if (!isRecommendedPlaybackVisible) {
      const silentElem = document.querySelector('#appTitle');

      FocusService.setFocus(visible ? playPauseRef.current : silentElem);
    }
  }, [visible, isRecommendedPlaybackVisible]);

  const currentTimeFormatted = getTime(currentTime);
  const durationFormatted = getTime(duration);

  const playPauseButtonTextForTTS = isOverlayInfoAnnounced
    ? `${paused ? 'play' : 'pause'} button, button 1 of 1, press to ${paused ? 'resume' : 'pause'} on
      ${convertIntToTimeStringForTTS(currentTime)} ${getDurationString(duration)}`
    : '';

  const getButtonTextForTTS = () => {
    return isButtonShortAnnounced ? 'play button, press to play' : playPauseButtonTextForTTS;
  };

  const progressBarTextForTTS = `Progress bar, position ${convertIntToTimeStringForTTS(currentTime)}
    ${getDurationString(duration)}`;

  const onProgressBarFocusHandler = (e) => {
    speakTTS(progressBarTextForTTS);

    const progressCircle = e.target.querySelector('.progress-circle');

    if (progressCircle) {
      progressCircle.style.background = playerStyles['scrubberBarColor'];
    }
  };

  useEffect(() => {
    if (Math.abs(seekSpeed) === 0 && isButtonShortAnnounced) {
      setIsButtonShortAnnounced(false);
    } else if (Math.abs(seekSpeed) !== 0 && !isButtonShortAnnounced) {
      setIsButtonShortAnnounced(true);
    }
    // eslint-disable-next-line
  }, [seekSpeed]);

  const onProgressBarBlurHandler = (e) => {
    const progressCircle = e.target.querySelector('.progress-circle');

    if (progressCircle) {
      progressCircle.style.background = 'rgba(128, 128, 128, 1)';
    }
  };

  const alreadyPlayed = `${(currentTime / duration) * 100}%`;

  let visibleRecommendedPlayback = false;

  if (isRecommendedPlaybackAvailable) {
    const timeLeft = Math.round((duration - currentTime) / 1000);

    visibleRecommendedPlayback = duration ? timeLeft <= 10 : false;
  }

  return (
    <div className='video-player-controls' style={{ display: visible && isConnectedToNetwork ? 'block' : 'none' }}>
      <div style={{ opacity: seekSpeed ? 1 : 0, transition: '0.3s ease' }}>
        <div className='seek-speed'>x{seekSpeed}</div>
        <img
          aria-hidden={true}
          src={Backward}
          alt='Backward'
          className={`fast-seek-status ${seekSpeed < 0 && 'fast-seek-status-active'}`}
        />
        <img
          aria-hidden={true}
          src={Forward}
          alt='Forward'
          className={`fast-seek-status ${seekSpeed > 0 && 'fast-seek-status-active'}`}
        />
      </div>
      <div className='background-blur'></div>
      <div className='controls-container'>
        <div className='control-buttons-container'>
          <div className='play-pause-wrapper'>
            <div
              ref={playPauseRef}
              tabIndex={visible && isConnectedToNetwork ? 0 : -1}
              className='play-pause-container'
              onKeyDown={onPlayPause}
              data-tv-focus-up={'.play-pause-container'}
              data-tv-focus-right={
                `${
                  (isRecommendedPlaybackAvailable && visibleRecommendedPlayback) ?
                    '.recommended-playback-container' :
                    '.play-pause-container'
                }`
              }
              data-tv-focus-down='.progress-bar-container'
              data-tv-focus-left='.play-pause-container'
              aria-label={!seekSpeed ? playPauseButtonTextForTTS : ''}
              onFocus={() => isSeeked && speakTTS(getButtonTextForTTS())}
              // eslint-disable-next-line
              role={''}
            >
              <img
                aria-hidden={true}
                src={Play}
                alt='Play'
                className={`play-pause ${paused && 'play'}`}
                role={''}
              />
              <div
                // eslint-disable-next-line
                role=''
                className={`play-pause ${paused && 'play--focused'}`}
              >
                <ReactImageTint src={PlaySelected} color={playerStyles['scrubberBarColor']} />
              </div>
              <img
                aria-hidden={true}
                src={Pause}
                alt='Pause'
                className={`play-pause ${!paused && 'pause'}`}
                role={''}
              />
              <div
                // eslint-disable-next-line
                role=''
                className={`play-pause ${!paused && 'pause--focused'}`}
              >
                <ReactImageTint src={PauseSelected} color={playerStyles['scrubberBarColor']} />
              </div>
            </div>
          </div>
        </div>
        <div className='progress-info-container'>
          <div className='progress-time'>
            {isTimeVisible && currentTimeFormatted}
          </div>

          <div
            className='progress-bar-container'
            tabIndex={visible && isConnectedToNetwork ? 0 : -1}
            onKeyDown={onSeek}
            onKeyUp={onKeyUp}
            data-tv-focus-up='.play-pause-container'
            data-tv-focus-right='.progress-bar-container'
            data-tv-focus-down='.progress-bar-container'
            data-tv-focus-left='.progress-bar-container'
            onFocus={onProgressBarFocusHandler}
            onBlur={onProgressBarBlurHandler}
            aria-label=''
            // eslint-disable-next-line
            role={''}
          >
            <div className='progress-line-container'>
              <div className='progress-circle' style={{ left: alreadyPlayed }}></div>
              {midrollBreaks.map((timeBreak: number, index: number) => {
                return (
                  <div
                    className='progress-ad-break'
                    key={`${timeBreak}-${index}`}
                    style={{ left: `${(timeBreak / duration) * 100}%` }}
                  ></div>
                );
              })}
              <div
                className='progress-line'
                style={{ width: alreadyPlayed, backgroundColor: playerStyles['scrubberBarColor'] }}
              ></div>
            </div>
          </div>

          <div className='progress-time'>
            {isTimeVisible && durationFormatted}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Controls;
