import { combineReducers } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';

import config from './config';
import OTTPage from './OTTPage';
import OTTEpisode from './OTTEpisode';
import OTTFeed from './OTTFeed';
import OTTChannelGuide from './OTTChannelGuide';
import OTTSearch from './OTTSearch';
import OTTSettings from './OTTSettings';
import OTTText from './OTTText';
import common from './common';
import liveramp from './liverampCollector';
import Login from './Login';
import cleengService from './cleengService';
import linearTelemetry from './linearTelemetry';
import player from './player';
import titleLevelReport from './titleLevelReport';

export const history = createBrowserHistory();

export const rootReducer = combineReducers({
  router: connectRouter(history),
  config,
  OTTPage,
  OTTEpisode,
  OTTFeed,
  OTTChannelGuide,
  OTTSearch,
  OTTSettings,
  OTTText,
  player,
  Login,
  common,
  liveramp,
  linearTelemetry,
  titleLevelReport,
  cleengService,
});

export type RootState = ReturnType<typeof rootReducer>;
