import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearResetPassword, logoutPlus, resetPassword } from '../../../stores/actions/Login';
import { RootState } from '../../../stores/reducers';
import { BACK_BTN_TEXT, RESET_PASSWORD } from '../../../constants/text';
import { GAEvent } from '../../../services/analytics';
import { LOG_OUT, PW_RESET, PW_RESET_FAIL, SETTINGS, SUBSCRIPTION } from '../../../constants/analyticsTypes';
import { handleTSSMoveFocusToFirstEl } from '../../../utils/commonFunc';

import Button from '../../Button/Button';
import Modal from '../../Modals/Modal';
import Input from '../../Input/Input';
import backArrowIcon from '../../../assets/icons/GoBackArrow.svg';
import EmailIcon from './../../../assets/Tennis_channel/icons/email_icon.svg';

import './ResetPassword.scss';

interface PasswordResetScreenProps {
  goToResetPassword?: boolean;
  analyticsValue: () => string;
  goBack: () => void;
}

const ResetPassword = ({
  goToResetPassword = false,
  analyticsValue,
  goBack,
}: PasswordResetScreenProps) => {
  const [emailState, setEmailState] = useState<string>('');
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const dispatch = useDispatch();

  const resetPasswordTitle = useSelector((state: RootState) => state.config.subscription['reset_password_title']);
  const resetPasswordText = useSelector((state: RootState) => state.config.subscription['reset_password_detail']);
  const resetPasswordSuccessTitle = useSelector(
    (state: RootState) => state.config.subscription['reset_password_success_title']
  );
  const resetPasswordSuccessDetails = useSelector(
    (state: RootState) => state.config.subscription['reset_password_success_detail']
  );
  const logoBrandPlus = useSelector((state: RootState) => state.config.styles?.['general']?.['logoPlus']);
  const signInBgImg = useSelector((state: RootState) => state.config.styles?.account?.resetPasswordImageFull);
  const isResetPasswordSuccess = useSelector((state: RootState) => state.Login.subscription.isResetPasswordSuccess);
  const isResetPasswordError = useSelector((state: RootState) => state.Login.subscription.isResetPasswordError);
  const TTSStatus = useSelector((state: RootState) => state.common.TTSEnabled);
  const titleFont = useSelector((state: RootState) => state.config.styles.general?.heading?.titleFont);

  const isBtnDisabled = useMemo(() => !emailState, [emailState]);

  const resetPasswordBtnHandler = (e) => {
    e.preventDefault();
    if (emailState && !isBtnDisabled) {
      dispatch(resetPassword(emailState));
    }
  };

  useEffect(() => {
    const resetPasswordInput = document.querySelector('.input-wrapper-acc-0');
    const phraseForTTS = `${resetPasswordTitle}. ${resetPasswordText}`;

    handleTSSMoveFocusToFirstEl(TTSStatus, phraseForTTS, resetPasswordInput);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isResetPasswordSuccess) {
      setShowSuccessModal(true);
      GAEvent(SETTINGS, PW_RESET, analyticsValue());
    } else {
      if (isResetPasswordError) {
        GAEvent(SETTINGS, PW_RESET_FAIL, analyticsValue());
      }
    }

    return () => {
      if (isResetPasswordSuccess) {
        dispatch(clearResetPassword());
        dispatch(logoutPlus());
        GAEvent(SUBSCRIPTION, LOG_OUT, SETTINGS);
      }
    };
    // eslint-disable-next-line
  }, [isResetPasswordSuccess, isResetPasswordError]);

  return (
    <div className='reset-password-screen'>
      {showSuccessModal ? (
        <Modal
          title={resetPasswordSuccessTitle}
          description={resetPasswordSuccessDetails}
          buttons={[{id: 'modal-go-back-button', text: 'Go back', onPress: goBack}]}
        />
      ) : (
        <div
          // eslint-disable-next-line
          role=''
          className='reset-password-wrapper'
          style={{backgroundImage: 'url(' + signInBgImg + ')'}}
        >
          {(goToResetPassword && !logoBrandPlus) ? null : (
            <img
              src={logoBrandPlus}
              className='logo' alt='Tennis Channel Subscribe Logo' />
          )}
          <h2
            className='reset-password-title'
            style={{ fontFamily: titleFont }}
            // eslint-disable-next-line
            role=''
          >
            {resetPasswordTitle}
          </h2>
          <div
            // eslint-disable-next-line
            role=''
            className='info-text'
          >
            {resetPasswordText}
          </div>
          <Input
            type={'email'}
            value={emailState}
            onChangeCb={setEmailState}
            placeholder='Enter email'
            ariaLabel={'Enter email'}
            index={0}
            dataTvFocusUp={'.input-wrapper-acc-0'}
            dataTvFocusRight={'.input-wrapper-acc-0'}
            dataTvFocusDown={isBtnDisabled ? '.btn-acc-1' : '.btn-acc-0'}
            dataTvFocusLeft={'.input-wrapper-acc-0'}
            hasValueToBeTrimmed={false}
            styleWrapper={{
              fontStyle: 'italic',
            }}
          >
            <img
              className='email-icon'
              src={EmailIcon}
              alt=""
            />
          </Input>
          <Button
            title={RESET_PASSWORD}
            index={0}
            disabled={isBtnDisabled}
            focusUp='.input-wrapper-acc-0'
            focusDown='.btn-acc-1'
            focusLeft='.btn-acc-0'
            focusRight='.btn-acc-0'
            onKeyDown={resetPasswordBtnHandler}
          />
          <Button
            title={BACK_BTN_TEXT}
            index={1}
            focusUp={isBtnDisabled ? '.input-wrapper-acc-0' : '.btn-acc-0'}
            focusDown='.btn-acc-1'
            focusLeft='.btn-acc-1'
            focusRight='.btn-acc-1'
            onKeyDown={goBack}
          >
            {<img src={backArrowIcon} alt='back arrow' style={{marginRight: '16px'}}/>}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
